import React from 'react';
import { connect } from 'react-redux';

import history from '../history';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import queryString from 'query-string';

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';

import LoginAuth from '../components/Login/LoginAuth.jsx';
import GoogleAuth from '../components/Login/GoogleAuth.jsx';
import FacebookAuth from '../components/Login/FacebookAuth.jsx';
import SignUpAuth from '../components/Login/SignUpAuth.jsx';

import { setCurrentPage } from '../actions/';

const stylesTabs = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'rgba(0,0,0,0.4)',
    marginTop: '20px',
  },
  tabsRoot: {    
  	backgroundColor: 'rgba(0,0,0,0.7)',
  },
  tabsIndicator: {
    backgroundColor: 'rgba(0,0,0,0.0)',
  },
  tabRoot: {
    textTransform: 'initial',
    width: '50%',
    fontWeight: theme.typography.fontWeightRegular, 
    color: '#fff',     
    '&:hover': {      
      opacity: 1,
      backgroundColor: 'rgba(0,0,0,0.4)',
    },
    '&$tabSelected': {
      color: '#fff',
      borderBottom: 0,      
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: 'rgba(0,0,0,0.8)',
    },
    '&:focus': {
      
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
});



const DialogActions = withStyles(theme => ({
	root: {
    	justifyContent: "center",
	},
}))(MuiDialogActions);


class LoginCampaignView extends React.Component {

	constructor(props) {

		super(props);	

		if(this.props.location.search == ''){
			//no tenim parametres
			this.state = {
				brandName: 'default',
				openAlert: false,
				tabValue: "entrar",
				idLanding: {
								citroen: "/campaigns/c3-aircross-mas-versatilidad-32", 
								mercedes: "30",
								smart: "/campaigns/smart-days-39",
								lexus: "/campaigns/dias-de-lexus-36",
								bmw: "/campaigns/liquidacion-bmw-37",
								audi: "/campaigns/motorprive-audi-event-38",
								peugeot: "",
								fiat: ""
							}
			};
			
		}else{
			//tenim parametres
			const parametres = queryString.parse(this.props.location.search);				
			

			if(parametres.b){
				this.state = {
					brandName: parametres.b,
					openAlert: false,
					tabValue: "entrar",
					idLanding: {
									citroen: "/campaigns/c3-aircross-mas-versatilidad-32", 
									mercedes: "30"
								}
				};
				
			}else{
				this.state = {
					brandName: 'default',
					openAlert: false,
					tabValue: "entrar",
					idLanding: {	
									citroen: "/campaigns/c3-aircross-mas-versatilidad-32", 
									mercedes: "30"
								}
				};
			}
		}

		this.props.setCurrentPage('login');
	}

	componentDidUpdate(prevProps) {

    	if(this.props.isSignedIn){
    		if(this.state.brandName == 'default'){
    			history.push('/campaigns'); 
    		}else{
    			history.push(this.state.idLanding[this.state.brandName]);    			
    		}    		   		  		
    	}
    	
    }

    handleChange = (event, value) => {
    	this.setState({ tabValue: value });
  	};

  	changeToInscribirse = () => {
  		this.setState({tabValue: 'inscribirse'});
  	}

  	handleCloseAlert = () => {
    	this.setState({ openAlert: false });
  	};


	render(){

		const { classes } = this.props;
		
		const fondo = "https://media.motorprive.com/proves/prova-"+this.state.brandName+".jpg";


		if(this.props.isSignedIn === null){
			return (<div className="template-loading"><CircularProgress color="secondary" /></div>);
		}

		if(this.props.isSignedIn){
		
			return (<div></div>);
		
		}else{

			return(
				<div className="template-login-campaign template-login-page">
					
					<div className="container-bg" style={{ backgroundImage: `url(${fondo})` }}>	
					</div>
					<div>				
								
						<Grid container style={{flexGrow: 1}}  justify="center">
							<Grid item xs={12} sm={12} md={10} lg={8} >
								<Grid container alignItems="center" justify="center">
									<Grid item xs={12} sm={12} md={6} >
										<div className="container-block-login" >
											<div className={classes.root} >
									        	<Tabs
									            	value={this.state.tabValue}
										            onChange={this.handleChange}
										            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}						            
										        >
									            	<Tab value="entrar" label={i18n.t('login.login')} classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
									            	<Tab value="inscribirse" label={i18n.t('login.signin')} classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
									          </Tabs>
										    </div>
										    {this.state.tabValue === 'entrar' && 
										    	<TabContainer>
										    		<div className="login-box">
														<FacebookAuth btnText={i18n.t('login.loginfacebook')} />
														<GoogleAuth btnText={i18n.t('login.logingoogle')} />
														<div className="separator-with-text">{i18n.t('login.or')}</div>
														<LoginAuth />								
														<p className="link-login" onClick={this.changeToInscribirse}>{i18n.t('login.notyet')}</p>	
													</div>	
										    	</TabContainer>


											}
										    {this.state.tabValue === 'inscribirse' && 
										    	<TabContainer>
										    		<div className="login-box">
											    		<FacebookAuth btnText={i18n.t('login.siginfacebook')} />
														<GoogleAuth btnText={i18n.t('login.sigingoogle')} />
														<div className="separator-with-text">{i18n.t('login.or')}</div>
														<SignUpAuth nombre='' email='' tlf=''  />													
													</div>
										    	</TabContainer>
										    }



										</div>
									</Grid>
									{/*<Hidden mdDown>
										<Grid item xs={12} sm={12} md={6}  >
											<div className="container-block-login" style={{ maxWidth: '500px' }} >
												<img style={{ width: '100%' }} src={"https://media.motorprive.com/proves/prova-"+this.state.brandName+".jpg"} />												
											</div>
										</Grid>
									</Hidden>
								*/}
								</Grid>
								
							</Grid>
						</Grid>
					</div>



				</div>
			);

		}

		

	}

}

const TabContainer = ({ children }) => {
  return (
    <Typography component="div" >
      {children}
    </Typography>
  );
};


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		userName: state.auth.userName
	};
};

LoginCampaignView = withStyles(stylesTabs)(LoginCampaignView);

LoginCampaignView = connect(
	mapStateToProps, { setCurrentPage }	
)(withNamespaces()(LoginCampaignView));

export default LoginCampaignView;



