import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { signIn, signOut } from '../actions/';

//import firebase, { authFirebase } from '../components/Firebase/Firebase.js';

import ChangePwdAuth from '../components/Login/ChangePwdAuth.jsx';


class ChangePwdView extends React.Component {

	componentDidMount() {

		/*authFirebase.onAuthStateChanged((user) => {
		  	if (user) {
		    	// User is signed in.
		    	console.log("usuari logat");
		    	console.log(user);
		    	this.props.signIn(user.uid, user.email, user.displayName);
		  	} else {
		   		// No user is signed in.
		   		console.log("usuari no logat");
		  	}
		});*/

	}

	renderContent(){
		if(this.props.isSignedIn === null){
			return ( <p>Loading...</p>);
		}else if(this.props.isSignedIn === true){
				return ( <ChangePwdAuth  />);
		}else{
				return (
					<div>
						No puedes acceder si no estás logado -->
						<Link to="/">Logarse</Link>
					</div>
				); 
		}
		
	}

	render(){
		return(
			<div>
				Change password				
				{this.renderContent()}
			</div>
		);
	}

}


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn, 
		userId: state.auth.userId, 
		userMail: state.auth.userMail , 
		userName: state.auth.userName 
	};
};

export default connect(
	mapStateToProps,
	{ signIn, signOut }
)(ChangePwdView);



