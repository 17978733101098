import React from 'react';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';


import CircularProgress from '@material-ui/core/CircularProgress';


class CountDown extends React.Component{

	constructor(props) {
		super(props);
		
		this.state = {	      
	      	currentDate: props.dataFinal,	      	
	      	days: -1,
      		hours: -1,
      		min: -1,
      		sec: -1,
      		finish: false	      
	    };

	}

	componentDidMount() {
	    // update every second
	    this.interval = setInterval(() => {
	    	const date = this.calculateCountdown(this.state.currentDate);
	      	date ? this.setState(date) : this.stop();
	    }, 1000);
	}

	componentWillUnmount() {
		this.stop();
  	}

  	stop() {
    	clearInterval(this.interval);
    	this.setState({ finish: true });
  	}

  	addLeadingZeros(value) {
	    value = String(value);
	    while (value.length < 2) {
	      value = '0' + value;
	    }
	    return value;
  	}

  	renderCoundDown(){
  		
  		if(this.state.finish === true){
  			return( <div className="wrapper-container-countdown"><div className="container-countdown"><p className="closed-event-text">{i18n.t('countDown.closedEvent')}</p></div></div> );
  		}else{
  			if((this.state.min === -1)&&(this.state.hours === -1)&&(this.state.sec === -1)){
	  			return ( <div className="wrapper-container-countdown"><div className="container-countdown"><CircularProgress color="secondary" /></div></div> );
	  		}else{  			
	  			return(
	  				<div className="wrapper-container-countdown">
	  					<p className="text-countdown">{i18n.t('countDown.remains')}</p>
			  			<div className="container-countdown">
			  				<div className="container-countdown-element">
			  					<div className="countdown-element">
				              		<p className="number-countdown">{this.addLeadingZeros(this.state.days)}</p>
				              		<p className="time-element-countdown">{this.state.days === 1 ? i18n.t('countDown.day') : i18n.t('countDown.days')}</p>
				          		</div>
			  				</div>
			  				<div className="container-countdown-element">
			  					<div className="countdown-element">
				              		<p className="number-countdown">{this.addLeadingZeros(this.state.hours)}</p>
				              		<p className="time-element-countdown">{this.state.hours === 1 ? i18n.t('countDown.hour') : i18n.t('countDown.hours')}</p>
				          		</div>
			  				</div>
			  				<div className="container-countdown-element">
			  					<div className="countdown-element">
				              		<p className="number-countdown">{this.addLeadingZeros(this.state.min)}</p>
				              		<p className="time-element-countdown">{this.state.min === 1 ? i18n.t('countDown.minute') : i18n.t('countDown.minutes')}</p>
				          		</div>
			  				</div>
			  				<div className="container-countdown-element">
			  					<div className="countdown-element">
				              		<p className="number-countdown">{this.addLeadingZeros(this.state.sec)}</p>
				              		<p className="time-element-countdown">{i18n.t('countDown.seconds')}</p>
				          		</div>
			  				</div>	          		
			  			</div>
			  			<p className="text-countdown">{i18n.t('countDown.forEvent')}</p>
			  		</div>

		  		);
	  		} 
  		}


  		 		

  	}

  	calculateCountdown(endDate) {

  		let dataSeparada = endDate.split('-');
    	//let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;
    	let diff = (Date.parse(new Date(dataSeparada[2], dataSeparada[1]-1, dataSeparada[0])) - Date.parse(new Date())) / 1000;
    	


    	// clear countdown when date is reached
    	if (diff <= 0) return false;

	    const timeLeft = {
	      	years: 0,
	      	days: 0,
	      	hours: 0,
	      	min: 0,
	      	sec: 0,
	    	millisec: 0,
    	};

	    // calculate time difference between now and expected date
	    if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
	      timeLeft.years = Math.floor(diff / (365.25 * 86400));
	      diff -= timeLeft.years * 365.25 * 86400;
	    }
	    if (diff >= 86400) { // 24 * 60 * 60
	      timeLeft.days = Math.floor(diff / 86400);
	      diff -= timeLeft.days * 86400;
	    }
	    if (diff >= 3600) { // 60 * 60
	      timeLeft.hours = Math.floor(diff / 3600);
	      diff -= timeLeft.hours * 3600;
	    }
	    if (diff >= 60) {
	      timeLeft.min = Math.floor(diff / 60);
	      diff -= timeLeft.min * 60;
	    }
	    timeLeft.sec = diff;

    	return timeLeft;
  	}

	render(){
		return(
			<div>
				{this.renderCoundDown()}
			</div>
		);
	}

}

CountDown = withNamespaces()(CountDown);

export default CountDown;