import React from 'react';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import Grid from '@material-ui/core/Grid';


import history from '../history';

class NoAccessPage extends React.Component {

	moveToHome = () => {
		history.push('/');
	};

	render(){

		return(
			<div>
				<Grid container justify="center" alignItems="center">
					<Grid item xs={12} sm={12} md={8} lg={8}>
						<div className="container-access-forbidden">
							<div className="content-access-forbidden">
								<div className="container-big-letters">Uuups...</div>
								<div className="container-text-forbidden">
									<p className="text-forbidden">{i18n.t('global.accessForbidden')}</p>
								</div>
							</div>
							<button onClick={this.moveToHome} className="btn btn1 ">{i18n.t('global.homePage')}</button>								
						</div>
					</Grid>
				</Grid>	
			</div>
		);

	}
	

};

export default withNamespaces()(NoAccessPage);
