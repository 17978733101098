import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';

import history from '../history';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';


import { DialogTitle, DialogContent, DialogActions } from '../assets/styles/components/DialogFormStyle.jsx';


import { sendLeadCallMe } from '../actions/';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



class LlamamePopUp extends React.Component{

	constructor(props) {
		super(props);
		
		this.handleChangeInput = this.handleChangeInput.bind(this);
	    this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);

		this.state = {
			phoneNumber: '',
			textButton: props.textButton,
			textLabel: props.textLabel,			
			currentEvent: props.idEvent,
			privacity: false,
			errorText: false,
			errorPrivacity: false,
			errorMsgText: 'error',
			errorMsgPrivacity: i18n.t('errorForms.required'),
			phoneCallMe: '',
			thinking: false,
			sourceUrl: props.sourceUrl,
			openForm: false
		}

	}

	renderAcceptPrivacity = () => {
		return(
			<div className="inputContainerCheckbox"> 			
				<input id="privacity" name="privacity" type="checkbox" checked={this.state.privacity} value={this.state.privacity} onChange={(e) => this.handleChangeCheckBox(e)} /><label className="input-label-privacity" dangerouslySetInnerHTML={{__html: i18n.t('landing.acceptPrivacity')}}></label>
			</div>
		);
	};

	renderErrorPrivacity = () => {

		if(this.state.errorPrivacity === true){
			return(	
				<div>	
					<div className="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{this.state.errorMsgPrivacity}</div>
				</div>
			);
		}else{
			return(
				<div></div>
			);
		}

	};

	renderInput = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainer">
				<input {...input} autoComplete="off" value={initialValue} placeholder={i18n.t('landing.enter')+" "+label.toLowerCase()} type={type} disabled={disabled} />
				{this.renderError(meta)}
			</div>
		);
	};

	handleClickOpenBar = () => {
    	this.setState({ openForm: true });
    	window.gtag('event', 'btn-header-te-llamamos'); 
  	};

  	handleClose = () => {
	    this.setState({ openForm: false });
	};

	handleChangeInput(e){	
		const inputName = e.target.name;
		const inputValue = e.target.value;		
		var copiaState = Object.assign({}, this.state);
		copiaState[inputName] = inputValue;
		this.setState(copiaState);  		
	}

	handleChangeCheckBox(e){	

		var copiaState = Object.assign({}, this.state);
		if(e.target.value == "false"){
			copiaState[e.target.id] = true;
		}else{
			copiaState[e.target.id] = false;
		}
		this.setState(copiaState);
	}



	renderError({error, touched}){		
		if(touched && error){
			return(
				<div>	
					<div className="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{error}</div>
				</div>
			);
		}
	};

	renderLoader = () => {

		if(this.state.thinking === true){
			return(
				<div className="div-loader"><div className="container-loader"><CircularProgress color="secondary" /></div></div>
			);
		}else{
			return(
				<div></div>				
			);
		}
	}

	sendPhoneLead = (formValues) => {

		this.state.thinking = true;

		return sleep(1000).then(() => {
		    // simulate server latency
		    
		    if(!this.state.phoneCallMe){
		    	this.state.thinking = false;
		    	throw new SubmissionError({
		        	phoneCallMe: i18n.t('errorForms.required'),
		        	_error: 'failed!'
		     	 })
		    }else if(this.state.phoneCallMe && !/^\+?\d+$/.test(this.state.phoneCallMe) ){
		    	this.state.thinking = false;
		    	throw new SubmissionError({
		        	phoneCallMe: i18n.t('errorForms.invalidintegerformat'),
		        	_error: 'failed!'
		     	 })
		    }else if(this.state.privacity === false){
		    	this.state.thinking = false;
		    	this.setState({
		    		errorPrivacity : true, 
		    		thinking: false
		    	});

		    }else{
		    	//enviem lead amb el tlf
		    	//console.log('enviem lead call me');
		    	//console.log(this.props.userId);
		    	//console.log(this.state.currentEvent);
		    	//console.log(this.state.phoneCallMe);
		    	this.handleClose();
		    	this.state.thinking = false;
		    	this.props.sendLeadCallMe(this.props.userId, this.state.nombre, this.state.phoneCallMe, this.state.currentEvent, this.state.sourceUrl)
		    	.then( (result) => {

		    		history.push("/thank-you");		    		

		    	});
		    	

		    }
		});


	}

	render(){

		const { classes } = this.props;
		let classesPopUp = " mercedes-benz-template-dialog";
		


		return(
			<div>
				<button className="btn1 btn-call-menu" onClick={this.handleClickOpenBar} >
					<span className="text-btn">{i18n.t('menu.callMe')}</span>
				</button>

				<Dialog
				        open={this.state.openForm}
				        onClose={this.handleClose}
				        maxWidth='sm'
				        classes={{ paper: classes.dialogPaper }}
				        fullWidth={true}
				        aria-labelledby="form-dialog-title"
				        className={classesPopUp}
			        >
			        	<DialogTitle id="form-dialog-title" onClose={this.handleClose}>{i18n.t('menu.callMe')}</DialogTitle>
			        	{/*<form className="form-more-info" onSubmit={ this.props.handleSubmit(this.sendLead) }  >*/}
			        	
			        	<form className="form-more-info" onSubmit={ this.props.handleSubmit(this.sendPhoneLead) }  >
			        		<DialogContent classes={{root: classes.inicioForm}}>
								<div className="container-inputs-call-me">
									<Field name="phoneCallMe" onChange={this.handleChangeInput} initialValue={this.state.phoneCallMe} component={this.renderInput} label={i18n.t('editAccount.tlf')}  />
									{this.renderAcceptPrivacity()}
									{this.renderErrorPrivacity()}
								</div>								
							</DialogContent>
							<DialogActions classes={{root: classes.inicioBtn}}>
								<button className="btn1 btn-lead">
									{this.state.textButton}
								</button>
							</DialogActions>
							{this.renderLoader()}
						</form>
			        </Dialog>
			</div>
		);

	}


}



const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		selectedLanguage: state.auth.selectedLanguage,
		userId: state.auth.userId, 
		userMail: state.auth.userMail, 
		userName: state.auth.userName
	};
};


const stylesDialog  = theme => ({
    dialogPaper: {       
        [theme.breakpoints.down('xs')]: {
     		margin: '20px',
    	}        
    },    
    inicioForm: {
    	[theme.breakpoints.down('xs')]: {
     		paddingTop: '0px !important',
    	} 
    },
    inicioBtn: {
    	[theme.breakpoints.down('xs')]: {
     		margin: '10px 0',
    	} 
    },
    registerDialogPaper:{
    	backgroundColor: '#00009A',
    	[theme.breakpoints.down('xs')]: {
     		margin: '20px',
    	} 
    },
    titleRegisterPopup: {
    	backgroundColor: '#00009A',
    	padding: '30px',
    	borderBottom: '0px',
    },
    contentRegisterPopup: {
    	backgroundColor: '#00009A',
    	color: '#fff',
    	fontSize: '18px',
    	textAlign: 'center',
    	padding: '20px',
    },
    footerRegisterPopup: {
    	fontSize: '16px',
    	backgroundColor: '#00009A',
    	margin: '40px',
    	
    },
    head: {
	    backgroundColor: theme.palette.common.black,
	    color: theme.palette.common.white,
	    textAlign: 'center',
	    padding: '10px'
	},
	cellPrice: {
		textAlign: 'center',
		padding: '10px'
	},
	hideMobile: {
		[theme.breakpoints.down('xs')]: {
     		display: 'none',
    	}
	},
	headHide: {
	    backgroundColor: theme.palette.common.black,
	    color: theme.palette.common.white,
	    textAlign: 'center',
	    padding: '10px',
	    [theme.breakpoints.down('xs')]: {
     		display: 'none',
    	}
	},
	cellPriceHide: {
		textAlign: 'center',
		padding: '10px',
		[theme.breakpoints.down('xs')]: {
     		display: 'none',
    	}
	},
	
});


LlamamePopUp = withStyles(stylesDialog)(LlamamePopUp);

LlamamePopUp = reduxForm({
  form: 'CallMeTopCampaign',
  enableReinitialize: true,  
})(LlamamePopUp);

LlamamePopUp = connect(
	mapStateToProps,
	{ sendLeadCallMe }
)(LlamamePopUp);


LlamamePopUp = withNamespaces()(LlamamePopUp);

export default LlamamePopUp;
