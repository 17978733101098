import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import { setCurrentPage } from '../actions/';



class PrivacyView extends React.Component{

	constructor(props) {

		super(props);
	    this.props.setCurrentPage('privacy');

	}

	render(){
		return(
			<div className="template-legal">
				<Grid container justify="center" >
					<Grid item xs={12}>							
						<div className="container-title">							
							<h1>Políticas de privacidad y uso de cookies</h1>							
						</div>
					</Grid>
					<Grid item xs={11} sm={11} md={8} lg={8}>
						<p style={{fontSize: '14px'}}>
							La confidencialidad y la seguridad son valores primordiales para Motor Privé y, en consecuencia, asumimos el compromiso de garantizar la privacidad del Usuario en todo momento y de no recabar información innecesaria. A continuación, y en virtud del Reglamento (UE) 2016/679 sobre la protección de las personas físicas en lo que respecta al tratamiento de datos personales y la Ley Orgánica 3/2018 de 5 de diciembre de Protección de Datos personales y Garantía de los Derechos Digitales, le proporcionamos toda la información necesaria sobre nuestra Política de Privacidad en relación con los datos personales que recabamos, explicándole:
						</p>
						<ul style={{fontSize: '14px'}}>
							<li>Quiénes son los responsables del tratamiento de sus datos.</li>
							<li>Para qué finalidades recabamos los datos que le solicitamos.</li>
							<li>Cuál es la legitimación para su tratamiento.</li>
							<li>Durante cuánto tiempo los conservamos.</li>
							<li>A qué destinatarios se comunican sus datos.</li>
							<li>Cuáles son sus derechos.</li>
						</ul>
						<h3>1. Responsables del tratamiento</h3>
						<p style={{fontSize: '14px'}}>
							<b>MEDIAGACH, S.L</b> (B-67287870) C/ Francesc Layret 119, 5º 1ª, 08911. Badalona, Barcelona. España <em>hola@motorprive.com</em>
							<br/>
							En adelante, “Motor Privé”, “nosotros” o el “Responsable del Tratamiento”.

						</p>
						<h3>2. Datos personales</h3>
						<p style={{fontSize: '14px'}}>
							Datos Personales hace referencia a cualquier información o datos que pueda identificarlo directamente (por ejemplo, su nombre o apellidos) o indirectamente (por ejemplo, su documento nacional de identidad o D.N.I.). Los Datos Personales incluyen información tal como el correo electrónico/ código postales/ teléfono de contacto, nombres de usuario, entre otros. También podría incluir identificadores numéricos únicos como la dirección IP de su ordenador, así como también la información que obtenemos a través de cookies.
							<br/><br/>
							Esta política de privacidad cubre todos los datos personales recopilados y utilizados por Motor Privé.
							<br/><br/>
							Por el presente, el usuario (usted) garantiza que los Datos Personales proporcionados son ciertos y exactos y se compromete a notificar cualquier cambio o modificación de los mismos. Cualquier pérdida o daño causado a la Web, al Responsable del Tratamiento o a cualquier tercero mediante la comunicación de información errónea, inexacta o incompleta en los formularios de registro, será responsabilidad exclusiva del usuario.
							<br/><br/>
							Podemos recabar o recibir sus datos a través de nuestro sitio web o formularios, entre otros. En algunos casos, usted nos facilita sus Datos Personales directamente (por ejemplo, cuando crea una cuenta, cuando se pone en contacto con nosotros), o en otros casos los recabamos nosotros (por ejemplo, usando cookies para comprender cómo usa nuestro sitio web).
						</p>
						<h3>3. Finalidades, legitimación y conservación de los tratamientos de los datos enviados a través de:</h3>
						<p style={{fontSize: '16px'}}>
							REGISTRO A LA PLATAFORMA (COMO USUARIO):
						</p>
						<p style={{fontSize: '14px'}}>
							Al darse de alta en la Plataforma, usted deberá introducir una dirección de e-mail, nombre completo, número de teléfono y seleccionar una clave de acceso (password). Usted podrá modificar posteriormente a través de la sección de MI PERFIL su nombre completo, el número de teléfono o el password. El password será estrictamente confidencial, personal e intransferible. Usted se compromete a no divulgar los datos relativos a su cuenta ni hacerlos accesibles a terceros. Usted será el único responsable en caso de uso de dichos datos por terceros, incluidas las manifestaciones vertidas en la Plataforma, o cualquier otra actuación que se lleve a cabo mediante el uso del username y/o password.
							<br/><br/>
							Usted garantiza que todos los datos sobre su identidad y legitimidad facilitados a Motor Privé en sus formularios de registro de la Plataforma, así como la información posteriormente facilitada, es veraz, exacta y completa. Asimismo, se compromete a mantener actualizados sus datos. En el supuesto de que usted facilite cualquier dato falso, inexacto o incompleto o si Motor Privé considera que existen motivos fundados para dudar sobre la veracidad, exactitud e integridad de los mismos, Motor Privé podrá denegarle el acceso y uso presente o futuro de la Plataforma o de cualquiera de sus contenidos y/o servicios.
							<br/><br/>
							Finalidades:
						</p>
						<ul style={{fontSize: '14px'}}>
							<li>Gestión del servicio de registro y alta a la plataforma.</li>
							<li>Acceso a las diferentes funcionalidades y servicios que ofrece la Plataforma de Motor Privé.</li>
							<li>Servicios de fidelización y calidad.</li>
							<li>Contacto telefónico para la comprobación de datos.</li>
							<li>o	En caso de su aceptación de las presentes políticas, envío de información publicitaria sobre los servicios de Motor Privé, así como de terceros sobre productos y/o servicios relacionados con el sector automóvil, incluso por medios electrónicos.</li>
						</ul>
						<p style={{fontSize: '14px'}}>
							Legitimación: Su consentimiento expreso, la ejecución de la relación contractual entre Usted y Motor Privé y el interés legítimo.
							<br/><br/>
							Conservación: Hasta que se cancele la suscripción y/o cuando exija que se eliminen. Igualmente, sus datos podrán ser conservados por imperativo legal.
							
						</p>
						<p style={{fontSize: '16px'}}>
							LOGIN MEDIANTE APLICACIONES TERCERAS:
						</p>
						<p style={{fontSize: '14px'}}>
							La Plataforma de Motor Privé también permite el registro mediante la sincronización con servicios de terceros (Ej. Log-In con cuenta de Facebook o Google). Si sigue un enlace a cualquiera de estos sitios web, tenga en cuenta que comparte su información de perfil social con nosotros. Los datos personales compartidos dependen de la configuración de su plataforma de medios sociales. Visite la plataforma de redes sociales en cuestión y revise su política de privacidad para comprender cómo se comparten y usan sus datos personales en este contexto.
							<br/><br/>
							No somos responsables de ninguna acción realizada por otras personas si publica datos personales en una de nuestras plataformas de redes sociales y le recomendamos que no comparta dicha información.						
						</p>
						<p style={{fontSize: '16px'}}>
							FORMULARIO DE INSCRIPCIÓN DE EVENTOS:
						</p>
						<ul style={{fontSize: '14px'}}>
							<li>Finalidad: Facilitar la inscripción a futuros eventos. Envío de comunicaciones informativas y publicitarias del evento y siempre relacionados con el sector del automóvil, incluso por medios electrónicos.</li>
							<li>Legitimación: Su consentimiento al inscribirse a nuestros. Usted podrá modificar y/ o actualizar sus preferencias a través del panel habilitado en su perfil.</li>
							<li>Conservación: Hasta que usted revoque el consentimiento y solicite la baja.</li>							
						</ul>
						<p style={{fontSize: '16px'}}>
							FORMULARIO DE CONTACTO:
						</p>
						<ul style={{fontSize: '14px'}}>
							<li>Finalidad: Facilitarle un medio para que pueda ponerse en contacto con, así como enviarle comunicaciones de nuestros productos, servicios y actividades, inclusive por medios electrónicos (correo electrónico, SMS, whatsApp), si marca la casilla de aceptación.</li>
							<li>Legitimación: Su consentimiento al solicitarnos información a través de nuestro formulario de contacto y al marcar la casilla de aceptación de envío de información.</li>
							<li>Conservación: Una vez resuelta su solicitud por medio de nuestro formulario o contestada por correo electrónico, si no ha generado un nuevo tratamiento, y en caso de haber aceptado recibir envíos comerciales, hasta que solicite la baja de los mismos.</li>							
						</ul>
						<p style={{fontSize: '16px'}}>
							ENVÍO DE CORREOS ELECTRÓNICOS:
						</p>
						<ul style={{fontSize: '14px'}}>
							<li>Finalidad: Contestar a sus solicitudes de información, atender a sus peticiones y responder sus consultas o dudas, así como hacerle llegar información asociada a las alertas que haya configurado el usuario. En caso de recibir su Currículum Vitae, sus datos personales y curriculares podrán formar parte de nuestras bases de datos para participar en nuestros procesos de selección presentes y futuros.</li>
							<li>Legitimación: El consentimiento del usuario al solicitarnos información a través de la dirección de correo electrónico o enviarnos sus datos y CV para participar en nuestros procesos de selección.</li>
							<li>Conservación: Una vez resulta contestada su petición por correo electrónico, si no ha generado un nuevo tratamiento. En el caso de recibir su CV, sus datos podrán ser conservados durante un año máximo para futuros procesos de selección.</li>							
						</ul>
						<p style={{fontSize: '16px'}}>
							DATOS PERSONALES RECOGIDOS TELEFONICAMENTE:
						</p>
						<p style={{fontSize: '14px'}}>
							En función de la modalidad del evento, usted recibirá una llamada de nuestro equipo para confirmar la fecha y hora en la que querría reservar cita. Asimismo, en el caso de que esté interesado en hablar con su concesionario para resolver cualquier, nosotros le redireccionaremos. En estos casos, usted será libre de comunicar cualquier tipo de información, incluidos datos de carácter personal.
							<br/><br/>
							Tenga en cuenta que las llamadas serán grabadas por motivos de calidad.					
						</p>
						<ul style={{fontSize: '14px'}}>
							<li>Finalidad: Los datos que usted proporcione serán tratados por motivos de calidad, para medir su satisfacción y para hacer seguimiento de eventuales compras. </li>
							<li>Legitimación: Su consentimiento al solicitar información telefónicamente.</li>
							<li>Conservación: Una vez contestada su petición, si no ha generado un nuevo tratamiento.</li>							
						</ul>
						<p style={{fontSize: '16px'}}>
							FORMULARIO DE SOLICITUD DE INFORMACIÓN PARA CONCESIONARIOS/ ENVÍO DE EMAILS A LA DIRECCIÓN DE DEALERS@MOTORPRIVE.COM.
						</p>
						<p style={{fontSize: '14px'}}>
							Si usted representa a un concesionario y está interesado en unirse a Motor Privé, puede rellenar el formulario de contacto que se pone a su disposición, con los datos de contacto corporativo correspondientes.
						</p>
						<ul style={{fontSize: '14px'}}>
							<li>Finalidad: para poder atender a su petición de unirse a la red de concesionarios de Motor Privé y para proporcionarle más información al respecto.</li>
							<li>Legitimación: Su consentimiento al solicitarnos información.</li>
							<li>Supresión: Una vez resulta su petición, si no ha generado un nuevo tratamiento.</li>							
						</ul>
						<p style={{fontSize: '14px'}}>
							Es posible que retengamos algunos datos personales para cumplir con nuestras obligaciones legales o reglamentarias, así como para administrar nuestros derechos (por ejemplo, para hacer valer nuestras reclamaciones ante los tribunales) o con fines estadísticos o históricos.
							<br/><br/>
							Cuando ya no necesitemos usar sus datos personales, se eliminarán de nuestros sistemas y registros o se anonimizarán para que ya no podamos identificarlos.
							<br/><br/>
							Obligación de facilitarnos sus datos personales y consecuencias de no hacerlo.
							<br/><br/>
							El suministro de datos personales requiere una edad mínima de 14 años, o en su caso, disponer de capacidad jurídica suficiente para contratar.
							<br/><br/>
							Los datos personales solicitados son necesarios para gestionar sus solicitudes y/o prestarle los servicios que pueda contratar, por lo que, si no nos los facilita, no podremos atenderle correctamente ni prestarle el servicio que ha solicitado.
							<br/><br/>
							En todo caso, nos reservamos el derecho de decidir sobre la incorporación o no de sus datos personales y demás información a nuestras bases de datos.
						</p>
						<h3>4. Destinatarios de sus datos</h3>
						<p style={{fontSize: '14px'}}>
							Los Datos Personales que recabamos de usted pueden ser tratados por miembros del personal de Mediagach, S.L., sobre la base legal del interés legítimo. Asimismo, sus datos personales podrán ser comunicados a:
						</p>
						<ul style={{fontSize: '14px'}}>
							<li>Los concesionarios, a fin de verificar si ha habido transacción con el usuario.</li>
							<li>Terceros que nos ayudan a la gestión de leads, proporcionar servicios de IT, como proveedores de plataforma, servicios de alojamiento, mantenimiento y soporte en nuestras bases de datos, así como en nuestro software y aplicaciones que pueden contener datos sobre usted.</li>
							<li>Terceros que nos ayudan a proporcionar servicios digitales, como grabación de llamadas, localizador de concesionarios, programas de lealtad, administración de identidades, calificaciones y reseñas, CRM, análisis web y motor de búsqueda.</li>							
							<li>Agencias de publicidad, marketing, medios digitales y redes sociales para ayudarnos a ofrecer publicidad, marketing y campañas, para analizar su efectividad y para administrar su contacto y sus preguntas.</li>
							<li>Prestadores de servicios ubicados fuera del EEE tales como Facebook, Inc, Google, LLC o Iterable, Inc podrán acceder a sus datos de carácter personal. Estos prestadores de servicios se encuentran acogidos al “Privacy Shield” o escudo de privacidad y, por tanto, se encuentran obligados a proteger sus datos personales con arreglo a una serie de normas de protección y salvaguardias bien definidas por la Unión Europea.</li>
						</ul>
						<p style={{fontSize: '14px'}}>
							Nos aseguraremos de que toda comunicación de su información personal que realicemos nosotros o aquellos terceros con los que compartimos su información personal, cumplan con la legislación que nos sea de aplicación.
						</p>
						<h3>5. Derechos en relación con sus datos personales</h3>
						<p style={{fontSize: '14px'}}>
							Cualquier persona puede retirar su consentimiento en cualquier momento, cuando el mismo se haya otorgado para el tratamiento de sus datos. En ningún caso, la retirada de este consentimiento condiciona la ejecución del contrato de suscripción o las relaciones generadas con anterioridad.
							<br/><br/>
							Igualmente, puede ejercer los siguientes derechos:
						</p>
						<ul style={{fontSize: '14px'}}>
							<li>Solicitar el acceso a sus datos personales o su rectificación cuando sean inexactos.</li>
							<li>Solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines para los que fueron recogidos.</li>
							<li>Solicitar la limitación de su tratamiento en determinadas circunstancias.</li>							
							<li>Solicitar la oposición al tratamiento de sus datos por motivos relacionados con su situación particular.</li>
							<li>Solicitar la portabilidad de los datos en los casos previstos en la normativa.</li>
						</ul>
						<p style={{fontSize: '14px'}}>
							Otros derechos reconocidos en las normativas aplicables.
							<br/><br/>
							Dónde y cómo solicitar sus Derechos: Mediante un escrito dirigido a los responsables a la dirección postal o electrónica (indicadas en el punto 1), indicando la referencia “Datos Personales”, especificando el derecho que se quiere ejercer y respecto a qué datos personales.
							<br/><br/>
							En caso de divergencias con la empresa en relación con el tratamiento de sus datos, puede presentar una reclamación ante la Agencia de Protección de Datos (<a href="https://www.aepd.es/">www.agpd.es</a>).
						</p>
						<h3>6. Seguridad de sus datos personales</h3>
						<p style={{fontSize: '14px'}}>
							Con el objetivo de salvaguardar la seguridad de sus datos personales, le informamos que hemos adoptado todas las medidas de índole técnica y organizativa necesarias para garantizar la seguridad de los datos personales suministrados de su alteración, pérdida y tratamientos o accesos no autorizados.
						</p>
						<h3>7. Actualización de sus datos</h3>
						<p style={{fontSize: '14px'}}>
							Es importante que para que podamos mantener sus datos personales actualizados, nos informe siempre que haya habido alguna modificación en ellos, en caso contrario, no respondemos de la veracidad de los mismos.
							<br/><br/>
							No nos hacemos responsables de la política de privacidad respecto a los datos personales que pueda facilitar a terceros por medio de los enlaces disponibles en nuestra página web.
						</p>
						<h3>8. Contacto</h3>
						<p style={{fontSize: '14px'}}>
							Si tiene cualquier duda o preocupación acerca de la forma en que tratamos y usamos sus datos personales o desea ejercer cualquiera de los derechos anteriormente descritos, póngase en contacto con <em>hola@motorprive.com</em>.
						</p>
					</Grid>
				</Grid>
			</div>
		);
	}


}

const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		userName: state.auth.userName
	};
};

PrivacyView = connect(
	mapStateToProps, { setCurrentPage }	
)(PrivacyView);

export default withNamespaces()(PrivacyView);



