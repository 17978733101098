import React from 'react';
import { Link } from 'react-router-dom';



const CustomLink = ({ children, to, idEvent, nomClasse }) => (
	
  	to == "" ? <Link className={nomClasse} to={`/campaigns/${idEvent}`}>{children}</Link> : <a href={to} className={nomClasse} target="_blank" rel="noopener noreferrer">{children}</a>    
);


export default CustomLink;
