import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

export const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
    backgroundColor: theme.palette.secondary.main,
  },
  closeButton: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    color: theme.palette.secondary.contrastText,
    cursor: 'pointer',
    fontSize: '25px',
  },
  titlePopUp: {
  	fontSize: '18px',
  	margin: 0,
  	color: theme.palette.secondary.contrastText,
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
     <p className={classes.titlePopUp}>{children}</p>
      {onClose ? (
      	<div aria-label="Close" className={classes.closeButton} onClick={onClose}>
      		<FontAwesomeIcon icon={['fal', 'times']} />
      	</div>
        
      ) : null}
    </MuiDialogTitle>
  );
});



export const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);


export const DialogActions = withStyles(theme => ({
  root: {
    justifyContent: 'center',
    margin: '20px'
    
  },
}))(MuiDialogActions);

