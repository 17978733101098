import React from "react";

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

//import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class Footer extends React.Component{

	

	render(){

		let d = new Date();
  		let currentYear = d.getFullYear();

		return(
			<div className="template-container-footer">
				<Grid container style={{flexGrow: 1}}  justify="center">
					<Grid item xs={12}>
						<div className="footer-container">
							<Grid container alignItems="center" justify="center">
								<Grid item xs={12} sm={12} md={10} lg={8} >
									<div className="footer-content">
										<Grid container alignItems="center" justify="center">											
											<Grid item xs={12} sm={6} md={6} lg={6} >
												<div className="interests-link-content">													
													<div className="interest-links-content">
														<a href="/contact">{i18n.t('footer.contact')}</a>
														<a href="/">{i18n.t('footer.signin')}</a>
														<a href="/faqs">FAQS</a>
													</div>
												</div>												
											</Grid>
											<Grid item xs={12} sm={6} md={6} lg={6} >
												<div className="copyright-links-content">
													<span>© {currentYear} Motor Privé.</span>
													<div className="interest-privacy-content">
														<a href="/privacy-policies">{i18n.t('footer.privacy')}</a>-
														<a href="/terms">{i18n.t('footer.legal')}</a>-
														<a href="/use-conditions">{i18n.t('footer.conditions')}</a>														
													</div>
												</div>												
											</Grid>


											{/*
											<Grid item xs={12} sm={3} md={4} lg={4} >
												<div className="footer-links-content">
													<p className="title-footer">{i18n.t('footer.links')}</p>
													<a href="/contact">{i18n.t('footer.contact')}</a>
													<a href="/">{i18n.t('footer.about')}</a>
													<a href="/">{i18n.t('footer.signin')}</a>
												</div>												
											</Grid>
											<Hidden xsDown>
												<Grid item xs={12} sm={3} md={4} lg={4} >
													<div className="logo-content">
														<FontAwesomeIcon icon={['fal', 'car-side']} />													
														<p><em dangerouslySetInnerHTML={{__html: i18n.t('footer.sentence')}}></em></p>
													</div>												
												</Grid>
											</Hidden>
											<Grid item xs={12} sm={3} md={4} lg={4}  >
												<div className="contact-content">
													<p className="title-footer">{i18n.t('footer.contact')}</p>
													<a href="/"><em>hola@motorprivee.com</em></a>
													<a href="/">&nbsp;</a>
													<a href="/">&nbsp;</a>
												</div>												
											</Grid>

											<div className="separator"></div>

											<Grid item xs={12} sm={3} md={4} lg={4} >
												<div className="copyright-content">
													<p>© 2019 Copyright</p>
												</div>												
											</Grid>
											<Grid item xs={12} sm={6} md={4} lg={4} >
												<div className="politics-content">
													<a href="/">{i18n.t('footer.privacy')}</a>
													<a href="/">{i18n.t('footer.legal')}</a>
													<a href="/">{i18n.t('footer.sitemap')}</a>
												</div>												
											</Grid>
											<Grid item xs={12} sm={3} md={4} lg={4} >
												<div className="social-media-content">
													<a href="/"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>
													<a href="/"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
												</div>
											</Grid>
											*/}
											
										</Grid>
									</div>
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Grid>
			</div>
		);
	}

}

export default withNamespaces()(Footer);


