import React from 'react';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

//import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import GridListTile from '@material-ui/core/GridListTile';
//import Card from '@material-ui/core/Card';
//import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
//import eventsListStyle from '../../assets/styles/components/EventsList';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const BlockList = (props) => {	

		let totalProvincias, totalBrands;
		let provincias, marcas;
		if(props.eventLocation === undefined ){
			totalProvincias = 0;
			provincias = [];
		}else{
			totalProvincias = props.eventLocation.length;	
			provincias = props.eventLocation;
		}	

		if(props.eventBrands === undefined){
			totalBrands = 0;
			marcas = [];
		}else{
			totalBrands = props.eventBrands.length;
			marcas = props.eventBrands;
		}

		const divStyle = {					  
			backgroundImage: 'url(' + props.eventImage + ')'
		};

		return(			
				<GridListTile key={props.numCampaign} cols={1} rows={1}>
					
					<div className="container-card">
						<div className="container-card-border">
							<div className="container-image-card-background">	
								<div className="container-image-card" style={divStyle}>
									<div className="container-days-until"><p>{ props.eventCaducidad === 0 ? i18n.t('listCampaigns.closedInscriptions') : i18n.t('listCampaigns.openInscriptions')+' '+props.eventCaducidad+' '+i18n.t('listCampaigns.days') }</p></div>
									<div className="container-suscribers"><span className="number-suscribers">{props.eventsSuscribes}</span>{ i18n.t('listCampaigns.suscribers') }</div>
									{/*<div className="container-location">
										<FontAwesomeIcon icon={['fal', 'map-marker-alt']} />	
						        		{	
						        			provincias.map( (item, index) => (	
					        					<span key={index}>{item} { index == totalProvincias-1 ? '' : ' - '  }</span>
					        				))
					        			}
									</div>
									*/}
									<div className="container-gradient-bottom"></div>
								</div>

							</div>
							<div className="container-info-card">							
								<Grid container justify="center" >
									<Grid item xs={12} sm={8} md={8} lg={8}>
										<div className="text-info-card">
											<div className="location-container">
												<span className="map-event"><img src={props.eventMap} /></span>												
											</div>
											<p className="title-event">
												{props.eventTextLocation}
													{/*	
									        			provincias.map( (item, index) => (	
								        					<span key={index}>{item} { index == totalProvincias-1 ? '' : ' - '  }</span>
								        				))
								        				*/
								        			}
											</p>
											<p className="slogan-event">{props.eventName}</p>
											{/*<span className="cities-title-event">{totalProvincias > 1 ? 'Evento simultanea en las ciudades' : 'Evento en'}</span>*/}
										</div>
									</Grid>
									<Grid item xs={12} sm={4} md={4} lg={4}>
										<div className="container-brands">
										{	
						        			marcas.map( (item, index) => (
						        				<img key={index} src={item.logo} alt={item.nombre} />			        					
					        				))
					        			}
					        			</div>
									</Grid>
									{/*<Grid item xs={12}>
										<p className="cities-event">
												{totalProvincias > 1 ? 'Evento simultanea en las ciudades' : ''}											
								        		<FontAwesomeIcon icon={['fal', 'map-marker-alt']} />	
								        		{	
								        			provincias.map( (item, index) => (	
							        					<span key={index}>{item} { index == totalProvincias-1 ? '' : ' - '  }</span>
							        				))
							        			}
								        	</p>
									</Grid>
									*/}
								</Grid>													
							</div>
						</div>
					</div>
					{/*
					<Card classes={{
							    		root: props.estils.cardContainer					        
									}}
							>
						<CardMedia	
							classes={{
					    		root: props.estils.cardImage				        
							}}									          
				        	image={props.eventImage}
				        	title={props.eventName}
				        />
				        <span className={props.estils.cardUntilImage}>{ props.eventCaducidad === 0 ? i18n.t('listCampaigns.closedInscriptions') : i18n.t('listCampaigns.openInscriptions')+' '+props.eventCaducidad+' '+i18n.t('listCampaigns.days') }</span>
				        <div className={props.estils.cardTitleContainer}><span className={props.estils.cardTitleImage}>{props.eventName}</span></div>
				        <CardContent className={props.estils.cardContent}>
				        	<div className={props.estils.cardDescription}>{props.eventDescription}</div>
				        	<p className="event-card-location">
				        		<FontAwesomeIcon icon={['fal', 'map-marker-alt']} />	
				        		{	
				        			provincias.map( (item, index) => (	
			        					<span key={index}>{item} { index == totalProvincias-1 ? '' : ' - '  }</span>
			        				))
			        			}
				        	</p>
				        	<p className="container-marcas">
				        		{	
				        			props.eventBrands.map( (item, index) => (
				        				<img key={index} src={item.logo} alt={item.nombre} />			        					
			        				))
			        			}
				        	</p>
				        </CardContent>
					</Card>
					*/}
				</GridListTile>	
		);
		
			
};

//size="large" color="secondary" variant="contained"

export default withNamespaces()(BlockList);

