import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';


import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { setCurrentPage } from '../actions/';


class GraciasModCitaView extends React.Component{

	constructor(props) {

		super(props);
	    this.state = {
	        render: false 
	    }
	    this.props.setCurrentPage('thanks');

	}

	componentDidMount() {
		window.scrollTo(0,0);
  		setTimeout(function() { 
      		this.setState({render: true}) 
  		}.bind(this), 1000);
  		window.gtag('event', 'gracias-cancel-cita');
	}


	render(){
		if(this.state.render) {
			return(
				<div className="template-thankyou-page">
					<Grid container justify="center">
						<Grid item xs={11} sm={8} md={6} lg={6}>
							<div className="container-block-cancel-thanyou">
								{/*<div className="icon-title-section"><FontAwesomeIcon icon={['fal', 'comment-alt-smile']} /></div>*/}
								<p className="title-text">{i18n.t('thankyou.cancelDate')}</p>	
							</div>
						</Grid>
					</Grid>
					<Grid container justify="center">
						<Grid item xs={11} sm={8} md={6} lg={6}>
							<div className="container-text-cancel-thankyou">							
								
								<a href="/campaigns" >									
									<span className="btn btn1 ">{i18n.t('thankyou.thankyou')}</span>
								</a>								
							</div>
						</Grid>
					</Grid>
				</div>
			);
		}else{
			return ( <div className="template-loading-thanks"><CircularProgress color="secondary" /></div> );
		}
		

	}


}


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		userName: state.auth.userName
	};
};

GraciasModCitaView = connect(
	mapStateToProps, { setCurrentPage }	
)(GraciasModCitaView);

export default withNamespaces()(GraciasModCitaView);


