import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';

import history from '../../history';

import { editDateAppointment } from '../../actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const stylesAlert = theme => ({
  	root: {
        background: '#009688'
    }
});


class EditDateForm extends React.Component {

	constructor(props) {
		super(props);

		this.handleChangeInput = this.handleChangeInput.bind(this);

		this.state = {	           
	        codeTicket: this.props.codeTicket,    
	        message: ''
	    };
	}

	

	changeDateAppointment = (formValues) => {
		
		this.props.editDateAppointment( this.state.codeTicket, formValues['message'])
		.then( result => {
					
			history.push("/thank-you-edit");
		});
		
				
	}

	handleChangeInput(e){	
		const inputName = e.target.name;
		const inputValue = e.target.value;
		var copiaState = Object.assign({}, this.state);
		copiaState[inputName] = inputValue;
		this.setState(copiaState);  		
	}

	

	renderError({error, touched}){
		
		if(touched && error){
			return(
				<div>	
					<div className="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{error}</div>
				</div>
			);
		}

	}

	

	renderHiddenInput = ({input, label, meta, type, initialValue, disabled, valor}) => {
		
		return (
			<div className="inputContainer">								
				<input {...input} value={initialValue} type={type} />				
			</div>
		);
	};


	renderTextArea = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainer">
								
				<textarea {...input} autoComplete="off" value={initialValue} placeholder={label} type={type} disabled={disabled} />
				{this.renderError(meta)}
			</div>
		);

	};

	
	render(){

		const { openAlert } = this.state;
		const { classes } = this.props;
		

		return(
			<div>
				<form onSubmit={ this.props.handleSubmit(this.changeDateAppointment) } >
					<Grid container justify="center">
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Field name="message" onChange={this.handleChangeInput} initialValue={this.state.message} component={this.renderTextArea} label={i18n.t('editDate.writeLines')} />
							
						</Grid>
					</Grid>		
					
					<button className="btn btn1 btn-contact">{i18n.t('contact.send')}</button>					
				</form>

				

			</div>
		);
	}

}



const validate = (formValues) => {

	const errors = {};
	const requiredFields = [  
	    'message'
  	];
 	
  	requiredFields.forEach(field => {
	    if (!formValues[field]) {
	      errors[field] = i18n.t('errorForms.required');
	    }
  	})

    

	return errors;

};

const mapStateToProps = (state) => {
	return { 
		userId: state.auth.userId, 
		userMail: state.auth.userMail, 
		userName: state.auth.userName,
		selectedLanguage: state.auth.selectedLanguage
	};
};

EditDateForm = reduxForm({
  form: 'ContactForm',
  validate: validate 
})(EditDateForm);


EditDateForm = withStyles(stylesAlert)(EditDateForm);

EditDateForm = connect(
	mapStateToProps, 
	{ editDateAppointment }
)(withNamespaces()(EditDateForm));


export default EditDateForm;
