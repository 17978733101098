import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { editUserData, getUserData } from '../../actions';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
//import Dialog from '@material-ui/core/Dialog';

//import { DialogTitle, DialogContent, DialogActions } from '../../assets/styles/components/AlertNotificationStyle.jsx';


import { getApiUrl } from '../../config/urlBase.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const stylesAlert = theme => ({
  	root: {
        background: '#009688'
    },
    snackAlert: {
          background: '#009688'
    },
    dialogPaper: {       
        [theme.breakpoints.down('xs')]: {
        margin: '20px',
      }        
    },
    inicioForm: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: '0px !important',
      } 
    },
    inicioBtn: {
      [theme.breakpoints.down('xs')]: {
        margin: '10px 0',
      } 
    }
});


class PersonalDataForm extends React.Component {

	constructor(props) {
    	super(props);
    	this.handleChangeInput = this.handleChangeInput.bind(this);
      	this.state = {
	        nombre:'',
	        apellidos:'',	 
	        email: '',       
	        zip:'',
	        gender: '',
	        tlf: '',
	        idioma: '',
	        poblacion: '',
	        tiempoCompra: '',
	        presupuesto: '',
	        operacioOk: '',
	        openAlert: false    		
	    };       
    }

    componentDidMount(){    	
    	this.getInfoUser();
    	/*
    	if(this.props.goTo == 'buy-interest'){
    		document.getElementById(this.props.goTo).scrollIntoView({ behavior: 'smooth'});
    	}
    	*/
    }

    getInfoUser(){
    	//console.log('get info user');
    	//fetch(getApiUrl()+"getUserData.php?uid="+this.props.userId+"&locale="+this.props.selectedLanguage)
	    //.then(res => res.json())
	    this.props.getUserData(this.props.userId, this.props.selectedLanguage)
	      .then(
	        (result) => {	  
	        	//this.loadFirstEvents(result.events);	  
	        	 
	        	this.setState({
	        		nombre: result.nombre,
	        		apellidos: result.apellidos,
	        		email: this.props.userMail,
	        		zip: result.codigoPostal,
	        		gender: result.gender,
	        		idioma: result.idioma,
	        		poblacion: result.poblacion,
	        		tlf: result.tlf,
	        		tiempoCompra: result.tiempoCompra,
	        		presupuesto: result.presupuesto
	        	});   	
	        	const initData = {
	        		"nombre": result.nombre,
	        		"apellidos": result.apellidos,
	        		"email": this.props.userMail,
	        		"zip": result.codigoPostal,
	        		"gender": result.gender,
	        		"idioma": result.idioma,
	        		"poblacion": result.poblacion,
	        		"tlf": result.tlf,
	        		"tiempoCompra": result.tiempoCompra,
	        		"presupuesto": result.presupuesto

	        	};

	            this.props.initialize(initData);
	        },	       
	        (error) => {
	          
	        }
	    );
    }

	saveUserChanges = (formValues) => {		

		this.props.editUserData(formValues, this.props.userId, this.props.userMail)
		.then( result => {			
			this.openAlert(i18n.t('errors.'+result.error));			
		} );

	}

	handleChangeInput(e){	
		const inputName = e.target.name;
		const inputValue = e.target.value;
		var copiaState = Object.assign({}, this.state);
		copiaState[inputName] = inputValue;
		this.setState(copiaState);  		
	}

	renderError({error, touched}){
		
		if(touched && error){
			return(
				<div>	
					<div className="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{error}</div>
				</div>
			);
		}

	}

	renderInput = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainer">
				<label className="input-label">{label}</label>				
				<input {...input} autoComplete="off" value={initialValue} placeholder={i18n.t('global.enter')+" "+label.toLowerCase()} type={type} disabled={disabled} />
				{this.renderError(meta)}
			</div>
		);
	};

	renderInputPlaceholder = ({input, label, meta, type, initialValue, disabled, placeholder}) => {
		return (
			<div className="inputContainer">
				<label className="input-label">{label}</label>				
				<input {...input} autoComplete="off" value={initialValue} placeholder={placeholder} type={type} disabled={disabled} />
				{this.renderError(meta)}
			</div>
		);
	};

	/*renderInput = ({input, label, meta, initialValue}) => {
		return (
			<div className="field">
				<label>{label}</label>
				<input {...input} value={initialValue} autoComplete="off" />
				{this.renderError(meta)}
			</div>
		);
	}*/

	renderSelect= ({input, label, meta, initialValue, children}) => {
		return (
			<div className="inputContainer selectContainer">
				<label className="input-label">{label}</label>
				<select {...input} value={initialValue}>
					{children}
				</select>
				{this.renderError(meta)}
			</div>
		);
	}

	/*renderOperationStatus = () => {

		if(this.state.operacioOk !== ''){
			return (<p className="error-alert">{this.state.operacioOk}</p>);
		}else{
			return (<div></div>);
		}		
	}*/

	openAlert = (textMissatge) => {
    	this.setState({ openAlert: true, operacioOk: textMissatge });  
	};

	closeAlert = () => {
		this.setState({ openAlert: false });
	};

	render(){

		const { openAlert } = this.state;
		const { classes } = this.props;

		
		return(		
			<div className="container-parent-personal-data">
				<div className="container-title">
					<p className="title-sub-section"><span>{i18n.t('editAccount.title')}</span></p>	
				</div>
				<div className="container-change-pwd">
					
					<form onSubmit={ this.props.handleSubmit(this.saveUserChanges) } >
						<Grid container justify="center">
							<Grid item xs={12}>
								<Field name="email" initialValue={ this.state.email == '' ? this.props.userMail : this.state.email} component={this.renderInput} label={i18n.t('editAccount.email')} props={{ disabled: true, }} />
							</Grid>
						</Grid>

						<Grid container justify="center">
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Field name="nombre" onChange={this.handleChangeInput} initialValue={this.state.nombre} component={this.renderInput} label={i18n.t('editAccount.name')} />					
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Field name="apellidos" onChange={this.handleChangeInput} initialValue={this.state.apellidos} component={this.renderInput} label={i18n.t('editAccount.surname')} />					
							</Grid>
						</Grid>
						{/*
						<Grid container justify="center">						
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Field name="poblacion" initialValue={this.state.poblacion} props={{ disabled: true, }} component={this.renderInput} label={i18n.t('editAccount.city')} />										
							</Grid>
						</Grid>
						*/}

						<Grid container justify="center">
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Field name="tlf" onChange={this.handleChangeInput} initialValue={this.state.tlf} component={this.renderInput} label={i18n.t('editAccount.tlf')} />					
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Field name="zip" onChange={this.handleChangeInput} initialValue={this.state.zip} component={this.renderInput} label={i18n.t('editAccount.zip')} />					
							</Grid>						
						</Grid>

						<Grid container justify="center">
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Field name="gender" onChange={this.handleChangeInput} initialValue={this.state.gender} component={this.renderSelect} label={i18n.t('editAccount.me')}>
									<option value="" name="no-value" disabled>{i18n.t('global.select')+" "+i18n.t('editAccount.gender')}</option>
									<option value="male" name="male">{i18n.t('editAccount.male')}</option>
									<option value="female" name="female">{i18n.t('editAccount.female')}</option>
									<option value="other" name="other">{i18n.t('editAccount.other')}</option>
								</Field>					
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Field name="idioma" onChange={this.handleChangeInput} initialValue={this.state.idioma} component={this.renderSelect} label={i18n.t('editAccount.language')}>
									<option value="--" name="no-value" disabled>{i18n.t('global.select')+" "+i18n.t('editAccount.idiom')}</option>
									<option value="es" name="castellano">{i18n.t('editAccount.es')}</option>
									<option value="ca" name="catalan">{i18n.t('editAccount.ca')}</option>						
								</Field>										
							</Grid>
						</Grid>
					
						<Grid container justify="center">
							<Grid item xs={12}>
								<Field name="tiempoCompra" onChange={this.handleChangeInput} initialValue={this.state.tiempoCompra} component={this.renderSelect} label={i18n.t('editAccount.whenGet')}>
									<option value="0" name="no-value" disabled>{i18n.t('editAccount.notKnow')}; {i18n.t('editAccount.aboutMonth')}; {i18n.t('editAccount.fourMonth')}... </option>
									<option value="1" name="1">{i18n.t('editAccount.now')}</option>
									<option value="2" name="2">{i18n.t('editAccount.aboutMonth')}</option>
									<option value="3" name="3">{i18n.t('editAccount.twoMonth')}</option>
									<option value="4" name="4">{i18n.t('editAccount.fourMonth')}</option>
									<option value="5" name="5">{i18n.t('editAccount.notKnow')}</option>
								</Field>	
							</Grid>
						</Grid>
						<Grid container justify="center">
							<Grid item xs={12}>
								<Field name="presupuesto" onChange={this.handleChangeInput} initialValue={this.state.presupuesto} component={this.renderInputPlaceholder} placeholder={i18n.t('editAccount.budgetPlaceholder')} label={i18n.t('editAccount.budget')} />					
							</Grid>
						</Grid>
						
						{/*this.renderOperationStatus()*/}					
						<button className="btn btn1 btn-edit-pro">{i18n.t('editAccount.edit')}</button>
					</form>
					
					<Snackbar
				          anchorOrigin={{  vertical: 'bottom', horizontal: 'right' }}
				          open={openAlert}
				          onClose={this.closeAlert}			         
				          ContentProps={{			            
				            classes: {
	                        	root: classes.root
	                    	}
				          }}
				          autoHideDuration={2500}
				          message={<span className="snackbar-text-msg"><FontAwesomeIcon icon={['fal', 'check-circle']} />{this.state.operacioOk}</span>}
				          
				    />
				
				{/*
					<Dialog
	                  open={openAlert}
	                  onClose={this.closeAlert}
	                  maxWidth='sm'
	                  classes={{ paper: classes.dialogPaper }}
	                  fullWidth={true}
	                  aria-labelledby="form-dialog-title"
	                >
	                  <DialogTitle id="form-dialog-title" onClose={this.closeAlert}>&nbsp;</DialogTitle>
	                  
	                    <DialogContent classes={{root: classes.inicioForm}}>
	                      <p className="alert-text">{this.state.operacioOk}</p>        
	                    </DialogContent>
	                    <DialogActions classes={{root: classes.inicioBtn}}>
	                        <button  className="btn1 btn-lead" onClick={this.closeAlert}>
	                         {i18n.t('global.accept')}                 
	                        </button>                 
	                    </DialogActions>
	                  
	                </Dialog>
	                */}
				</div>
			</div>
		);
	}
};

const validate = (formValues) => {

	const errors = {};
	const requiredFields = [	    
	    'nombre',
	    'apellidos',
	    'zip',
	    'tlf'	    
  	];
 	
  	requiredFields.forEach(field => {
	    if (!formValues[field]) {
	      errors[field] = i18n.t('errorForms.required');
	    }
  	})
  
  	const int = /^[-+]?[0-9]+$/;

  	if((formValues.presupuesto!= null)&&(formValues.presupuesto!= '')){
  		if ( ! int.test(formValues.presupuesto) ) {
	    	errors.presupuesto = i18n.t('errorForms.invalidintegerformat');
	  	}
  	}
  	

	return errors;

};



const mapStateToProps = (state) => {
	return { 
		userId: state.auth.userId, 
		userMail: state.auth.userMail, 
		userName: state.auth.userName,
		selectedLanguage: state.auth.selectedLanguage
	};
};




PersonalDataForm = reduxForm({
  form: 'PersonalDataForm',
  validate: validate 
})(PersonalDataForm);

PersonalDataForm = withStyles(stylesAlert)(PersonalDataForm);

PersonalDataForm = connect(
	mapStateToProps, 
	{ editUserData, getUserData }
)(withNamespaces()(PersonalDataForm));



export default PersonalDataForm;

