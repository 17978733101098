import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import Select, { createFilter, components } from 'react-select';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { fetchBrandsModelsRegions, fetchBrandsModelsRegionsAlerts, getUserAlerts, saveInterests, updateAlertsStatusSteppers } from '../../actions';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';

import { DialogTitle, DialogContent, DialogActions } from '../../assets/styles/components/AlertNotificationStyle.jsx';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const styles = theme => ({   
    snackAlert: {
          background: '#009688'
    },
    dialogPaper: {       
        [theme.breakpoints.down('xs')]: {
        margin: '20px',
      }        
    },
    inicioForm: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: '0px !important',
      } 
    },
    inicioBtn: {
      [theme.breakpoints.down('xs')]: {
        margin: '10px 0',
      } 
    }
});

class AlertsFormPopUp extends React.Component {

	constructor(props) {
    	
    	super(props);
      this.state = {
          valors: [],
          pintem: false,
          valorModelsSeleccionats: [],
          valorRegionsSeleccionades: [],
          openAlert: false         
      }

    }

    componentDidMount() {


    	this.props.fetchBrandsModelsRegionsAlerts(this.props.selectedLanguage)
		  .then( (result) => {
			
    			this.setState({ 
    				//brands: _.mapKeys(result.marcas, 'idMarca'),
    				
            brands: result.marcas,    				
            regions: result.comunidades 


            /*
            modelos: _.mapKeys(result.modelos, 'idModelo'),
    				regionsAux: _.mapKeys(result.comunidades, 'idComunidad'),
    				countries: result.paises,
    				regions: result.comunidades		
    				*/		
    			});

    	    this.props.getUserAlerts(this.props.userId)
    			.then( (result) => {	
    					
              //console.log('interesos rebuts');
    					//console.log(result);
              
              for(var j=0; j<result.modelos.length; j++){
                  const idModel = result.modelos[j];
                  const marques = this.state.brands;
                  for(var i=0; i< marques.length; i++){
                      const posicio=_.findIndex(marques[i]['options'], ['value', idModel]);
                      if(posicio !== -1){                         
                          this.state.valorModelsSeleccionats.push(this.state.brands[i].options[posicio]);
                      }     
                  }
              }

              for(var t=0; t<result.regiones.length; t++){

                  const idRegio = result.regiones[t];
                  const regions = this.state.regions;
                  const posicio=_.findIndex(regions, ['value', idRegio]);
                  if(posicio !== -1){
                    this.state.valorRegionsSeleccionades.push(this.state.regions[posicio]);
                  }

                  

              }

              this.setState({ 
                pintem: true
              });
                        
    			});
		
		  });


    }

    openAlert = (textMissatge) => {
      this.setState({ openAlert: true, operacioOk: textMissatge });  
    };

    closeAlert = () => {
      this.setState({ openAlert: false });
    };

    saveUserAlerts = () => {

        /*console.log('guardem les alertes');
        console.log(this.state.valorModelsSeleccionats);
        console.log(this.state.valorRegionsSeleccionades);
        */
        let modelsSeleccionats = [];
        let regionsSeleccionades = [];
        for(var i=0; i<this.state.valorModelsSeleccionats.length; i++){
            modelsSeleccionats.push(this.state.valorModelsSeleccionats[i]['value']);
        }

        for(var i=0; i<this.state.valorRegionsSeleccionades.length; i++){
            regionsSeleccionades.push(this.state.valorRegionsSeleccionades[i]['value']);
        }
        
        if((modelsSeleccionats.length != 0)&&(regionsSeleccionades.length != 0)){
          
          this.props.saveInterests(this.props.userId, modelsSeleccionats, regionsSeleccionades)
          .then( (result) => {
            
            this.props.updateAlertsStatusSteppers(result.error);
            //this.openAlert(i18n.t('errors.'+result.error));

          });
        }

        

        

    }

    handleChangeModels = (newValue: any, actionMeta: any) => {
        //console.log('hi ha canvis!!!!');
        //console.log(newValue);
        this.state.valorModelsSeleccionats = newValue;
    };

    handleChangeRegions = (newValue: any, actionMeta: any) => {
        //console.log('hi ha canvis!!!!');
        //console.log(newValue);
        this.state.valorRegionsSeleccionades = newValue;
    };

    noOptionsMessage() {
      return i18n.t('myAlerts.noResults');
    }
 
    render(){
    	 {/*defaultValue={{ label: 'Busca...', value: 'default-value' }}*/}

      //console.log('pinto markes');
      //console.log(this.state.brands);
      const { openAlert } = this.state;
      const { classes } = this.props;
      
      if(!this.state.pintem){
          return (<div></div>);
      }else{
          return(
              <div className="alerts-form-popup">
                <div className="container-block-alerts">
                    <p className="text-explanation" >{i18n.t('myAlerts.text1')}</p>
                    <Select 
                      isMulti 
                      defaultValue={this.state.valorModelsSeleccionats}
                      options={this.state.brands}             
                      components={{ MultiValueRemove, ClearIndicator }}
                      placeholder={i18n.t('myAlerts.enterVehicle')}
                      formatGroupLabel={formatGroupLabel}                      
                      filterOption={createFilter(filterConfig)}
                      styles={colourStyles}
                      noOptionsMessage={this.noOptionsMessage}
                      onChange={this.handleChangeModels}

                      theme={theme => ({
                        ...theme,                        
                        colors: {
                          ...theme.colors,                         
                          primary: '#05bdac',
                        },
                      })}

                    />         
                    <p className="text-explanation">{i18n.t('myAlerts.text2')}</p>      
                    <Select 
                      isMulti       
                      defaultValue={this.state.valorRegionsSeleccionades}                
                      options={this.state.regions}             
                      components={{ MultiValueRemove, ClearIndicator }}
                      placeholder={i18n.t('myAlerts.enterAreas')}
                      formatGroupLabel={formatGroupLabel}                      
                      filterOption={createFilter(filterConfig)}
                      styles={colourStyles}
                      noOptionsMessage={this.noOptionsMessage}
                      onChange={this.handleChangeRegions}
                      theme={theme => ({
                        ...theme,                        
                        colors: {
                          ...theme.colors,                         
                          primary: '#05bdac',
                        },
                      })}

                    />    
                    <button className="btn btn1 btn-save-alerts" onClick={this.saveUserAlerts}>{i18n.t('myAlerts.save')}</button>    
                </div> 

                {/*
                <p className="sub-title-section-cars">{i18n.t('myAlerts.myareasTitle')}</p>
                <div className="container-block-alerts">
                   <p>{i18n.t('myAlerts.myareas')}:</p>
                   <Select 
                      isMulti       
                      defaultValue={this.state.valorRegionsSeleccionades}                
                      options={this.state.regions}             
                      components={{ MultiValueRemove, ClearIndicator }}
                      placeholder={i18n.t('myAlerts.enterAreas')}
                      formatGroupLabel={formatGroupLabel}
                      filterOption={createFilter(filterConfig)}
                      styles={colourStyles}
                      onChange={this.handleChangeRegions}
                    />   

                </div>
                */}
                <Snackbar
                    anchorOrigin={{  vertical: 'bottom', horizontal: 'right' }}
                    open={openAlert}
                    onClose={this.closeAlert}              
                    ContentProps={{                 
                        classes: {
                                root: classes.snackAlert
                            }
                    }}
                    autoHideDuration={2500}
                    message={<span className="snackbar-text-msg"><FontAwesomeIcon icon={['fal', 'check-circle']} />{this.state.operacioOk}</span>}               
                />
             
                {/*
              <Dialog
                  open={openAlert}
                  onClose={this.closeAlert}
                  maxWidth='sm'
                  classes={{ paper: classes.dialogPaper }}
                  fullWidth={true}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title" onClose={this.closeAlert}>&nbsp;</DialogTitle>
                  
                    <DialogContent classes={{root: classes.inicioForm}}>
                      <p className="alert-text">{this.state.operacioOk}</p>        
                    </DialogContent>
                    <DialogActions classes={{root: classes.inicioBtn}}>
                        <button  className="btn1 btn-lead" onClick={this.closeAlert}>
                         {i18n.t('global.accept')}                 
                        </button>                 
                    </DialogActions>
                  
                </Dialog>
                */}

                {/*
                <div className="container-btn-save-interests">
                    <div className="container-interested">            
                      <Grid container justify="center">           
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                          <div className="sub-container-btn-save-interests">
                            <button className="btn btn1 btn-save-interests" onClick={this.saveUserAlerts}>{i18n.t('myAlerts.save')}</button>
                          </div>                
                        </Grid>
                      </Grid>           
                  </div>
                </div>
                */}


              </div>       
          );
        }
    }

}



const colourStyles = {
  control: (styles, { data }) => {    
    return {
      ...styles,
      minHeight: '60px',
    };
  },
  multiValue: (styles, { data }) => {    
    return {
      ...styles,
      backgroundColor: '#e4e4e4',
      padding: '5px',
      borderRadius: '17px',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: '#777777',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: '#d4d4d4',
    cursor: 'pointer',
    backgroundColor: '#9a9a9a',
    borderRadius: '7px',
    padding: '3px 7px',
    borderRadius: '15px',
    marginLeft: '5px',    
    ':hover': {      
      color: 'white',
    },
  }),  
  menu: (provided, state) => ({
    ...provided,
    margin: '0 !important',
    div: {
      margin: '0',
      padding: '0',
    }
  }),
  groupHeading: () => ({
    padding: '0',

  }),
  option: (provided, state) => ({
    ...provided,    
    padding: '7px !important',
  }), 
  
};



const filterConfig = {
      ignoreCase: true,
      ignoreAccents: true,
      trim: false,
      matchFrom: 'any',
};

const formatGroupLabel = data => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f7f7f7', padding: '10px', margin: '0', borderTop: '2px solid #383838' }}>
      <img src={data.logo} style={{width: '30px', marginRight: '10px'}}></img>
      <span style={{display: 'inline-block', lineHeight: '1', minWidth: 1, padding: '0.16666666666667em 0.5em' }}>{data.label}</span>
      <span style={{ backgroundColor: '#fff', borderRadius: '17px', color: '#02a696', display: 'inline-block', fontSize: 12, fontWeight: 'normal', lineHeight: '1', minWidth: 1, padding: '5px 7px', textAlign: 'center' }}>
          {data.options.length}
      </span>
  </div>
);

const MultiValueRemove = (props) => {
  return (    
      <components.MultiValueRemove {...props}>
          <FontAwesomeIcon icon={['fal', 'times']} />
      </components.MultiValueRemove>    
  );
};

const ClearIndicator = (props) => { 
  return (
    <div>
      <div>
        
      </div>
    </div>
  );
};



const mapStateToProps = (state) => {
	return { 
		userId: state.auth.userId,
		selectedLanguage: state.auth.selectedLanguage
	};
};


AlertsFormPopUp = withStyles(styles)(AlertsFormPopUp);


AlertsFormPopUp = connect(
	mapStateToProps,
	{ fetchBrandsModelsRegionsAlerts, getUserAlerts, saveInterests, updateAlertsStatusSteppers }
)(withNamespaces()(AlertsFormPopUp));

export default AlertsFormPopUp;



