import React from 'react';
import { connect } from 'react-redux';

import { signIn, signOut, createNewUser, errorLogin } from '../../actions/';
import history from '../../history';
import { serverValidation } from './LoginAuth.jsx';


import { authFirebase, providerGoogle } from '../Firebase/Firebase.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import ReactGA from 'react-ga';
/*
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    dataLayer: {
        category: 'User',
		action: 'Created an Account'        
    },
    dataLayerName: 'PageDataLayer'
};
*/


class GoogleAuth extends React.Component {

	
	onLogInClick = () => {
		authFirebase.signInWithPopup(providerGoogle) 
    	.then((result) => {
    		if (result.credential) {
    			
    			//console.log(result);    			
    			//this.props.signIn(result.user.uid, result.user.email, result.user.displayName);
    					

    			if(result.additionalUserInfo.isNewUser){
    				//és primera vegada que es loga - guardem info de l'usuari a bbdd
    				const usuari = {
    					uid: result.user.uid,
    					mail: result.user.email,
						nombre: result.additionalUserInfo.profile.given_name,
						apellido: result.additionalUserInfo.profile.family_name,
						codigoPostal: '',
						sexo: result.additionalUserInfo.profile.gender,
						language: result.additionalUserInfo.profile.locale,
						provider: 'google'
    				};

    				this.props.createNewUser(usuari).
    				then((result) => {
    					history.push('/campaigns');
    				});

    				window.gtag('event', 'Created an Account');
    				//TagManager.dataLayer(tagManagerArgs);

    				/*
    				ReactGA.event({
					  category: 'User',
					  action: 'Created an Account'
					});
					*/

    			}else{
    				history.push('/campaigns');
    			}
    			
				//console.log(this.props);

    		}else{
   				history.push('/campaigns');
    		}
      		
    	}).catch((error) => {
    		//console.log("error login facebook");
    		//console.log(error);
    		this.props.errorLogin(serverValidation(error.code));
    		
    	});
		
	};

	onLogOutClick = () => {		
		authFirebase.signOut()
		.then(() => {
  			// Sign-out successful.  			
  			this.props.signOut();
		}).catch(function(error) {
  			// An error happened.
  			console.log(error);
		});
	};

	renderLoginButtons(){
		if(this.props.isSignedIn === null){
			return null;
		}else if(this.props.isSignedIn === true){
			return(
				<button className="btn google-btn" onClick={this.onLogOutClick}>Desconectar</button>
			);
			
		}else{
			return(
				<button className="btn google-btn" onClick={this.onLogInClick}>
					<span className="icon-btn"><FontAwesomeIcon icon={['fab', 'google']} /></span>
					<span className="text-btn">{this.props.btnText}</span>

				</button>
			);
			
			
		}
	}

	render(){
		return(
			<div >
				{this.renderLoginButtons()}
			</div>
		);
	}

}



const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn, 
		userId: state.auth.userId, 
		userMail: state.auth.userMail , 
		userName: state.auth.userName,
		errorLog: state.auth.errorLog  
	};
};

export default connect(
	mapStateToProps, 
	{ signIn, signOut, createNewUser, errorLogin }
)(GoogleAuth);

