import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import queryString from 'query-string';

import CircularProgress from '@material-ui/core/CircularProgress';

import FreeLandingView from './FreeLandingView.jsx';
import PlusLandingView from './PlusLandingView.jsx';
import CustomLandingView from './CustomLandingView.jsx';
import AutobeltranLandingView from './AutobeltranLandingView.jsx';
import FutureCustomLandingView from './FutureCustomLandingView.jsx';
import FAQSBlock from '../components/FAQSBlock.jsx';
import VentasBlock from '../components/VentasBlock.jsx';


import Grid from '@material-ui/core/Grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { urlBase } from '../config/urlBase.js';

import { fetchEventInfo, getUtmSource } from '../actions';


class CampaignLandingView extends React.Component{

	constructor(props) {
	    super(props);
	    this.state = {	  
	    	requestSent: false,    
	      	currentEvent: null,
	      	locale: this.props.selectedLanguage,
	      	haveEvents: null,
	      	idCampaign: '',
	      	sourceUrl: ''	      
	    };	    	  

	}

	componentDidMount() {

		const parametres = queryString.parse(this.props.location.search);
		let utm_source;

		if(parametres.utm_source){
			utm_source = parametres.utm_source;

			if(parametres.utm_source != ''){
				this.props.getUtmSource(parametres.utm_source);
			}

		}else{
			utm_source = '';
		}

		this.state.sourceUrl = utm_source;		

		window.scrollTo(0,0);
		this.loadInfoEvent();
	}


	
	componentDidUpdate(prevProps){

		if (this.props.selectedLanguage !== prevProps.selectedLanguage) {	
			this.setState({ locale: this.props.selectedLanguage });
		    this.loadInfoEvent();
		}		
	}

	
	
	loadInfoEvent(){

		let idioma;
		if(this.props.isSignedIn === true){
			idioma = this.props.selectedLanguage;
		}else{
			 if(this.props.location.search == ''){
				idioma = this.props.selectedLanguage;
			}else{
				const parametres = queryString.parse(this.props.location.search);
				if(parametres.lng){
					idioma = parametres.lng;
				}else{
					idioma = this.props.selectedLanguage;
				}			
			}  
		}
		
		

		let aux = this.props.match.params.idCampaign.split("-");
		const idCampaign = aux[aux.length-1];
		this.state.idCampaign = idCampaign; 
		//console.log(this.props.match.params.idCampaign);
		//console.log('stic akkkkkkiiiiiii'+idioma);
		//fetch(urlBase+"eventInfo_servlet.php?idEvent="+idCampaign+"&locale="+idioma)
		//.then(res => res.json())
		this.props.fetchEventInfo(idCampaign, idioma)
	    .then(
	        (result) => {	   
       			//console.log("reeeeebbbboooo");
       			//console.log(result[0]);
	        	if(result.length === 0){

	        		this.setState({
		            	requestSent: true,
		            	haveEvents: false  	            	            
		          	}); 

	        	}else{
	        		
	        		let nomEvent = result[0].urlLocal;
	        		const iguals = nomEvent.localeCompare(this.props.match.params.idCampaign);

	        		if(iguals === 0){

	        			let fechaFinal = result[0].fechaFinal.split('-');
						let endDate = new Date();
						endDate.setFullYear(fechaFinal[2],fechaFinal[1]-1,fechaFinal[0]);
						const today = Date.now();

						if(endDate < today){
							//console.log("està tancada");
							this.setState({
				            	requestSent: true,
				            	currentEvent: result[0],
				            	haveEvents: true
			          		});
							
						}else{
							//console.log("està oberta");
							this.setState({
				            	requestSent: true,	          
				            	currentEvent: result[0],
				            	haveEvents: true  	            	            
				          	}); 

						}



	        			
	        		}else{

	        			
	        			this.setState({
			            	requestSent: true,
			            	haveEvents: false  	            	            
		          		}); 
	        		}
	        		
	        	}
        		
	        }
	    );
	}

	renderContent() {
		if((this.props.isSignedIn === null)||(this.state.haveEvents === null)){			
			return ( <div className="template-loading"><CircularProgress color="secondary" /></div> );
		}else if(this.state.haveEvents !== null){	
			if(this.state.haveEvents === false){
				return(
					<div className="template-landing-not-found">						
						<div className="container-no-results">
							<div className="no-results-content">
								<FontAwesomeIcon icon={['fas', 'ghost']} /><p className="text-no-result" dangerouslySetInnerHTML={{__html: i18n.t('landing.noResults') }}></p>
							</div>
						</div>
					</div>
					);
			}else{
				{/*switch(this.state.currentEvent.tipoLanding){
					case '1':		
						return ( <FreeLandingView currentEvent={this.state.currentEvent} localeLan = {this.state.locale}/> );	
						break;
					case '2':					
						return( <PlusLandingView currentEvent={this.state.currentEvent} localeLan = {this.state.locale} /> );	
						break;
				}*/}
				let fechaInicio = this.state.currentEvent.fechaInicio.split('-');
				let startDate = new Date();
				startDate.setFullYear(fechaInicio[2],fechaInicio[1]-1,fechaInicio[0]);
				const today = Date.now();

				if(startDate > today){					
					return( 
						<div>
							<FutureCustomLandingView currentEvent={this.state.currentEvent} localeLan = {this.state.locale} sourceUrl={this.state.sourceUrl} />
							<Grid container justify="center" >	
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<VentasBlock idVenta={this.state.idCampaign} />
								</Grid>						
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<FAQSBlock/>
								</Grid>								
							</Grid>
						</div>
					);					
				}else{
					if((this.state.currentEvent.idEvent == 48)||(this.state.currentEvent.idEvent == 58)||
						(this.state.currentEvent.idEvent == 49)||(this.state.currentEvent.idEvent == 47)||
						(this.state.currentEvent.idEvent == 50)||(this.state.currentEvent.idEvent == 55)||
						(this.state.currentEvent.idEvent == 65)){
						return( 
							<div>
								<AutobeltranLandingView currentEvent={this.state.currentEvent} localeLan = {this.state.locale} sourceUrl={this.state.sourceUrl} />
								
							</div>
						);		
					}else{
						return( 
							<div>
								<CustomLandingView currentEvent={this.state.currentEvent} localeLan = {this.state.locale} sourceUrl={this.state.sourceUrl} />
								

								{/*<Grid container justify="center" >	
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<VentasBlock idVenta={this.state.idCampaign} />
									</Grid>					
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<FAQSBlock/>
									</Grid>
									
								</Grid>*/}	
							</div>
						);
					}
					
				}

				

			}				
		}
	}

	render(){
		return(
			<div>
				{this.renderContent()}				
			</div>
		);
	}

};


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		selectedLanguage: state.auth.selectedLanguage,
		brandSelected: state.searchFilters.brandSelected,
		utm_source : state.auth.utm_source
	};
};


CampaignLandingView = connect(
	mapStateToProps, { fetchEventInfo, getUtmSource }	
)(withNamespaces()(CampaignLandingView));


export default CampaignLandingView;


