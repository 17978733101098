import i18n from 'i18next';
import { initReactI18next } from 'react-i18next/hooks';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


import translationEN from '../locale/en.json';
import translationES from '../locale/es.json';
import translationCA from '../locale/ca.json';

// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init
i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: "es",
    fallbackLng: 'es', // used if detected lng is not available
    debug: false,
    detection: {
      order: ['querystring','cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain']
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },    
    resources: {
      en: {
        translation: translationEN
      },
      es: {
        translation: translationES
      },
      ca: {
        translation: translationCA
      },
    },
  });

  export default i18n;