import React from 'react';
import { connect } from 'react-redux';

//import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { signIn, signOut, createNewUser, errorLogin } from '../../actions/';
import history from '../../history';

import { serverValidation } from './LoginAuth.jsx';

import { authFirebase, providerFacebook } from '../Firebase/Firebase.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import ReactGA from 'react-ga';

/*import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    dataLayer: {
        category: 'User',
		action: 'Created an Account'        
    },
    dataLayerName: 'PageDataLayer'
};
*/



class FacebookAuth extends React.Component {

	

	onLogInClick = (idioma) => {
		authFirebase.signInWithPopup(providerFacebook) 
    	.then((result) => {

    		if (result.credential) {
    			if(result.additionalUserInfo.isNewUser){
    				const usuari = {
    					uid: result.user.uid,
    					mail: result.user.email,
						nombre: result.additionalUserInfo.profile.first_name,
						apellido: result.additionalUserInfo.profile.last_name,
						codigoPostal: '',
						sexo: '',
						language: 'es' ,
						provider: 'facebook'
    				};



    				this.props.createNewUser(usuari)
    				.then( (result) => {

    					history.push('/campaigns');

    				});

    				window.gtag('event', 'Created an Account');
    				//TagManager.dataLayer(tagManagerArgs);
    				
    				/*
    				ReactGA.event({
					  category: 'User',
					  action: 'Created an Account'
					});
					*/
    				
    			}else{
    				history.push('/campaigns');
    			}
    			//console.log("log facebook");
    			//console.log(result);   

    			//this.props.signIn(result.user.uid, result.user.email, result.user.displayName);
    			
				//console.log(this.props);

    		}else{
   				history.push('/campaigns');
    		}
      		 
      		
    	}).catch((error) => {
    		//console.log("error login facebook");
    		//console.log(error);
    		this.props.errorLogin(serverValidation(error.code));
    		
    	});
		
	};


	renderLoginButtons(){
		if(this.props.isSignedIn === null){
			return null;
		}else if(this.props.isSignedIn === true){
			return(
				<button className="btn facebook-btn" onClick={this.onLogOutClick}>Desconectar</button>
			);
			
		}else{
			return(
				<button className="btn facebook-btn" onClick={this.onLogInClick}>
					<span className="icon-btn"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></span><span className="text-btn">{this.props.btnText}</span>
				</button>
			);
			
			
		}
	}

	render(){
		return(
			<div>
				{this.renderLoginButtons()}				
			</div>
		);
	}

}

const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn, 
		userId: state.auth.userId, 
		userMail: state.auth.userMail, 
		userName: state.auth.userName,
		errorLog: state.auth.errorLog 
	};
};


FacebookAuth = withNamespaces()(FacebookAuth);


export default connect(
	mapStateToProps, 
	{ signIn, signOut, createNewUser, errorLogin }
)(FacebookAuth);


