import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ChangePwdForm extends React.Component{

	renderError({error, touched}){
		
		if(touched && error){
			return(
				<div>	
					<div className="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{error}</div>
				</div>
			);
		}

	}

	onSubmit = (formValues) => {
		//console.log(formValues);
		this.props.onSubmit(formValues);

	}
	
	renderInput = ({input, label, meta, type}) => {
		return (
			<div className="inputContainer">
				<label className="input-label">{label}</label>
				<input {...input} autoComplete="off" placeholder="***********" type={type} />
				{this.renderError(meta)}
			</div>
		);
	}


	render(){
		return(
			<div className="container-parent-personal-data container-parent-change-pwd">
				<div className="container-title">
					<p className="title-sub-section">{i18n.t('editAccount.changePassword')}</p>
				</div>
				<div className="container-change-pwd">					
					<form onSubmit={ this.props.handleSubmit(this.onSubmit) }>
						<Grid container justify="center">
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Field name="passwordOne" component={this.renderInput} label={i18n.t('editAccount.newPassword')} type="password" />
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Field name="passwordTwo" component={this.renderInput} label={i18n.t('editAccount.confirmNewPassword')} type="password" />					
							</Grid>
						</Grid>			
						
						<button className="btn btn1 btn-change-password" >{i18n.t('editAccount.edit')}</button>
					</form>
				</div>
			</div>
		);
	}


}


const validate = (formValues) => {

	const errors = {};

	const requiredFields = [	    
	    'passwordTwo',
	    'passwordOne'
  	];
 	
  	requiredFields.forEach(field => {
	    if (!formValues[field]) {
	      errors[field] = i18n.t('errorForms.required');
	    }
  	})	

	if(formValues.passwordOne !== formValues.passwordTwo){
		errors.passwordTwo = i18n.t('errorForms.passwordDifferent');

	}
	return errors;

};


ChangePwdForm =  reduxForm({
	form: 'ChangePwdForm',
	validate: validate
})(ChangePwdForm);

//ChangePwdForm = withStyles()(ChangePwdForm);

ChangePwdForm = withNamespaces()(ChangePwdForm);

export default ChangePwdForm;


