import React from 'react';
import { connect } from 'react-redux';

import history from '../history';

import Grid from '@material-ui/core/Grid';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { setCurrentPage } from '../actions/';


class PageNotFound extends React.Component{

	constructor(props) {

		super(props);
	    this.props.setCurrentPage('404');

	}


	moveToHome = () => {
		history.push('/campaigns');
	};

	
	render(){
		return(
			<div className="template-page-not-found">
				<Grid container justify="center" alignItems="center">
					<Grid item xs={12} sm={12} md={8} lg={6}>	
						<div className="container-page-not-found">
							<p className="container-error-number">
								<span className="firstNumber">4</span>
								<span className="secondNumber">0</span>
								<span className="thirdNumber">4</span>
							</p>														
							<p>{i18n.t('global.pageNotFound')}</p>
							<button onClick={this.moveToHome} className="btn btn1">{i18n.t('global.backCampaigns')}</button>
						</div>					
			          	
			        </Grid>
				</Grid>							
			</div>
		);
	}


}

const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		userName: state.auth.userName
	};
};

PageNotFound = connect(
	mapStateToProps, { setCurrentPage }	
)(PageNotFound);

export default withNamespaces()(PageNotFound);

