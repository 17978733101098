import {
	C_MAIN, 
	C_MAIN_LIGHT, 
	C_MAIN_DARK, 
	C_SECONDARY, 
	C_SECONDARY_LIGHT, 
	C_SECONDARY_DARK, 
	C_TEXT,
 /* C_TEXT_LIGHT*/

} from './variables.jsx';

import { createMuiTheme } from '@material-ui/core/styles';


export const theme = createMuiTheme({
  palette: {
    primary: {
      light: C_MAIN_LIGHT,
      main: C_MAIN,
      dark: C_MAIN_DARK,
      contrastText: C_TEXT,
    },
    secondary: {
      light: C_SECONDARY_LIGHT,
      main: C_SECONDARY,
      dark: C_SECONDARY_DARK,
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [     
      'Poppins',
      'Bodoni Moda',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
      
    },
  },
  
});