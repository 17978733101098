import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import NoAccessPage from './NoAccessPage.jsx';

import PersonalDataForm from '../components/Forms/PersonalDataForm.jsx';
import ChangePwdAuth from '../components/Login/ChangePwdAuth.jsx';
import VehiclesForm from '../components/Forms/VehiclesForm.jsx';

import { setCurrentPage } from '../actions/';

class PersonalAccountView extends React.Component {

	constructor(props) {
		super(props);

		this.props.setCurrentPage('editProfile');


		/*		
		if(this.props.location.hash !== ''){

			let resultat = this.props.location.hash.split("#");

			console.log('valor');
			console.log(resultat);

			this.state = {
				scrollToElement: resultat[1]
			}

		}else{
			this.state = {
				scrollToElement: 0
			}
		}
		*/

	}

	componentDidMount() {

		window.scrollTo(0, 0);
		/*
		if(this.state.scrollToElement == 0){
			window.scrollTo(0, 0);  
		}else{

		}
  		*/	  		
	}
	
	renderPersonalAccountForm(){

		if(this.props.isSignedIn === null){			
			return(<CircularProgress color="secondary" />);			
		}else if(this.props.isSignedIn === true){	
			return ( 
				<div>
					<PersonalDataForm />
					<VehiclesForm />
					<ChangePwdAuth />
					<div className="container-baja">
						<a href="/contact?type=4" className="btn btn-baja">{i18n.t('editAccount.unregister')}</a>
					</div>
				</div> );
		}else{
			return(<NoAccessPage />);
			//console.log("No puedes acceder sin estar registrado 2");
		}
	}
	
	render(){
		return(
			<div className="template-page template-personal-data">
				<Grid container justify="center">
					<Grid item xs={11} sm={8} md={6} lg={5}>						
			          	<div className="container-block-personal-data">
			          		{this.renderPersonalAccountForm()}			          		
			          	</div>
			        </Grid>
				</Grid>								
			</div>
		);
	}

}


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		userId: state.auth.userId
	};
};


PersonalAccountView = connect(
	mapStateToProps, { setCurrentPage }
)(withNamespaces()(PersonalAccountView));

export default PersonalAccountView;

