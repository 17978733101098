import firebase from 'firebase';

const config = {
   	apiKey: "AIzaSyDARCZX3VSS47dUC9HFnnxSCyiWih2gLvk",
    authDomain: "motor-privee.firebaseapp.com",
    databaseURL: "https://motor-privee.firebaseio.com",
    projectId: "motor-privee",
    storageBucket: "motor-privee.appspot.com",
    messagingSenderId: "363045760325"
};


firebase.initializeApp(config);
export const providerGoogle = new firebase.auth.GoogleAuthProvider();
export const providerFacebook = new firebase.auth.FacebookAuthProvider();
export const authFirebase = firebase.auth();
export default firebase;


