const INITIAL_STATE = {
	formVeh: false,
	formPurch: false,
	formAlt: false,
	msgRslt: null
};


export default (state = INITIAL_STATE, action) => {

	switch(action.type){

		case 'INITIALIZE_FILTERS':
			return{
				formVeh: false,
				formPurch: false,
				formAlt: false,
				msgRslt: null
			};

		case 'OPEN_POPUP':
			return{
				...state, 
				formVeh: true
			};

		case 'CLOSE_POPUP':
			return{
				...state, 
				formVeh: false,
				msgRslt: null
			};

		case 'OPEN_POPUP_PURCHASE':
			return{
				...state, 
				formPurch: true
			};

		case 'CLOSE_POPUP_PURCHASE':
			return{
				...state, 
				formPurch: false,
				msgRslt: null
			};
		case 'OPEN_POPUP_ALERTS':
			return{
				...state, 
				formAlt: true
			};

		case 'CLOSE_POPUP_ALERTS':
			return{
				...state, 
				formAlt: false,
				msgRslt: null
			};
		case 'VEHICLES_UPDATE_STEP':
			return{
				...state,
				msgRslt: action.payload
			};
		case 'ALERTS_UPDATE_STEP':
			return{
				...state,
				msgRslt: action.payload
			};
		default:
			return state;

	}

};

