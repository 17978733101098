import React from 'react';
import { connect } from 'react-redux';

import history from '../history';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import queryString from 'query-string';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';

import LoginAuth from '../components/Login/LoginAuth.jsx';
import GoogleAuth from '../components/Login/GoogleAuth.jsx';
import FacebookAuth from '../components/Login/FacebookAuth.jsx';
import SignUpAuth from '../components/Login/SignUpAuth.jsx';

import { setCurrentPage } from '../actions/';



const stylesTabs = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'rgba(0,0,0,0.4)',
    marginTop: '20px',
  },
  tabsRoot: {    
  	backgroundColor: 'rgba(0,0,0,0.7)',
  },
  tabsIndicator: {
    backgroundColor: 'rgba(0,0,0,0.0)',
  },
  tabRoot: {
    textTransform: 'initial',
    width: '50%',
    fontWeight: theme.typography.fontWeightRegular, 
    color: '#fff',     
    '&:hover': {      
      opacity: 1,
      backgroundColor: 'rgba(0,0,0,0.4)',
    },
    '&$tabSelected': {
      color: '#fff',
      borderBottom: 0,      
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: 'rgba(0,0,0,0.8)',
    },
    '&:focus': {
      
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
});



const DialogActions = withStyles(theme => ({
	root: {
    	justifyContent: "center",
	},
}))(MuiDialogActions);


class LoginView extends React.Component {

	
	constructor(props) {

		super(props);	

		if(this.props.location.hash !== ''){

			if(this.props.location.search == ''){
				//console.log('no tenim parametres');
				//?number=1&caseta=3#inscribirse
				this.state = {
		      		tabValue: "inscribirse",
		      		currentImage: 0,
		      		currentEvent: 0,
		      		openAlert: false
		        	/*nombre: '',
		        	email: '',
		        	tlf: ''  */

		    	};
			}else{
				//console.log('tenim parametres');
				const parametres = queryString.parse(this.props.location.search);				
				let currentEvent;

				if(parametres.c){
					currentEvent = parametres.c;
				}else{
					currentEvent = '';
				}

				/*let nombre, tlf, email;

				if(parametres.n){					
					nombre = parametres.n;
				}else{					
					nombre = '';
				}

				if(parametres.m){
					email = parametres.m;
				}else{
					email = '';
				}

				if(parametres.t){
					tlf = parametres.t;
				}else{
					tlf = '';
				}
				*/
				
				this.state = {
		      		tabValue: "inscribirse",
		      		currentImage: 0,
		      		currentEvent: currentEvent,
		      		openAlert: false
		        	/*nombre: nombre,
		        	email: email,
		        	tlf: tlf*/   		        	
		    	};

				

			}


			
		}else{

			if(this.props.location.search == ''){
				//console.log('no tenim parametres');
				this.state = {
			      	tabValue: "entrar",
			      	currentImage: 0,
			      	currentEvent: 0,
			      	openAlert: false
			    };

			}else{
				//console.log('tenim parametres');
				const parametres = queryString.parse(this.props.location.search);
				if(parametres.chpw){
					console.log('mostrar missatge que thas de tornar a logar pq has fer reset');
					this.state = {
				      	tabValue: "entrar",
				      	currentImage: 0,
				      	currentEvent: 0,
				      	openAlert: true
				    };
					
				}else{
					//console.log('no te el parametre');
					this.state = {
				      	tabValue: "entrar",
				      	currentImage: 0,
				      	currentEvent: 0,
				      	openAlert: false
				    };
				}
			}

			/*
			this.state = {
		      tabValue: "entrar",
		      currentImage: 0,
		      currentEvent: 0
		    };
		    */
		}    

		this.props.setCurrentPage('loginView');

	}

	updateBackground(){
		
		this.setState(prevState => ({
          currentImage: (prevState.currentImage + 1)%8
        }));
		
	}

	componentDidMount(){		
		this.interval = setInterval(() => this.updateBackground(), 10000);	 
	}


	componentWillUnmount() {
    	clearInterval(this.interval);
    }

    componentDidUpdate(prevProps) {
    	if(this.props.isSignedIn){
    		if(this.state.currentEvent === 0){
    			//console.log('entroooo currentEvent');
    			history.push('/campaigns');
    		}else{
    			history.push('/campaigns/'+this.state.currentEvent);
    		}    		
    	}
    }


	handleChange = (event, value) => {
    	this.setState({ tabValue: value });
  	};

  	changeToInscribirse = () => {
  		this.setState({tabValue: 'inscribirse'});
  	}

  	handleCloseAlert = () => {
    	this.setState({ openAlert: false });
  	};

	render(){

		const { classes } = this.props;

		

		if(this.props.isSignedIn === null){
			return (<div className="template-loading"><CircularProgress color="secondary" /></div>);
		}

		if(this.props.isSignedIn){

			/*history.push('/campaigns');*/
			return (<div></div>);
		}else{

			return(
				<div className="template-login-page">
					{/*<div className="container-background"></div>*/}
					<BackgroundContainer currentImage={this.state.currentImage} futurImage={this.state.nextImage} />
					{/*<img className="login-background" src="https://www.motorprivee.com/media/banner-img-01.jpg"  />*/} 
					<div>						
						<Grid container style={{flexGrow: 1}}  justify="center">
							<Grid item xs={12} sm={12} md={10} lg={8} >
								<Grid container alignItems="center" justify="center">
									<Grid item xs={12} sm={12} md={6} >
										<div className="container-block-login" >
											<div className={classes.root} >
									        	<Tabs
									            	value={this.state.tabValue}
										            onChange={this.handleChange}
										            classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}						            
										        >
									            	<Tab value="entrar" label={i18n.t('login.login')} classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
									            	<Tab value="inscribirse" label={i18n.t('login.signin')} classes={{ root: classes.tabRoot, selected: classes.tabSelected }} />
									          </Tabs>
										    </div>
										    {this.state.tabValue === 'entrar' && 
										    	<TabContainer>
										    		<div className="login-box">
														<FacebookAuth btnText={i18n.t('login.loginfacebook')} />
														<GoogleAuth btnText={i18n.t('login.logingoogle')} />
														<div className="separator-with-text">{i18n.t('login.or')}</div>
														<LoginAuth />								
														<p className="link-login" onClick={this.changeToInscribirse}>{i18n.t('login.notyet')}</p>	
													</div>	
										    	</TabContainer>


											}
										    {this.state.tabValue === 'inscribirse' && 
										    	<TabContainer>
										    		<div className="login-box">
											    		<FacebookAuth btnText={i18n.t('login.siginfacebook')} />
														<GoogleAuth btnText={i18n.t('login.sigingoogle')} />
														<div className="separator-with-text">{i18n.t('login.or')}</div>
														<SignUpAuth nombre='' email='' tlf=''  />													
													</div>
										    	</TabContainer>
										    }



										</div>
									</Grid>
									<Hidden mdDown>
										<Grid item xs={12} sm={12} md={6}  >
											<div className="container-block-login" >
												<div className="container-text-intro">
													<p className="text-intro" 
														dangerouslySetInnerHTML={{__html: i18n.t('login.sentence')}}>
										        		
										        	</p>
										        </div>
											</div>
										</Grid>
									</Hidden>
								</Grid>
								
							</Grid>
						</Grid>
					</div>
					<br/>
					<br/>
					

					<br/><br/><br/>
					{/*<CurrentInfo />*/}

					<Dialog
			          open={this.state.openAlert}
			          onClose={this.handleCloseAlert}
			          aria-labelledby="alert-dialog-title"
			          aria-describedby="alert-dialog-description"
			        >		          
			          	<DialogContent>			            	
			            	<p className="text-alert-change-pwd">Debes volver a logarte con el nuevo password para finalizar el proceso correctamente.</p>
			            </DialogContent>
			          	<DialogActions>
			        		<button className="btn btn1" onClick={this.handleCloseAlert} >Entendido</button>			            
			           	</DialogActions>
			        </Dialog>
				</div>
			);
		}
	}

}

const TabContainer = ({ children }) => {
  return (
    <Typography component="div" >
      {children}
    </Typography>
  );
};


const BackgroundContainer = ({ currentImage }) => {

	
	/*const images = [
			"https://cdn.mediagach.com/motor-privee/banners/banner-gb-sea.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-03.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-04.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-05.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-06.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-07.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-08.jpg"
	];*/

	const images = [
			"https://media.motorprive.com/banners/banner-bg-iceland.jpg",
			"https://media.motorprive.com/banners/banner-bg-driver.jpg",
			"https://media.motorprive.com/banners/banner-bg-beach-curve.jpg"				
	];


	return(
		<div className="container-images-bg">
			{/*<div className="container-background" style={{ backgroundImage: `url(${images[currentImage]})` }}></div>*/}
			<div className="container-background" style={{ backgroundImage: `url(${images[0]})` }}></div>
			<div className="container-background" style={{ backgroundImage: `url(${images[1]})` }}></div>
			<div className="container-background" style={{ backgroundImage: `url(${images[2]})` }}></div>
			
			{/*<div className="container-background-down" style={{ backgroundImage: `url(${images[futurImage]})` }}></div>*/}
		</div>
		
	);

};


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		userName: state.auth.userName
	};
};




LoginView = withStyles(stylesTabs)(LoginView);

LoginView = connect(
	mapStateToProps, { setCurrentPage }	
)(withNamespaces()(LoginView));

export default LoginView;

