export const C_MAIN = '#fff';
export const C_MAIN_LIGHT = '#f5f5f5';
export const C_MAIN_DARK = '#c2c2c2';

export const C_SECONDARY = '#05bdac';
export const C_SECONDARY_LIGHT = '#03c7b5';
export const C_SECONDARY_DARK = '#009688';


/*
export const C_SECONDARY = '#009688';
export const C_SECONDARY_LIGHT = '#effaf6';
export const C_SECONDARY_DARK = '#00675b';
*/

/*export const C_TEXT = '#666459';
export const C_TEXT_LIGHT = '#aead9e';*/
export const C_TEXT = '#292929';
export const C_TEXT_LIGHT = '#dcdcdc';
