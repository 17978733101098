import React from 'react';
import { connect } from 'react-redux';

import history from '../history';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import queryString from 'query-string';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';




class PortadaView extends React.Component {

	
	constructor(props) {

		super(props);	

		if(this.props.location.search == ''){
			//no tenim parametres
			this.state = {
				userType: null,
				classType: 'template-default',
				brandsType: ['mercedes','citroen', 'peugeot', 'smart', 'fiat']
			};

		}else{
			//tenim parametres
			const parametres = queryString.parse(this.props.location.search);				
			

			if(parametres.t){
				if(parametres.t == 'premium'){
					this.state = {
						userType: parametres.t,
						classType: 'template-nondefault',
						brandsType: ['mercedes','smart', 'lexus', 'bmw', 'audi']
					};
				}else{
					this.state = {
						userType: null,
						classType: 'template-default',
						brandsType: ['mercedes','citroen', 'peugeot', 'smart', 'fiat']
					};
				}
				
				
			}else{
				this.state = {
					userType: null,
					classType: 'template-default',
					brandsType: ['mercedes','citroen', 'peugeot', 'smart', 'fiat']
				};
			}
		}

	}

	componentDidUpdate(prevProps) {
    	if(this.props.isSignedIn){
    		history.push('/campaigns');    		  		
    	}
    }

    renderUserType = () => {

    	/*if(this.state.userType === null){
    		
    		return(
	    		<div>
	    			render user : NULL
	    		</div>
	    	);

    	}else{
    	*/
    		
    		return(
	    		<div className={this.state.classType}>	    			
	    			<Grid container justify="center" alignItems="flex-start" >	
						<Grid item xs={11} sm={11} md={9} lg={7}>
							<Grid container justify="center" alignItems="flex-start" >
								<Grid item xs={12} sm={6} md={6} lg={6}>
									<a href={"/users/authentication?b="+this.state.brandsType[0]} >
										<div className="container-line first-container" >	
										
										</div>
									</a>
									
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={6}>
									<div className="container-line second-container">											
										<p className="container-claim-text first-claim">
											"El mayor buscador de<br/>ventas privadas de coches"
										</p>
									</div>
								</Grid>
							</Grid>

							<Grid container justify="center" alignItems="flex-start" >
								<Grid item xs={12} sm={6} md={6} lg={6}>
									<div className="container-line third-container">	
										<p className="container-claim-text">
											<span>Ventas exclusivas </span><br/> de coches sólo para socios											
										</p>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={6}>
									<a href={"/users/authentication?b="+this.state.brandsType[1]} >
										<div className="container-line fourth-container">	
											
										</div>
									</a>
								</Grid>								
							</Grid>		

							<Grid container justify="center" alignItems="flex-start" >
								<Grid item xs={12} sm={6} md={6} lg={6}>
									<a href={"/users/authentication?b="+this.state.brandsType[2]} >
										<div className="container-line fifth-container">	
											
										</div>
									</a>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={6}>
									<a href={"/users/authentication?b="+this.state.brandsType[3]} >
										<div className="container-line sixth-container">	
											
										</div>
									</a>
								</Grid>
							</Grid>
							<Grid container justify="center" alignItems="flex-start" >
								<Grid item xs={12} sm={6} md={6} lg={6}>
									<a href={"/users/authentication?b="+this.state.brandsType[4]} >
										<div className="container-line seventh-container">	
											
										</div>
									</a>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={6}>
									<div className="container-line eighth-container">	
										<p className="container-claim-text last-claim">
											Grandes marcas,<br/>precios pequeños
										</p>
									</div>
								</Grid>														
							</Grid>						
						</Grid>
					</Grid>
	    		</div>
	    	);


    		/*
    		return(
	    		<div className={this.state.classType}>	    			
	    			<Grid container justify="center" alignItems="flex-start" >	
						<Grid item xs={11} sm={11} md={9} lg={7}>
							<Grid container justify="center" alignItems="flex-start" >
								<Grid item xs={12} sm={6} md={7} lg={7}>
									<a href={"/users/authentication?b="+this.state.brandsType[0]} >
										<div className="container-line first-container" >	
										
										</div>
									</a>
									
								</Grid>
								<Grid item xs={12} sm={6} md={5} lg={5}>
									<div className="container-line second-container">											
										<p className="container-claim-text">
											render user : {this.state.userType}
										</p>
									</div>
								</Grid>
							</Grid>
							<Grid container justify="center" alignItems="flex-start" >
								<Grid item xs={12} sm={6} md={5} lg={5}>
									<div className="container-line third-container">	
										<p className="container-claim-text">
											render user : {this.state.userType}
										</p>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={7} lg={7}>
									<a href={"/users/authentication?b="+this.state.brandsType[1]} >
										<div className="container-line fourth-container">	
											
										</div>
									</a>
								</Grid>								
							</Grid>			
							<Grid container justify="center" alignItems="flex-start" >
								<Grid item xs={12} sm={6} md={7} lg={7}>
									<a href={"/users/authentication?b="+this.state.brandsType[2]} >
										<div className="container-line fifth-container">	
											
										</div>
									</a>
								</Grid>
								<Grid item xs={12} sm={6} md={5} lg={5}>
									<a href={"/users/authentication?b="+this.state.brandsType[3]} >
										<div className="container-line sixth-container">	
											
										</div>
									</a>
								</Grid>
							</Grid>
							<Grid container justify="center" alignItems="flex-start" >
								<Grid item xs={12} sm={5} md={5} lg={5}>
									<a href={"/users/authentication?b="+this.state.brandsType[4]} >
										<div className="container-line seventh-container">	
											
										</div>
									</a>
								</Grid>
								<Grid item xs={12} sm={7} md={7} lg={7}>
									<div className="container-line eighth-container">	
										<p className="container-claim-text">
											render user : {this.state.userType}
										</p>
									</div>
								</Grid>								
							</Grid>						
						</Grid>
					</Grid>
	    		</div>
	    	);
			*/	    	

    	//}

    };


	render(){

		const { classes } = this.props;

		if(this.props.isSignedIn === null){
			return (<div className="template-loading"><CircularProgress color="secondary" /></div>);
		}

		if(this.props.isSignedIn){
			return (<div></div>);
		}else{
			return(
				<div className="template-page template-portada">
					
					{this.renderUserType()}
				</div>
			);
		}

	}

}


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		userName: state.auth.userName
	};
};



PortadaView = connect(
	mapStateToProps,	
)(withNamespaces()(PortadaView));


export default PortadaView;


