import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';


import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';


import InterestsForm from '../components/Forms/InterestsForm.jsx';
import NoAccessPage from './NoAccessPage.jsx';


import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';


class InterestsUserView extends React.Component{

	constructor(props) {
		super(props);	
	}

	componentDidMount() {
  		window.scrollTo(0, 0);
  		
	}

	renderInterestsForm(){
		if(this.props.isSignedIn === null){			
			return(<div className="container-loading"><CircularProgress color="secondary" /></div>);			
		}else if(this.props.isSignedIn === true){	
			return( <InterestsForm /> );
		}else{
			return(<NoAccessPage />);
			console.log("No puedes acceder sin estar registrado 2");
		}
	}


	render(){
		return(
			<div className="template-page template-interests-page">
				<Grid container justify="center">
					<Grid item xs={11} sm={10} md={10} lg={8}>	
						{this.renderInterestsForm()}
					</Grid>
				</Grid>	
			</div>
		);
	}

}


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn
	};
};


InterestsUserView = connect(
	mapStateToProps, 
	{  }
)(withNamespaces()(InterestsUserView));


export default InterestsUserView;



