import {
  C_MAIN, 
  /*C_MAIN_LIGHT, 
  C_MAIN_DARK, 
  C_SECONDARY, 
  C_SECONDARY_LIGHT, 
  C_SECONDARY_DARK,
  C_TEXT,
  C_TEXT_LIGHT*/ 

} from '../variables.jsx';

const headerStyle = theme => ({
  container: {
    height: '75px',   
    [theme.breakpoints.up("lg")]: {
      width: 1140
    },
    [theme.breakpoints.only("md")]: {
      width: 960
    },
    [theme.breakpoints.only("sm")]: {
      width: 720,
      height: '60px'
    },
    [theme.breakpoints.only("xs")]: {
      width: 540,
      paddingLeft: 25
    }
  },
  containerBackground:{
    background: '#151515',
    height: '75px'   
  },
  logoBrand:{
    color: C_MAIN,
    textAlign: 'left',
    [theme.breakpoints.only("sm")]: {
      textAlign: 'center'
    },
    [theme.breakpoints.only("xs")]: {
      textAlign: 'left'
    }
  }
});

/*const drawerWidth = 240;


const headerStyle = {
  topBarBrand:{
    minHeight: '95px',
    backgroundColor: C_MAIN,
  },
  menuBar:{
    boxShadow: 'none',
  },
  center:{
    textAlign: 'center',
  },
  menuStyle: {
    minHeight: '52px',
    borderTop: '1px solid #efeee6',
    borderBottom: '1px solid #efeee6',   
  },
  menuOptionsStyle: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    borderRadius: '0',
    borderBottom: '2px solid transparent',
    padding: '16px 16px',
    margin: '0 20px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: C_SECONDARY,
    },
    '&:active': {
      boxShadow: 'none',      
    },
    '&:focus': {
      color: C_SECONDARY,
      borderBottom: '2px solid '+C_SECONDARY,
    },
  },
  menuLateral: {
    width: '250px',
    
  },
  flex: {
    flex: 1,
    textAlign: 'center',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',    
    justifyContent: 'flex-end',
  },
  llenguaCanvi:{
    backgroundColor: 'red'
  }
};

*/

export default headerStyle;