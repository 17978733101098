import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';

import history from '../../history';

import { sendContactMail } from '../../actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const stylesAlert = theme => ({
  	root: {
        background: '#009688'
    }
});


class ContactForm extends React.Component {

	constructor(props) {
		super(props);

		this.handleChangeInput = this.handleChangeInput.bind(this);

		this.state = {
	        nombre:'',	         
	        email: '',   
	        question: this.props.contactType,    
	        message: '',
	        operacioOk: '',
	        openAlert: false,
	        privacity: false,
	        errorPrivacity: false   		
	    };
	}




	sendContactMessage = (formValues) => {
		
		if(this.state.privacity === false){
			this.setState({
		    		errorPrivacity : true		    				
		    	});
		}else{
			this.props.sendContactMail(formValues['nombre'], formValues['email'], formValues['message'], formValues['question'])
			.then( result => {
				//console.log('resposta:::');
				//console.log(result);
				//this.openAlert(i18n.t('errors.'+result.error));
				history.push("/thank-you-contact");

			});
		}

		
		
	}

	openAlert = (textMissatge) => {
    	this.setState({ openAlert: true, operacioOk: textMissatge });  
	};

	closeAlert = () => {
		this.setState({ openAlert: false });
	};


	handleChangeInput(e){	
		const inputName = e.target.name;
		const inputValue = e.target.value;
		var copiaState = Object.assign({}, this.state);
		copiaState[inputName] = inputValue;
		this.setState(copiaState);  		
	}

	handleChangeCheckBox(e){	

		var copiaState = Object.assign({}, this.state);
		if(e.target.value == "false"){
			copiaState[e.target.id] = true;
		}else{
			copiaState[e.target.id] = false;
		}
		this.setState(copiaState);
	}

	renderError({error, touched}){
		
		if(touched && error){
			return(
				<div>	
					<div className="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{error}</div>
				</div>
			);
		}

	}

	renderInput = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainer">
				<label className="input-label">{label}</label>				
				<input {...input} autoComplete="off" value={initialValue} placeholder={i18n.t('global.enter')+" "+label.toLowerCase()} type={type} disabled={disabled} />
				{this.renderError(meta)}
			</div>
		);
	};

	renderTextArea = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainer">
				<label className="input-label">{i18n.t('contact.reason')}</label>				
				<textarea {...input} autoComplete="off" value={initialValue} placeholder={label} type={type} disabled={disabled} />
				{this.renderError(meta)}
			</div>
		);

	};

	renderSelect= ({input, label, meta, initialValue, children}) => {
		return (
			<div className="inputContainer selectContainer">
				<label className="input-label">{label}</label>
				<select {...input} value={initialValue}>
					{children}
				</select>
				{this.renderError(meta)}
			</div>
		);
	};

	renderPrivacity = () => {

		return(
			<div className="inputContainerCheckbox"> 			
				<input id="privacity" name="privacity" type="checkbox" checked={this.state.privacity} value={this.state.privacity} onChange={(e) => this.handleChangeCheckBox(e)} /><label className="input-label" dangerouslySetInnerHTML={{__html: i18n.t('landing.acceptPrivacity')}}></label>
			</div>
		);

	};

	renderErrorPrivacity = () => {

		if(!this.state.errorPrivacity){			
			return(
				<div></div>
			);
		}else{
			return(
				<div style={{marginTop: '15px', border: '1px solid #ed4c3e', paddingTop: '10px' }}>	
					<div className="error-container" style={{textAlign: 'center'}}><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{i18n.t('landing.errorPrivacity')}</div>
				</div>
			);
		}

	};

	render(){

		const { openAlert } = this.state;
		const { classes } = this.props;

		return(
			<div>
				<form onSubmit={ this.props.handleSubmit(this.sendContactMessage) } >
					<Grid container justify="center">
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Field name="nombre" onChange={this.handleChangeInput} initialValue={this.state.nombre} component={this.renderInput} label={i18n.t('contact.name')} />
						</Grid>
					</Grid>
					<Grid container justify="center">
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Field name="email" onChange={this.handleChangeInput} initialValue={this.state.email} component={this.renderInput} label={i18n.t('contact.email')} />					
						</Grid>
					</Grid>
					<Grid container justify="center">
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Field name="question" onChange={this.handleChangeInput} initialValue={this.state.question} component={this.renderSelect} label={i18n.t('contact.askAbout')}>
								<option value="0" name="no-value" disabled style={{fontStyle: 'italic'}}>{i18n.t('contact.selectOption')}</option>
								<option value="1" name="userAccount">{i18n.t('contact.userAccount')}</option>
								<option value="2" name="campaigns">{i18n.t('contact.aboutCampaigns')}</option>
								<option value="3" name="collaboration">{i18n.t('contact.collaboration')}</option>
								<option value="4" name="signout">{i18n.t('contact.signOut')}</option>
								<option value="5" name="other">{i18n.t('editAccount.other')}</option>
							</Field>				
						</Grid>
					</Grid>
					<Grid container justify="center">
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Field name="message" onChange={this.handleChangeInput} initialValue={this.state.message} component={this.renderTextArea} label={i18n.t('contact.writeLines')} />
						</Grid>
					</Grid>
					<Grid container justify="center">
						<Grid item xs={12} sm={6} md={6} lg={6}>
							{this.renderPrivacity()}
							{this.renderErrorPrivacity()}
						</Grid>
					</Grid>
					<button className="btn btn1 btn-contact">{i18n.t('contact.send')}</button>
				</form>

				<Snackbar
			          anchorOrigin={{  vertical: 'bottom', horizontal: 'right' }}
			          open={openAlert}
			          onClose={this.closeAlert}			         
			          ContentProps={{			            
			            classes: {
                        	root: classes.root
                    	}
			          }}
			          autoHideDuration={2500}
			          message={this.state.operacioOk}
			          
			    />

			</div>
		);
	}

}



const validate = (formValues) => {

	const errors = {};
	const requiredFields = [	    
	    'nombre',
	    'email',
	    'question'
  	];
 	
  	requiredFields.forEach(field => {
	    if (!formValues[field]) {
	      errors[field] = i18n.t('errorForms.required');
	    }
  	})

  	if ( formValues.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email) ) {
    	errors.email = i18n.t('errorForms.invalidemail');
  	}

  

	return errors;

};

const mapStateToProps = (state) => {
	return { 
		userId: state.auth.userId, 
		userMail: state.auth.userMail, 
		userName: state.auth.userName,
		selectedLanguage: state.auth.selectedLanguage
	};
};

ContactForm = reduxForm({
  form: 'ContactForm',
  validate: validate 
})(ContactForm);


ContactForm = withStyles(stylesAlert)(ContactForm);

ContactForm = connect(
	mapStateToProps, 
	{ sendContactMail }
)(withNamespaces()(ContactForm));


export default ContactForm;
