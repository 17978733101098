import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import ContactForm from '../components/Forms/ContactForm.jsx';

import queryString from 'query-string';

//import TagManager from 'react-gtm-module';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { setCurrentPage } from '../actions/';

/*
const tagManagerArgs = { 	   
    dataLayer: {        
        page: 'contactooo'
    },
    dataLayerName: 'PageDataLayer'
};
*/


class ContactView extends React.Component{

	constructor(props) {

		super(props);	

		//console.log('constructor contact');
		//console.log(this.props.location.search);

		if(this.props.location.search == ''){
			this.state = {
				contactType: '0'
			};
		}else{
			const parametres = queryString.parse(this.props.location.search);
			//console.log(parametres);
			if(parametres.type){
				this.state = {
					contactType: parametres.type
				};
			}else{
				this.state = {
					contactType:'0'
				};
			}
		}
		this.props.setCurrentPage('contact');
		//TagManager.dataLayer(tagManagerArgs);

	}

	componentDidMount(){
		
	}

	render(){

		return(
			<div className="template-page template-contact-page">
				<Grid container justify="center">
					<Grid item xs={11} sm={8} md={6} lg={5}>
						<div className="container-block-contact">
							{/*<div className="icon-title-section"><FontAwesomeIcon icon={['fal', 'comment-alt-smile']} /></div>*/}
							<p className="title-text">{i18n.t('contact.contact')}</p>	
							<p className="text-comments" dangerouslySetInnerHTML={{__html: i18n.t('contact.topText')}}></p>
							<ContactForm contactType={this.state.contactType} />
						</div>
					</Grid>
				</Grid>
			</div>
		);

	}


}

const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		userName: state.auth.userName
	};
};



ContactView = connect(
	mapStateToProps, { setCurrentPage }	
)(ContactView);

export default withNamespaces()(ContactView);


