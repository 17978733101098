import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';


import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import NoAccessPage from './NoAccessPage.jsx';
import AlertsForm from '../components/Forms/AlertsForm.jsx';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setCurrentPage } from '../actions/';


class AlertsUserView extends React.Component{


	constructor(props){
		super(props);
		this.state = {
			loadInfo: false
		};

		this.props.setCurrentPage('alerts');

	}


	renderAlertsUser(){

		if(this.props.isSignedIn === null){			
			return(<div className="container-loading"><CircularProgress color="secondary" /></div>);			
		}else if(this.props.isSignedIn === true){	
			return( <AlertsForm /> );
		}else{
			return(<NoAccessPage />);
			//console.log("No puedes acceder sin estar registrado 2");
		}		
		
	}

	render(){

		return(
			<div className="template-page template-alerts-page">
				<Grid container justify="center">
					<Grid item xs={11} sm={10} md={8} lg={6}>									
						{this.renderAlertsUser()}
					</Grid>
				</Grid>	
				
			</div>
		);

	}

}

const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn
	};
};


AlertsUserView = connect(
	mapStateToProps, 
	{ setCurrentPage }
)(withNamespaces()(AlertsUserView));


export default AlertsUserView;
