import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import NoAccessPage from './NoAccessPage.jsx';
import StepsBlock from '../components/StepsBlock.jsx';



class StepsProfileView extends React.Component {

	constructor(props) {
		super(props);
	}

	renderStepsBlock(){

		if(this.props.isSignedIn === null){			
			return(<CircularProgress color="secondary" />);			
		}else if(this.props.isSignedIn === true){	
			return ( 
				<div>
					<StepsBlock />					
				</div> );
		}else{
			return(<NoAccessPage />);
			//console.log("No puedes acceder sin estar registrado 2");
		}

	}


	render(){

		return(
			<div className="template-page template-container-steps-profile">
				<Grid container justify="center">
					<Grid item xs={11} sm={8} md={6} lg={5}>						
			          	<div className="container-block-personal-data">
			          		{this.renderStepsBlock()}			          		
			          	</div>
			        </Grid>
				</Grid>								
			</div>
		);

	}

}


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		userId: state.auth.userId
	};
};


StepsProfileView = connect(
	mapStateToProps, {  }
)(withNamespaces()(StepsProfileView));

export default StepsProfileView;



