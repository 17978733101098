import {
  C_MAIN, 
  /*C_MAIN_LIGHT, 
  C_MAIN_DARK, */
  C_SECONDARY, 
  /*C_SECONDARY_LIGHT, 
  C_SECONDARY_DARK, 
  C_TEXT,
  C_TEXT_LIGHT*/

} from '../variables.jsx';

const headerAuthStyle = theme => ({
  root: {
    flexGrow: 1,
        
  },
  grow: {
    flexGrow: 1,
  },
  menuMobileTranparentContainer:{
    backgroundColor: 'transparent',
  },
  menuMobileContainer:{
    /*backgroundColor: 'rgba(0,0,0,0.9)',*/
    backgroundColor: C_MAIN,
    minWidth: '250px',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',    
    justifyContent: 'flex-start',
    color: '#000',
  },
  iconCloseMobileMenu: {
    color: '#000',
    '&:hover': {
      background: 'transparent',      
    }
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
    '&:hover': {
      background: 'transparent',      
    }
  },
  mobileMenuOption: {
    '&:hover': {
      background: 'transparent',
      color: C_SECONDARY,
    },
    
  }

});


export default headerAuthStyle;