import React from 'react';
import ReactDOM from 'react-dom';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



class ShareButton extends React.Component{

		

	constructor(props) {
		super(props);

		this.state = {
			openWindow: false,
			currentUrl : '',
			titleEvent: props.titleCampaign
			
			
		};	

	}



	handleClickOpenShare = () => {
		this.setState({ openWindow: true });

	};

	handleClickFacebook = () => {

		//console.log('clikem facebook');
		window.open("https://www.facebook.com/sharer/sharer.php?u="+window.location.href,'facebook-share-dialog',"width=626,height=436");

	};

	handleClickTwitter = () => {
		//console.log('clickem twitter');
		var width  = 626,
		        height = 436,
		        left   = (window.innerWidth  - width)  / 2,
		        top    = (window.innerHeight - height) / 2,
		        url    = "http://twitter.com/share?url="+window.location.href+"&text="+this.state.titleEvent,
		        opts   = 'status=1' +
		                 ',width='  + width  +
		                 ',height=' + height +
		                 ',top='    + top    +
		                 ',left='   + left;
		    

    	window.open(url, 'twitter-share-dialog', opts);

	};

	handleClickLinkedin = () => {
		//console.log('clickem linkedin');
		window.open("https://www.linkedin.com/shareArticle?mini=true&url="+window.location.href+"&title="+this.state.titleEvent+"");
	};

	handleClickMail = () => {
		//console.log('clickem email');
		window.open("mailto:?subject="+this.state.titleEvent+ "&body="+window.location.href,'mail-share-dialog',"width=626,height=436");
	};

	handleClickWhatsapp = () => {
		//console.log('clickem whatsapp');
		window.location.href = "whatsapp://send?text="+this.state.titleEvent+" - "+window.location.href;
		
	};

	handleCloseWindow = () => {
		//console.log('hem fet click');
		this.setState({ openWindow: false });

		//console.log(this.state.openWindow);
	};

	copyToClipboard = () => {
		//console.log('copy paste');
		//console.log(document.getElementById('container-input-path').innerHTML);
		/*const contentInput = document.getElementById('container-input-path');
		contentInput.select();
		document.execCommand('SelectAll');
		document.execCommand('copy', false, null);
		*/
		this.state.currentUrl.select()
        let onClipboard = this.state.currentUrl.value;
        onClipboard = document.execCommand('copy');
		
	}

	render(){
		return(
			<div className="container-sharebutton">
				<div className="container-btn-share" onClick={this.handleClickOpenShare} >
					<div className="share-in"><FontAwesomeIcon icon={['fab', 'linkedin-in']} /></div>
					<div className="share-twitter"><FontAwesomeIcon icon={['fab', 'twitter']} /></div>
					<div className="share-fb"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></div>
					<div className="share-envelope"><FontAwesomeIcon icon={['fal', 'envelope']} /></div>
					<div className="share-whatsapp"><FontAwesomeIcon icon={['fab', 'whatsapp']} /></div>		
					<span className="text-share">{i18n.t('global.share')}</span>		
				</div>
				<Dialog
		          fullScreen
		          open={this.state.openWindow}
		          onClose={this.handleCloseWindow}
		          TransitionComponent={Transition}
		        >
		        	<Grid container justify="center" >
		        		<Grid item xs={12} sm={10} md={8} lg={4}>
		        			<div className="container-share-btns">
		        				<div aria-label="Close" className="header-share" onClick={this.handleCloseWindow}>		        					
		        					<FontAwesomeIcon icon={['fal', 'times']}  />
		        				</div>
        						<p>{i18n.t('global.shareIt')}</p>        						
		        			</div>
		        			<div className="container-copy-share">
		        				<p className="copy-to-friends">Copia y pega la dirección a tus amigos</p>
		        				<input type="text" ref={(input) => this.state.currentUrl = input} className="input-path" value={window.location.href} readOnly ></input><div className="copy-icon" onClick={this.copyToClipboard}><FontAwesomeIcon icon={['fal', 'paste']}  /></div>
		        			</div>
		        			<Grid container justify="center" >
		        				<Grid item xs={4}>
		        					<div className="list-share-btn">
		        						<div className="share-btn-container facebook-icon">
		        							<FontAwesomeIcon icon={['fab', 'facebook-f']} onClick={this.handleClickFacebook}  />
		        						</div>
		        					</div>
		        				</Grid>
		        				<Grid item xs={4}>
		        					<div className="list-share-btn">
		        						<div className="share-btn-container twitter-icon">
		        							<FontAwesomeIcon icon={['fab', 'twitter']} onClick={this.handleClickTwitter}  />
		        						</div>
		        					</div>
		        				</Grid>
		        				<Grid item xs={4}>
		        					<div className="list-share-btn">
		        						<div className="share-btn-container linkedin-icon">
		        							<FontAwesomeIcon icon={['fab', 'linkedin-in']} onClick={this.handleClickLinkedin}  />
		        						</div>
		        					</div>
		        				</Grid>
		        				<Grid item xs={4}>
		        					<div className="list-share-btn">
		        						<div className="share-btn-container envelope-icon">
		        							<FontAwesomeIcon icon={['fal', 'envelope']}  onClick={this.handleClickMail} />
		        						</div>
		        					</div>
		        				</Grid>
		        				<Grid item xs={4}>
		        					<div className="list-share-btn">
		        						<div className="share-btn-container whatsapp-icon">
		        							<FontAwesomeIcon icon={['fab', 'whatsapp']} onClick={this.handleClickWhatsapp}  />
		        						</div>
		        					</div>
		        				</Grid>
		        				<Grid item xs={4}>
		        					<p></p>
		        				</Grid>
		        				
		        			</Grid>
		        		</Grid>


		        	</Grid>
		        </Dialog>
			</div>

		);
	}
}

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

//ShareButton = withStyles()(ShareButton);

ShareButton = withNamespaces()(ShareButton);

export default ShareButton;

