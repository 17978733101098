import React from 'react';
import ReactDOM from 'react-dom';


import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';


import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const styles = theme => ({
 
  demo: {
    height: 40,
  }
});



class CookieMessage extends React.Component{

	constructor(props){

		super(props);

		this.state = {
			cookieName: 'mp_sess',
			message: i18n.t('cookies.message'),
			expiresIn: 60,
			cookieSet: false
		};

	}


	componentWillMount(){

		if (this.props.message) this.setState({message: this.props.message});
    	if (this.props.expires) this.setState({expiresIn: this.props.expires});

    	this.checkCookie();
	}

	componentDidMount(){

		window.setTimeout(() => this.setState({cookieSet: true}),5000);

	}


	closeMessage(evt){

    	evt.preventDefault();
    	this.setState({cookieSet : true})
  	
  	}

	checkCookie() {
	    //agafem la cookie
	    let cookie = this.getCookie();
	    //mirem si existeix 
	    if (cookie !=="") {this.setState({cookieSet : true}); return;}
	    //si no existeix posem el missatge
	    this.setCookie();
	}

	setCookie() {
    	
    	var d = new Date();
    	var cvalue = this.setCookieValue();
	    d.setTime(d.getTime() + (this.state.expiresIn*24*60*60*1000));
	    let expires = "expires="+d.toUTCString();	    
	    document.cookie = this.state.cookieName + "=" + cvalue + "; " + expires;

	    /*
	    console.log('setCookie');
	    console.log(expires);
	    console.log(cvalue);
	    */

  	
  	}

  	setCookieValue() {

    	var a = () => { return (((1 + Math.random()) * 65536) | 0).toString(16).substring(1)};
    	return (a() + a() + "-" + a() + "-" + a() + a() + a());

  	}

  	getCookie() {
	    
	    var name = this.state.cookieName + "=";
		var ca = document.cookie.split(';');
	    //console.log(document.cookie);
	    for(let i=0; i<ca.length; i++) {
	        var c = ca[i];
	        while (c.charAt(0)==' ') c = c.substring(1);
	        if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
	    }
	    return "";

  	}


  	renderCookie(){
  		
  		const { classes } = this.props;

  		if(this.state.cookieSet === true){

  		}else{
  			
  			return(
	  			<div>
	  				<Grid container justify="center" alignItems="center" className={classes.demo} >
	  					<Grid item xs={12} sm={12} md={12} lg={12}>
	  						<div className="cookies-message">
	  							<span dangerouslySetInnerHTML={{__html: this.state.message}}></span>
	  							<span onClick={this.closeMessage.bind(this)} ><FontAwesomeIcon icon={['fal', 'times']} /></span>
							</div>
	  					</Grid>	  					
	  				</Grid>
				</div>
	  		);
  		} 
  	}

	render(){

		

		return(
			<div className="cookie-container">
				{this.renderCookie()}
			</div>
		);
	}


}

CookieMessage = withStyles(styles)(CookieMessage);
CookieMessage = withNamespaces()(CookieMessage);

export default CookieMessage;



