import React from 'react';
import { Link } from 'react-router-dom';

import ResetForm from './ResetForm.jsx';

import { authFirebase } from '../Firebase/Firebase.js';


class ResetAuth extends React.Component{

	state = { messageText: '' };

	sendResetEmail = (email, password) => authFirebase.sendPasswordResetEmail(email);

	

	onSubmit = (formValues) => {

		const {email} = formValues;
		//console.log(email);

		this.sendResetEmail(email).then(() => {  			
			this.setState({messageText: 'En breve recibirá un correo electrónico para resetear la contraseña'});
		}).catch(function(error) {
  			console.log(error);
		});

	}

	renderMessage(){
		if(this.state.messageText !== ''){
			return <div className="text-confirmation">{this.state.messageText}</div>;
		}else{
			return <div></div>;
		}
		
	}

	render(){
		return(
			<div className="forget-pwd-box">	
				<p className="title-box">He olvidado mi contraseña</p>	
				<p className="explanation-text">Indica tu email para restaurar la contraseña.</p>		
				<ResetForm onSubmit={this.onSubmit} />
				{this.renderMessage()}				 
				<Link to={{ pathname: '/', hash: '#inscribirse',}} className="link-login link-top">¿Aún no eres socio? Regístrate</Link>				
				<Link to={{ pathname: '/'}} className="link-login">¿Ya eres socio? Pulsa aquí</Link>
			</div>
		);
	}

}

export default ResetAuth;

