import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import { setCurrentPage } from '../actions/';


class TermsView extends React.Component{

	constructor(props) {

		super(props);
	    this.props.setCurrentPage('privacy-policies');

	}

	render(){
		return(			
			<div className="template-legal">
				<Grid container justify="center" >
					<Grid item xs={12}>							
						<div className="container-title">							
							<h1>Aviso legal</h1>							
						</div>
					</Grid>
					<Grid item xs={11} sm={11} md={8} lg={8}>
						<p style={{fontSize: '14px'}}>
							En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico, se exponen los siguientes datos identificativos del prestador del servicio a través del sitio web app.motorprive.com (en adelante, el sitio web):
						</p>
						<ul style={{fontSize: '14px'}}>
							<li>Nombre: <b>Mediagach, SL</b></li>
							<li>Domicilio: C/ Francesc Layret 119, 5º 1ª, 08911. Badalona, Barcelona. España</li>
							<li>NIF: B-67287870</li>
							<li>Correo electrónico: <em>hola@motorprive.com</em></li>
						</ul>
					</Grid>
				</Grid>
			</div>
		);
	}


}

const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		userName: state.auth.userName
	};
};

TermsView = connect(
	mapStateToProps, { setCurrentPage }	
)(TermsView);


export default withNamespaces()(TermsView);

