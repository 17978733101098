import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class ResetForm extends React.Component{


	renderError({error, touched}){		
		if(touched && error){
			return(
				<div>	
					<div class="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{error}</div>
				</div>
			);
		}
	}

	onSubmit = (formValues) => {		
		this.props.onSubmit(formValues);
	}

	renderInput = ({input, label, meta, type}) => {
		return (
			<div className="inputContainer">				
				<input {...input} autoComplete="off" placeholder={label} type={type} />
				{this.renderError(meta)}
			</div>
		);
	};

	
	render(){
		return(
			
			<form className="reset-pwd-form" onSubmit={ this.props.handleSubmit(this.onSubmit) }>
				<Field name="email" type="email" component={this.renderInput} label="*Email" />
				<button className="btn btn1 btn-reset-pwd">Reset Password</button>
			</form>
			
		);
	}


}


const validate = (formValues) => {

	const errors = {};
	const requiredFields = [
	    'email'	    	    
  	];
  	
  	requiredFields.forEach(field => {
	    if (!formValues[field]) {
	      errors[field] = 'Campo obligatorio';
	    }
  	})

	if ( formValues.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email) ) {
    	errors.email = 'Formato de email incorrecto';
  	}

	return errors;

};


export default reduxForm({
	form: 'ResetForm',
	validate: validate
})(ResetForm);



