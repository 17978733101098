import React from 'react';
import ReactDOM from 'react-dom';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const ExpansionPanel = withStyles({
  root: {
  	width: '100%',
   	boxShadow: 'none',
   	backgroundColor: 'transparent',
   	'&:before':{
   		backgroundColor: 'transparent',
   	},
  },
  expanded: {},
})(MuiExpansionPanel);


const ExpansionPanelSummary = withStyles({
  root: {
   
    borderBottom: '0',
    
    minHeight: 56,
    
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);




class FAQSBlock extends React.Component{

	constructor(props) {
		super(props);
		this.myRef = React.createRef();

	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {		
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = event => {

		/*
		if(window.scrollY > this.myRef.current.offsetTop){
			alert('hem passat el FAQS');
		}
		*/

	}

	render(){

		return(
			<div ref={this.myRef} className="faqs-block-container">
				<Grid container justify="center" >
					<Grid item xs={12} sm={10} md={8} lg={6}>
						<p className="title-faqs">{i18n.t('faqs.title')}</p>
					</Grid>
				</Grid>

				<Grid container justify="center" >
					<Grid item xs={12} sm={10} md={8} lg={6}>
						<Grid container justify="flex-start" >

							<ExpansionPanel onClick={ () => { window.gtag('event', 'click-faqs-question-01'); } }>
						        <ExpansionPanelSummary
						          
						          aria-controls="panel-content"						          
						        >
						        	<p className="faqs-question"><FontAwesomeIcon icon={['fal', 'plus-square']} />{i18n.t('faqs.question_01')}</p>
						        </ExpansionPanelSummary>
						        <ExpansionPanelDetails>
							          <p className="faqs-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_01')}}></p>
						        </ExpansionPanelDetails>
						    </ExpansionPanel>		

							<ExpansionPanel onClick={ () => { window.gtag('event', 'click-faqs-question-02'); } }>
						        <ExpansionPanelSummary						         
						          aria-controls="panel-content"						          
						        >
						        	<p className="faqs-question"><FontAwesomeIcon icon={['fal', 'plus-square']} />{i18n.t('faqs.question_02')}</p>
						        </ExpansionPanelSummary>
						        <ExpansionPanelDetails>
							          <p className="faqs-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_02')}}></p>
						        </ExpansionPanelDetails>
						    </ExpansionPanel>									
							<ExpansionPanel onClick={ () => { window.gtag('event', 'click-faqs-question-03'); } }>
						        <ExpansionPanelSummary						          
						          aria-controls="panel-content"						          
						        >
						        	<p className="faqs-question"><FontAwesomeIcon icon={['fal', 'plus-square']} />{i18n.t('faqs.question_03')}</p>
						        </ExpansionPanelSummary>
						        <ExpansionPanelDetails>
							          <p className="faqs-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_03')}}></p>
						        </ExpansionPanelDetails>
						    </ExpansionPanel>								
							<ExpansionPanel onClick={ () => { window.gtag('event', 'click-faqs-question-04'); } }>
						        <ExpansionPanelSummary						          
						          aria-controls="panel-content"						          
						        >
						        	<p className="faqs-question"><FontAwesomeIcon icon={['fal', 'plus-square']} />{i18n.t('faqs.question_04')}</p>
						        </ExpansionPanelSummary>
						        <ExpansionPanelDetails>
							          <p className="faqs-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_04')}}></p>
						        </ExpansionPanelDetails>
						    </ExpansionPanel>	
							<ExpansionPanel onClick={ () => { window.gtag('event', 'click-faqs-question-05'); } }>
						        <ExpansionPanelSummary						          
						          aria-controls="panel-content"						          
						        >
						        	<p className="faqs-question"><FontAwesomeIcon icon={['fal', 'plus-square']} />{i18n.t('faqs.question_05')}</p>
						        </ExpansionPanelSummary>
						        <ExpansionPanelDetails>
							          <p className="faqs-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_05')}}></p>
						        </ExpansionPanelDetails>
						    </ExpansionPanel>									
							<ExpansionPanel onClick={ () => { window.gtag('event', 'click-faqs-question-06'); } }>
						        <ExpansionPanelSummary						         
						          aria-controls="panel-content"						          
						        >
						        	<p className="faqs-question"><FontAwesomeIcon icon={['fal', 'plus-square']} />{i18n.t('faqs.question_06')}</p>
						        </ExpansionPanelSummary>
						        <ExpansionPanelDetails>
							          <p className="faqs-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_06')}}></p>
						        </ExpansionPanelDetails>
						    </ExpansionPanel>								
							<ExpansionPanel onClick={ () => { window.gtag('event', 'click-faqs-question-07'); } }>
						        <ExpansionPanelSummary						          
						          aria-controls="panel-content"						          
						        >
						        	<p className="faqs-question"><FontAwesomeIcon icon={['fal', 'plus-square']} />{i18n.t('faqs.question_07')}</p>
						        </ExpansionPanelSummary>
						        <ExpansionPanelDetails>
							          <p className="faqs-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_07')}}></p>
						        </ExpansionPanelDetails>
						    </ExpansionPanel>	
								
							{/*<Grid item xs={12} sm={6} md={6} lg={6}>
								<div className="block-grid-container" >
									<p className="faqs-question">{i18n.t('faqs.question_01')}</p>
									<p className="faqs-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_01')}}></p>
								</div>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<div className="block-grid-container" >
									<p className="faqs-question">{i18n.t('faqs.question_02')}</p>
									<p className="faqs-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_02')}}></p>	
								</div>					
							</Grid>	
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<div className="block-grid-container" >
									<p className="faqs-question">{i18n.t('faqs.question_03')}</p>
									<p className="faqs-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_03')}}></p>
								</div>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<div className="block-grid-container" >
									<p className="faqs-question">{i18n.t('faqs.question_04')}</p>
									<p className="faqs-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_04')}}></p>
								</div>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<div className="block-grid-container" >
									<p className="faqs-question">{i18n.t('faqs.question_05')}</p>
									<p className="faqs-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_05')}}></p>
								</div>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<div className="block-grid-container" >
									<p className="faqs-question">{i18n.t('faqs.question_06')}</p>
									<p className="faqs-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_06')}}></p>
								</div>
							</Grid>	
							<Grid item xs={12} sm={6} md={6} lg={6} >
								<div className="block-grid-container" >
									<p className="faqs-question">{i18n.t('faqs.question_07')}</p>
									<p className="faqs-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_07')}}></p>
								</div>
							</Grid>	
							*/}

						</Grid>
					</Grid>
				</Grid>
			</div>
		);

	}


}



export default withNamespaces()(FAQSBlock);

