import React from 'react';
import ReactDOM from 'react-dom';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';


const UserMessage = (props) => {
	
	const images = [
		"https://media.motorprive.com/banner-welcome-01.jpg",
		"https://media.motorprive.com/banner-welcome-02.jpg"
		];

	const messages = [
		i18n.t('userMessage.messageOne'),
		i18n.t('userMessage.messageTwo'),
		i18n.t('userMessage.messageThree')
	];

	return(	
		<div className="container-user-message" style={{ backgroundColor: '#fff', }}>
			<p className="user-message-name">Comunicado a todos los soci@s:</p>
			<p className="covid-text">Por motivo del Covid-19 quedan cancelados todos los eventos hasta que la situación se normalice.<br/>
Estamos trabajando para poder realizas las Ventas Privadas de forma online.<br/>
¡Estate atento! En los próximos días mandaremos un comunidado con las novedades. Mientras tanto, te recomendamos que rellenes tu perfil para que podamos seguir enviándote información relevante.</p>
			<a className="btn-edit-profile" href="/users/edit-account">Completar Perfil</a>

		</div>
	);


 


}


export default withNamespaces()(UserMessage);
/*
<div className="container-user-message" style={{ backgroundImage: `url(${images[props.numImatge]})`}}>
			<p className="user-message-name">{i18n.t('userMessage.hello')}, {props.name}!</p>
			<p className="user-message-text">{messages[props.numMessage]}</p>
		</div>


*/