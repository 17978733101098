import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import history from '../history';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';


import { DialogTitle, DialogContent, DialogActions } from '../assets/styles/components/DialogFormStyle.jsx';

import { sendLeadNoLogged, sendLeadUser, getUserData } from '../actions/';

import ShareButton from '../components/ShareButton.jsx';
import FAQSBlock from '../components/FAQSBlock.jsx';

class FutureCustomLandingView extends React.Component{

	constructor(props) {
	    super(props);

	    this.handleChangeInput = this.handleChangeInput.bind(this);
	    this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
	    
	    this.state = {	      
	      	currentEvent: props.currentEvent,	      	
	      	tlf: '',
	      	nombre: '',
	      	email: '',
	      	openForm: false,
	      	operacioOk: '',
	      	openAlert: false,
	      	locale: props.localeLan,
	      	inscritos: props.currentEvent.totalEnrolled,
	      	registrar: true,
	      	privacity: false,
	      	errorPrivacity: false,
	      	poblacion: props.currentEvent.centros[0].poblaciones,
	      	thinking: false,
	      	sourceUrl: props.sourceUrl,
	      	duration: 0,
	      	timeToPopUp: 5,
	      	openRegisterPopup: false,
	      	haveOpenedPopup: 0     
	    };

	    

	}


	componentDidMount() {		
		//document.getElementByClassName("template-container-footer").style.marginBottom="70px";	
		document.body.getElementsByClassName("footer-container")[0].style.marginBottom="70px";
		window.addEventListener('scroll', this.handleScroll);
		this.loadInfoUser();	
		//this.intervalID = setInterval(() => this.tick(), 1000);	
	}

	componentWillUnmount() {
		document.body.getElementsByClassName("footer-container")[0].style.marginBottom="0px";
		window.removeEventListener('scroll', this.handleScroll);
		//clearInterval(this.intervalID);
	}


	tick() {
		
		if(this.props.isSignedIn !== true){
			if(this.state.haveOpenedPopup == 0){
				if(this.state.duration == this.state.timeToPopUp){
		    		if((!this.state.openForm) && (!this.state.openAlert) ){
		    			//alert("portem "+this.state.duration);
		    			this.state.openRegisterPopup = true;
		    			this.state.haveOpenedPopup = 1;
		    			this.state.duration = 0;		    			
		    		}else{
		    			this.state.duration = 0;
		    		}
		    	}else{

		    		let time = this.state.duration + 1;

			    	this.setState({
			      		duration: time
			    	});
		    	}
			}	    	     	
	    }

  	}
  	

	handleScroll = event => {
		if(window.scrollY > 170){
			this.setState({ styleUpButton: 'btn-up-visible' });
		}else{
			this.setState({ styleUpButton: '' });
		}

		/*
		const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if ((windowBottom >= (docHeight-200))&&(this.state.haveOpenedPopup == 0)&&(this.props.isSignedIn !== true)) {
        	this.state.duration = 0;
            this.state.openRegisterPopup = true;
            this.state.haveOpenedPopup = 1;            
        } else {
           
        }
        */
	};

	loadInfoUser(){
		
		//if((this.props.isSignedIn === true)&&(this.props.isAnonymous === false)){
		if(this.props.isSignedIn === true){
			//fetch("http://127.0.0.1:9000/php/servlet/motor-privee/getUserData.php?uid="+this.props.userId)
			//.then(res => res.json())			
			
			this.props.getUserData(this.props.userId, this.props.selectedLanguage)
		    .then( (result) => {			      		
		    		
	        		this.setState({		        		
		        		nombre: this.props.userName,
		        		email: this.props.userMail,
		        		tlf: result.tlf
		        	});  

		        	
		        	const initData = {		        		
		        		nombre: this.props.userName,
		        		email: this.props.userMail,
		        		tlf: result.tlf
		        	}

		        	this.props.initialize(initData)
		        	
		    });
		}		
	}

	handleClickOpen = () => {
    	this.setState({ openForm: true });
  	};

	handleClose = () => {
	    this.setState({ openForm: false });
	};

	handleCloseRegister = () => {
		this.setState({ openRegisterPopup: false, duration: 0 });
	};


	openAlert = (textMissatge) => {
    	this.setState({ openAlert: true, operacioOk: textMissatge });  
	};

	closeAlert = () => {
		this.setState({ openAlert: false });
	};

	handleChangeInput(e){	
		const inputName = e.target.name;
		const inputValue = e.target.value;		
		var copiaState = Object.assign({}, this.state);
		copiaState[inputName] = inputValue;
		this.setState(copiaState);  		
	}

	handleChangeCheckBox(e){		
		var copiaState = Object.assign({}, this.state);
		if(e.target.value == "false"){
			copiaState[e.target.id] = true;
		}else{
			copiaState[e.target.id] = false;
		}
		this.setState(copiaState);
	}

	handleChangeSelectPoblacion(e){		
		this.setState({ poblacion: e.target.value });
	}

	renderInput = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainer">
				<label className="input-label">{label}</label>				
				<input {...input} autoComplete="off" value={initialValue} placeholder={i18n.t('landing.enter')+" "+label.toLowerCase()} type={type} disabled={disabled} />
				{this.renderError(meta)}
			</div>
		);
	};

	renderTextArea = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainer">
				<label className="input-label">{label}</label>				
				<textarea {...input} autoComplete="off" value={initialValue} placeholder={label} type={type} disabled={disabled} />
				{this.renderError(meta)}
			</div>
		);
	};

	renderCheckBox = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainerCheckbox">								
				<input {...input}  type={type} value={initialValue} /><label className="input-label">{label}</label>
				{this.renderError(meta)}
			</div>
		);
	}; 

	renderAcceptPrivacity = () => {
		return(
			<div className="inputContainerCheckbox"> 			
				<input id="privacity" name="privacity" type="checkbox" checked={this.state.privacity} value={this.state.privacity} onChange={(e) => this.handleChangeCheckBox(e)} /><label className="input-label" dangerouslySetInnerHTML={{__html: i18n.t('landing.acceptPrivacity')}}></label>
			</div>
		);
	};

	renderErrorPrivacity = () => {

		if(!this.state.errorPrivacity){			
			return(
				<div></div>
			);
		}else{
			return(
				<div style={{marginTop: '15px', border: '1px solid #ed4c3e', paddingTop: '10px' }}>	
					<div className="error-container" style={{textAlign: 'center'}}><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{i18n.t('landing.errorPrivacity')}</div>
				</div>
			);
		}

	};

	renderConfirmPolicies = () => {		
		//if((this.props.isSignedIn === true)&&(this.props.isAnonymous === false)){
		if(this.props.isSignedIn === true){
			return('');
		}else{
			return(
				<div className="inputContainerCheckbox"> 
					<input id="registrar" name="registrar" type="checkbox" checked={this.state.registrar} value={this.state.registrar} onChange={(e) => this.handleChangeCheckBox(e)} /><label className="input-label">{i18n.t('landing.acceptRegister')}</label>
				</div>
			);
		}
	};

	renderSelectRegion = () => {		
		return(
			<div className="selectRegionContainer">
				<label className="input-label">Selecciona la población dónde irías</label>
				<select id="poblacion" onChange={(e) => this.handleChangeSelectPoblacion(e)} value={this.state.poblacion}>
					{
						this.state.currentEvent.centros.map( (item, index) => (	
			        		<option key={index} value={item.poblaciones} >{item.poblaciones}</option>
			        	))
					}
				</select>
			</div>

		);
	};

	renderError({error, touched}){		
		if(touched && error){
			return(
				<div>	
					<div className="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{error}</div>
				</div>
			);
		}
	};

	renderLoader = () => {

		if(this.state.thinking === true){
			return(
				<div className="div-loader"><div className="container-loader"><CircularProgress color="secondary" /></div></div>
			);
		}else{
			return(
				<div></div>				
			);
		}
	}


	sendLead = (formValues) => {
	
		let inscripciones = this.state.currentEvent.fechaCierreInscripciones.split('-');
		let inscriptionsDate = new Date();
		inscriptionsDate.setFullYear(inscripciones[2],inscripciones[1]-1,inscripciones[0]);
		const today = Date.now();

		const tipusLead = 2;


		this.state.thinking = true;
			
		return sleep(1000).then(() => {
		    // simulate server latency
		    if(!this.state.nombre){
		    	this.state.thinking = false;
		    	throw new SubmissionError({
		        	nombre: i18n.t('errorForms.required'),
		        	_error: 'Login failed!'
		     	 })
		    }else if(!this.state.email){
		    	this.state.thinking = false;
		    	throw new SubmissionError({
		        	email: i18n.t('errorForms.required'),
		        	_error: 'Login failed!'
		     	 })
		    }else if ( this.state.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email) ) {    
		    	this.state.thinking = false;	
		    	throw new SubmissionError({
		        	email: i18n.t('errorForms.invalidemail'),
		        	_error: 'Login failed!'
		     	 })
		    }else if(!this.state.tlf ){
		    	this.state.thinking = false;
		    	throw new SubmissionError({
		        	tlf: i18n.t('errorForms.required'),
		        	_error: 'Login failed!'
		     	 })
		    }else if(this.state.privacity === false){
		    	
		    	this.setState({
		    				errorPrivacity : true, 
		    				thinking: false
		    	});

		    }else{	    	
		    	

		    	var emailsProhibits = ["yopmail", "urhen", "bcaoo", "mailinator", "harakirimail", "dispostable", "sharklasers",
		    						"guerrillamail", "pokemail", "spam4", "spamgourmet", "mintemail", "trashmail", 
		    						"mailforspam", "mailnesia", "mailnull", "superrito.com", "teleworm.us"];

				var totals = emailsProhibits.length;				
				let i, aux;
				

				for(i=0; i<totals; i++){
					if (this.state.email.indexOf(emailsProhibits[i]) === -1) {
						//console.log("no hi es");
					}else{
						this.state.thinking = false;	
				    	throw new SubmissionError({
				        	email: i18n.t('errorForms.invalidemail'),
				        	_error: 'Login failed!'
				     	 })
					}
				}
				

		    	if(this.props.isSignedIn === true){

		    		
		    		this.props.sendLeadUser(this.props.userId, this.state.nombre, this.state.email, this.state.tlf, this.state.currentEvent.idEvent, tipusLead, this.state.poblacion, this.state.sourceUrl )
					.then( (result) => {
						//this.openAlert(result.message);
						//this.handleClose();
						//this.setState({inscritos: this.state.inscritos + 1 });
						history.push("/thank-you");
					});
					
					

				}else{
					
					this.props.sendLeadNoLogged(formValues.nombre, formValues.email, formValues.tlf, this.state.registrar, this.state.currentEvent.idEvent, tipusLead, this.state.poblacion, this.state.sourceUrl )
					.then( (result) => {
						console.log(result);
						if(this.state.registrar == true){	
							history.push(
								{ 
									pathname: "/thank-you-sign-up", 
									search: "?n="+formValues.nombre+"&m="+formValues.email+"&t="+formValues.tlf							
								}
							);	
						}else{
							//this.openAlert(result.message);
							//this.handleClose();
							history.push("/thank-you");
						}

					});

				}
		    }

		  });

		/*

		if(this.props.isSignedIn === true){
			//console.log('estem logats');
			

			this.props.sendLeadUser(this.props.userId, this.state.nombre, this.state.email, this.state.tlf, this.state.currentEvent.idEvent, tipusLead, this.state.poblacion )
			.then( (result) => {
				//this.openAlert(result.message);
				//this.handleClose();
				//this.setState({inscritos: this.state.inscritos + 1 });
				history.push("/thank-you");
			});
			

		}else{
			
			this.props.sendLeadNoLogged(formValues.nombre, formValues.email, formValues.tlf, this.state.registrar, this.state.currentEvent.idEvent, tipusLead, this.state.poblacion )
			.then( (result) => {
				console.log(result);
				if(this.state.registrar == true){	
					history.push(
						{ 
							pathname: "/users/sign-up", 
							search: "?n="+formValues.nombre+"&m="+formValues.email+"&t="+formValues.tlf							
						}
					);	
				}else{
					//this.openAlert(result.message);
					//this.handleClose();
					history.push("/thank-you");
				}

			});
		}
		*/
	}

	renderGetMember(){
		/*
  		if(this.props.isSignedIn !== true){
  			return(
		  		<Grid container justify="center" >
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div className="container-get-registered">
							<p className="text-get-registered">
								Like What You See? We’re Just Getting Started
							</p>	
							<button className=" btn-get-registered" onClick={this.handleClickMemberButton} >
								<span className="text-btn">{i18n.t('landing.getMember')}</span>
							</button>					
						</div>						
					</Grid>
				</Grid>
			);
  		}  	
  		*/	

  	}

	render(){

		const { classes } = this.props;
		const totalPoblaciones = this.state.currentEvent.centros.length;
		const { openAlert } = this.state;

		let inscripciones = this.state.currentEvent.fechaCierreInscripciones.split('-');
		let inscriptionsDate = new Date();
		inscriptionsDate.setFullYear(inscripciones[2],inscripciones[1]-1,inscripciones[0]);
		const today = Date.now();

		let estil = {};
		let estilTitol = {};

		if(this.props.isSignedIn !== true){
			estil = {
				marginTop: '0px'
			};
			estilTitol = {
				marginTop: '30px'
			};
		}

		return(
			<div className="custom-template-landing-campaign custom-landing future-event" style={estil}>
				<Grid container justify="center" >
					<Grid item xs={12}>							
						<div className="container-title-custom-landing">							
							<h1 style={estilTitol}>{this.state.currentEvent.nombreEvento}</h1>							
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={8} lg={8}>						
						<div className = "main-container-info">
							<div className="back-image-main-container" style={{ backgroundImage: `url(${this.state.currentEvent.imagenBack})`}}>
								<div className="main-info-event">
									<div className="info-event-container">
										<div className="container-second-info">
											<FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
											<div className="location-event-custom-landing">
												{this.state.currentEvent.localizacion}
												{/*								        			
							        				this.state.currentEvent.centros.map( (item, index) => (	
							        					<span key={index}>{item.poblaciones} { index == totalPoblaciones-1 ? '' : ' - '  }</span>
							        				))
							        				*/
							        			}
							        		</div>
							        		<div className="brands-event-custom-landing">
							        			{	
								        			this.state.currentEvent.marcas.map( (item, index) => (
								        				<img key={index} src={item.logo} alt={item.nombre} />			        					
							        				))
						        				}
							        		</div>
							        	</div>
									</div>
								</div>								
							</div>	
							<div className="incritos-container">
								<div className="inscrito-main-container">
									<div className="number-inscritos-container">
										{this.state.inscritos}
									</div>
									<div className="inscrito-text-container">
										{i18n.t('landing.interested')}
									</div>
								</div>
								{/*
								<div className="container-share-inscritos">
									<ShareButton titleCampaign={this.state.currentEvent.nombreEvento} />
								</div>
								*/}
							</div>
							<div className="container-top-info">							
								<img className="main-car-image" src={this.state.currentEvent.imagen} />
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={10} md={8} lg={8}>						
						<div className="container-mobile-info">
							<FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
							<div className="location-event-custom-landing">
								{this.state.currentEvent.localizacion}
								{		
				        			/*
			        				this.state.currentEvent.centros.map( (item, index) => (	
			        					<span key={index}>{item.poblaciones} { index == totalPoblaciones-1 ? '' : ' - '  }</span>
			        				))
			        				*/
			        			}
			        		</div>			        		
						</div>					
					</Grid>
				</Grid>
				<Grid container justify="center" >
					<Grid item xs={10} sm={6} md={5} lg={4}>	
						<div className="wrapper-container-countdown">																	
							<div className="container-countdown">
								<p className="container-coming-soon">{i18n.t('landing.comingSoon')}</p>
							</div>	
						</div>						
					</Grid>
				</Grid>
				{/*
				<Grid container justify="center" alignItems="center" >
					<Grid item xs={12} >
						<div className="container-main-info-event">
							<Grid container justify="center" alignItems="center" >
								<Grid item xs={12} sm={12} md={12} lg={8}>
									<Grid container justify="center" alignItems="center" >
										<Grid item xs={11} sm={11} md={6} lg={6}>
											<div className="container-negociador">
												<img src={this.state.currentEvent.imagenNegociador} className="img-negociador" />												
												<div className="negociador-talk" dangerouslySetInnerHTML={{__html: this.state.currentEvent.negociador}}>
													
												</div>
											</div>
										</Grid>
									</Grid>	
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Grid>
				*/}
				<Grid container justify="center" >
					<Grid item xs={12}>
						<div className="mobile-container-title-custom-landing">							
							<p className="landing-title-content">{this.state.currentEvent.nombreEvento}</p>

							<h2>
								<FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
								{this.state.currentEvent.localizacion}
								{	/*								        			
			        				this.state.currentEvent.centros.map( (item, index) => (	
			        					<span key={index}>{item.poblaciones} { index == totalPoblaciones-1 ? '' : ' - '  }</span>
			        				))
			        				*/
			        			}
							</h2>
							

						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div className="container-fluid-slogan">
							<Grid container justify="center" >
								<Grid item xs={12} sm={10} md={8} lg={6}>													
									<div className="content-custom-landing"  dangerouslySetInnerHTML={{__html: this.state.currentEvent.slogan}}></div>
								</Grid>
							</Grid>
						</div>
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div className="container-fluid-btn-interested">
							<Grid container justify="center" >							
								<Grid item xs={12} sm={10} md={8} lg={8}>
									<div className="container-btn-advice">
										<button className="btn1 btn-interested" onClick={this.handleClickOpen} >
											<span className="text-btn">{i18n.t('landing.inscribirme')}</span>
										</button>
									</div>
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Grid>
				
				{this.renderGetMember()}

				<Grid container justify="center" >
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div className="container-fluid-description">
							<Grid container justify="center" >
								<Grid item xs={12} sm={10} md={8} lg={6}>
									<div className="content-custom-landing"  dangerouslySetInnerHTML={{__html: this.state.currentEvent.descripcion}}></div>
								</Grid>
							</Grid>
						</div>
					</Grid>


					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div className="container-fluid-btns">
							<div className="container-btn-advice">
								<button className="btn1 btn-interested" onClick={this.handleClickOpen} >
									<span className="text-btn">{i18n.t('landing.inscribirme')}</span>
								</button>
							</div>
							<div style={{textAlign: 'center'}}>
								<ShareButton titleCampaign={this.state.currentEvent.nombreEvento} />
							</div>
						</div>
					</Grid>
					{/*
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<FAQSBlock/>
					</Grid>
					*/}
				</Grid>
				<div className="container-btn-interested-custom">
					<div className="container-interested">
					{/*
						<div className="name-interested">
							<img src={this.state.currentEvent.imagenNegociador} style={{width: '50px', borderRadius: '30px'}} />
							<div className="event-seller-tell">{this.state.currentEvent.fraseNegociador}</div>
						</div>
					*/}
						
						<div className="name-interested">
							<div className="event-name">{this.state.currentEvent.nombreEvento}</div>
							<div className="event-location">								
								<FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
								<span>{this.state.currentEvent.localizacion}</span>
								{		
									/*   			
			        				this.state.currentEvent.centros.map( (item, index) => (	
			        					<span key={index}>{item.poblaciones} { index == totalPoblaciones-1 ? '' : ' - '  }</span>
			        				))
			        				*/
								}
							</div>
							{/*
							<div className="event-brands">
								{	
				        			this.state.currentEvent.marcas.map( (item, index) => (
				        				<img key={index} src={item.logo} alt={item.nombre} />			        					
			        				))
		        				}
							</div>
							*/}
						</div>
						
						<button className="btn1 btn-interested-bar" onClick={this.handleClickOpen} >
							<span className="text-btn">{i18n.t('landing.inscribirme')}</span>
						</button>
					</div>
				</div>

				<Dialog
			        open={this.state.openForm}
			        onClose={this.handleClose}
			        maxWidth='sm'
			        classes={{ paper: classes.dialogPaper }}
			        fullWidth={true}
			        aria-labelledby="form-dialog-title"
		        >
		        	<DialogTitle id="form-dialog-title" onClose={this.handleClose}>{i18n.t('landing.formInscription')}</DialogTitle>
		        	<form className="form-more-info" onSubmit={ this.props.handleSubmit(this.sendLead) }  >
			        	<DialogContent>
			        		{inscriptionsDate >= today ? '' : <p className="text-closed-event">{i18n.t('landing.enroll')}</p>
			        		}
			           		<Field name="nombre" onChange={this.handleChangeInput} initialValue={this.state.nombre } component={this.renderInput} label={i18n.t('editAccount.name')} />
							<Field name="email" onChange={this.handleChangeInput} initialValue={this.state.email} component={this.renderInput} label={i18n.t('editAccount.email')}  />			
							<Field name="tlf" onChange={this.handleChangeInput} initialValue={this.state.tlf} component={this.renderInput} label={i18n.t('editAccount.tlf')}  />			
							{/*this.renderSelectRegion()*/}
							{this.renderAcceptPrivacity()}
							{this.renderConfirmPolicies()}		
							{this.renderErrorPrivacity()}
				        </DialogContent>
				        <DialogActions>
				            <button  className="btn1 btn-lead">
				            	{i18n.t('landing.send')}			            	
				            </button>			            
				        </DialogActions>
				        {this.renderLoader()}
			        </form>
		        </Dialog>

		        <Dialog
			        open={this.state.openRegisterPopup}
			        onClose={this.handleCloseRegister}
			        maxWidth='sm'
			        classes={{ paper: classes.registerDialogPaper }}
			        fullWidth={false}
			        aria-labelledby="register-dialog-title"
		        >
		        	<DialogTitle id="register-dialog-title" classes={{root: classes.titleRegisterPopup}} onClose={this.handleCloseRegister}></DialogTitle>
		        	{/*<form className="form-more-info" onSubmit={ this.props.handleSubmit(this.sendLead) }  >*/}
		        	
		        	
		        	<DialogContent classes={{root: classes.contentRegisterPopup}}>
		        		Like What You See? We’re Just Getting Started!!!		
			        </DialogContent>
			        <DialogActions classes={{root: classes.footerRegisterPopup}}>
			            <button  className="btn-register-popup" onClick={this.handleClickMemberButton} >
			            	Quiero ser miembro				            	
			            </button>			            
			        </DialogActions>  
		        </Dialog>

		        <Snackbar
			          anchorOrigin={{  vertical: 'bottom', horizontal: 'right' }}
			          open={openAlert}
			          onClose={this.closeAlert}			         
			          ContentProps={{			            
			            classes: {
                        	root: classes.root
                    	}
			          }}
			          autoHideDuration={1500}
			          message={ i18n.t(this.state.operacioOk) }			          
			    />
			</div>
		);
	}

}


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const validate = (formValues) => {

	const errors = {};
	const requiredFields = [	    
	    'nombre',
	    'email',	    
	    'tlf'	    
  	];

  	//console.log('validattteeeee');
  	//console.log(formValues);
 	
  	requiredFields.forEach(field => {
  		//console.log('validate');
  		//console.log(formValues[field]);
	    if (!formValues[field]) {
	      errors[field] = i18n.t('errorForms.required');
	    }
  	})

  	if ( formValues.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email) ) {
    	errors.email = i18n.t('errorForms.invalidemail');
  	}


	return errors;

};

const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		selectedLanguage: state.auth.selectedLanguage,
		userId: state.auth.userId, 
		userMail: state.auth.userMail, 
		userName: state.auth.userName
	};
};

const stylesDialog  = theme => ({
    dialogPaper: {       
        [theme.breakpoints.down('xs')]: {
     		margin: '20px',
    	}        
    },
    registerDialogPaper:{
    	backgroundColor: '#00009A',
    	[theme.breakpoints.down('xs')]: {
     		margin: '20px',
    	} 
    },
    titleRegisterPopup: {
    	backgroundColor: '#00009A',
    	padding: '30px',
    	borderBottom: '0px',
    },
    contentRegisterPopup: {
    	backgroundColor: '#00009A',
    	color: '#fff',
    	fontSize: '18px',
    	textAlign: 'center',
    	padding: '20px',
    },
    footerRegisterPopup: {
    	fontSize: '16px',
    	backgroundColor: '#00009A',
    	margin: '40px',
    	
    }
});


FutureCustomLandingView = withStyles(stylesDialog)(FutureCustomLandingView);


FutureCustomLandingView = reduxForm({
  form: 'InterestedInCampaign',
  enableReinitialize: true,
  /*validate: validate */
  
})(FutureCustomLandingView);


FutureCustomLandingView = connect(
	mapStateToProps,
	{ sendLeadNoLogged, sendLeadUser, getUserData }
)(withNamespaces()(FutureCustomLandingView));

export default FutureCustomLandingView;


