import React from 'react';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

//import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import GridListTile from '@material-ui/core/GridListTile';
//import Card from '@material-ui/core/Card';
//import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
//import eventsListStyle from '../../assets/styles/components/EventsList';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const FutureBlockList = (props) => {	

		let totalProvincias;
		let provincias;
		if(props.eventLocation === undefined ){
			totalProvincias = 0;
			provincias = [];
		}else{
			totalProvincias = props.eventLocation.length;	
			provincias = props.eventLocation;
		}	

		const divStyle = {					  
			backgroundImage: 'url(' + props.eventImage + ')'
		};

		

		return(			
				<GridListTile key={props.numCampaign} cols={1} rows={1}>
					
					<div className="container-card">
						<div className="container-card-border">
							<div className="container-image-card-background">	
								<div className="container-image-card" style={divStyle}>
									<div className="container-days-until"><p>{ i18n.t('listCampaigns.comingSoon') }</p></div>
									<div className="container-suscribers"><span className="number-suscribers">{props.eventsSuscribes}</span>{ i18n.t('listCampaigns.interested') }</div>
									{/*<div className="container-location">
										<FontAwesomeIcon icon={['fal', 'map-marker-alt']} />	
						        		{	
						        			provincias.map( (item, index) => (	
					        					<span key={index}>{item} { index == totalProvincias-1 ? '' : ' - '  }</span>
					        				))
					        			}
									</div>
									*/}
									<div className="container-gradient-bottom"></div>
								</div>

							</div>
							<div className="container-info-card">							
								<Grid container justify="center" >
									<Grid item xs={12} sm={8} md={8} lg={8}>
										<div className="text-info-card">
											<div className="location-container">
												<span className="map-event"><img src={props.eventMap} alt="location event" /></span>												
											</div>
											<p className="title-event">
												{props.eventTextLocation}
													{/*	
									        			provincias.map( (item, index) => (	
								        					<span key={index}>{item} { index == totalProvincias-1 ? '' : ' - '  }</span>
								        				))
								        				*/
								        			}
								        			
											</p>
											<p className="slogan-event">{props.eventName}</p>
											{/*<span className="cities-title-event">{totalProvincias > 1 ? 'Evento simultanea en las ciudades' : 'Evento en'}</span>*/}
											
										</div>
										
									</Grid>
									<Grid item xs={12} sm={4} md={4} lg={4}>
										<div className="container-brands">
										{	
						        			props.eventBrands.map( (item, index) => (
						        				<img key={index} src={item.logo} alt={item.nombre} />			        					
					        				))
					        			}
					        			</div>
									</Grid>
									
								</Grid>													
							</div>
						</div>
					</div>
				</GridListTile>	
		);
		
			
};

//size="large" color="secondary" variant="contained"

export default withNamespaces()(FutureBlockList);

