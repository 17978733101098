
const INITIAL_STATE = {
	brandSelected: '',
	regionSelected: '',
	priceRangeSelected: [5000, 90000]
};

export default (state = INITIAL_STATE, action) => {

	switch(action.type){

		case 'INITIALIZE_FILTERS':
			return{
				brandSelected: '',
				regionSelected: '',
				priceRangeSelected: [5000, 90000]
			};
		case 'SEARCH_BRANDS_FILTER':
			return{
				...state, 
				brandSelected: action.brandSelected
			};
		case 'SEARCH_REGION_FILTER':
			return{
				...state, 
				regionSelected: action.regionSelected
			};
		case 'SEARCH_PRICE_FILTER':
			return{
				...state, 
				priceRangeSelected: action.priceRangeSelected
			};
		case 'SEARCH_CAMPAIGNS':			
			return { 
				...state,
				brandSelected: action.brandSelected,
				regionSelected: action.regionSelected,
				priceRangeSelected: action.priceRange
			};		
		default:
			return state;
	
	}
};

