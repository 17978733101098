import {
  /*C_MAIN, 
  C_MAIN_LIGHT, 
  C_MAIN_DARK, */
  C_SECONDARY, 
  /*C_SECONDARY_LIGHT, 
  C_SECONDARY_DARK, */
  C_TEXT,
  /*C_TEXT_LIGHT*/

} from '../variables.jsx';

const stylesFilter = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '90%',
    
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  inputSelectLabel: {
  	color: C_TEXT,
    fontSize: '14px',
  },
  btnFilters: {
  	backgroundColor: '#f1f1f1',
  	color: C_SECONDARY,
  	borderRadius: '3px',
  	border: '2px solid '+C_SECONDARY,
  	width: '90%',
  	margin: '15px 5px 5px 5px'
  },
  appBar: {
  	backgroundColor: C_SECONDARY,
  	color: '#fff',
    position: 'relative',
    textAlign: 'center'  
  },
  btnResults: {
  	backgroundColor: C_SECONDARY,
  	color: '#fff',
  	borderRadius: '3px',
  	margin: '5px auto',
  	width: '70%',
  },
});



export default stylesFilter;