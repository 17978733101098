import {
  /*C_MAIN, 
  C_MAIN_LIGHT, 
  C_MAIN_DARK, */
  C_SECONDARY, 
  C_SECONDARY_LIGHT, 
  /*C_SECONDARY_DARK,*/ 
  C_TEXT,
  C_TEXT_LIGHT

} from '../variables.jsx';


const eventsListStyle = theme => ({

	cardContainer:{
		position: 'relative',
		margin: '10px',
		borderRadius: '2px',
		minHeight: '400px',
		[theme.breakpoints.down('xs')]: {
     		minHeight: '350px',
    	}
	},
	cardImage: {
		height: '240px',
		[theme.breakpoints.down('xs')]: {
     		height: '190px',
    	}
	},
	cardContent: {
		marginBottom: '0px',
		paddingBottom: '15px',
		'&:last-child': {
			paddingBottom: '15px',
		}
	},
	containerListEvents: {
		display: 'flex',
    	flexWrap: 'wrap',
    	justifyContent: 'space-around',
    	overflow: 'hidden',
	},
	gridList: {
		width: '100%'
		/*width: '1080px',
		[theme.breakpoints.down('sm')]: {
     		width: '960px',
    	},
    	[theme.breakpoints.down('xs')]: {
     		width: '95%',
    	},*/		

	},
	cardUntilImage:{
		position: 'absolute',
		top: '0',
		right: '0',
		textAlign: 'right',		
		color: '#fff',
		fontSize: '12px',
		padding: '7px 15px',
		width: '100%',
		height: '50px',
		background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 60%, rgba(0,0,0,0) 100%)',
	},
	cardTitleContainer:{
		position: 'absolute',
		top: '176px',
		left: '0',
		textAlign: 'left',			
		padding: '7px 15px',
		width: '100%',
		height: '50px',
		background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 40%, rgba(0,0,0,0.7) 100%)',
		[theme.breakpoints.down('xs')]: {
     		top: '126px',
    	}
	},
	cardTitleImage: {
		position: 'absolute',
		bottom: '10px',
		fontFamily: '"Bodoni Moda", serif',
		fontSize: '25px',
		color: C_TEXT_LIGHT,
	},
	cardMainTitle: {
		color: C_TEXT,
		textAlign: 'left'
	},
	cardDescription:{
		textAlign: 'left',
		color: C_TEXT,
		marginBottom: '0',
		marginTop: '0',		
		fontSize: '14px',
		height: '36px',
		overflow: 'hidden'
	},
	actions: {
    	display: 'flex',
  	},
  	cardActionDate:{
  		marginLeft: '8px',
  		color: C_TEXT_LIGHT
  	},
  	cardButton: {
  		backgroundColor: C_SECONDARY,
  		color: '#fff',
  		'&:hover': {
	      backgroundColor: C_SECONDARY_LIGHT,      
	    }
  	},


});

export default eventsListStyle;