import React from 'react';
import { connect } from 'react-redux';


import {  signOut } from '../../actions/';
import history from '../../history';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { withStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';

import { authFirebase } from '../Firebase/Firebase.js';

import profileSelectorStyle from '../../assets/styles/components/ProfileSelectorStyle.jsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ProfileSelectOptions extends React.Component{


	constructor(props) {
		super(props);
		this.state = {
	      anchorEl: null
	    };
	}


	onLogOutClick = () => {		
		authFirebase.signOut()
		.then(() => {
  			// Sign-out successful.  			
  			this.props.signOut();
  			history.push('/users/authentication');
		}).catch(function(error) {
  			// An error happened.
  			console.log(error);
		});
	};


	handleClick = event => {
    	this.setState({ anchorEl: event.currentTarget });
  	};

  	handleClose = () => {
    	this.setState({ anchorEl: null });
  	};

  	optionMenuClicked = (path) => {
  		history.push(path);
  		this.handleClose();
  	};

	render(){

		const { anchorEl } = this.state;

		return(
			<div className="container-right-options">

				<IconButton
                  aria-owns={anchorEl ? 'profile-menu' : undefined}
                  aria-haspopup="true"                 
                  color="inherit"
                  disableRipple={true }
                  onClick={this.handleClick}

                >	
                	{/*<div className="icon-personal-account">
                		<FontAwesomeIcon icon={['fal', 'user-alt']} />
                		<span style={{display: 'block'}}>Cuenta</span>
                	</div>
                	*/}
                	
                		<FontAwesomeIcon className="container-personal-account-icon" icon={['fal', 'user-alt']} /><span className="text-hello-user">{i18n.t('userMessage.hello')}, <span>{this.props.userName}</span>!</span>
                	
                  	<span className="down-icon"><FontAwesomeIcon icon={['fal', 'chevron-down']} /></span>
                  
                </IconButton>
                <Menu
		          id="profile-menu"
		          anchorEl={anchorEl}
		          open={Boolean(anchorEl)}
		          onClose={this.handleClose}
		          TransitionComponent={Fade}
		          MenuListProps={{ style: { padding: 0 } }}
		          classes={{
					        paper: this.props.classes.menuSelectorContainer					        
					    }}
		        >		        	
		        	{/*<MenuItem		          		
		          		classes={{
					        root: this.props.classes.infoProfile					        
					    }}
		          	>
		          		<span className={this.props.classes.nameProfile }><em>{this.props.userName}</em></span>
		          	</MenuItem>*/}
		          	<MenuItem
		          		onClick={() => this.optionMenuClicked('/users/edit-account')}
		          		classes={{
					        root: this.props.classes.optionProfile					        
					    }}
		          	>	
		          		<div className="text-option-menu">		          			
		          			<FontAwesomeIcon icon={['fal', 'user-edit']} /><span style={{ marginLeft: '10px', }}>{i18n.t('menu.edit')}</span>
		          		</div>		          		
		          	</MenuItem>
		          	<MenuItem
		          		onClick={() => this.optionMenuClicked('/users/alerts')}
		          		classes={{
					        root: this.props.classes.optionProfile					        
					    }}
		          	>	
		          		<div className="text-option-menu">		          			
		          			<FontAwesomeIcon icon={['fal', 'heart']} /><span style={{ marginLeft: '10px', }}>{i18n.t('menu.interests')}</span>
		          		</div>		          		
		          	</MenuItem>
		          	<MenuItem
		          		onClick={() => this.onLogOutClick()}
		          		classes={{
					        root: this.props.classes.optionProfile					        
					    }}
		          	>
		          		<div className="text-option-menu">
		          			<FontAwesomeIcon icon={['fal', 'sign-out']} /><span style={{ marginLeft: '10px', }}>{i18n.t('menu.logout')}</span>
		          		</div>		          		
		          	</MenuItem>		          
		        </Menu>

			</div>
		);
	}

}

const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		userName: state.auth.userName
	};
};


 
ProfileSelectOptions = withStyles(profileSelectorStyle)(withNamespaces()(ProfileSelectOptions));


ProfileSelectOptions = connect(
	mapStateToProps, 
	{ signOut }
)(ProfileSelectOptions);


export default ProfileSelectOptions;

//export default withStyles(profileSelectorStyle)(withNamespaces()(ProfileSelectOptions));