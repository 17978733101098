import React from 'react';
import { connect } from 'react-redux';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { saveSearch, fetchEventsList } from '../../actions/';

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import GridList from '@material-ui/core/GridList';
import Snackbar from '@material-ui/core/Snackbar';

import CustomLink from '../../components/CustomLink.jsx';
import BlockList from './BlockList.jsx';
import FutureBlockList from './FutureBlockList.jsx';

import eventsListStyle from '../../assets/styles/components/EventsList.jsx';

import history from '../../history';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getApiUrl } from '../../config/urlBase.js';

const querystring = require('querystring');

class MotorEventsList extends React.Component{


	constructor(props) {
	    super(props);
	    this.state = {
	      error: null,
	      requestSent: false,
	      currentEvents: [],
	      totalCurrentEvents: 0,
	      futureEvents: [],
	      totalFuturEvents: 0,
	      currentPage: 0,
	      finalPage: false,
	      noResults: false,	   
	      numCols: 1,
	      operacioOk: '',
	      openAlert: false  
	    };	    
	    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount(){	
		//console.log('entrem a motorevents didmount');
      	this.loadEvents();	 
      	this.updateWindowDimensions();
  		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
  		window.removeEventListener('resize', this.updateWindowDimensions);
	}
	
	componentDidUpdate(prevProps) {

		if (this.props.brandSelected !== prevProps.brandSelected) {		    
		    this.loadEvents();
		}
		if (this.props.regionSelected !== prevProps.regionSelected) {		   
		    this.loadEvents();
		}
		if ((this.props.priceRangeSelected[0] !== prevProps.priceRangeSelected[0])||(this.props.priceRangeSelected[1] !== prevProps.priceRangeSelected[1])) {		   
			this.loadEvents();    
		}

	}

	updateWindowDimensions() {
		
		if(window.innerWidth<960){
			this.setState({ numCols: 2 });
		}else{
			this.setState({ numCols: 1 });
		}

  		
	}

	setEventsState = (events) => {
			
		//return { ...this.state.currentEvents, ..._.mapKeys(events, 'idEvent') };
	}


	loadEvents(){				
		//fetch(getApiUrl()+"eventsList_servlet.php?brand="+this.props.brandSelected+"&region="+this.props.regionSelected+
			//"&range="+this.props.priceRangeSelected+"&locale="+this.props.selectedLanguage)		
		//.then(res => res.json())
		this.props.fetchEventsList(this.props.brandSelected, this.props.regionSelected, this.props.priceRangeSelected, this.props.selectedLanguage )
	      .then(
	        (result) => {	  
	        	        	

	        	if((result.totalEvents == 0)&&(result.totalEventsFuturs == 0)){
	        		this.setState({
		            	requestSent: true,	          
		            	currentEvents: [],  
		            	finalPage: result.final,
		            	noResults: true,
		            	totalCurrentEvents: 0,
						totalFuturEvents: 0
		          	});
	        	}else{
	        		this.setState({
		            	requestSent: true,	          
		            	currentEvents: result.events, 
		            	totalCurrentEvents: result.totalEvents,
		            	futureEvents: result.eventsFuturs,
		            	totalFuturEvents: result.totalEventsFuturs, 
		            	finalPage: result.final,
		            	noResults: false	            
		          	});
	        	}	 
	        	       		         
	        },	       
	        (error) => {
	          this.setState({
	            requestSent: true,
	            error
	          });
	        }
	      );
	}

	saveUserSearch = () => {

		/*console.log('guardem busqueda');
		console.log(this.props.brandSelected);
		console.log(this.props.regionSelected);
		*/

		if((this.props.brandSelected === '')&&(this.props.regionSelected === '')){

		}else{
			this.props.saveSearch(this.props.userId, this.props.brandSelected, this.props.regionSelected)
			.then( (result) => {
				
				//console.log('tenim restultat');
				//console.log(result);
				//this.openAlert(i18n.t('errors.'+result.error));
				history.push('/users/alerts');
			});
		}
		
	};


	openAlert = (textMissatge) => {
    	this.setState({ openAlert: true, operacioOk: textMissatge });  
	};

	closeAlert = () => {
		this.setState({ openAlert: false });
	};

	renderFutureEvents(totalFuturEvents, futureEvents) {
		if(totalFuturEvents == 0){
			
			return(<div></div>);
		}else{
			
			const estilsBlock = this.props.classes;
			const items = futureEvents.map( function(item, index){
				return (
					<CustomLink to={item.tipoLanding == 3 ? item.url : ''} idEvent={item.urlLocal} nomClasse={item.tipoEvento == 25 ? 'event-container-premium' : 'event-container-normal'} key={item.idEvent}>			        			
		        		<FutureBlockList 
							estils={estilsBlock}
							eventName={item.nombreEvento} 
							eventDescription = {item.slogan}
							eventImage = {item.imagenListado}
							eventMap = {item.mapa}
							eventLocation = {item.poblaciones}
							eventBrands = {item.marcas}
							eventsSuscribes = {item.totalInscrits}
							numCampaign = {index}
							eventTextLocation={item.localizacion}										
							/>				
					</CustomLink>
				);
			});

			return[							
					items			        
	        ];
		}
	};

	renderCurrentEvents(totalEvents, currentEvents){
		if(totalEvents == 0){
			
			return(<div style={{ display: 'none' }}></div>);
		}else{
			
			const estilsBlock = this.props.classes;
			const items = currentEvents.map( function(item, index){
				return (			
	        		<CustomLink to={item.tipoLanding == 3 ? item.url : ''} idEvent={item.urlLocal} nomClasse={item.tipoEvento == 25 ? 'event-container-premium' : 'event-container-normal'} key={index}>			        			
		        		<BlockList 
							estils={estilsBlock}
							eventName={item.nombreEvento} 
							eventDescription = {item.slogan}
							eventCaducidad = {item.daysUntil}
							eventImage = {item.imagenListado}
							eventMap = {item.mapa}
							eventLocation = {item.poblaciones}
							eventBrands = {item.marcas}
							eventsSuscribes = {item.totalInscrits}
							numCampaign = {index}
							eventTextLocation={item.localizacion}										
							/>								
					</CustomLink>							
				);
			});

			return[							
					items			        
	        ];
		}
	}

	renderListEvent(currentEvents, noResults, totalEvents, totalFuturEvents, futureEvents){
		
		if(noResults){
			return(
				<div className="container-no-results">
					<div className="no-results-content">
						<p className="text-no-result" dangerouslySetInnerHTML={{__html: i18n.t('listCampaigns.noResults') }}></p><FontAwesomeIcon icon={['fal', 'car-crash']} />
					</div>
					<p style={{ fontSize: '12px' }}>{i18n.t('listCampaigns.textExplanation')}</p>
					{/*<button className="btn btn1" onClick={this.saveUserSearch}>{i18n.t('listCampaigns.btnTextConfirmation')}</button>*/}
					<button className="btn btn1" onClick={this.saveUserSearch}>{i18n.t('listCampaigns.btnMyAlerts')}</button>
				</div>
	        );
		}else{
			
			if(this.state.widthWindow < 960){
				//console.log('soc mes petit');
			}

			
			/*if(totalEvents == 0){
				return(<div></div>);
			}else{
			*/
				return(
				
					<div className={this.props.classes.containerListEvents}>

						<GridList cols={2} className={this.props.classes.gridList}>						
				        	{/*currentEvents.map( (item, index) => (	
				        		<CustomLink to={item.tipoLanding == 3 ? item.url : ''} idEvent={item.urlLocal} nomClasse={item.tipoEvento == 25 ? 'event-container-premium' : 'event-container-normal'} key={index}>			        			
						        		<BlockList 
											estils={this.props.classes}
											eventName={item.nombreEvento} 
											eventDescription = {item.slogan}
											eventCaducidad = {item.daysUntil}
											eventImage = {item.imagenListado}
											eventMap = {item.mapa}
											eventLocation = {item.poblaciones}
											eventBrands = {item.marcas}
											eventsSuscribes = {item.totalInscrits}
											numCampaign = {index}										
											/>								
								</CustomLink>							
				        	))*/}		
				        	{this.renderCurrentEvents(totalEvents, currentEvents)}
				        	{this.renderFutureEvents(totalFuturEvents, futureEvents)}
				        </GridList>	  

				        {/*<ul>	          
				          	{currentEvents.map(item => (
				            	<li key={item.nombre}>
				              		<h1>{item.nombre}</h1>
				              		<p>{item.descripcion}</p>
				              		<img src={item.imagen} />

				            	</li>	   

				          	))}
				        </ul> */}  
			        </div>
		        );
			//}
			
		}		
	}


	render(){

		const { currentEvents, noResults, openAlert, totalCurrentEvents, totalFuturEvents, futureEvents } = this.state;		
		
		return(
			<div>

				{this.renderListEvent(currentEvents, noResults, totalCurrentEvents, totalFuturEvents, futureEvents)}
				{/*this.renderFutureEvents(totalFuturEvents, futureEvents)*/}
		        {(() => {
		          if (!this.state.requestSent) {
		            return(
		              <div className="data-loading">
		               	<CircularProgress  />
		              </div>
		               
		            );
		          } else {
		            return(
		              <div className="data-loading">
		              	
		              </div>
		            );
		          }
		        })()}

	         	<Snackbar
		          	anchorOrigin={{  vertical: 'bottom', horizontal: 'right' }}
		          	open={openAlert}
		          	onClose={this.closeAlert}			         
		          	autoHideDuration={2500}
		          	message={this.state.operacioOk}
		        />

			</div>
		);
	}


}

const mapStateToProps = (state) => {
	return { 
		userId: state.auth.userId,
		selectedLanguage: state.auth.selectedLanguage,
		brandSelected: state.searchFilters.brandSelected,
		regionSelected: state.searchFilters.regionSelected,
		priceRangeSelected: state.searchFilters.priceRangeSelected
	};
};


MotorEventsList = connect(
	mapStateToProps, { saveSearch, fetchEventsList }
)(withNamespaces()(MotorEventsList));

MotorEventsList = withStyles(eventsListStyle)(MotorEventsList);

export default MotorEventsList;



