
import LoginView from "../views/LoginView.jsx";
import ForgetPwdView from "../views/ForgetPwdView.jsx";
import ChangePwdView from "../views/ChangePwdView.jsx";
import PersonalAccountView from "../views/PersonalAccountView.jsx";
import CampaignsListView from "../views/CampaignsListView.jsx";
import CampaignLandingView from "../views/CampaignLandingView.jsx";
import PageNotFound from "../views/PageNotFound.jsx";
import RegisterView from "../views/RegisterView.jsx";
import ContactView from '../views/ContactView.jsx';
import GraciasLeadView from '../views/GraciasLeadView.jsx';
import GraciasView from '../views/GraciasView.jsx';
import GraciasModCitaView from '../views/GraciasModCitaView.jsx';
import GraciasCancelCitaView from '../views/GraciasCancelCitaView.jsx';
import InterestsUserView from '../views/InterestsUserView.jsx';
import AlertsUserView from '../views/AlertsUserView.jsx';
import FaqsView from '../views/FaqsView.jsx';
import PrivacyView from '../views/PrivacyView.jsx';
import TermsView from '../views/TermsView.jsx';
import ConditionsView from '../views/ConditionsView.jsx';
import PortadaView from '../views/PortadaView.jsx';
import LoginCampaignView from '../views/LoginCampaignView.jsx';
import StepsProfileView from '../views/StepsProfileView.jsx';
import ModifyDateView from '../views/ModifyDateView.jsx';
import CancelDateView from '../views/CancelDateView.jsx';
import ChangePwdFirebaseView from "../views/ChangePwdFirebaseView.jsx";


//import SignUpView from "../views/SignUpView.jsx";
//import FuturEventsList from "../views/FuturEventsList.jsx";
//import PageList from "../views/PageList.jsx";
//import EventShow from "../views/EventShow.jsx";

var indexRoutes = [ 

 	{ 
 		path: "/", 
 		name: "LoginView", 
 		component: LoginView 
 	},
 	{ 
 		path: "/portada", 
 		name: "PortadaView", 
 		component: PortadaView 
 	},
 	{ 
 		path: "/users/login", 
 		name: "LoginView", 
 		component: LoginView 
 	},
 	{ 
 		path: "/users/authentication", 
 		name: "LoginCampaignView", 
 		component: LoginCampaignView 
 	},  	 	
 	{ 
 		path: "/users/forget-password", 
 		name: "ForgetPwdView", 
 		component: ForgetPwdView 
 	},
 	{ 
 		path: "/users/change-password", 
 		name: "ChangePwdView", 
 		component: ChangePwdView 
 	},
 	{ 
 		path: "/users/user-auth-action", 
 		name: "ChangePwdView", 
 		component: ChangePwdFirebaseView 
 	},
 	{ 
 		path: "/users/edit-account", 
 		name: "PersonalDataView", 
 		component: PersonalAccountView 
 	}, 	
 	{ 
 		path: "/users/steps", 
 		name: "StepsView", 
 		component: StepsProfileView 
 	},
 	{ 
 		path: "/users/sign-up", 
 		name: "RegisterView", 
 		component: RegisterView 
 	},
 	{
 		path: "/users/alerts", 
 		name: "AlertsView", 
 		component: AlertsUserView
 	},
 	{ 
 		path: "/users/edit-date", 
 		name: "ModifyDate", 
 		component: ModifyDateView 
 	}, 	
 	{ 
 		path: "/users/cancel-date", 
 		name: "CancelDate", 
 		component: CancelDateView 
 	}, 
 	{ 
 		path: "/users/interests", 
 		name: "InterestsView", 
 		component: InterestsUserView 
 	},
 	{ 
 		path: "/campaigns", 
 		name: "CampaignsList", 
 		component: CampaignsListView 
 	},
 	{
 		path: "/campaigns/:idCampaign", 
 		name: "CampaignView", 
 		component: CampaignLandingView 
 	},
 	{ 
 		path: "/contact", 
 		name: "Contact", 
 		component: ContactView 
 	},
 	{ 
 		path: "/contacto", 
 		name: "Contacto", 
 		component: ContactView 
 	},  
 	{ 
 		path: "/thank-you", 
 		name: "GraciasLead", 
 		component: GraciasLeadView 
 	},
 	{ 
 		path: "/thank-you-contact", 
 		name: "Gracias", 
 		component: GraciasView 
 	},
 	{ 
 		path: "/thank-you-sign-up", 
 		name: "RegisterView", 
 		component: RegisterView 
 	},
 	{ 
 		path: "/thank-you-edit", 
 		name: "GraciasModCitaView", 
 		component: GraciasModCitaView 
 	},
 	{ 
 		path: "/thank-you-cancel", 
 		name: "GraciasCancelCitaView", 
 		component: GraciasCancelCitaView 
 	},
 	{ 
 		path: "/faqs", 
 		name: "FAQS", 
 		component: FaqsView 
 	},
 	{ 
 		path: "/privacy-policies", 
 		name: "Privacy", 
 		component: PrivacyView 
 	},
 	{ 
 		path: "/terms", 
 		name: "Terms", 
 		component: TermsView 
 	}, 	
 	{
 		path: "/use-conditions", 
 		name: "Conditions", 
 		component: ConditionsView 
 	},
 	{ 
 		path: "*", 
 		name: "404", 
 		component: PageNotFound 
 	},
 	


 	/*
 	{ 
 		path: "/sign-up", 
 		name: "SignUpView", 
 		component: SignUpView 
 	},
 	{ 
 		path: "/futur-events", 
 		name: "FutureEvents", 
 		component: FuturEventsList 
 	}
	{ 
 		path: "/events", 
 		name: "PageList", 
 		component: PageList 
 	},
	{ 
		path: "/event/:idEvent", 
		name: "EventShow", 
		component: EventShow 
	},
 	*/
 	
	


	/*,
	{ path: "/profile-page", name: "ProfilePage", component: ProfilePage },
	{ path: "/login-page", name: "LoginPage", component: LoginPage },*/
];

export default indexRoutes;
