import React from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';


import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Hidden from "@material-ui/core/Hidden";

import LlamamePopUp from '../LlamamePopUp.jsx';

import headerStyle from '../../assets/styles/components/HeaderStyle.jsx';

//const brand = "Motor Privé";



class Header extends React.Component{

	constructor(props) {
	    super(props);
	   	this.state = {	    	
	    	isLanding: false,
	    	currentEvent: 0
	    };

	}

	renderButton(){

		if(this.props.currentPage === 'landing'){

			let currentUrl = window.location.href;

	    	let separat = currentUrl.split("campaigns/"); 

	    	if(separat[1].length > 0){
	    		this.state.isLanding = true;
	    		let cadenaLanding = separat[1].split('-');
				const idCampaign = cadenaLanding[cadenaLanding.length-1];
	    		this.state.currentEvent = idCampaign;
	    	}

			return(
				<div className="no-logged-call-me">
					<LlamamePopUp 
							textButton={i18n.t('landing.callMeFree')} 
							textLabel={i18n.t('landing.weCallYou')} 
							iconText="" 
							iconRight=""
							idEvent={this.state.currentEvent}
							sourceUrl={window.location.href} />
				</div>
			);

		}else{
			return(	<div></div>	);
		}

		
	}

	render(){
		const { classes } = this.props;
		return(
			<div>	
				<Grid container justify="center" alignItems="center" className={classes.containerBackground}>
			        
			        	<Grid item xs={11} sm={12} md={8} lg={8}>
			        		<Link to="/"><h1 className={classes.logoBrand}><img src="https://media.motorprive.com/logos/motor-prive-white-logo.png" alt="motorPrive" className="logo-header-no-auth"  /></h1></Link>
			        	</Grid>
			          
			    </Grid>
			    <Hidden mdUp>
		        	{this.renderButton()}
		        </Hidden> 
			</div>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		isSignedIn: state.auth.isSignedIn,
		userName: state.auth.userName,
		stps: state.auth.stps,
		currentPage: state.auth.page
	};
};

Header = withStyles(headerStyle)(withNamespaces()(Header));

Header = connect(
	mapStateToProps, 	
)(Header);


export default Header;



