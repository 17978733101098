
const INITIAL_STATE = {
	isSignedIn: null,	
	userId: null,
	userName: null,
	userMail: null,
	isAnonymous: null, 
	selectedLanguage: null,
	errorLog: null,
	stps: '0000',
	utm_source: '',
	page: ''
};

export default (state = INITIAL_STATE, action) => {

	switch(action.type){
		case 'LOG_IN':			
			return { 
				...state, 				
				isSignedIn: true, 
				userId: action.uid,				
				userMail: action.uMail, 
				userName: action.uName,
				isAnonymous: false,
				errorLog: null
			};
		case 'LOG_OUT':
			return { ...state, isSignedIn: false, userId: null, userName: null, userMail: null, isAnonymous: null, stps: '0000' };		
		case 'LOGGED':	

			return { 
				...state, 
				isSignedIn: true, 
				userId: action.payload.uid,				
				userMail: action.payload.uMail, 
				userName: action.payload.uName,
				selectedLanguage: action.payload.uLocale,
				isAnonymous: false,
				errorLog: null,
				stps: action.payload.steps
			};

		case 'LOGGED_ANONYMOUS':			
			return { 
				...state, 				
				isSignedIn: true, 
				userId: action.uid,
				isAnonymous: true
			};			
		case 'LOG_IN_FAIL':
			//console.log("error fail login");
			return {...state, isSignedIn: false, errorLog: action.messageError };
		case 'EDIT_USER_DATA':
			return { ...state, userName: action.payload.uName, selectedLanguage: action.payload.uLocale };
		case 'NEW_USER':			
			return{ 
				...state, 
				isSignedIn: true,				
				userId: action.payload.uid,
				userMail: action.payload.mail, 
				userName: action.payload.nombre,
				isAnonymous: false,
				stps: '1000'
			};
		case 'EDIT_USER_VEHICLE':
			const purchase = state.stps[0]+state.stps[1]+state.stps[2]+'1';
			return {
				...state,
				stps: purchase
			};		
		case 'EDIT_USER_PURCHASE':
			const final = state.stps[0]+'1'+state.stps[2]+state.stps[3];
			return {
				...state,
				stps: final
			};	
		case 'EDIT_USER_ALERTS':
			const alertsSteps = state.stps[0]+state.stps[1]+action.payload.alerts+state.stps[3];
			return {
				...state,
				stps: alertsSteps
			};	
		case 'UTM_SOURCE':
			return {
				...state,
				utm_source: action.utmSource				
			};
		case 'SET_CURRENT_PAGE':
			return {
				...state,
				page: action.page				
			};

		default:
			return state;
	
	}
};