import React from "react";
import { connect } from 'react-redux';

import Header from './Header.jsx';
import HeaderAuth from './HeaderAuth.jsx';
//import Header_antic from './Header_antic';


class Navigation extends React.Component{


	renderNavigation(){
		if(this.props.isSignedIn === null){
			//console.log("soc null");
		}else if(this.props.isSignedIn === true){	
			return ( <HeaderAuth /> );
		}else{
			return( <Header /> );
		}
	}

	render(){
		return(
			<div>
				{this.renderNavigation()}
			</div>
		);
	}

}

const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn		
	};
};

export default connect(mapStateToProps)(Navigation);


