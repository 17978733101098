import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { fetchBrandsModelsRegions, saveInterests, getUserInterests } from '../../actions';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Snackbar from '@material-ui/core/Snackbar';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { DialogTitle, DialogContent, DialogActions } from '../../assets/styles/components/AlertNotificationStyle.jsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const styles = theme => ({
  
  rootFichasModelos: {
  	width: '100%',    
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  rootMarcas: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  rootChipsModelos:{
  	display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  rootListItemBrands: {
  	width: '10%',
  	border: '0',
  	margin: '3px',    		
  	textAlign: 'center',
  	flexDirection: 'column',
  	justifyContent: 'center',
  	[theme.breakpoints.down('md')]: {
     		width: '15%',
    	},
  	[theme.breakpoints.down('sm')]: {
     		width: '18%',
    	},
	[theme.breakpoints.down('xs')]: {
     		width: '30%',
    	}
  },
  rootListItemRegions: {
  	width: '15%',
  	border: '1px solid #dadada',
  	margin: '3px',  
  	borderRadius: '7px',
  	minHeight: '84px',	
  	textAlign: 'center',
  	flexDirection: 'column',
  	justifyContent: 'center',
  	[theme.breakpoints.down('md')]: {
     		width: '15%',
    	},
  	[theme.breakpoints.down('sm')]: {
     		width: '18%',
    	},
	[theme.breakpoints.down('xs')]: {
     		width: '30%',
    	}
  },
  rootListItemModelos: {
  	width: '30%',
  	border: '1px solid rgba(0, 0, 0, 0.23)',
  	margin: '5px',
  	
	[theme.breakpoints.down('xs')]: {
     		width: '45%',
    	}
  },
  rootListItemSelected: {
  	borderColor: '#05bdac',   
    backgroundColor: 'transparent !important',
    color: '#05bdac',
    border: '1px solid'
  },
  chipMarcas: {
    margin: '3px',
    cursor: 'pointer',
    '&:hover': {
	    borderColor: '#05bdac',   
	    backgroundColor: 'transparent !important',
	    color: '#05bdac'   
	},
	'&:active': {
	    borderColor: '#05bdac',   
	    backgroundColor: 'transparent !important',
	    color: '#05bdac'   
	},
	'&:focus': {
	    borderColor: '#05bdac',   
	    backgroundColor: 'transparent !important',
	    color: '#05bdac'   
	}
  },
  labelChipMarcas:{
  	fontSize: '12px !important',
  	paddingLeft: '10px',
  	paddingRight: '10px',
  },
  iconChipMarcas:{
  	fontSize: '16px',
  },
  tabRoot: {    
     
    '&:hover': {
      color: '#05bdac',
      opacity: 1,
    },
    
    '&:focus': {
      color: '#05bdac',
    },
  },
  tabSelected: {
  	/*color: '#fff',
  	backgroundColor: '#05bdac',
  	border: '2px solid #05bdac',*/

  },
  snackAlert: {
        background: '#009688'
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  buttonChangeBrand:{
  	textTransform: 'none',
  	fontSize: '12px',
  	backgroundColor: 'transparent',
  	boxShadow: 'none',
  },
  snackAlert: {
          background: '#009688'
    },
    dialogPaper: {       
        [theme.breakpoints.down('xs')]: {
        margin: '20px',
      }        
    },
    inicioForm: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: '0px !important',
      } 
    },
    inicioBtn: {
      [theme.breakpoints.down('xs')]: {
        margin: '10px 0',
      } 
    }

});



class InterestsForm extends React.Component {

	constructor(props) {
    	
    	super(props);

    	this.state = {
    		valueTab: 0,
	    	brands: [],	//resultat de la sql que retorna tota la informació de les marques     	
	    	modelos: [], //resultat de la sql que retorn tots els models que existeixen de cotxes i estan actius
	    	regions: [], //resultat de la sql que retorna totes les regions
	    	modelosSelected: [], //array de models de la marca que està seleccionada en aquell moment i s'estan mostrant al llistat de checkbox
	    	checked: [], //indica quins checkbox stan seleccionats dels models
	    	brandSelected: [],
	    	dataSelected: [], //ids dels models que estan seleccionats
	    	//chipsData: [], //array amb id-nom de la info que està seleccionada i es mostra en els chips	   
	    	countries: [],
	    	regionsSelected: [],
	    	regionsAux: [],
	    	operacioOk: '',
	        openAlert: false
	    	 	
	    };

    }
  	
  	componentDidMount() {

		this.props.fetchBrandsModelsRegions(this.props.selectedLanguage)
		.then( (result) => {

			this.setState({ 
				//brands: _.mapKeys(result.marcas, 'idMarca'),
				brands: result.marcas,
				modelos: _.mapKeys(result.modelos, 'idModelo'),
				regionsAux: _.mapKeys(result.comunidades, 'idComunidad'),
				countries: result.paises,
				regions: result.comunidades				
			});

			this.props.getUserInterests(this.props.userId)
			.then( (result) => {		
				
				
				console.log('interesos rebuts');
				console.log(result);
				console.log(result.modelos);
				/*
				console.log(result.regiones);	
				console.log(result.regiones.length);
				*/	

				let newRegions = [];
				let newModelos = [];
				
				if(result.regiones[0] === ""){					
				}else{
					newRegions = result.regiones;
				}

				if(result.modelos[0] === ""){					
				}else{
					newModelos = result.modelos;
				}

				this.setState({
					dataSelected: newModelos, 
					regionsSelected: newRegions
				});
			});

		});

	}


  	handleChangeTab= (value) => () => {
    	this.setState({ valueTab: value });
  	};

    handleToggle = (value, idModelo) => () => {

	    const { checked, dataSelected } = this.state;
	    const currentIndex = checked.indexOf(value);
	    const newChecked = [...checked];
	    const newInfoSelected = [...dataSelected];
	    const currentIdModeloIndex = dataSelected.indexOf(idModelo);	   

	    if (currentIndex === -1) {
	      	newChecked.push(value);	      	
	    } else {
	      	newChecked.splice(currentIndex, 1);	      	
	    }

	    if(currentIdModeloIndex === -1){	    	
	    	newInfoSelected.push(idModelo);
	    }else{	    	
	    	newInfoSelected.splice(currentIdModeloIndex, 1);
	    } 
	    
	    /*
	    console.log('seleccionat');
	    console.log(newChecked);
	    console.log(newInfoSelected);
		*/

	    this.setState({
	      	checked: newChecked,
	      	dataSelected: newInfoSelected
	    });

  	};

  	/*
  	handleSelectedModelo =  item => () => {
  		console.log('apretem model!!!');
  		console.log(item);

  		const { dataSelected } = this.state;
  		const newInfoSelected = [...dataSelected];
  		const currentIdModeloIndex = dataSelected.indexOf(item.idModelo);

  		if(currentIdModeloIndex === -1){	    	
	    	newInfoSelected.push(item.idModelo);
	    }else{	    	
	    	newInfoSelected.splice(currentIdModeloIndex, 1);
	    } 

	    this.setState({	      	
	      	dataSelected: newInfoSelected
	    });

	    console.log(this.state.dataSelected);
  	};
  	*/

    /*handleChangeBrand = event => {	   

    	
    	console.log('id Marca seleccionado');
    	console.log(event.target.value);
    	console.log(this.state.brands[event.target.value]["modelos"]);
    	

    	const seleccionats = [];

    	this.state.brands[event.target.value]["modelos"].map((item, i) => {
    		if(this.state.dataSelected.indexOf(item.idModelo) !== -1){
    			seleccionats.push(i);
    		}
    	})

    	this.setState({ 
			modelosSelected: this.state.brands[event.target.value]["modelos"],
			checked: seleccionats			
		});

	};*/

	

	handleSelectedRegion = (item, indexSelected) => () => {

		const { regionsSelected } = this.state;
		const newInfoSelected = [...regionsSelected];
		const indexOfRegion = regionsSelected.indexOf(item.idComunidad);

		if(indexOfRegion === -1){
			newInfoSelected.push(item.idComunidad);	
		}else{
			newInfoSelected.splice(indexOfRegion, 1);
		}

		this.setState({
			regionsSelected: newInfoSelected
		});

	};

	handleSelectedBrand = (item, indexSelected) => () => {
		
		const seleccionats = [];

		const { brandSelected } = this.state;
		let newBrandChecked = [...brandSelected];
		const indexOfBrand = brandSelected.indexOf(item.idMarca);

		/*
		console.log('select brand');
		console.log(item);
		console.log(brandSelected);
		*/

		if(indexOfBrand === -1){	   
			newBrandChecked = [];
			newBrandChecked[0] = item.idMarca;
	    }else{	    	
	    	newBrandChecked.splice(indexOfBrand, 1);
	    }

    	
    	item.modelos.map((item, i) => {
    		if(this.state.dataSelected.indexOf(item.idModelo) !== -1){
    			seleccionats.push(i);
    		}
    	})
		

    	this.setState({ 
			modelosSelected: item.modelos,
			checked: seleccionats,
			brandSelected: newBrandChecked 			
		});
		
	};

	unselectBrand = () => () => {

		const newBrandChecked = [];
		this.setState({ 			
			brandSelected: newBrandChecked, 		
			modelosSelected: []	
		});

	};

	handleDelete = item => () => {

		const { checked, dataSelected } = this.state;
	    const newChecked = [...checked];
	    const newInfoSelected = [...dataSelected];
	    const currentIdModeloIndex = dataSelected.indexOf(item);	   
	   
	    if(currentIdModeloIndex === -1){	   

	    }else{	    	
	    	newInfoSelected.splice(currentIdModeloIndex, 1);
	    } 
	    	  
		this.state.modelosSelected.map( (elemento, i) => {
			if(elemento.idModelo == item ){
				const indexABorrar = checked.indexOf(i);
				if(indexABorrar !== -1){
					newChecked.splice(indexABorrar, 1);
				}				
			}
		});

	    this.setState({
	      	checked: newChecked,
	      	dataSelected: newInfoSelected
	    });

	   
	};

	handleDeleteRegion = item => () => {

		const { regionsSelected } = this.state;
		const newRegionsSelected = [...regionsSelected];
		const currentIndex = regionsSelected.indexOf(item);

		if(currentIndex === -1){

		}else{
			newRegionsSelected.splice(currentIndex, 1);
		}

		this.setState({
			regionsSelected: newRegionsSelected
		});

	};


	
	//Oranienbaum

	renderBrandsSelect(){

		const { classes } = this.props;
		return(
			<Grid container justify="center">
				<Grid item xs={12} sm={10} md={10} lg={12}>
					{/*<p className="sub-title-brands-tab">Marcasss</p>*/}
				    <div className={classes.rootMarcas}>
				    	<List className={classes.rootFichasModelos}>
							{this.state.brands.map( (item, i) => (
								item.totalModelos == 0 ? ' ' : 
								<ListItem selected={(this.state.brandSelected.indexOf(item.idMarca) !== -1)} className={classes.rootListItemBrands} classes={{selected: classes.rootListItemSelected}} key={item.idMarca} role={undefined} dense button onClick={this.handleSelectedBrand(item, i)}>
							        <img src={item.logo} alt={item.nombreMarca} style={{ maxWidth: '60px' }} />
							        	{/*<p className="name-brand">{item.nombreMarca}</p>		*/}		
							        <p className="name-brand">{item.nombreMarca}</p>			        		            
		          				</ListItem>
		          				
					        ))}
					    </List>
				    </div>
		    	</Grid>
		    </Grid>
		);

	};

	renderSelectedBrand(){

		const { classes } = this.props;

		let imatge = '';
		let nomMarca = '';

		this.state.brands.map( (item, i) => {
			if(item.idMarca == this.state.brandSelected[0] ){
				imatge = item.logo;
				nomMarca = item.nombreMarca;			
			}
		});

		return(
			<Grid container justify="center" alignItems="center">
				<Grid item xs={6} sm={3} md={2} lg={1} className="grid-brand-selected">
					<img src={imatge} style={{ maxWidth: '95px', maxHeight: '60px', display: 'block', margin: '0 auto', }} />	
					<p className="name-brand">{nomMarca}</p>
				</Grid>
				<Grid item xs={5} sm={4} md={3} lg={2} className="grid-brand-selected">	
					<div className="close-selected-brand">
						{/*<div style={{ display: 'inline-block' }} onClick={this.unselectBrand()}>
							<p style={{ fontSize: '12px', cursor: 'pointer', }}>Cambiar de marca</p><FontAwesomeIcon icon={['fal', 'times']} />	
						</div>*/}
						<Button variant="contained" color="default" className={classes.buttonChangeBrand} onClick={this.unselectBrand()}>
        					{i18n.t('interests.changeBrand')}
        					<FontAwesomeIcon icon={['far', 'plus']} className={classes.rightIcon} />        					
      					</Button>
					</div>
				</Grid>
		    </Grid>			
		);

	}

	renderBrandsModelsTab(){
		const { classes } = this.props;

		if(this.state.brandSelected.length > 0){

			return(
				<div>
					<h2>Els meus cotxes</h2>
					{this.renderSelectedBrand()}
				    <hr/>
				    <Grid container justify="center">
						<Grid item xs={11} sm={10} md={10} lg={8}>
							<p>{i18n.t('interests.models')}</p>
						    <div className={classes.rootChipsModelos}>
						    	<List className={classes.rootFichasModelos}>
									{this.state.modelosSelected.map( (item, i) => (
							        	<ListItem selected={(this.state.checked.indexOf(i) !== -1)||(this.state.dataSelected.indexOf(item.idModelo) !== -1 )} className={classes.rootListItemModelos} classes={{selected: classes.rootListItemSelected}} key={item.idModelo} role={undefined} dense button onClick={this.handleToggle(i, item.idModelo)}>
								            {/*<Checkbox
								              checked={ (this.state.checked.indexOf(i) !== -1)||(this.state.dataSelected.indexOf(item.idModelo) !== -1 )}
								              tabIndex={-1}
								              disableRipple							              
								            />	
								           	*/}	           
			            					<ListItemText primary={item.nombre} />	            
			          					</ListItem>				
							        ))}
							    </List>
						    </div>
				    	</Grid>
				    </Grid>			    
				</div>
			);

		}else{

			return(<div><h4>Els meus cotxes</h4>{this.renderBrandsSelect()}</div>);

		}
		
	};

	renderRagionsTab(){

		const { classes } = this.props;

		return(
			<div>
				<h4>Les meves zones</h4>
				<Grid container justify="center">
					<Grid item xs={12} sm={10} md={10} lg={10}>
						{/*<p className="sub-title-brands-tab">Regiones</p>*/}
					    <div className={classes.rootMarcas}>
					    	<List className={classes.rootFichasModelos}>
								{this.state.regions.map( (item, i) => (
									<ListItem selected={(this.state.regionsSelected.indexOf(item.idComunidad) !== -1)} className={classes.rootListItemRegions} classes={{selected: classes.rootListItemSelected}} key={item.idComunidad} role={undefined} dense button onClick={this.handleSelectedRegion(item, i)}>
								        <p style={{ fontSize: '13px', }}>{item.nombre}</p>			        		            
			          				</ListItem>
						        ))}
						    </List>
					    </div>
			    	</Grid>
			    </Grid>
				{/*<FormControl>
	          		<InputLabel htmlFor="select-multiple-checkbox">REgION</InputLabel>
	          		<Select
			            multiple
			            value={this.state.regionsSelected}
			            onChange={this.handleChangeRegion}
			            input={<Input id="select-multiple-checkbox" />}
			            renderValue={selected => selected.join(', ')}
			            MenuProps={MenuProps}
	          		>
			            {this.state.regions.map((item, i) => (
			            	<MenuItem key={item.idComunidad} value={item.idComunidad} caseta={item.nombre}>
			                	<Checkbox checked={ this.state.regionsSelected.indexOf(item.idComunidad) !== -1} />
			                	<ListItemText primary={item.nombre} />
			              	</MenuItem>
			            ))}
          			</Select>
        		</FormControl>
        		*/}
        	</div>
		);
	};

	saveUserIterests = () => {

		/*
		console.log('aki entrem a guardar');		
		console.log('models seleccionats');
		console.log(this.state.dataSelected);
		console.log('regions seleccionades');
		console.log(this.state.regionsSelected);
		*/
		
		this.props.saveInterests(this.props.userId, this.state.dataSelected, this.state.regionsSelected)
		.then( (result) => {
			
			console.log('tenim restultat');
			console.log(result);

			this.openAlert(i18n.t('errors.'+result.error));

		});

	};

	openAlert = (textMissatge) => {
    	this.setState({ openAlert: true, operacioOk: textMissatge });  
	};

	closeAlert = () => {
		this.setState({ openAlert: false });
	};


    render(){
    	
    	const { openAlert } = this.state;
    	const { classes } = this.props;
    	return(
    		<div>
    		<p style={{ fontSize: '18px', textAlign: 'center', fontWeight: '700' }}>Les meves alertes</p>
    		<div className="container-block-interests">
				
    			{/*
				<p className="title-sub-section">{i18n.t('interests.interests')}</p>
				<p className="explanation-text">
					{i18n.t('interests.explanation')}
				</p>
				*/}
				
	        	{/*<Grid container justify="center">
					<Grid item xs={11} sm={10} md={10} lg={8}>
						
							<p style={{ fontSize: '25px', textAlign: 'center', margin: '20px 0 0 0', color: '#a5a5a5' }}><FontAwesomeIcon icon={['fal', 'car-side']} className={classes.rightIcon} /></p>
							<div style={{ backgroundColor: '#fff', border: '1px solid #a2a2a2', padding: '10px', borderRadius: '5px', marginBottom: '20px', marginRight: '5px' }}>
								{this.state.dataSelected.map( (item, i) => (
						        	<Chip
						              key={item}	 
						              variant="outlined"             
						              label={this.state.modelos[item]["nombre"]}
						              onDelete={this.handleDelete(item)}	
						              className={classes.chipMarcas}  
						              classes={{label: classes.labelChipMarcas, deleteIcon: classes.iconChipMarcas}}				                          
						            />
						        ))}					       
						    </div>
						
						
					</Grid>
					
			    </Grid>
				*/}


			    {/*
			    <p className="explanation-text">Fes-nos saber què t'agrada per poder-te ajudar a trobar les millors ofertes.
				</p>
	        	<Grid container justify="center">
					<Grid item xs={11} sm={10} md={10} lg={8}>
						<div className="container-btn-tabs">
							<button className={ this.state.valueTab === 0 ? 'selected btn-mis-coches' : 'btn-mis-coches' } onClick={this.handleChangeTab(0)}>{i18n.t('interests.modelsInterest')}</button>
	           				<button className={ this.state.valueTab === 1 ? 'selected btn-mis-zonas' : 'btn-mis-zonas' } onClick={this.handleChangeTab(1)}>{i18n.t('interests.regionInterest')}</button>
						</div>	            		
	           		</Grid>
			    </Grid>
			    */
				}

	            {this.state.valueTab === 0 && <div>{this.renderBrandsModelsTab()}</div>}
        		{this.state.valueTab === 1 && <div>{this.renderRagionsTab()}</div>}
				
        		{/*
			    <Grid container justify="center">
					<Grid item xs={11} sm={10} md={10} lg={8}>
						<div className="container-btn-interests">
							<button className="btn btn1 btn-save-interests" onClick={this.saveUserIterests}>{i18n.t('interests.save')}</button>
						</div>						
					</Grid>
			    </Grid>
			    */}
			    
			    {/*
			    <Snackbar
			        anchorOrigin={{  vertical: 'bottom', horizontal: 'right' }}
			        open={openAlert}
			        onClose={this.closeAlert}			         
			        ContentProps={{			            
			            classes: {
                        	root: classes.snackAlert
                    	}
			        }}
			        autoHideDuration={2500}
			        message={this.state.operacioOk}			          
			    />
			*/}

				<Dialog
                  open={openAlert}
                  onClose={this.closeAlert}
                  maxWidth='sm'
                  classes={{ paper: classes.dialogPaper }}
                  fullWidth={true}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title" onClose={this.closeAlert}>&nbsp;</DialogTitle>
                  
                    <DialogContent classes={{root: classes.inicioForm}}>
                      <p className="alert-text">{this.state.operacioOk}</p>        
                    </DialogContent>
                    <DialogActions classes={{root: classes.inicioBtn}}>
                        <button  className="btn1 btn-lead" onClick={this.closeAlert}>
                         {i18n.t('global.accept')}                 
                        </button>                 
                    </DialogActions>
                  
                </Dialog>
				
			</div>


			<div className="container-block-interests">
				{/*<Grid container justify="center">
					<Grid item xs={11} sm={10} md={10} lg={8}>
		    			<p style={{ fontSize: '25px', textAlign: 'center', margin: '20px 0 0 0', color: '#a5a5a5' }}><FontAwesomeIcon icon={['fal', 'map-marker-alt']} className={classes.rightIcon} /></p>
						<div style={{ backgroundColor: '#fff', border: '1px solid #a2a2a2', padding: '10px', borderRadius: '5px', marginBottom: '20px', marginLeft: '5px' }}>
							
					        {this.state.regionsSelected.map( (item, i) => (
					        	<Chip
					              key={item}	 
					              variant="outlined"             
					              label={this.state.regionsAux[item]["nombre"]}
					              onDelete={this.handleDeleteRegion(item)}	
					              className={classes.chipMarcas}  
					              classes={{label: classes.labelChipMarcas, deleteIcon: classes.iconChipMarcas}}            
					            />
					        ))}
					    </div>
					</Grid>
			    </Grid>
				*/}
				{this.renderRagionsTab()}
			</div>

				<div className="container-btn-save-interests">
					<div className="container-interested">						
						<Grid container justify="center">						
							<Grid item xs={12} sm={8} md={6} lg={4}>
								<div className="sub-container-btn-save-interests">
									<button className="btn btn1 btn-save-interests" onClick={this.saveUserIterests}>{/*i18n.t('interests.saveInterests')*/}Guardar alertes</button>
								</div>								
							</Grid>
					    </Grid>						
					</div>
				</div>
			</div>

    	);
    }

}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const mapStateToProps = (state) => {
	return { 
		userId: state.auth.userId,
		selectedLanguage: state.auth.selectedLanguage		
	};
};

InterestsForm = withStyles(styles)(InterestsForm);


InterestsForm = connect(
	mapStateToProps,
	{ fetchBrandsModelsRegions, saveInterests, getUserInterests }
)(withNamespaces()(InterestsForm));


export default InterestsForm;

