import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';

import history from '../history';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import { sendLeadCallMe } from '../actions/';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



class LlamameTopContainer extends React.Component{

	constructor(props) {
		super(props);

		this.handleChangeInput = this.handleChangeInput.bind(this);
		this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);

		this.state = {
			phoneNumber: props.textTlf,
			textButton: props.textButton,
			textLabel: props.textLabel,
			iconLeft: props.iconText,
			iconRight: props.iconRight,
			currentEvent: props.idEvent,
			privacity: false,
			errorText: false,
			errorPrivacity: false,
			errorMsgText: 'error',
			errorMsgPrivacity: i18n.t('errorForms.required'),
			phoneCallMe: '',
			thinking: false,
			sourceUrl: props.sourceUrl
		}

	}


	handleChangeCheckBox(e){	

		var copiaState = Object.assign({}, this.state);
		if(e.target.value == "false"){
			copiaState[e.target.id] = true;
		}else{
			copiaState[e.target.id] = false;
		}
		this.setState(copiaState);
	}



	renderIconLeft = () => {
		if(this.state.iconLeft !== ""){
			return(<span className="icon-call-me">{this.state.iconLeft}</span>);
		}else{
			return(<span></span>);
		}
	}

	renderIconRight = () => {
		if(this.state.iconRight !== ""){
			return(<span className="icon-right">{this.state.iconRight}</span>);
		}else{
			return(<span></span>);
		}
	}

	renderAcceptPrivacity = () => {
		return(
			<div className="inputContainerCheckbox"> 			
				<input id="privacity" name="privacity" type="checkbox" checked={this.state.privacity} value={this.state.privacity} onChange={(e) => this.handleChangeCheckBox(e)} /><label className="input-label-privacity" dangerouslySetInnerHTML={{__html: i18n.t('landing.acceptPrivacity')}}></label>
			</div>
		);
	};

	renderTextError = () => {

		if(this.state.errorText === true){
			return(
				<div>	
					<div className="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{this.state.errorMsgText}</div>
				</div>
			);
		}else{			
			return(
				<div></div>
			);
		}

	};

	renderErrorPrivacity = () => {

		if(this.state.errorPrivacity === true){
			return(	
				<div>	
					<div className="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{this.state.errorMsgPrivacity}</div>
				</div>
			);
		}else{
			return(
				<div></div>
			);
		}

	};

	renderError({error, touched}){		
		if(touched && error){
			return(
				<div>	
					<div className="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{error}</div>
				</div>
			);
		}
	};

	renderInput = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainer">
				<input {...input} autoComplete="off" value={initialValue} placeholder={i18n.t('landing.enter')+" "+label.toLowerCase()} type={type} disabled={disabled} />
				{this.renderError(meta)}
			</div>
		);
	};

	handleChangeInput(e){	
		const inputName = e.target.name;
		const inputValue = e.target.value;		
		var copiaState = Object.assign({}, this.state);
		copiaState[inputName] = inputValue;
		this.setState(copiaState);  		
	}

	renderLoader = () => {

		if(this.state.thinking === true){
			return(
				<div className="div-loader"><div className="container-loader"><CircularProgress color="secondary" /></div></div>
			);
		}else{
			return(
				<div></div>				
			);
		}
	}

	sendPhoneLead = (formValues) => {

		//console.log("hem fet submit");
		//console.log(formValues);
		//errorText,errorPrivacity

		this.state.thinking = true;

		return sleep(1000).then(() => {
		    // simulate server latency
		    
		    if(!this.state.phoneCallMe){
		    	this.state.thinking = false;
		    	throw new SubmissionError({
		        	phoneCallMe: i18n.t('errorForms.required'),
		        	_error: 'failed!'
		     	 })
		    }else if(this.state.phoneCallMe && !/^\+?\d+$/.test(this.state.phoneCallMe) ){
		    	this.state.thinking = false;
		    	throw new SubmissionError({
		        	phoneCallMe: i18n.t('errorForms.invalidintegerformat'),
		        	_error: 'failed!'
		     	 })
		    }else if(this.state.privacity === false){
		    	this.state.thinking = false;
		    	this.setState({
		    		errorPrivacity : true, 
		    		thinking: false
		    	});

		    }else{
		    	//enviem lead amb el tlf
		    	//console.log('enviem lead call me');
		    	//console.log(this.props.userId);
		    	//console.log(this.state.currentEvent);
		    	//console.log(this.state.phoneCallMe);

		    	this.props.sendLeadCallMe(this.props.userId, this.state.nombre, this.state.phoneCallMe, this.state.currentEvent, this.state.sourceUrl)
		    	.then( (result) => {

		    		history.push("/thank-you");		    		

		    	});
		    	




		    }
		});

	}



	render(){

		return(

			<div className="container-call-me-container">
				<p className="label-call-me">{i18n.t('landing.callUs')}<br/><a  href={"tel:+34"+ this.state.phoneNumber.replace(/\s/g,'')}>{this.state.phoneNumber}</a><br/>{i18n.t('landing.orWeCall')}</p>
				<form className="form-more-info" onSubmit={ this.props.handleSubmit(this.sendPhoneLead) }  >
					<div className="container-inputs-call-me">
						<Field name="phoneCallMe" onChange={this.handleChangeInput} initialValue={this.state.phoneCallMe} component={this.renderInput} label={i18n.t('editAccount.tlf')}  />
						{this.renderAcceptPrivacity()}
						{this.renderErrorPrivacity()}
					</div>
					<div>
						<button className="btn1 btn-call-me">
							<span className="text-btn">{this.state.textButton}</span>
						</button>
					</div>
				</form>
				{this.renderLoader()}
			</div>

		);

	}


}



const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		selectedLanguage: state.auth.selectedLanguage,
		userId: state.auth.userId, 
		userMail: state.auth.userMail, 
		userName: state.auth.userName
	};
};



LlamameTopContainer = reduxForm({
  form: 'CallMeCampaign',
  enableReinitialize: true,  
})(LlamameTopContainer);

LlamameTopContainer = connect(
	mapStateToProps,
	{ sendLeadCallMe }
)(LlamameTopContainer);


LlamameTopContainer = withNamespaces()(LlamameTopContainer);

export default LlamameTopContainer;
