import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';


import { signIn, signOut, createNewUser, errorLogin } from '../../actions/';

import { authFirebase } from '../Firebase/Firebase.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import ReactGA from 'react-ga';
/*
import TagManager from 'react-gtm-module';

const tagManagerArgs = {    
    events: {
        category: 'User',
		action: 'Created an Account'
    },
    dataLayerName: 'PageDataLayer'
};
*/

class SignUpAuth extends React.Component {

	constructor(props) {
		
		super(props);
		
		this.handleChangeInput = this.handleChangeInput.bind(this);
		this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);

		this.state = {	      
	      	nombre: this.props.nombre,
    		email: this.props.email,
    		tlf: this.props.tlf,
    		apellido: '',
    		zip: '',
    		pwd: '',
    		privacity: false,
	      	errorPrivacity: false,    
	    };	
	}

	
	componentDidMount() {
 		
 		const initData = {		        		
    		nombre: this.props.nombre,
    		email: this.props.email,
    		tlf: this.props.tlf,
    		apellido: '',
    		zip: '',
    		pwd: ''
    	};

		this.props.initialize(initData);

 		
	}

	handleChangeInput(e){	

		const inputName = e.target.name;
		const inputValue = e.target.value;	
		var copiaState = Object.assign({}, this.state);
		copiaState[inputName] = inputValue;
		this.setState(copiaState); 
				
	}

	handleChangeCheckBox(e){	

		var copiaState = Object.assign({}, this.state);
		if(e.target.value == "false"){
			copiaState[e.target.id] = true;
		}else{
			copiaState[e.target.id] = false;
		}
		this.setState(copiaState);
	}


	doCreateUserWithEmailAndPassword = (email, password) => authFirebase.createUserWithEmailAndPassword(email, password);	

	onSubmit = (formValues) => {
		
		//authFirebase.createUserWithEmailAndPassword(email, password)
		//console.log("fem submit");
		const {email, pwd, nombre, apellido, zip, tlf } = formValues;
		

		if(this.state.privacity === false){
		    	
	    	this.setState({
	    		errorPrivacity : true
	    	});
	    	
	    }else{	    	
		    
		    this.doCreateUserWithEmailAndPassword(email, pwd)
			.then( (authUser) => {

				//console.log("resultat usuari");
				//console.log(authUser);
				this.props.signIn(authUser.user.uid, authUser.user.email, nombre );
				const usuari = {
						uid: authUser.user.uid,
						mail: authUser.user.email,
						nombre: nombre,
						apellido: apellido,
						codigoPostal: zip,
						tlf: tlf,
						sexo: '',
						language: 'es',
						provider: 'motor-prive'
					};
	    		this.props.createNewUser(usuari);

	    		window.gtag('event', 'Created an Account');

	    		//TagManager.dataLayer(tagManagerArgs);
	    		/*
	    		ReactGA.event({
					  category: 'User',
					  action: 'Created an Account'
					});
	    		*/

			}).catch((error) => {
	  			// An error happened.
	  			//console.log(error);
	  			this.setState({
		    		errorPrivacity : false
		    	});
	  			this.props.errorLogin(serverValidation(error.code));
			});
	    }


		






		//authFirebase.createUserWithEmailAndPassword(email, pwd);

		//console.log(email);
		//console.log(formValues);
	};

	renderError({error, touched}){
		
		if(touched && error){
			return(
				<div>	
					<div className="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{error}</div>
				</div>
			);
		}

	}

	renderInput = ({input, label, meta, type }) => {
		return (
			<div className="inputContainer">				
				<input {...input} autoComplete="off" placeholder={label} type={type} />
				{this.renderError(meta)}
			</div>
		);
	}

	renderLoginErrors(){
		if(this.props.errorLog){
			return(
				<p className="error-alert">{this.props.errorLog}</p>
			);
		}
	}

	renderErrorPrivacity = () => {
		if(!this.state.errorPrivacity){			
			return(
				<div></div>
			);
		}else{
			return(
				<div style={{marginTop: '15px', border: '1px solid #ed4c3e', paddingTop: '10px' }}>	
					<div className="error-container" style={{textAlign: 'center'}}><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{i18n.t('landing.errorPrivacity')}</div>
				</div>
			);
		}

	};


	renderAcceptPrivacity = () => {
		return(
			<div className="inputContainerCheckbox"> 			
				<input id="privacity" name="privacity" type="checkbox" checked={this.state.privacity} value={this.state.privacity} onChange={(e) => this.handleChangeCheckBox(e)} /><label className="input-label" dangerouslySetInnerHTML={{__html: i18n.t('landing.acceptPrivacity')}}></label>
			</div>
		);
	};

	render(){
		return(
			<div>				
				<form className="signin-form" onSubmit={ this.props.handleSubmit(this.onSubmit) }>
					<Field name="nombre" component={this.renderInput} label={"*"+i18n.t('editAccount.name')} type="text" />
					<Field name="apellido" component={this.renderInput} initialValue={this.state.apellido} onChange={this.handleChangeInput} label={"*"+i18n.t('editAccount.surname')} type="text" />
					<Field name="tlf" component={this.renderInput} label={"*"+i18n.t('editAccount.tlf')} type="text" />
					<Field name="zip" component={this.renderInput} label={i18n.t('editAccount.zip')} type="text" />
					<Field name="email" component={this.renderInput} label={"*"+i18n.t('editAccount.email')} type="email" />
					<Field name="pwd" component={this.renderInput} label={"*"+i18n.t('editAccount.pwd')} type="password" />
					{this.renderAcceptPrivacity()}
					{this.renderErrorPrivacity()}
					{this.renderLoginErrors()}					
					<button className="btn btn1 btn-signin" >{i18n.t('login.signin')}</button>
				</form>
				
				{/*<SignInForm onSubmit={this.onSubmit} />*/}
			</div>
		);
	}
}

const validate = (formValues) => {

	const errors = {};
	const requiredFields = [
	    'email',
	    'pwd',
	    'nombre',
	    'apellido',
	    'tlf'	    
  	];

  	  	
  	requiredFields.forEach(field => {
	    if (!formValues[field]) {
	      errors[field] = i18n.t('errorForms.required');
	    }
  	})

	if ( formValues.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email) ) {
    	errors.email = i18n.t('errorForms.invalidemail');
  	}else{

  		var emailsProhibits = ["yopmail", "urhen", "bcaoo", "mailinator", "harakirimail", "dispostable", "sharklasers",
		    						"guerrillamail", "pokemail", "spam4", "spamgourmet", "mintemail", "trashmail", 
		    						"mailforspam", "mailnesia", "mailnull", "superrito.com", "teleworm.us", "wpdfs.com"];

		var totals = emailsProhibits.length;				
		let i, aux;
		
		if ( formValues.email){
			for(i=0; i<totals; i++){
				if (formValues.email.indexOf(emailsProhibits[i]) === -1) {
					//console.log("no hi es");
				}else{
					errors.email = i18n.t('errorForms.invalidemail');
					i=5000;
				}
			}
		}
		


  	}
	
	
	return errors;

};

export const serverValidation = (errorId) => {

	return i18n.t('errors.'+errorId);
};



const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn, 
		userId: state.auth.userId, 
		userMail: state.auth.userMail, 
		userName: state.auth.userName,
		errorLog: state.auth.errorLog 
	};
};


SignUpAuth = reduxForm({
    form: 'SignInForm',  
    /*enableReinitialize: true,  */
    validate
})(SignUpAuth);

SignUpAuth = withNamespaces()(SignUpAuth);

SignUpAuth = connect(
	mapStateToProps, 
	{ signIn, signOut, createNewUser, errorLogin }
)(SignUpAuth);

export default SignUpAuth;



