import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import { Field, reduxForm } from 'redux-form';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';


import { signIn, signOut, errorLogin } from '../../actions/';
//import history from '../../history';

import { authFirebase } from '../Firebase/Firebase.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



class LoginAuth extends React.Component{

	
	constructor(props) {

		super(props);


	}

	componentDidMount() {
		//console.log("hi ha actualitzacio");
	}
	

	loginUserWithEmailAndPassword = (email, password) => authFirebase.signInWithEmailAndPassword(email, password);


	onSubmit = (formValues) => {

		//console.log("fem submit");
		const {email, pwd } = formValues;

		this.loginUserWithEmailAndPassword(email, pwd)
		.then( authUser => {

			//this.props.signIn(authUser.user.uid, authUser.user.email, authUser.user.displayName);
			//history.push('/campaigns');
		}).catch((error) => {
  			// An error happened.
  			//console.log("no ens hem pogut logar"); 			
  			//console.log(error); 	
  			this.props.errorLogin(serverValidation(error.code));		
  			
		});
	};


	renderError({error, touched}){
		
		if(touched && error){
			return(
				<div>	
					<div className="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{error}</div>
				</div>
			);
		}

	}

	renderInput = ({input, label, meta, type}) => {
		return (
			<div className="inputContainer">				
				<input {...input} autoComplete="off" placeholder={label} type={type} />
				{this.renderError(meta)}
			</div>
		);
	};
	
	renderLoginErrors(){
		if(this.props.errorLog){
			return(
				<p className="error-alert">{this.props.errorLog}</p>
			);
		}
	}

	render(){
		
		return(
			<div>				
				<form className="login-form"  onSubmit={ this.props.handleSubmit(this.onSubmit) }>	
						
					<Field name="email" type="email" component={this.renderInput} label={"*"+i18n.t('editAccount.email')} />					
					<Field name="pwd" component={this.renderInput} type="password" label={"*"+i18n.t('editAccount.pwd')} />
					<div className="container-link-forget">
						<Link to="/users/forget-password" className="forget-link">{i18n.t('login.forgetpassword')}</Link>
					</div>					
					{this.renderLoginErrors()}					
					<button className="btn btn1 btn-signin">{i18n.t('login.login')}</button>
				</form>			
			</div>
		);
	}


}

export const serverValidation = (errorId) => {

	return i18n.t('errors.'+errorId);
};


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn, 
		userId: state.auth.userId, 
		userMail: state.auth.userMail , 
		userName: state.auth.userName, 
		errorLog: state.auth.errorLog 
	};
};


const validate = (formValues) => {

	const errors = {};
	const requiredFields = [
	    'email',
	    'pwd'	    
  	];

  	
  	requiredFields.forEach(field => {
	    if (!formValues[field]) {
	      errors[field] = i18n.t('errorForms.required');
	    }
  	})

	if ( formValues.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email) ) {
    	errors.email = i18n.t('errorForms.invalidemail');
  	}

	return errors;

};

function caca(values){

	const {email, pwd } = values;
	var hola = 'fff';
	console.log(values);
	authFirebase.signInWithEmailAndPassword(email, pwd).then().catch(function(error) {
  			// An error happened.
  			console.log("no ens hem pogut logar");
  			console.log(error.code);
  			console.log(error);  	
  			//errorLogin(error.code);
	});
	
	console.log(hola);
	throw new SubmissionError({ pwd: 'Wrong password', _error: hola });
   	
}

LoginAuth = withNamespaces()(LoginAuth);

LoginAuth = connect(
	mapStateToProps, 
	{ signIn, signOut, errorLogin }
)(LoginAuth);

export default reduxForm({
    form: 'loginFormulari',
    onSubmit: caca,
    validate: validate
})(LoginAuth);




/*
class LoginAuth extends React.Component{

	

	loginUserWithEmailAndPassword = (email, password) => authFirebase.signInWithEmailAndPassword(email, password);


	onSubmit = (formValues) => {

		console.log("fem submit");
		const {email, pwd } = formValues;

		this.loginUserWithEmailAndPassword(email, pwd)
		.then( authUser => {

			//this.props.signIn(authUser.user.uid, authUser.user.email, authUser.user.displayName);
			//history.push('/events');
		}).catch(function(error) {
  			// An error happened.
  			console.log("no ens hem pogut logar");
  			//this.props.serverValidation(error.code);

  			console.log(error.code);
  			console.log(error);  			
  			
		});


	};

	mostraErrors = (codi ) => {
		this.errorServer = 'errorServer';
	};

	renderError(){
		
		if(this.errorServer == ''){
			return(
				<div>	
					<div>No hi ha error</div>
				</div>
			);
		}else{
			return(
				<div>	
					<div>error</div>
				</div>
			);
		}
		
		
	}


	render(){
		return(
			<div>
				ACCEDER
				<LoginForm onSubmit={this.onSubmit} />
				
				<Link to="/signup">¿Aún no eres socio? Regístrate</Link>
				<Link to="/forget-password">¿Olvidaste la contraseña?</Link>
				
			</div>
		);
	}


}

export const serverValidation = (errorId) => {

	switch(errorId){

		case 'auth/invalid-email':
			return 'invalid email';
		break;
		case 'auth/user-disabled':
			return 'usuari no activat';
			break;
		case 'auth/user-not-found':
			return 'usuari no trobat';

			break;
		case 'auth/wrong-password':
			return 'pwd erroni';
			break;
		default:
			return '';
	}
};


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn, 
		userId: state.auth.userId, 
		userMail: state.auth.userMail , 
		userName: state.auth.userName 
	};
};

export default connect(
	mapStateToProps, 
	{ signIn, signOut, serverValidation }
)(LoginAuth);
*/






