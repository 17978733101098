import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';


import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import Grid from '@material-ui/core/Grid';

import { setCurrentPage } from '../actions/';

class ConditionsView extends React.Component{

	constructor(props) {

		super(props);
	    this.props.setCurrentPage('use-conditions');

	}


	render(){
		return(			
			<div className="template-legal">
				<Grid container justify="center" >
					<Grid item xs={12}>							
						<div className="container-title">							
							<h1>Condiciones de uso</h1>							
						</div>
					</Grid>
					<Grid item xs={11} sm={11} md={8} lg={8}>
						<p style={{fontSize: '14px'}}>
							Las presentes Condiciones de Uso para los usuarios de la plataforma de Motor Privé, conforman junto al <a style={{color: '#000'}} href="https://app.motorprive.com/terms"><b>Aviso Legal</b></a> y la <a href="https://app.motorprive.com/privacy-policies" style={{color: '#000'}}><b>Política de privacidad</b></a>, los Términos y Condiciones globales de acceso y uso a la plataforma.
						</p>
						<h3>1. Introducción</h3>
						<p style={{fontSize: '14px'}}>
							Las presentes Condiciones generales de uso (en adelante, Condiciones) regulan el uso y acceso al servicio accesible mediante el portal web app.motorprive (en adelante, la "Plataforma"), titularidad de Mediagach, S.L. (en adelante, "Motor Privé" o "Nosotros") para usuarios registrados (en adelante, "Usuario" o "Usuarios").
							<br/><br/>
							La Plataforma de Motor Privé ofrece un servicio que permite al Usuario la posibilidad de inscribirse a eventos de venta de automóviles organizados por concesionarios debidamente autorizados. También permite al Usuario introducir sus preferencias de búsqueda para recibir alertas en caso que se active un evento.
							<br/><br/>
							En Motor Privé no intervenimos en ninguna transacción de compraventa, ni de renting, que se pueda producir entre el Usuario y los concesionarios. Asimismo, en Motor Privé no realizamos ningún tipo de supervisión, control, valoración o aprobación sobre la calidad o validez de los coches. En cualquier caso, Motor Privé puede solicitar datos para que concesionarios, dispongan de mayor información para ofrecerle un mejor servicio.
							<br/><br/>
							En consecuencia, en Motor Privé declinamos cualquier tipo de responsabilidad derivada tanto de la información comunicada por el concesionario al Usuario a través de la Plataforma como de cualquier tipo de transacción que se pueda llevar a cabo a partir de la misma fuera de la Plataforma. El acceso a la Plataforma de Motor Privé implica el conocimiento y la aceptación de las presentes Condiciones:
						</p>
						<h3>2. Acceso y Registro</h3>
						<p style={{fontSize: '14px'}}>
							Para poder ser Usuario de la Plataforma de Motor Privé es indispensable que se cumplan los siguientes requisitos:
						</p>
						<ul style={{fontSize: '14px'}}>
							<li>Haber cumplido o ser mayor de 18 años de edad.</li>
							<li>Cumplimentar de manera veraz los campos obligatorios del formulario de registro, en el que se solicitan datos de carácter personal.</li>
							<li>Tener un interés real y particular en la adquisición de uno o varios coches para uso particular o comercial, a través de una operación de compraventa o mediante una operación de renting.</li>
							<li>Capacidad legal suficiente para suscribir contratos.</li>
						</ul>
						<p style={{fontSize: '14px'}}>
							Al darse de alta en la Plataforma, el Usuario tienes la opción de (i) introducir un e-mail y seleccionar una clave de acceso, (ii) sincronización con servicios de terceros (Ej. cuenta de Facebook o Google. El Usuario podrá modificar posteriormente a de la sección EDITAR PERFIL, su nombre, el e-mail de acceso o el password, así como añadir más información de perfil que pueda ayudar a recibir comunicación más personalizada. 
							<br/><br/>
							En Motor Privé solicitaremos siempre al Usuario el consentimiento de forma previa al registro antes de proceder a la sincronización de servicios entre un tercero y Motor Privé.
							<br/><br/>
							El password será estrictamente confidencial, personal e intransferible. El Usuario se compromete a no divulgar los datos relativos a su cuenta ni hacerlos accesibles a terceros. El Usuario será el único responsable en caso de uso de dichos datos por terceros, o cualquier otra actuación que se lleve a cabo mediante el uso del usuario y/o password.
							<br/><br/>
							El Usuario garantiza que todos los datos sobre su identidad y legitimidad facilitados a Motor Privé en los formularios de la Plataforma son veraces, exactos y completos. Asimismo, se compromete a mantener actualizados sus datos. En el supuesto de que el Usuario facilite cualquier dato falso, inexacto o incompleto o si Motor Privé considera que existen motivos fundados para dudar sobre la veracidad, exactitud e integridad de los mismos, Motor Privé podrá denegarle el acceso y uso presente o futuro de la Plataforma o de cualquiera de sus contenidos y/o servicios.
							<br/><br/>
							En Motor Privé no podemos garantizar la identidad de los Usuarios registrados, por tanto, no seremos responsables del uso de la identidad de un Usuario registrado por terceros no registrados. El Usuario se obliga a poner inmediatamente en conocimiento de Motor Privé la sustracción, divulgación o pérdida de su password, comunicándolo a <em>hola@motorprive.com</em>
						</p>
						<h3>3. Características de la Plataforma</h3>
						<p style={{fontSize: '14px'}}>
							Las principales funciones ofrecidas por la Plataforma de Motor Privé constarán de:	
						</p>
						<ul style={{fontSize: '14px'}}>
							<li>Buscador de eventos de venta de automóviles.</li>
							<li>Sección de detalle de eventos para la inscripción de los Usuarios de la Plataforma.</li>
							<li>Mensajería instantánea (chat) para comunicarse entre los Usuarios de la Plataforma y el servicio de atención de Motor Privé.</li>
							<li>Notificaciones y alertas mediante comunicaciones por e-mail y sistema push.</li>
						</ul>
						<h3>4. Funcionamiento de la Plataforma</h3>
						<h4>Opción de Inscripción a eventos</h4>
						<p style={{fontSize: '14px'}}>
							En Motor Privé no vendemos vehículos ni organizamos eventos, tan solo hacemos posible que los concesionarios puedan organizar eventos para la venta de vehículos. Si el Usuario decide finalmente comprar un vehículo de un concesionario en un evento, la transacción se producirá directamente con el concesionario.
							<br/><br/>
							Las ofertas que reciban los Usuarios por los concesionarios en el mismo evento, no suponen ningún compromiso de compra por parte del Usuario. Las ofertas mostradas a través de Motor Privé y realizadas por los concesionarios, no serán vinculantes para el concesionario, pudiendo el Concesionario modificar y/o cancelar los términos y condiciones de la oferta a su libre discreción en cualquier momento.
							<br/><br/>
							El procedimiento para que el Usuario se inscriba a un evento será el siguiente:
							<br/><br/>
							El Usuario selecciona un evento mediante el buscador de eventos de la Plataforma. En la sección de detalle podrá inscribirse rellenando la información del formulario de inscripción. En eso momento, recibirá un correo electrónica notificando que se ha inscrito con éxito. Según la modalidad del evento, el usuario recibirá una llamada por parte del equipo de Motor Privé o del mismo concesionario para elegir la fecha y hora que más le convenga al Usuario. 
							<br/><br/>
							En caso que el evento esté supeditado a un número mínimo de inscritos, el Usuario no recibirá la llamada hasta que no se alcance el objetivo de inscripciones.
							<br/><br/>
							Motor Privé no verifica ni respalda ningún evento ni recomienda qué coche comprar ni en qué términos.
						</p>
						<h3>5. Obligaciones del Usuario</h3>
						<p style={{fontSize: '14px'}}>
							El Usuario es responsable de contar con los servicios y equipos necesarios para la navegación por Internet y para acceder a la Plataforma. En caso de cualquier incidencia o dificultad para acceder a la Plataforma, el Usuario puede informarlo a Motor Privé para que procedamos a analizar la incidencia y daremos indicaciones al Usuario acerca de cómo resolverla en el plazo más breve posible.
							<br/><br/>
							El Usuario es completamente responsable del acceso y correcto uso de su perfil y demás contenidos y servicios de la Plataforma con sujeción a la legalidad vigente, sea nacional o internacional, así como a los principios de buena fe, a la moral, buenas costumbres y orden público. Y específicamente, adquiere el compromiso de observar diligentemente las presentes Condiciones.
							<br/><br/>
							Los Usuarios se abstendrán de usar su perfil y el resto de contenidos de la Plataforma con fines o efectos ilícitos y que sean lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, afectar o deteriorar la Plataforma, sus contenidos y sus servicios. Asimismo, queda prohibido impedir la normal utilización o disfrute de la Plataforma a otros Usuarios.
							<br/><br/>
							Quienes incumplan tales obligaciones responderán de cualesquier perjuicio o daño que ocasionen. Motor Privé no responderá de ninguna consecuencia, daño o perjuicio que pudieran derivarse de dicho acceso o uso ilícito por parte de terceros.
							<br/><br/>
							En general, el Usuario se compromete, a título enunciativo y no taxativo, a:
						</p>
						<ul style={{fontSize: '14px'}}>
							<li>No utilizar la Plataforma para fines ilegales o no autorizados;</li>
							<li>No alojar, almacenar, divulgar, publicar, distribuir o compartir cualquier contenido que pueda suponer una intromisión ilegítima o vulneración en cualquier forma de los derechos fundamentarles al honor, imagen e intimidad personal y familiar de terceros y muy especialmente, de los menores de edad;</li>
							<li>No publicar en ningún apartado de la Plataforma ni en su perfil o el de otro Usuario ningún comentario o información que sea ilegal, racista, xenófoba, obscena, pornográfica, abusiva, difamatoria, engañosa, fraudulenta o de cualquier forma contraria a la moral o al orden público;</li>
							<li>No alterar o modificar, total o parcialmente la Plataforma, eludiendo, desactivando o manipulando de cualquier otra las funciones o servicios de la misma;</li>
							<li>No infringir los derechos de propiedad industrial e intelectual o las normas reguladoras de la protección de datos de carácter personal;</li>
							<li>No usar la Plataforma para injuriar, difamar, intimidar, violar la propia imagen o acosar a otros Usuarios o a ningún concesionarios;</li>
							<li>No acceder a las cuentas de correo electrónico de otros Usuarios;</li>
							<li>No introducir virus informáticos, archivos defectuosos, o cualquier otro programa informático que pueda provocar daños o alteraciones en los contenidos o sistemas de Motor Privé o terceras personas;</li>
							<li>No remitir correos electrónicos con carácter masivo y/o repetitivo a una pluralidad de personas, ni enviar direcciones de correo electrónico de terceros sin su consentimiento;</li>
							<li>No realizar acciones publicitarias de bienes o servicios sin el previo consentimiento de Motor Privé.</li>
						</ul>
						<p style={{fontSize: '14px'}}>
							Cualquier Usuario podrá reportar a otro Usuario cuando considere que está incumpliendo las presentes Condiciones, asimismo todos los Usuarios pueden informar a Motor Privé de cualquier abuso o vulneración de las presentes condiciones, a través del correo electrónico hola@motorprive.com . En Motor Privé verificaremos los reportes recibidos, a la mayor brevedad posible, para adoptar las medidas que consideremos oportunas, reservándonos el derecho a retirar y/o suspender a cualquier Usuario de la Plataforma por el incumplimiento de las presentes Condiciones. Asimismo nos reservamos el derecho a retirar y/o suspender cualquier mensaje con contenido ilegal u ofensivo, sin necesidad de previo aviso o posterior notificación al Usuario.
						</p>
						<h3>6. Responsabilidad de Motor Privé</h3>
						<p style={{fontSize: '14px'}}>
							Las descripciones de los eventos publicados en la plataforma pueden ser modificados por inconvenientes logísticos por parte del concesionario. En consecuencia, Motor Privé no responderá frente a posibles inexactitudes en las descripciones de los eventos.
							<br/><br/>
							En Motor Privé no tenemos obligación de controlar ni controlamos la utilización que los Usuarios hacen de la Plataforma y, por consiguiente, no podemos garantizar que los Usuarios utilicen la Plataforma de conformidad con lo establecido en las presentes Condiciones, ni que hagan un uso diligente y/o prudente de la misma. En Motor Privé no tenemos la obligación de verificar ni verificamos la identidad de los Usuarios, ni la veracidad, vigencia, exhaustividad y/o autenticidad de los datos que los mismos nos proporcionan en la Plataforma.
							<br/><br/>
							En Motor Privé excluimos cualquier responsabilidad por los daños y perjuicios de toda naturaleza que pudieran deberse a la utilización ilícita de la Plataforma por parte de los Usuarios o que puedan deberse a la falta de veracidad, vigencia, exhaustividad y/o autenticidad de la información que los Usuarios proporcionan a otros Usuarios acerca de sí mismos y, en particular, acerca de los servicios ofrecidos para su contratación a través de la Plataforma, así como por los daños y perjuicios de toda naturaleza que puedan deberse a la suplantación de la personalidad de un tercero efectuada por un Usuario en cualquier clase de comunicación realizada a través de la Plataforma.
							<br/><br/>
							Sin perjuicio de lo anterior, nos reservamos la facultad de limitar, total o parcialmente, el acceso a la Plataforma a determinados Usuarios, así como de cancelar, suspender, bloquear o eliminar determinado tipo de contenidos, mediante la utilización de instrumentos tecnológicos aptos al efecto, si tuviese conocimiento efectivo de que la actividad o la información almacenada es ilícita o de que lesiona bienes o derechos de un tercero. En este sentido, Motor Privé podrá establecer los filtros necesarios a fin de evitar que a través del servicio puedan verterse en la red contenidos ilícitos o nocivos.
							<br/><br/>
							Motor Privé no responderá en caso de interrupciones del servicio, errores de conexión, falta de disponibilidad o deficiencias en el servicio de acceso a Internet, ni por interrupciones de la red de Internet o por cualquier otra razón ajena a su control.
							<br/><br/>
							En Motor Privé no nos hacemos responsable de los errores de seguridad que se puedan producir ni de los daños que puedan causarse al sistema informático del Usuario (hardware y software), ni a los ficheros o documentos almacenados en el mismo, como consecuencia de:
						</p>
						<ul style={{fontSize: '14px'}}>
							<li>La presencia de un virus en el sistema informático o terminal móvil del Usuario que sea utilizado para la conexión a los servicios y contenidos de la Plataforma;</li>
							<li>Un mal funcionamiento del navegador;</li>
							<li>Del uso de versiones no actualizadas del mismo.</li>
						</ul>
						<h3>7. Actualización y Modificación de la Plataforma</h3>
						<p style={{fontSize: '14px'}}>
							Motor Privé se reserva el derecho a modificar el presente documento de Condiciones en cualquier momento. En caso de realizar algún cambio de las Condiciones, estas serán publicadas en la Plataforma y se indicara la fecha de su publicación, para que el Usuario conozca la fecha en que se ha hecho efectiva dicha modificación. En cualquier caso, usted, recibirá una notificación a través de medios electrónicos sobre la actualización de las Condiciones de uso de Motor Privé con anterioridad a su publicación en la Plataforma.
							<br/><br/>
							Una vez reciba usted la notificación pertinente sobre la modificación de los Términos y Condiciones, usted podrá rescindir su contrato previo a la fecha en que entren en vigor los nuevos Términos y Condiciones o podrá optar por la continuación de su acceso y utilización de la Plataforma.
						</p>
						<h3>8. Propiedad Intelectual</h3>
						<p style={{fontSize: '14px'}}>
							Los contenidos relacionados con la marca Motor Privé, dominios, logotipos, dibujos o documentación, incluyendo, software, programas informáticos, o cualquier elemento que pueda ser susceptible de protección por la legislación de Propiedad Intelectual o Industrial, que puedan ser de acceso a los usuarios, son de propiedad de Motor Privé y quedan expresamente reservados todos los derechos de uso sobre los mismos.
							<br/><br/>
							Usted podrá hacer uso de dicho material únicamente en la forma en que Motor Privé se lo autorice expresamente o quienes nos otorgaron licencia para su uso.
							<br/><br/>
							Cualquier uso indebido del servicio o de sus obligaciones por parte del usuario podrán derivar, a discreción de Motor Privé, en la cancelación de la cuenta del usuario, o suspensión del servicio hasta que se resuelva la incidencia, en su caso.
							<br/><br/>
							Asimismo, el usuario se compromete a no retirar, suprimir, alterar, manipular ni en modo alguno modificar:
						</p>
						<ul style={{fontSize: '14px'}}>
							<li>Aquellas notas, leyendas, indicaciones o símbolos que bien Motor Privé o los legítimos titulares de los derechos incorporen a sus propiedades en materia de Propiedad Intelectual o Industrial (como por ejemplo, copyright, ©, ® y ™, etc.).</li>
							<li>Los dispositivos técnicos de protección o identificación que pueda contener la Propiedad (como por ejemplo, marcas de agua, huellas digitales, etc.).</li>
						</ul>
						<p style={{fontSize: '14px'}}>
							El usuario reconoce que en virtud de los presentes Condiciones Generales de Uso, Motor Privé no cede ni transfiere al usuario ningún derecho sobre su Propiedad, o sobre cualesquiera propiedades de terceras partes. Motor Privé sólo autoriza al usuario el acceso y uso de los mismos de conformidad con los términos indicados en los presentes términos y condiciones.
							<br/><br/>
							Los usuarios no están autorizados para copiar, distribuir (incluyendo correos electrónicos e Internet), transmitir, comunicar, modificar, alterar, transformar, ceder o, en cualquier otra forma desplegar actividades que conlleven el uso comercial de los derechos, ya sea con carácter parcial o total, sin que conste el expreso consentimiento, otorgado por escrito, del legítimo titular de los derechos de explotación.
							<br/><br/>
							Motor Privé se reserva todos los derechos que le correspondan incluyendo, a título enunciativo y no limitativo, todos los derechos de Propiedad Intelectual e Industrial que ostente sobre los mismos.
							<br/><br/>
							Motor Privé no concede ninguna otra licencia o autorización de uso al usuario sobre sus derechos distinta de la que expresamente se detalla en la presente cláusula.
							<br/><br/>
							Motor Privé se reserva el derecho de resolver o modificar en cualquier momento y por cualquier causa, las licencias otorgadas en virtud de los presentes términos y condiciones. Sin perjuicio de ello, Motor Privé podrá emprender acciones legales contra cualquier utilización por parte del usuario que:
						</p>
						<ul style={{fontSize: '14px'}}>
							<li>No sea conforme a los Condiciones Generales de Uso aquí especificados;</li>
							<li>Infrinja o vulnere los derechos de Propiedad Intelectual e Industrial u otros análogos de la Sociedad o de cualquier otro tercer legítimo titular; o infrinja cualquier normativa que sea de aplicación.</li>
						</ul>
						<h3>9. Resolución de disputas</h3>
						<p style={{fontSize: '14px'}}>
							El Usuario exime de responsabilidad a Motor Privé así como a los administradores, agentes, subsidiarios, asociados y empleados de Motor Privé ante cualquier reclamación, demanda y daño de cualquier tipo que pueda surgir como consecuencia de una disputa entre el Usuario y los concesionarios que participan en la Plataforma en relación a la información compartida o a la ejecución o a la calidad de la prestación de los servicios contratados posteriormente por fuera de la Plataforma.
							<br/><br/>
							En el supuesto de que surja una disputa entre usuarios de la Plataforma, solicitamos que se utilice de forma previa el canal de Atención al Cliente mediante el envío de un correo a la dirección de e-mail: hola@motorprive.com detallando los motivos de la disputa y aportando toda aquella documentación que pueda ser de interés. Posteriormente analizaremos los motivos y los razonamientos aportados por ambas partes y proporcionará una solución en el menor tiempo posible principalmente teniendo en cuenta que el día de ejecución de los servicios contratados no se vea perjudicado.
						</p>
						<h3>10. Baja del Usuario</h3>
						<p style={{fontSize: '14px'}}>
							El Usuario podrá darse de baja de la Plataforma comunicándolo mediante un correo electrónico a la dirección: hola@motorprive.com.
							<br/><br/>
							También podrá darse de baja a través de su perfil en el que se le proporciona la opción de “Eliminar cuenta”.
						</p>
						<h3>11. Protección de datos</h3>
						<p style={{fontSize: '14px'}}>
							El suministro de datos personales a través de nuestro portal requiere la aceptación expresa de nuestra Política de Privacidad.
						</p>
						<h3>12. Independencia de las Cláusulas</h3>
						<p style={{fontSize: '14px'}}>
							Si cualquiera de las cláusulas de las presentes Condiciones fuera nula de pleno derecho o anulable, se tendrá por no puesta. Dicha declaración de nulidad no invalidará el resto de las Condiciones, que mantendrá su vigencia y eficacia entre las partes.
							<br/><br/>
							Si cualquier cláusula de las presentes Condiciones fuese declarada, total o parcialmente, nula o ineficaz, tal nulidad o ineficacia afectará tan solo a dicha disposición o a la parte de la misma que resulte nula o ineficaz, y las Condiciones subsistirán en todo lo demás, teniéndose tal disposición o la parte de la misma que resulte afectada por no puesta.
						</p>
						<h3>13. Legislación Aplicable</h3>
						<p style={{fontSize: '14px'}}>
							Las presentes Condiciones se regirán por la legislación española, que será de aplicación en lo no dispuesto en estas Condiciones en materia de interpretación, validez y ejecución. Asimismo ambas partes se someten, con renuncia expresa a cualquier otro fuero, a los Juzgados y Tribunales de Barcelona.
							<br/>
							La Comisión europea recientemente ha puesto a disposición de los consumidores de la Unió n Europea la plataforma de resolución de litigios en línea para resolver los litigios que se produzcan en el comercio electrónico de forma amistosa (artículo 14 apartado 1 Reglamento (UE) 524/2013) por lo cual los Usuarios de la Plataforma tienen derecho presentar reclamaciones en el siguiente enlace:
							<br/><br/>
							<a href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=ES">https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=ES</a>
							<br/><br/>
							Mediagach , SL
							<br/>
							B67287870
							<br/>
							Domicilio fiscal: C/ Francesc Layret 119, 5º 1ª, 08911. Badalona, Barcelona. España
							<br/>
							<em>hola@motorprive.com</em>
						</p>
					</Grid>
				</Grid>
			</div>
		);
	}


}

const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		userName: state.auth.userName
	};
};

ConditionsView = connect(
	mapStateToProps, { setCurrentPage }	
)(ConditionsView);

export default withNamespaces()(ConditionsView);



