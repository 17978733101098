import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { signIn, signOut } from '../actions/';

import queryString from 'query-string';

//import firebase, { authFirebase } from '../components/Firebase/Firebase.js';

import ChangePwdFirebaseAuth from '../components/Login/ChangePwdFirebaseAuth.jsx';


class ChangePwdFirebaseView extends React.Component {

	constructor(props) {
		super(props);

		//mirem si rebem paràmetres
		if(this.props.location.search == ''){
			//console.log('no en tenim');
			this.state = {	      
		      currentImage: 0,
		      currentAccio: 'none'	           
		    };	

		}else{

			console.log('tenim parametres');


			const parametres = queryString.parse(this.props.location.search);				
				
			let accio;
			let oobCode;

			if(parametres.mode){
				accio = parametres.mode;
			}else{
				accio = '';
			}

			if(parametres.oobCode){
				oobCode = parametres.oobCode;
			}else{
				oobCode = '';
			}

			
			this.state = {	      
		      currentImage: 0,
		      currentAccio: accio,
		      currentOobCode: oobCode	           
		    };	

		    console.log(this.state);
		}


	       //?mode=resetPassword&oobCode=s8HfN0Zy0y0w_ZrE2y6-UUWqPsxzS6JVbUhMxjP95owAAAFvrs8ARg

    
	}

	componentDidMount() {

		/*authFirebase.onAuthStateChanged((user) => {
		  	if (user) {
		    	// User is signed in.
		    	console.log("usuari logat");
		    	console.log(user);
		    	this.props.signIn(user.uid, user.email, user.displayName);
		  	} else {
		   		// No user is signed in.
		   		console.log("usuari no logat");
		  	}
		});*/

	}

	renderContent(){
		if(this.props.isSignedIn === null){
			return ( <p>Loading...</p>);
		}else{

			switch(this.state.currentAccio){
				case 'resetPassword':
					return ( <ChangePwdFirebaseAuth codi={this.state.currentOobCode} />);
				break;
				case 'verifyEmail':
					return ( <div className="container-parent-personal-data change-fire-box no-action">{i18n.t('global.operationNotFound')}<br/><Link to="/" className="forget-link">{i18n.t('global.back')}</Link></div>);
				break;
				default:
					return ( <div className="container-parent-personal-data change-fire-box no-action">{i18n.t('global.operationNotFound')}<br/><Link to="/" className="forget-link">{i18n.t('global.back')}</Link></div>);
			}
			

			
		}
				
		
		
	}

	render(){
		return(
			<div className="template-forget-pwd-page template-change-pwd-page-fire">
				<BackgroundContainer currentImage={this.state.currentImage} />
				<Grid container justify="center">
					<Grid item xs={12} sm={10} md={5} lg={4}>
			          	<div className="container-block">	
							{this.renderContent()}
						</div>
			        </Grid>
				</Grid>
			</div>
		);
	}

}



const BackgroundContainer = ({ currentImage }) => {

	
	/*const images = [
			"https://cdn.mediagach.com/motor-privee/banners/banner-gb-sea.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-03.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-04.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-05.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-06.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-07.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-08.jpg"
	];*/

	const images = [
			"https://media.motorprive.com/banners/banner-bg-iceland.jpg",
			"https://media.motorprive.com/banners/banner-bg-driver.jpg",
			"https://media.motorprive.com/banners/banner-bg-beach-curve.jpg"				
	];


	return(
		<div className="container-images-bg">
			{/*<div className="container-background" style={{ backgroundImage: `url(${images[currentImage]})` }}></div>*/}
			<div className="container-background" style={{ backgroundImage: `url(${images[0]})` }}></div>
			<div className="container-background" style={{ backgroundImage: `url(${images[1]})` }}></div>
			<div className="container-background" style={{ backgroundImage: `url(${images[2]})` }}></div>
			
			{/*<div className="container-background-down" style={{ backgroundImage: `url(${images[futurImage]})` }}></div>*/}
		</div>
		
	);

};



const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn, 
		userId: state.auth.userId, 
		userMail: state.auth.userMail , 
		userName: state.auth.userName 
	};
};

ChangePwdFirebaseView = connect(
	mapStateToProps,
	{ signIn, signOut }
)(ChangePwdFirebaseView);

ChangePwdFirebaseView = withNamespaces()(ChangePwdFirebaseView);

export default ChangePwdFirebaseView;

