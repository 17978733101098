import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import { setCurrentPage } from '../actions/';


class FaqsView extends React.Component{

	constructor(props) {

		super(props);

	    this.props.setCurrentPage('faqs');

	}

	render(){
		return(			
			<div className="template-faqs">
				<Grid container justify="center" >
					<Grid item xs={12}>							
						<div className="container-title">							
							<h1>Preguntas frecuentes - FAQS</h1>							
						</div>
					</Grid>
					<Grid item xs={11} sm={11} md={8} lg={8}>
						<ExpansionPanel>
					        <ExpansionPanelSummary
					          expandIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
					          aria-controls="panel-content"					          
					        >
					        	<p className="question-title" >{i18n.t('faqs.question_01')}</p>
					        </ExpansionPanelSummary>
					        <ExpansionPanelDetails>
					        	<p className="question-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_01')}}></p>
					        </ExpansionPanelDetails>
					    </ExpansionPanel>
					    <ExpansionPanel>
					        <ExpansionPanelSummary
					          expandIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
					          aria-controls="panel-content"					          
					        >
					        	<p className="question-title">{i18n.t('faqs.question_02')}</p>
					        </ExpansionPanelSummary>
					        <ExpansionPanelDetails>
					        	<p className="question-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_02')}}></p>
					        </ExpansionPanelDetails>
					    </ExpansionPanel>
					    <ExpansionPanel>
					        <ExpansionPanelSummary
					          expandIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
					          aria-controls="panel-content"					          
					        >
					        	<p className="question-title">{i18n.t('faqs.question_03')}</p>
					        </ExpansionPanelSummary>
					        <ExpansionPanelDetails>
					        	<p className="question-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_03')}}></p>
					        </ExpansionPanelDetails>
					    </ExpansionPanel>
					    <ExpansionPanel>
					        <ExpansionPanelSummary
					          expandIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
					          aria-controls="panel-content"					          
					        >
					        	<p className="question-title">{i18n.t('faqs.question_04')}</p>
					        </ExpansionPanelSummary>
					        <ExpansionPanelDetails>
					        	<p className="question-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_04')}}></p>
					        </ExpansionPanelDetails>
					    </ExpansionPanel>
					    <ExpansionPanel>
					        <ExpansionPanelSummary
					          expandIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
					          aria-controls="panel-content"					          
					        >
					        	<p className="question-title">{i18n.t('faqs.question_05')}</p>
					        </ExpansionPanelSummary>
					        <ExpansionPanelDetails>
					        	<p className="question-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_05')}}></p>
					        </ExpansionPanelDetails>
					    </ExpansionPanel>
					    <ExpansionPanel>
					        <ExpansionPanelSummary
					          expandIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
					          aria-controls="panel-content"					          
					        >
					        	<p className="question-title">{i18n.t('faqs.question_06')}</p>
					        </ExpansionPanelSummary>
					        <ExpansionPanelDetails>
					        	<p className="question-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_06')}}></p>
					        </ExpansionPanelDetails>
					    </ExpansionPanel>
					    <ExpansionPanel>
					        <ExpansionPanelSummary
					          expandIcon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
					          aria-controls="panel-content"					          
					        >
					        	<p className="question-title">{i18n.t('faqs.question_07')}</p>
					        </ExpansionPanelSummary>
					        <ExpansionPanelDetails>
					        	<p className="question-answer" dangerouslySetInnerHTML={{__html: i18n.t('faqs.answer_07')}}></p>
					        </ExpansionPanelDetails>
					    </ExpansionPanel>
					</Grid>
				</Grid>
			</div>
		);
	}


}

const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		userName: state.auth.userName
	};
};

FaqsView = connect(
	mapStateToProps, { setCurrentPage }	
)(FaqsView);



export default withNamespaces()(FaqsView);
