import React from 'react';
import { connect } from 'react-redux';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';



import { signOut, errorLogin, getUserProvider } from '../../actions/';
import ChangePwdForm from './ChangePwdForm.jsx';

//import { serverValidation } from './LoginAuth.jsx';

import history from '../../history';
import { authFirebase } from '../Firebase/Firebase.js';

import { withStyles } from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const stylesAlert = theme => ({
  	root: {
        background: '#009688'
    }
});


class ChangePwdAuth extends React.Component{

	constructor(props){

		super(props);
		this.state = {	        
	        operacioOk: '',
	        openAlert: false,
	        provider: ''    		
	    };   
	}

	componentDidMount() {
		this.getProviderUser();

	}

	getProviderUser(){
		
		
		this.props.getUserProvider(this.props.userId)
  		.then((result) => {
  			console.log('rebemmm');
  			console.log(result);
  			
  			this.setState({
	        	provider: result.provider
	        });

  		});
	}

	changePassword = (password) => authFirebase.currentUser.updatePassword(password);

	onSubmit = (formValues) => {

		const {passwordOne } = formValues;
				
		this.changePassword(passwordOne).then(() => {

			authFirebase.signOut();		
			history.push('/?chpw=1');

		}).catch((error) => {
  			// An error happened.
  			//console.log(error);
  			this.openAlert(i18n.t('errors.'+error.code));
  			//this.props.errorLogin(serverValidation(error.code));
		});
		
	}

	openAlert = (textMissatge) => {
    	this.setState({ openAlert: true, operacioOk: textMissatge });  
	};

	closeAlert = () => {
		this.setState({ openAlert: false });
	};


	renderChangePwd(){

		const { openAlert } = this.state;
		const { classes } = this.props;
		const valorProvider = "motor-privee";
		console.log(this.state.provider);
		if(valorProvider.localeCompare(this.state.provider) === 0){
			return(
				<div>
					<ChangePwdForm  onSubmit={this.onSubmit} />
					<Snackbar
				          anchorOrigin={{  vertical: 'bottom', horizontal: 'right' }}
				          open={openAlert}
				          onClose={this.closeAlert}			         
				          ContentProps={{			            
				            classes: {
	                        	root: classes.root
	                    	}
				          }}
				          autoHideDuration={2500}
				          message={<span className="snackbar-text-msg"><FontAwesomeIcon icon={['fal', 'check-circle']} />{this.state.operacioOk}</span>}			          
				    />
				</div>

			);
		}else{
			return(<div></div>);
		}

	}

	render(){
		return(

			<div>		
				{this.renderChangePwd()}
			</div>
		);
	}

}

const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn, 
		userId: state.auth.userId, 
		userMail: state.auth.userMail , 
		userName: state.auth.userName,
		errorLog: state.auth.errorLog 
	};
};



ChangePwdAuth = connect(
	mapStateToProps, 
	{ signOut, errorLogin, getUserProvider }
)(ChangePwdAuth);

ChangePwdAuth = withStyles(stylesAlert)(ChangePwdAuth);

ChangePwdAuth = withNamespaces()(ChangePwdAuth);

export default ChangePwdAuth;


