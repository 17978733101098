

export const getEnvironment = () => process.env.NODE_ENV
  ? process.env.NODE_ENV
  : 'development';

export const getApiUrl = () => {
    switch (getEnvironment()) {
      case 'production':
        return 'https://admin.mediagach.com/php/servlet/motor-privee/';
      case 'development':
      default:
        return 'http://127.0.0.1:9001/php/servlet/motor-privee/';
    }
};

export const urlBase='http://127.0.0.1:9001/php/servlet/motor-privee/';
