import React from 'react';
import { connect } from 'react-redux';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';



import { signOut, errorLogin, getUserProvider } from '../../actions/';
import ChangePwdFirebaseForm from './ChangePwdFirebaseForm.jsx';

//import { serverValidation } from './LoginAuth.jsx';

import history from '../../history';
import { authFirebase } from '../Firebase/Firebase.js';

import { withStyles } from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Dialog from '@material-ui/core/Dialog';

import { DialogTitle, DialogContent, DialogActions } from '../../assets/styles/components/AlertNotificationStyle.jsx';


const stylesAlert = theme => ({
  	root: {
        background: '#009688'
    },
    dialogPaper: {       
        [theme.breakpoints.down('xs')]: {
        margin: '20px',
      }        
    }
});





class ChangePwdFirebaseAuth extends React.Component{

	constructor(props){

		super(props);

		this.state = {	        
	        operacioOk: '',
	        openAlert: false,
	        openDialog: false,
	        provider: '',
	        oobCode: this.props.codi    		
	    };  

	}

	componentDidMount() {
		//this.getProviderUser();

	}

	/*
	getProviderUser(){
			
		this.props.getUserProvider(this.props.userId)
  		.then((result) => {
  			
  			this.setState({
	        	provider: result.provider
	        });

  		});
	}
	*/

	//changePassword = (password) => authFirebase.currentUser.updatePassword(password);

	onSubmit = (formValues) => {

		const {passwordOne } = formValues;
		
		
		
		authFirebase.confirmPasswordReset(this.state.oobCode, passwordOne).then(() => {
			//console.log('entro aki');
			this.handleClickOpen();
		}).catch((error) => {
			//console.log('entro a error'+error);
			this.openAlert(i18n.t('errors.'+error.code));
			
		});

		/*console.log('fem submit');
		console.log(this.state.oobCode);
		*/
				
	}

	openAlert = (textMissatge) => {
    	this.setState({ openAlert: true, operacioOk: textMissatge });  
	};

	closeAlert = () => {
		this.setState({ openAlert: false });
	};

	handleClickOpen = () => {  
    	this.setState({ openDialog: true });
  	};

  	handleClose = () => {
    	this.setState({ openDialog: false });
    	history.push('/');
  	};


	renderChangePwd(){

		const { openAlert, openDialog } = this.state;
		const { classes } = this.props;
		
		
		return(
			<div>
				<ChangePwdFirebaseForm  onSubmit={this.onSubmit} />
				<Snackbar
			          anchorOrigin={{  vertical: 'bottom', horizontal: 'right' }}
			          open={openAlert}
			          onClose={this.closeAlert}			         
			          ContentProps={{			            
			            classes: {
                        	root: classes.root
                    	}
			          }}
			          autoHideDuration={2500}
			          message={<span className="snackbar-text-msg"><FontAwesomeIcon icon={['fal', 'check-circle']} />{this.state.operacioOk}</span>}			          
			    />

			    <Dialog
                  open={openDialog}
                  onClose={this.handleClose}
                  maxWidth='sm'
                  classes={{ paper: classes.dialogPaper }}
                  fullWidth={true}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title" onClose={this.handleClose}>&nbsp;</DialogTitle>
                  
                    <DialogContent classes={{root: classes.inicioForm}}>
                      <p className="alert-text" dangerouslySetInnerHTML={{__html: i18n.t('global.pwdChangeCorrect')}}></p>        
                    </DialogContent>
                    <DialogActions classes={{root: classes.inicioBtn}}>
                        <button  className="btn1 btn-lead" onClick={this.handleClose}>
                         {i18n.t('global.accept')}                 
                        </button>                 
                    </DialogActions>
                  
                </Dialog>
			</div>

		);
		

	}

	render(){
		return(

			<div>		
				{this.renderChangePwd()}
			</div>
		);
	}

}

const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn, 
		userId: state.auth.userId, 
		userMail: state.auth.userMail , 
		userName: state.auth.userName,
		errorLog: state.auth.errorLog 
	};
};



ChangePwdFirebaseAuth = connect(
	mapStateToProps, 
	{ signOut, errorLogin, getUserProvider }
)(ChangePwdFirebaseAuth);

ChangePwdFirebaseAuth = withStyles(stylesAlert)(ChangePwdFirebaseAuth);

ChangePwdFirebaseAuth = withNamespaces()(ChangePwdFirebaseAuth);

export default ChangePwdFirebaseAuth;


