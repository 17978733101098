import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import history from '../history';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';


import { sendLeadUser, getUserData } from '../actions/';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CustomLink from '../components/CustomLink.jsx';
import CountDown from '../components/CountDown.jsx';

import { DialogTitle, DialogContent, DialogActions } from '../assets/styles/components/DialogFormStyle.jsx';


class FreeLandingView extends React.Component{
	
	constructor(props) {
	    super(props);

	    this.handleChangeInput = this.handleChangeInput.bind(this);

	    this.state = {	      
	      	currentEvent: props.currentEvent,
	      	openMobileBanner: '',
	      	openDialog: false,
	      	openForm: false,
	      	openAlert: false, 
	      	tlf: '',
	      	nombre: '',
	      	email: '',	
	      	locale: props.localeLan      	  
	    };	    	    
	    

	}

	componentDidMount() {			
		this.loadInfoUser();		
	}

	componentDidUpdate(prevProps){
		
		if(prevProps.currentEvent.poblaciones !== this.props.currentEvent.poblaciones){
			this.setState({currentEvent: this.props.currentEvent});
		}
			
	}

	loadInfoUser(){
		
		//if((this.props.isSignedIn === true)&&(this.props.isAnonymous === false)){
		if(this.props.isSignedIn === true){
			//fetch("http://127.0.0.1:9000/php/servlet/motor-privee/getUserData.php?uid="+this.props.userId)
			//.then(res => res.json())
			this.props.getUserData(this.props.userId)
		    .then(
		        (result) => {	   
		        	//console.log(result);
	        		this.setState({
		        		/*
		        		apellidos: result.apellidos,
		        		
		        		zip: result.codigoPostal,
		        		gender: result.gender,
		        		idioma: result.idioma,
		        		poblacion: result.poblacion,*/
		        		nombre: this.props.userName,
		        		email: this.props.userMail,
		        		tlf: result.tlf
		        	});  

		        	const initData = {		        		
		        		nombre: this.props.userName,
		        		email: this.props.userMail,
		        		tlf: result.tlf
		        	}

		        	this.props.initialize(initData);	
		        	
		    });
		}		
	}
	
	handleClickOpenDialog = () => {
    	this.setState({ openDialog: true });
  	};

	handleClickCloseDialog = () => {
		this.setState({ openDialog: false });
	};

	handleClickOpenForm = () => {
    	this.setState({ openForm: true });
  	};

	handleClickCloseForm = () => {
		this.setState({ openForm: false });
	};
	
	handleCloseBanner = () => {
    	this.setState({ openMobileBanner: 'mobile-wrapper-container-hidden' });
  	};

  	openAlert = (textMissatge) => {
    	this.setState({ openAlert: true, operacioOk: textMissatge });  
	};

	closeAlert = () => {
		this.setState({ openAlert: false });
	};

	handleButtonInterested = () => {
		if(this.props.isSignedIn){
			//console.log('estem logats');
			//console.log(this.state.currentEvent);
			if(this.state.currentEvent.url === ''){
				//console.log('no tenim url obrim popup amb form');
				this.setState({openForm: true});
			}else{
				//console.log('tenim url redireccionem');				
				window.open(this.state.currentEvent.url, "_blank");
			}
		}else{
			//console.log('no estem logats');
			//console.log(this.state.currentEvent);
			this.handleClickOpenDialog();
			
		}
	};

	renderInput = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainer">
				<label className="input-label">{label}</label>				
				<input {...input} autoComplete="off" value={initialValue} placeholder={label} type={type} disabled={disabled} />
				{this.renderError(meta)}
			</div>
		);
	};

	renderError({error, touched}){		
		if(touched && error){
			return(
				<div>	
					<div className="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{error}</div>
				</div>
			);
		}
	}

	handleChangeInput = (e) => {	
		const inputName = e.target.name;
		const inputValue = e.target.value;				
		var copiaState = Object.assign({}, this.state);
		copiaState[inputName] = inputValue;
		this.setState(copiaState);  		
	};

	agreeSignUp = () => {

		this.handleClickCloseDialog();
		history.push(
			{ 
				pathname: "/", 
				search: "?c="+this.state.currentEvent.idEvent,
				hash: "#inscribirse" 
				
			}
		);

	};

	sendLead = () => {
				
		this.props.sendLeadUser(this.props.userId, this.state.nombre, this.state.email, this.state.tlf, this.state.currentEvent.idEvent )
			.then( (result) => {
				this.openAlert(result.message);
				this.handleClickCloseForm();
			});
			

	};
  	
	render(){

		const { classes } = this.props;		
		const totalPoblaciones = this.state.currentEvent.centros.length;

		
		let inscripciones = this.state.currentEvent.fechaCierreInscripciones.split('-');
		let inscriptionsDate = new Date();
		inscriptionsDate.setFullYear(inscripciones[2],inscripciones[1]-1,inscripciones[0]);
		const today = Date.now();

		return(
			<div className="template-event-landing">
				<Grid container justify="center" >
					<Grid item xs={12} sm={10} md={8} lg={8} >
						<div className="container-image-campaign">
							<div className="container-image">
								<div className="image-campaign" style={{ backgroundImage: `url(${this.state.currentEvent.imagen})`}}></div>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={10} md={8} lg={8} >
						<Grid container justify="flex-start">
							<Grid item xs={12} sm={12} md={7} lg={8} >		


								<CountDown dataFinal={this.state.currentEvent.fechaCierreInscripciones} />

								<div className="container-information">
									<div className="main-info-section section-element">
										<p className="title-event">{this.state.currentEvent.nombre}</p>
										<p className="dates-event">{i18n.t('landing.from')} {this.state.currentEvent.fechaInicio} {i18n.t('landing.to')} {this.state.currentEvent.fechaFinal}</p>
										<h3>{this.state.currentEvent.slogan}</h3>
										<p className="description-event">{this.state.currentEvent.descripcion}</p>
									</div>							
									<hr/>
									<div className="brands-section section-element">
										<p className="title-section">{i18n.t('landing.brands')}</p>
										<p className="brands-event">
											{	
							        			this.state.currentEvent.marcas.map( (item, index) => (
							        				<img key={index} src={item.logo} alt={item.nombre} />			        					
						        				))
					        				}
										</p>
									</div>							
									<hr/>
									<div className="btn-section section-element">
										{/*inscriptionsDate >= today ? <button className="btn btn1" onClick={this.handleButtonInterested} >
											{this.props.isSignedIn === true ? '¡'+i18n.t('global.register')+'!' : '¡'+i18n.t('global.interested')+'!'}
										</button> : <button className="btn btn1" onClick={this.handleButtonInterested} >
											{this.props.isSignedIn === true ? "¡Avisa'm quan hi hagi una altra venta d'aquestes característiques!" : '¡'+i18n.t('global.interested')+'!'}
										</button>*/}

										<button className="btn btn1" onClick={this.handleButtonInterested} >
											{this.props.isSignedIn === true ? '¡'+i18n.t('global.register')+'!' : '¡'+i18n.t('global.interested')+'!'}
										</button>		
																	
									</div>							
									<hr/>
									<div className="ubication-section section-element">
										<p className="title-section">{i18n.t('landing.location')}</p>
										<p className="ubication-event">					
											<FontAwesomeIcon icon={['fal', 'map-marker-alt']} />	
											{		
							        			
						        				this.state.currentEvent.centros.map( (item, index) => (	
						        					<span key={index}>{item.poblaciones} { index == totalPoblaciones-1 ? '' : ' - '  }</span>
						        				))
						        			}		        			
										</p>
										{									        			
						        				this.state.currentEvent.centros.map( (item, index) => (	
						        					<p key={index} className="conce-time-table" dangerouslySetInnerHTML={{__html: item.address}}></p>

						        				))
						        		}
										{/*<p className="conce-time-table" dangerouslySetInnerHTML={{__html: this.state.currentEvent.direccion}}></p>*/}
										<p className="conce-phone"><FontAwesomeIcon icon={['fas', 'mobile-alt']} />{this.state.currentEvent.telefono}</p>
									</div>
									<hr/>
									<div className="btn-section section-element">
										{/*<CustomLink to={this.state.currentEvent.url} idEvent={this.state.currentEvent.idEvent} nomClasse="btn btn1" key={this.state.currentEvent.idEvent}>
											{this.props.isSignedIn === true ? '¡'+i18n.t('global.register')+'!' : '¡'+i18n.t('global.interested')+'!'}
										</CustomLink>
										*/}


										{/*inscriptionsDate >= today ? <button className="btn btn1" onClick={this.handleButtonInterested} >
											{this.props.isSignedIn === true ? '¡'+i18n.t('global.register')+'!' : '¡'+i18n.t('global.interested')+'!'}
										</button> : <button className="btn btn1" onClick={this.handleButtonInterested} >
											{this.props.isSignedIn === true ? "¡Avisa'm quan hi hagi una altra venta d'aquestes característiques!" : '¡'+i18n.t('global.interested')+'!'}
										</button>
										*/}


										<button className="btn btn1" onClick={this.handleButtonInterested} >
											{this.props.isSignedIn === true ? '¡'+i18n.t('global.register')+'!' : '¡'+i18n.t('global.interested')+'!'}
										</button>
										
									</div>									
								</div>
							</Grid>
							<Hidden smDown>
								<Grid item xs={1} sm={2} md={5} lg={4} >
									<div className="container-banner-lateral">
										<img src="https://www.motorprivee.com/media/banner-lateral.jpg" />
									</div>								
								</Grid>
							</Hidden>
						</Grid>
					</Grid>
				</Grid>
				

				<Hidden mdUp>
					<div className={`mobile-wrapper-container ${this.state.openMobileBanner}`}>
						<Grid container justify="center">
							<Grid item xs={12}>
								<div className="mobile-banner-container">
									<div onClick={this.handleCloseBanner}><FontAwesomeIcon icon={['fal', 'times']} /></div>
									<img className="banner-mobile" src="https://www.motorprivee.com/media/banner-superior.jpg" />
								</div>
							</Grid>
						</Grid>
					</div>
				</Hidden>
				<Dialog
		          open={this.state.openDialog}
		          TransitionComponent={Transition}
		          keepMounted
		          onClose={this.handleClickCloseDialog}
		          aria-labelledby="alert-dialog-slide-title"
		          aria-describedby="alert-dialog-slide-description"
		        >
		          	<DialogTitle id="alert-dialog-slide-title">
		            	{i18n.t('landing.inscriptionQuestion')}
		          	</DialogTitle>
		          	<DialogContent>
		            	<DialogContentText id="alert-dialog-slide-description">
		            		<p className="conce-time-table" dangerouslySetInnerHTML={{__html: i18n.t('landing.inscriptionExplanation')}}></p>		            				              	
			            </DialogContentText>
		          	</DialogContent>
		          	<DialogActions>
			            <button className="btn btn1" onClick={this.agreeSignUp} >
			            	{i18n.t('landing.wantInscription')}
			            </button>
			            <button className="btn btn1" onClick={this.handleClickCloseDialog} >
			            	{i18n.t('landing.noThanks')}
			            </button>
		          	</DialogActions>
		        </Dialog>

		        <Dialog
			        open={this.state.openForm}
			        onClose={this.handleClickCloseForm}			      
			        maxWidth='sm'
			        classes={{ paper: classes.dialogPaper }}
			        fullWidth={true}
			        aria-labelledby="form-dialog-title"			        
		        >
		        	<DialogTitle id="form-dialog-title" onClose={this.handleClickCloseForm}>Solicitar información</DialogTitle>
		        	<form className="form-more-info" onSubmit={ this.props.handleSubmit(this.sendLead) } >
			        	<DialogContent>
			           		<Field name="nombre" onChange={this.handleChangeInput} initialValue={this.state.nombre} component={this.renderInput} label={i18n.t('editAccount.name')} />
							<Field name="email" onChange={this.handleChangeInput} initialValue={this.state.email}  component={this.renderInput} label={i18n.t('editAccount.email')}  />			
							<Field name="tlf" initialValue={this.state.tlf} onChange={this.handleChangeInput} component={this.renderInput} label={i18n.t('editAccount.tlf')}  />		
				        </DialogContent>
				        <DialogActions>
				            <button  className="btn1 btn-lead">
				            	{i18n.t('global.askInformation')}
				            </button>			            
				        </DialogActions>
			        </form>
		        </Dialog>

		        <Snackbar
			          anchorOrigin={{  vertical: 'bottom', horizontal: 'right' }}
			          open={this.state.openAlert}
			          onClose={this.closeAlert}			         
			          ContentProps={{			            
			            classes: {
                        	root: classes.root
                    	}
			          }}
			          autoHideDuration={1500}
			          message={ i18n.t(this.state.operacioOk) }			          
			    />

			</div>
			
		);
	}
}

function Transition(props) {
  return <Slide direction="up" {...props} />;
}


const validate = (formValues) => {

	const errors = {};
	const requiredFields = [	    
	    'nombre',
	    'email',	    
	    'tlf'	    
  	];
 	
  	requiredFields.forEach(field => {
  		if (!formValues[field]) {
	      errors[field] = i18n.t('errorForms.required');
	    }
  	})

  	if ( formValues.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email) ) {
    	errors.email = i18n.t('errorForms.invalidemail');
  	}


	return errors;

};


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		selectedLanguage: state.auth.selectedLanguage,
		userId: state.auth.userId, 
		userMail: state.auth.userMail, 
		userName: state.auth.userName
	};
};

const stylesDialogForm  = theme => ({
    dialogPaper: {       
        [theme.breakpoints.down('xs')]: {
     		margin: '20px',
    	}        
    },
});


FreeLandingView = withStyles(stylesDialogForm)(FreeLandingView);

FreeLandingView = reduxForm({
  form: 'LoggedInterestedInCampaign',
  enableReinitialize: true,
  validate: validate   
})(FreeLandingView);

FreeLandingView = connect(
	mapStateToProps,
	{ sendLeadUser, getUserData }
)(withNamespaces()(FreeLandingView));

export default FreeLandingView;


