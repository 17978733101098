import React from "react";
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';

import { MuiThemeProvider } from '@material-ui/core/styles';
import history from '../history';
import indexRoutes from "../routes/index.jsx";

import { I18nextProvider } from 'react-i18next';

import queryString from 'query-string';

//import Firebase, { auth, provider } from './Firebase/Firebase.js';

import i18n from '../config/i18n';


import Navigation from './Header/Navigation.jsx';
import Footer from './Footer/Footer.jsx';
import CookieMessage from './CookieMessage.jsx';

/*
import ReactGA from 'react-ga';

*/

import TagManager from 'react-gtm-module';

/* START ESTILS APP*/

import { theme } from '../assets/styles/theme.jsx';

//import global from '../assets/styles/global.css';

/* END ESTILS APP*/



/*
 START TOTS ELS ICONOS QUE NECESSITEM
*/

import { library } from '@fortawesome/fontawesome-svg-core';

import { 	faIgloo, 
			faBars,
			faUserCircle,
			faStar,
			faMobileAlt,
			faGhost,
			faCheckCircle as faCheckCircleSolid/*,
			faUserAlt
			*/
			
} from '@fortawesome/pro-solid-svg-icons';

import { 	faBars as faBarsLight, 
			faGlobe, 
			faExclamationCircle, 
			faCarSide, 
			faUserAlt,
			/*faUserCircle,*/
			faSignOut, 
			faUserEdit,
			faChevronDown as faChevronDownLight,
			faChevronUp as faChevronUpLight,
			faTimes,
			faMapMarkerAlt,
			faCarCrash,
			faCommentAltSmile,
			faHeart,
			faCalendarAlt,
			faShareAltSquare,
			faEnvelope,
			faPaste,
			faCheckCircle,
			faHandPointRight,
			faCars,
			faHandHoldingUsd,
			faPlusSquare,
			faCircle,
			faChevronRight,
			faThumbsUp

} from '@fortawesome/pro-light-svg-icons';

import { 
	faChevronLeft, 
	faSteeringWheel,
	faPlus,
	faList
} from '@fortawesome/pro-regular-svg-icons';

import { 	faFacebookF, 
			faGoogle, 
			faInstagram, 
			faTwitter,
			faLinkedinIn,
			faWhatsapp 
} from '@fortawesome/free-brands-svg-icons';


import { authFirebase } from './Firebase/Firebase.js';

import { signIn, signOut, infoLogged, infoAnonymous } from '../actions/';

library.add(
	faIgloo, 
	faBars, 
	faBarsLight, 
	faChevronLeft,
	faGlobe,
	faFacebookF,
	faGoogle,
	faExclamationCircle,
	faInstagram,
	faTwitter,
	faLinkedinIn,
	faCarSide,
	faUserAlt,
	faUserCircle,
	faSignOut,
	faUserEdit,
	faChevronDownLight,
	faChevronUpLight,
	faTimes,
	faMapMarkerAlt,
	faCarCrash,
	faSteeringWheel,
	faStar,
	faMobileAlt,
	faGhost,
	faCommentAltSmile,
	faHeart,
	faCalendarAlt,
	faShareAltSquare,
	faEnvelope,
	faWhatsapp,
	faPaste,
	faPlus,
	faCheckCircle,
	faHandPointRight,
	faCars,
	faHandHoldingUsd,
	faPlusSquare,
	faCircle,
	faCheckCircleSolid,
	faChevronRight,
	faList,
	faThumbsUp
);

/*
 ENDS TOTS ELS ICONOS QUE NECESSITEM
*/



//ReactGA.initialize('GTM-5Q2Z7NJ');	

/*
ReactGA.initialize('UA-136137560-1');	 
	    
history.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
});
*/

/*
const tagManagerArgs = {
    gtmId: 'GTM-5Q2Z7NJ',
    dataLayerName: 'PageDataLayer',
    events: {
        sendUserInfo: 'userInfo'
    }
};

TagManager.initialize(tagManagerArgs);
*/

class App extends React.Component{

	constructor(props) {
	    super(props);

	    /*
	    this.state = {
	      reactGaInitialised: false
	      
	    };
	    */

	 }


	componentDidMount() {
		
		authFirebase.onAuthStateChanged((user) => {
		  	if (user) {

		    	//console.log("usuari logat");
		    	//console.log(user);	
		    	//if(!user.isAnonymous){
		    		this.props.infoLogged(user.uid,user.email);		    		
		    	//}else{
		    		//this.props.infoAnonymous(user.id);
		    	//}
		    	
		    		   
		  	} else {
		  		
		   		//console.log("usuari no logat");
		   		this.props.signOut();
		   		/*
		   		authFirebase.signInAnonymously().catch(function(error) {
					var errorCode = error.code;
					var errorMessage = error.message;
					console.log(errorMessage);
				});
				*/
		  	}
		});

	}

	componentDidUpdate(prevProps) {
		//console.log('hi ha actualització des de app');
		if(this.props.selectedLanguage !== null){
			i18n.changeLanguage(this.props.selectedLanguage);
		}		
	}

	

	render(){
		
		return(
			<div>
				<I18nextProvider i18n={ i18n }>	
					<Router history={history}>
						<div>
							<MuiThemeProvider theme={theme}>
								<Navigation />
								{/*<Buttons />*/}	
							    <Switch>
							     	{indexRoutes.map((prop, key) => {
							        	return <Route path={prop.path} key={key} exact component={prop.component} />;
							      	})}
							    </Switch>
							    <Footer />
							</MuiThemeProvider>
						</div>
					</Router>
					<CookieMessage />
				</I18nextProvider>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn, 
		status: state.auth.status,
		userId: state.auth.userId, 
		userMail: state.auth.userMail, 
		userName: state.auth.userName,
		selectedLanguage: state.auth.selectedLanguage
	};
};

export default connect(
	mapStateToProps,
	{ signIn, signOut, infoLogged, infoAnonymous }
)(App);



