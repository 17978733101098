import React from 'react';
import { connect } from 'react-redux';

import { fetchFilters, modifyFilterBrands, modifyFilterRegion, modifyFilterPrice } from '../../actions';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
//import Select from '@material-ui/core/Select';
//import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import NativeSelect from '@material-ui/core/NativeSelect';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import Slider from 'rc-slider';
//import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';

import stylesFilter from '../../assets/styles/components/FiltersListStyle.jsx';


const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
//const Handle = Slider.Handle;




function Transition(props) {
	return <Slide direction="up" {...props} />;
}


class EventsListFilters extends React.Component{

	constructor(props) {

	    super(props);
	    this.state = {
	    	brands: [],
	    	regions: [],
	    	style: '',
	    	openFilterWindow: false
	    	
	    };

	    this.props.fetchFilters(this.props.selectedLanguage)
		.then( (result) => {
			this.setState({ 
				brands: result.marcas,
				regions: result.comunidades
			});
		});

	}


	componentDidMount(){
		window.addEventListener('scroll', this.handleScroll); 
	}

	componentWillUnmount(){
		window.removeEventListener('scroll', this.handleScroll);
	}


	handleScroll = event => {
		if(window.scrollY > 170){
			this.setState({ style: 'fixed-filters' });
		}else{
			this.setState({ style: '' });
		}
	};

	handleChangeBrand = event => {	      
	    this.props.modifyFilterBrands(event.target.value);
	};

	handleChangeRegion = event => {	      
	    this.props.modifyFilterRegion(event.target.value);
	};

	
	handleChangePrice = event => {	
		this.props.modifyFilterPrice(event);
	};

	handleOpenFiltersWindow = event => {
		this.setState({ openFilterWindow: true });
	};

	handleCloseFiltersWindow = event => {
		this.setState({ openFilterWindow: false });
	};

	render(){
		const { brands, regions  } = this.state;
		const { classes } = this.props;
		return(
			<div className={`filters-container ${this.state.style}`} >
				<div className="filter-sub-container">
					<Hidden xsDown>
						<Grid container justify="center">
							<Grid item xs={12} sm={4} md={4} lg={4}>
								<FormControl className={classes.formControl}>
							        {/*<InputLabel htmlFor="marcaInput" className={classes.inputSelectLabel} >{i18n.t('filters.brand')}</InputLabel>
							        <Select
							        	native
							            value={this.props.brandSelected}	
							            onChange={this.handleChangeBrand}		            
							            inputProps={{
							              name: 'brandSelected',
							              id: 'marcaInput',
							            }}
							        >
							        	<option value="" disabled>{i18n.t('filters.allFemale')}</option>
							        	{brands.map(item => (
								        	<option key={item.idBrand} value={item.idBrand}>{item.name}</option>
								  			
						          		))}							        	    
							        </Select>
							    */}
							    <InputLabel shrink htmlFor="marcaInput" className={classes.inputSelectLabel}>{i18n.t('filters.brand')}</InputLabel>
							    <NativeSelect 	value={this.props.brandSelected} 
							    				onChange={this.handleChangeBrand}
							          			input={<Input name="brandSelected" id="marcaInput" />}
							        		>
							        	<option value="">{i18n.t('filters.allFemale')}</option>
							        	{brands.map(item => (
								        	<option key={item.idBrand} value={item.idBrand}>{item.name}</option>
								  			
						          		))}		
							    </NativeSelect>


						        </FormControl>
							</Grid>
							<Grid item xs={12} sm={4} md={4} lg={4}>
								<FormControl className={classes.formControl}>
							        {/*<InputLabel htmlFor="regionInput" className={classes.inputSelectLabel} >{i18n.t('filters.region')}</InputLabel>
							        <Select
							        	native
							            value={this.props.regionSelected}	
							            onChange={this.handleChangeRegion}		            
							            inputProps={{
							              name: 'regionSelected',
							              id: 'regionInput',
							            }}
							        >
								        <option value=""></option>
								        {regions.map(item => (
								        	<option key={item.idComunidad} value={item.idComunidad}>{item.nombre}</option>
								  			
						          		))}				        
							        </Select>
							    	*/}
							    	<InputLabel shrink htmlFor="regionInput" className={classes.inputSelectLabel}>{i18n.t('filters.region')}</InputLabel>
								    <NativeSelect 	value={this.props.regionSelected} 
								    				onChange={this.handleChangeRegion}
								          			input={<Input name="regionSelected" id="regionInput" />}
								        		>
								        	<option value="">{i18n.t('filters.allMale')}</option>
								        	{regions.map(item => (
								        		<option key={item.idComunidad} value={item.idComunidad}>{item.nombre}</option>
						          			))}		
								    </NativeSelect>

						        </FormControl>
							</Grid>
							<Grid item xs={12} sm={4} md={4} lg={4}>
								<div className="range-input-container">
						        	<p className="range-label">{i18n.t('filters.price')}</p><p className="range-values">{this.props.priceRangeSelected[0]}€ - {this.props.priceRangeSelected[1]}€</p>
						        	<Range className="input-range" min={5000} max={90000} step={1000} defaultValue={[5000, 90000]} tipFormatter={value => `${value}`} onAfterChange={this.handleChangePrice} />
						        </div>
							</Grid>
						</Grid>
					</Hidden>
					<Hidden smUp>
						<Button className={classes.btnFilters} onClick={this.handleOpenFiltersWindow}>
				        	{i18n.t('filters.filters')}
				        </Button>
				        <Dialog
				          fullScreen
				          open={this.state.openFilterWindow}
				          onClose={this.handleCloseFiltersWindow}
				          TransitionComponent={Transition}
				          className="filters-mobile-window"
				        >
				        	<AppBar className={classes.appBar}>
				        		<Toolbar>
				        			<IconButton color="inherit" onClick={this.handleCloseFiltersWindow} aria-label="Close">
						                <FontAwesomeIcon icon={['fal', 'times']} />
						            </IconButton>
						            <p>{i18n.t('filters.filters')}</p>
				        		</Toolbar>
				        	</AppBar>
				        	<List>
            					<ListItem>
            						<FormControl className={classes.formControl}>
								        {/*<InputLabel htmlFor="marcaInput" className={classes.inputSelectLabel} >{i18n.t('filters.brand')}</InputLabel>
								        <Select
								        	native
								            value={this.props.brandSelected}	
								            onChange={this.handleChangeBrand}		            
								            inputProps={{
								              name: 'brandSelected',
								              id: 'marcaInput',
								            }}
								        >
								        	<option value=""></option>
								        	{brands.map(item => (
									        	<option key={item.idBrand} value={item.idBrand}>{item.name}</option>
									  			
							          		))}	
								        </Select>
								    	*/}
								    	<InputLabel shrink htmlFor="marcaInput" className={classes.inputSelectLabel}>{i18n.t('filters.brand')}</InputLabel>
									    <NativeSelect 	value={this.props.brandSelected} 
									    				onChange={this.handleChangeBrand}
									          			input={<Input name="brandSelected" id="marcaInput" />}
									        		>
									        	<option value="">{i18n.t('filters.allFemale')}</option>
									        	{brands.map(item => (
										        	<option key={item.idBrand} value={item.idBrand}>{item.name}</option>
										  			
								          		))}		
									    </NativeSelect>
							        </FormControl>
            					</ListItem>
            					<Divider />
            					<ListItem>
            						<FormControl className={classes.formControl}>
								        {/*<InputLabel htmlFor="regionInput" className={classes.inputSelectLabel} >{i18n.t('filters.region')}</InputLabel>
								        <Select
								        	native
								            value={this.props.regionSelected}	
								            onChange={this.handleChangeRegion}		            
								            inputProps={{
								              name: 'regionSelected',
								              id: 'regionInput',
								            }}
								        >
									        <option value=""></option>
									        {regions.map(item => (
									        	<option key={item.idComunidad} value={item.idComunidad}>{item.nombre}</option>
									  			
							          		))}				        
								        </Select>
								    	*/}
								    	<InputLabel shrink htmlFor="regionInput" className={classes.inputSelectLabel}>{i18n.t('filters.region')}</InputLabel>
									    <NativeSelect 	value={this.props.regionSelected} 
									    				onChange={this.handleChangeRegion}
									          			input={<Input name="regionSelected" id="regionInput" />}
									        		>
									        	<option value="">{i18n.t('filters.allMale')}</option>
									        	{regions.map(item => (
									        		<option key={item.idComunidad} value={item.idComunidad}>{item.nombre}</option>
							          			))}		
									    </NativeSelect>
							        </FormControl>
            					</ListItem>
            					<Divider />
            					<ListItem>
            						<div className="range-input-container">
							        	<p className="range-label">{i18n.t('filters.price')}</p><p className="range-values">{this.props.priceRangeSelected[0]}€ - {this.props.priceRangeSelected[1]}€</p>
							        	<Range className="input-range" min={5000} max={90000} defaultValue={[5000, 90000]} step={1000} tipFormatter={value => `${value}`} onAfterChange={this.handleChangePrice} />
							        </div>
            					</ListItem>
            					<Divider />
            					<ListItem>
            						<Button className={classes.btnResults} onClick={this.handleCloseFiltersWindow}>
							        	{i18n.t('filters.results')}
							        </Button>
            					</ListItem>
            				</List>
				        </Dialog>
					</Hidden>
		        </div>
			</div>
		);
	}
}

/*
const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};
*/

const mapStateToProps = (state) => {
	return { 
		selectedLanguage: state.auth.selectedLanguage,
		brandSelected: state.searchFilters.brandSelected,
		regionSelected: state.searchFilters.regionSelected,
		priceRangeSelected: state.searchFilters.priceRangeSelected
	};
};

EventsListFilters = withStyles(stylesFilter)(EventsListFilters);

EventsListFilters = connect(
	mapStateToProps,
	{ fetchFilters, modifyFilterBrands, modifyFilterRegion, modifyFilterPrice }
)(withNamespaces()(EventsListFilters));

export default EventsListFilters;



