import {
  /*C_MAIN, 
  C_MAIN_LIGHT, 
  C_MAIN_DARK, */
  C_SECONDARY, 
  /*C_SECONDARY_LIGHT, 
  C_SECONDARY_DARK, 
  C_TEXT,*/
  C_TEXT_LIGHT
} from '../variables.jsx';


const profileSelectorStyle = theme => ({
  
  menuSelectorContainer:{
    background: 'red',
    marginTop: '35px',
    padding: 0,
    borderRadius: '3px',
    backgroundColor: 'rgba(0,0,0,0.9)',
    minWidth: '230px',
    top: '25px !important',
  },
  optionProfile:{
    color: C_TEXT_LIGHT,
    fontSize: '14px',
    '&:hover': {
      /*background: 'rgba(0,0,0,0.6)',*/     
      background: 'transparent',
      color: C_SECONDARY, 
    }
  },
  infoProfile:{
    color: '#808080',
    fontFamily: 'Cormorant Garamond',
    fontSize: '18px',
    fontWeight: '700',    
    '&:hover': {
      background: 'transparent',      
    }
  },
  nameProfile: {
    cursor: 'auto',
  },
  

});



export default profileSelectorStyle;