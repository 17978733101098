import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import history from '../history';

import CountDown from '../components/CountDown.jsx';

import { sendLeadNoLogged, sendLeadUser, getUserData } from '../actions/';
//import { authFirebase } from '../components/Firebase/Firebase.js';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
//import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
//import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';


import { DialogTitle, DialogContent, DialogActions } from '../assets/styles/components/DialogFormStyle.jsx';


class PlusLandingView extends React.Component{

	constructor(props) {		
	    super(props);
	    this.handleChangeInput = this.handleChangeInput.bind(this);
	    this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
	    this.state = {	      
	      	currentEvent: props.currentEvent,
	      	openForm: false,
	      	tlf: '',
	      	nombre: '',
	      	email: '',
	      	registrar: true,
	      	operacioOk: '',
	      	openAlert: false,
	      	locale: props.localeLan      
	    };	  
	    //console.log('init pluslanding');
	    //console.log(this.state.currentEvent);
	}

	componentDidMount() {
		this.loadInfoUser();		
	}

	componentDidUpdate(prevProps){		
		if(prevProps.currentEvent.provincias !== this.props.currentEvent.provincias){
			this.setState({currentEvent: this.props.currentEvent});
		}			
	}

	loadInfoUser(){
		//if((this.props.isSignedIn === true)&&(this.props.isAnonymous === false)){
		if(this.props.isSignedIn === true){
			//fetch("http://127.0.0.1:9000/php/servlet/motor-privee/getUserData.php?uid="+this.props.userId)
			//.then(res => res.json())
			this.props.getUserData(this.props.userId)
		    .then(
		        (result) => {	   
		        	console.log(result);
	        		this.setState({
		        		/*nombre: result.nombre,
		        		apellidos: result.apellidos,
		        		email: this.props.userMail,
		        		zip: result.codigoPostal,
		        		gender: result.gender,
		        		idioma: result.idioma,
		        		poblacion: result.poblacion,*/
		        		nombre: this.props.userName,
		        		email: this.props.userMail,
		        		tlf: result.tlf
		        	});  

		        	const initData = {		        		
		        		nombre: this.props.userName,
		        		email: this.props.userMail,
		        		tlf: result.tlf
		        	}

		        	this.props.initialize(initData);	   
		    });
		}		
	}

	handleClickOpen = () => {
    	this.setState({ openForm: true });
  	};

	handleClose = () => {
	    this.setState({ openForm: false });
	};

	openAlert = (textMissatge) => {
    	this.setState({ openAlert: true, operacioOk: textMissatge });  
	};

	closeAlert = () => {
		this.setState({ openAlert: false });
	};

	renderInput = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainer">
				<label className="input-label">{label}</label>				
				<input {...input} autoComplete="off" value={initialValue} placeholder={label} type={type} disabled={disabled} />
				{this.renderError(meta)}
			</div>
		);
	};

	renderTextArea = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainer">
				<label className="input-label">{label}</label>				
				<textarea {...input} autoComplete="off" value={initialValue} placeholder={label} type={type} disabled={disabled} />
				{this.renderError(meta)}
			</div>
		);
	};

	renderCheckBox = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainerCheckbox">								
				<input {...input}  type={type} value={initialValue} /><label className="input-label">{label}</label>
				{this.renderError(meta)}
			</div>
		);
	};

	renderError({error, touched}){		
		if(touched && error){
			return(
				<div>	
					<div className="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{error}</div>
				</div>
			);
		}
	}

	handleChangeInput(e){	
		const inputName = e.target.name;
		const inputValue = e.target.value;		
		var copiaState = Object.assign({}, this.state);
		copiaState[inputName] = inputValue;
		this.setState(copiaState);  		
	}

	handleChangeCheckBox(e){		
		var copiaState = Object.assign({}, this.state);
		if(e.target.value == "false"){
			copiaState['registrar'] = true;
		}else{
			copiaState['registrar'] = false;
		}
		this.setState(copiaState);
	}

	sendLead = (formValues) => {
	
		if(this.props.isSignedIn === true){
			//console.log('estem logats');
			this.props.sendLeadUser(this.props.userId, this.state.nombre, this.state.email, this.state.tlf, this.state.currentEvent.idEvent )
			.then( (result) => {
				this.openAlert(result.message);
				this.handleClose();
			});

		}else{
			
			this.props.sendLeadNoLogged(formValues.nombre, formValues.email, formValues.tlf, this.state.registrar, this.state.currentEvent.idEvent );

			if(this.state.registrar == true){	
				history.push(
					{ 
						pathname: "/users/sign-up", 
						search: "?n="+formValues.nombre+"&m="+formValues.email+"&t="+formValues.tlf/*,
						hash: "#inscribirse" 
						*/
					}
					);	
				/*
				authFirebase.signInAnonymously().catch(function(error) {
					var errorCode = error.code;
					var errorMessage = error.message;
					console.log(errorMessage);
				});
				*/
			}
		}
	
	}

	renderConfirmPolicies = () => {		
		//if((this.props.isSignedIn === true)&&(this.props.isAnonymous === false)){
		if(this.props.isSignedIn === true){
			return('');
		}else{
			return(
				<div className="inputContainerCheckbox"> 
					<input id="registrar" name="registrar" type="checkbox" checked={this.state.registrar} value={this.state.registrar} onChange={(e) => this.handleChangeCheckBox(e)} /><label className="input-label">{i18n.t('landing.acceptRegister')}</label>
				</div>
			);
		}
	}

	render(){

		let totalCentros;
		let centros;

		if(this.state.currentEvent.centros !== undefined){
			totalCentros = this.state.currentEvent.centros.length;
			centros = this.state.currentEvent.centros;
		}else{
			totalCentros = 0;
			centros = [];
		}
		
		const { classes } = this.props;
		const { openAlert } = this.state;

		return(
			<div className="template-landing-campaign">
				<Grid container justify="center" >
					<Hidden xsDown>
						<Grid item xs={12} sm={6} md={4} lg={4} >
							<div className="container-title-campaign">
								<div className="container-title">
									<div className="icons-campaign">
										<FontAwesomeIcon icon={['fas', 'star']} />
										<FontAwesomeIcon icon={['fas', 'star']} />
										<FontAwesomeIcon icon={['fas', 'star']} />
										<FontAwesomeIcon icon={['fas', 'star']} />
										<FontAwesomeIcon icon={['fas', 'star']} />
										<span>Plus Campaign</span>
									</div>
									<div className= "title-campaign">
										<div><p>{this.state.currentEvent.nombreEvento}</p></div>																				
									</div>				
									<div className="more-main-info">
										<p className="dates-event">{i18n.t('landing.from')} {this.state.currentEvent.fechaInicio} {i18n.t('landing.to')} {this.state.currentEvent.fechaFinal}</p>
									</div>													
								</div>							
							</div>
						</Grid>
					</Hidden>
					<Grid item xs={12} sm={6} md={8} lg={8} >
						<div className="container-image-campaign">
							<div className="container-image">
								<div className="image-campaign" style={{ backgroundImage: `url(${this.state.currentEvent.imagen})`}}></div>
							</div>
						</div>
					</Grid>										
					<Hidden smUp>
						<Grid item xs={12} >
							<div className="container-title-campaign-mobile">
								<div className="container-title">
									<div className="title-campaign">
										<h1>{this.state.currentEvent.nombre}</h1>
									</div>		
									<p className="dates-event">{i18n.t('landing.from')} {this.state.currentEvent.fechaInicio} {i18n.t('landing.to')} {this.state.currentEvent.fechaFinal}</p>							
								</div>							
							</div>
						</Grid>
					</Hidden>
				</Grid>
				<Grid container justify="center" >
					<Grid item xs={12} sm={10} md={8} lg={8} >
						<div className="container-info-event">
							<div className="section-description">								
								<CountDown dataFinal={this.state.currentEvent.fechaCierreInscripciones} />
								<h2>{this.state.currentEvent.slogan}</h2>
								<p className="text-description">{this.state.currentEvent.descripcion}</p>
							</div>
							<hr />
							<div className="brands-section section-element">
								<h2 className="title-section">{i18n.t('landing.brands')}</h2>
								<p className="brands-event">
									{	
					        			this.state.currentEvent.marcas.map( (item, index) => (
					        				<img key={index} src={item.logo} alt={item.nombre} />			        					
				        				))
			        				}
								</p>
							</div>
							<hr />
							<div className="section-ubicacion section-element">
								<h2 className="title-section">{i18n.t('landing.location')}</h2>
								<div className="ubication-event">					
									<FontAwesomeIcon icon={['fal', 'map-marker-alt']} />	
									{		
					        			/*this.state.currentEvent.provincias.map( (item, index) => (	
				        					<span key={index}>{item} { index == totalProvincias-1 ? '' : ' - '  }</span>
				        				))
				        				*/
				        				centros.map( (item, index) => (	
				        					<span key={index}>{item.poblaciones} { index == totalCentros-1 ? '' : ' - '  }</span>
				        				))
				        			}	

				        			{	
					        			
				        				centros.map( (item, index) => (	
				        					<p key={index} className="conce-time-table" dangerouslySetInnerHTML={{__html: item.address}}></p>
				        				))
				        			}	
				        			{/*<p className="conce-time-table" dangerouslySetInnerHTML={{__html: this.state.currentEvent.direccion}}></p>*/}
									<p className="conce-phone"><FontAwesomeIcon icon={['fas', 'mobile-alt']} />{this.state.currentEvent.telefono}</p>	        			
								</div>
							</div>
							
							<hr />
							<div className="section-conce section-element">
								<h2 className="title-section">{i18n.t('landing.about')}</h2>
								{	
					        			
				        			centros.map( (item, index) => (	
				        				<div key={index}>
					        				<div className="conce-name-info">									
												<a target="_blank" rel="noopener noreferrer" href={item.web}>{item.name} - {item.poblaciones}</a>
												
											</div>										
											<p className="conce-phone"><FontAwesomeIcon icon={['fas', 'mobile-alt']} />{item.phone}</p>										
											<p className="conce-time-table" dangerouslySetInnerHTML={{__html: item.timetable}}></p>
											<br/>
										</div>
				        			))
				        		}	
				        	</div>
								

								{/*
								<div className="conce-name-info">									
									<p>{this.state.currentEvent.concesionario}</p>
								</div>
								<p className="conce-address" dangerouslySetInnerHTML={{__html: this.state.currentEvent.direccionConcesionario}}></p>
								<p className="conce-phone"><FontAwesomeIcon icon={['fas', 'mobile-alt']} />{this.state.currentEvent.tlfConcesionario}</p>
								<p className="conce-time-table" dangerouslySetInnerHTML={{__html: this.state.currentEvent.horariosConcesionario}}></p>	
								*/}								
							
						</div>
					</Grid>
				</Grid>
				<div className="container-btn-interested">
					<div className="container-interested">
						<div className="name-interested">{this.state.currentEvent.nombre}</div>
						<button className="btn1 btn-interested" onClick={this.handleClickOpen} >
							<span className="text-btn">{i18n.t('global.interested')}</span>
						</button>
					</div>
				</div>
				<Dialog
			        open={this.state.openForm}
			        onClose={this.handleClose}
			        maxWidth='sm'
			        classes={{ paper: classes.dialogPaper }}
			        fullWidth={true}
			        aria-labelledby="form-dialog-title"
		        >
		        	<DialogTitle id="form-dialog-title" onClose={this.handleClose}>{i18n.t('global.askInformation')}</DialogTitle>
		        	<form className="form-more-info" onSubmit={ this.props.handleSubmit(this.sendLead) } >
			        	<DialogContent>
			           		<Field name="nombre" onChange={this.handleChangeInput} initialValue={this.state.nombre } component={this.renderInput} label={i18n.t('editAccount.name')} />
							<Field name="email" onChange={this.handleChangeInput} initialValue={this.state.email} component={this.renderInput} label={i18n.t('editAccount.email')}  />			
							<Field name="tlf" onChange={this.handleChangeInput} initialValue={this.state.tlf} component={this.renderInput} label={i18n.t('editAccount.tlf')}  />			
							{this.renderConfirmPolicies()}						
				        </DialogContent>
				        <DialogActions>
				            <button  className="btn1 btn-lead">
				            	{i18n.t('global.askInformation')}				            	
				            </button>			            
				        </DialogActions>
			        </form>
		        </Dialog>

		        <Snackbar
			          anchorOrigin={{  vertical: 'bottom', horizontal: 'right' }}
			          open={openAlert}
			          onClose={this.closeAlert}			         
			          ContentProps={{			            
			            classes: {
                        	root: classes.root
                    	}
			          }}
			          autoHideDuration={1500}
			          message={ i18n.t(this.state.operacioOk) }			          
			    />
			</div>
			);
	}
}


const validate = (formValues) => {

	const errors = {};
	const requiredFields = [	    
	    'nombre',
	    'email',	    
	    'tlf'	    
  	];

  	//console.log('validattteeeee');
  	//console.log(formValues);
 	
  	requiredFields.forEach(field => {
  		//console.log('validate');
  		//console.log(formValues[field]);
	    if (!formValues[field]) {
	      errors[field] = i18n.t('errorForms.required');
	    }
  	})

  	if ( formValues.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email) ) {
    	errors.email = i18n.t('errorForms.invalidemail');
  	}


	return errors;

};


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		selectedLanguage: state.auth.selectedLanguage,
		userId: state.auth.userId, 
		userMail: state.auth.userMail, 
		userName: state.auth.userName,
		isAnonymous: state.auth.isAnonymous
	};
};

const stylesDialog  = theme => ({
    dialogPaper: {       
        [theme.breakpoints.down('xs')]: {
     		margin: '20px',
    	}        
    },
});


PlusLandingView = withStyles(stylesDialog)(PlusLandingView);

PlusLandingView = reduxForm({
  form: 'InterestedInCampaign',
  enableReinitialize: true,
  validate: validate 
  
})(PlusLandingView);

PlusLandingView = connect(
	mapStateToProps,
	{ sendLeadNoLogged, sendLeadUser, getUserData }
)(withNamespaces()(PlusLandingView));

export default PlusLandingView;

