import React from 'react';
import ReactDOM from 'react-dom';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';

import { DialogTitle, DialogContent, DialogActions } from '../assets/styles/components/DialogFormStyle.jsx';

import { 	userProfileSteps, 
			openPopUpVehiclesForm, 
			closePopUpVehiclesForm, 
			openPopUpPurchaseForm, 
			closePopUpPurchaseForm, 
			openPopUpAlertsForm,
			closePopUpAlertsForm
} from '../actions/';


import VehiclesFormPopUp from './Forms/VehiclesFormPopUp.jsx';
import PurchaseDataForm from './Forms/PurchaseDataForm.jsx';
import AlertsFormPopUp from './Forms/AlertsFormPopUp.jsx';


class StepsBlock extends React.Component{

	constructor(props) {
		super(props);

		this.state = {	 
			profileCompleted: 0,
			stepsCompleted: 0,
			basic: false,
			adquisicion: false,
			alertes: false,
			vehicles: false,
			openFormVehicles: false,
			openAlert: false 
		};

	}

	componentDidMount() {
		this.loadStepsProfile();
		//this.getVehiclesUser();
	}

	componentDidUpdate(prevProps) {
	  	// Uso tipico (no olvides de comparar los props):
	  	if (this.props.vehUpdate !== prevProps.vehUpdate) {
		    //this.openAlert(i18n.t('errors.'+result.error));
		    if(this.props.vehUpdate !== null){
		    	this.openAlert(i18n.t('errors.'+this.props.vehUpdate));
		    }
		    
		}

	}

	handleClickOpenVehiclesForm = () => {

		this.props.openPopUpVehiclesForm();
		//popUpFormVehicles
    	//this.setState({ openFormVehicles: true });    	
  	};

	handleClose = () => {
	    //this.setState({ openFormVehicles: false });
	    this.props.closePopUpVehiclesForm();
	};

	handleClickOpenPurchaseForm = () => {
		this.props.openPopUpPurchaseForm();
	};

	handleClosePurchaseForm = () => {
		this.props.closePopUpPurchaseForm();
	};


	handleClickOpenAlertsForm = () => {
		this.props.openPopUpAlertsForm();
	};

	handleCloseAlerts = () => {
		this.props.closePopUpAlertsForm();
	};

	openAlert = (textMissatge) => {
    	this.setState({ openAlert: true, operacioOk: textMissatge });  
	};

	closeAlert = () => {
		this.setState({ openAlert: false });
	};

	loadStepsProfile(){

		this.props.userProfileSteps(this.props.userId)
			.then(
				(result) => {

					//console.log('steps block crida');
					//console.log(result);

					this.setState({
						profileCompleted: result.profileCompleted,
						basic: result.basic,
						adquisicion: result.adquisicio,
						alertes: result.alertes,
						vehicles: result.vehicles 
					});

					switch(result.profileCompleted){
						case 25:
							//console.log('25');
							this.setState({ stepsCompleted: 1 });
							break;
						case 50:
							//console.log('50');
							this.setState({ stepsCompleted: 2 });
							break;
						case 75:
							//console.log('75');
							this.setState({ stepsCompleted: 3 });
							break;
						case 100:
							//console.log('100');
							this.setState({ stepsCompleted: 4 });
							break;
					}

				},	       
		        (error) => {
		          
		        }
			);


	}


	render(){

		const { openAlert } = this.state;
		const { classes } = this.props;
		const totalSteps = (this.props.stps.match(/1/g) || []).length;

		
		return(

			<div className="template-steps-profile">
				
				{this.props.stps === '1111' ? <div><p className="container-icon-steps"><FontAwesomeIcon icon={['fal', 'thumbs-up']} className="icon-steps-completed" /> </p><p className="message-status-steppers">{i18n.t('profile.congrats')}</p></div> : ''}

				<p>{i18n.t('profile.steps_explanation')}</p>
				
				
				<div className="container-step-bar">
					{i18n.t('profile.youCompleted')} <b>{totalSteps}</b> {i18n.t('profile.of')} <b>4</b> {i18n.t('profile.steps')}
					<MobileStepper
				      variant="progress"
				      steps={5}
				      position="static"
				      color="secondary"
				      LinearProgressProps ={{ classes:  { bar: classes.bar} }}
				      classes={{ root: classes.stepperBar, progress: classes.progressBar}}
				      activeStep={ totalSteps }
				    />
				</div>	
				
				<div className={ this.state.basic == true ? 'container-step step-done' : 'container-step'}>
					<a href="/users/edit-account">
						<Grid container justify="center">
							<Grid item xs={2} sm={1} md={1} lg={1}>
								{ this.state.basic == true ? <FontAwesomeIcon icon={['fas', 'check-circle']} className="active-step" /> : <FontAwesomeIcon icon={['fal', 'circle']} className="inactive-step" /> }
							</Grid>
							<Grid item xs={8} sm={10} md={10} lg={10}>
								<span className="title-step">{i18n.t('profile.step_one')}</span>
								<br/>
								<span className="explanation-step">{i18n.t('profile.explanation_one')}</span>
							</Grid>
							<Grid item xs={2} sm={1} md={1} lg={1} className="icon-go">
								<FontAwesomeIcon icon={['fal', 'chevron-right']} className="inactive-step" /> 
							</Grid>
						</Grid>
					</a>
				</div>
				<div onClick={this.handleClickOpenPurchaseForm} className={ this.props.stps[1] == '1' ? 'container-step step-done' : 'container-step'}>
					<Grid container justify="center">
						<Grid item xs={2} sm={1} md={1} lg={1}>
							{ this.props.stps[1] == '1' ? <FontAwesomeIcon icon={['fas', 'check-circle']} className="active-step" /> : <FontAwesomeIcon icon={['fal', 'circle']} className="inactive-step" /> }
						</Grid>
						<Grid item xs={8} sm={10} md={10} lg={10}>
							<span className="title-step">{i18n.t('profile.step_two')}</span>
							<br/>
							<span className="explanation-step">{i18n.t('profile.explanation_two')}</span>
						</Grid>
						<Grid item xs={2} sm={1} md={1} lg={1} className="icon-go">
							<FontAwesomeIcon icon={['fal', 'chevron-right']} className="inactive-step" /> 
						</Grid>
					</Grid>					
				</div>
				<div onClick={this.handleClickOpenAlertsForm} className={ this.props.stps[2] == '1' ? 'container-step step-done' : 'container-step'}>
					{/*<a href="/users/alerts">*/}
						<Grid container justify="center">
							<Grid item xs={2} sm={1} md={1} lg={1}>
								{ this.props.stps[2] == '1' ? <FontAwesomeIcon icon={['fas', 'check-circle']} className="active-step" /> : <FontAwesomeIcon icon={['fal', 'circle']} className="inactive-step" /> }
							</Grid>
							<Grid item xs={8} sm={10} md={10} lg={10}>
								<span className="title-step">{i18n.t('profile.step_three')}</span>
								<br/>
								<span className="explanation-step">{i18n.t('profile.explanation_three')}</span>
							</Grid>
							<Grid item xs={2} sm={1} md={1} lg={1} className="icon-go">
								<FontAwesomeIcon icon={['fal', 'chevron-right']} className="inactive-step" /> 
							</Grid>
						</Grid>
					{/*</a>*/}
				</div>
				<div onClick={this.handleClickOpenVehiclesForm} className={ this.props.stps[3] == '1' ? 'container-step last-container-step step-done' : 'container-step last-container-step'}>
					
						<Grid container justify="center">
							<Grid item xs={2} sm={1} md={1} lg={1}>
								{ this.props.stps[3] == '1' ? <FontAwesomeIcon icon={['fas', 'check-circle']} className="active-step" /> : <FontAwesomeIcon icon={['fal', 'circle']} className="inactive-step" /> }
							</Grid>
							<Grid item xs={8} sm={10} md={10} lg={10}>
								<span className="title-step">{i18n.t('profile.step_four')}</span>
								<br/>
								<span className="explanation-step">{i18n.t('profile.explanation_four')}</span>
							</Grid>
							<Grid item xs={2} sm={1} md={1} lg={1} className="icon-go">
								<FontAwesomeIcon icon={['fal', 'chevron-right']} className="inactive-step" /> 
							</Grid>
						</Grid>
				</div>

				<Dialog
			        open={this.props.popUpFormVehicles}
			        onClose={this.handleClose}
			        maxWidth='sm'
			        classes={{ paper: classes.dialogPaper }}
			        fullWidth={true}
			        aria-labelledby="form-dialog-title"
		        >
		        	<DialogTitle id="form-dialog-title" onClose={this.handleClose}>{i18n.t('vehiclesUser.myCars')}</DialogTitle>
		        	
		        	<VehiclesFormPopUp />		        	
		        	
		    	</Dialog>

		    	<Dialog
			        open={this.props.popUpFormAlerts}
			        onClose={this.handleCloseAlerts}
			        maxWidth='sm'
			        classes={{ paper: classes.dialogPaper }}
			        fullWidth={true}
			        aria-labelledby="form-dialog-title"
		        >
		        	<DialogTitle id="form-dialog-title" onClose={this.handleCloseAlerts}>{i18n.t('myAlerts.title')}</DialogTitle>
		        	
		        	<AlertsFormPopUp />		        	
		        	
		    	</Dialog>

		    	<Dialog
			        open={this.props.popUpFormPurchase}
			        onClose={this.handleClosePurchaseForm}
			        maxWidth='sm'
			        classes={{ paper: classes.dialogPaper }}
			        fullWidth={true}
			        aria-labelledby="form-dialog-title"
		        >
		        	<DialogTitle id="form-dialog-title" onClose={this.handleClosePurchaseForm}>{i18n.t('editAccount.title')}</DialogTitle>
		        	
		        	<PurchaseDataForm />		        	
		        	
		    	</Dialog>

		    	<Snackbar
		          	anchorOrigin={{  vertical: 'bottom', horizontal: 'right' }}
		          	open={openAlert}
		          	onClose={this.closeAlert}			         
		          	ContentProps={{			            
		            	classes: {
                    		root: classes.root
                		}
		          	}}
		          	autoHideDuration={2500}
		          	message={<span className="snackbar-text-msg"><FontAwesomeIcon icon={['fal', 'check-circle']} />{this.state.operacioOk}</span>}
		          
				/>
		    	
		    	


			</div>

			

		);

	}

}


const stylesStepper  = theme => ({
	stepperBar: {
	    maxWidth: '100%',
	    flexGrow: 1,
	    backgroundColor: '#fff',
	  },
	 progressBar:{
	 	backgroundColor: '#afd2cf',
	 	width: '100%',
	 	height: '7px',
	 	borderRadius: '5px',
	 },
	 bar: {
	 	backgroundColor: '#05bdac',
	 },
	 dialogPaper: {       
        [theme.breakpoints.down('xs')]: {
     		margin: '20px',
    	}        
    },    
    inicioForm: {
    	[theme.breakpoints.down('xs')]: {
     		paddingTop: '0px !important',
    	} 
    },
    inicioBtn: {
    	[theme.breakpoints.down('xs')]: {
     		margin: '10px 0',
    	} 
    },
    registerDialogPaper:{
    	backgroundColor: '#00009A',
    	[theme.breakpoints.down('xs')]: {
     		margin: '20px',
    	} 
    },
    titleRegisterPopup: {
    	backgroundColor: '#00009A',
    	padding: '30px',
    	borderBottom: '0px',
    },
    contentRegisterPopup: {
    	backgroundColor: '#00009A',
    	color: '#fff',
    	fontSize: '18px',
    	textAlign: 'center',
    	padding: '20px',
    },
    footerRegisterPopup: {
    	fontSize: '16px',
    	backgroundColor: '#00009A',
    	margin: '40px',    	
    },
    root: {
        background: '#009688'
    },
    snackAlert: {
          background: '#009688'
    },
    dialogPaper: {       
        [theme.breakpoints.down('xs')]: {
        margin: '20px',
      }        
    },
    inicioForm: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: '0px !important',
      } 
    },
    inicioBtn: {
      [theme.breakpoints.down('xs')]: {
        margin: '10px 0',
      } 
    }


});


StepsBlock = withStyles(stylesStepper)(StepsBlock);

const mapStateToProps = (state) => {
	return { 
		userId: state.auth.userId,
		selectedLanguage: state.auth.selectedLanguage,
		stps: state.auth.stps,
		vehUpdate: state.steppers.msgRslt,
		popUpFormVehicles: state.steppers.formVeh,
		popUpFormPurchase: state.steppers.formPurch,
		popUpFormAlerts: state.steppers.formAlt		
	};
};

StepsBlock = connect(
	mapStateToProps, { userProfileSteps, openPopUpVehiclesForm, closePopUpVehiclesForm, 
						openPopUpPurchaseForm, closePopUpPurchaseForm, openPopUpAlertsForm,
							closePopUpAlertsForm 
						}
)(StepsBlock);



export default withNamespaces()(StepsBlock);

