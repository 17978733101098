import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';

import queryString from 'query-string';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import history from '../history';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';


import { DialogTitle, DialogContent, DialogActions } from '../assets/styles/components/DialogFormStyle.jsx';

import { sendLeadNoLogged, sendLeadUser, getUserData, setCurrentPage } from '../actions/';

import CountDown from '../components/CountDown.jsx';
import ShareButton from '../components/ShareButton.jsx';
import LlamameButton from '../components/LlamameButton.jsx';
import LlamameTopContainer from '../components/LlamameTopContainer.jsx';

import months  from '../config/months.js';

import TagManager from 'react-gtm-module';


class AutobeltranLandingView extends React.Component{

	constructor(props) {
	    super(props);

	    this.handleChangeInput = this.handleChangeInput.bind(this);
	    this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this);
	    
	    this.state = {	      
	      	currentEvent: props.currentEvent,	      	
	      	tlf: '',
	      	nombre: '',
	      	email: '',
	      	openForm: false,
	      	operacioOk: '',
	      	openAlert: false,
	      	locale: props.localeLan,
	      	inscritos: props.currentEvent.totalEnrolled,
	      	registrar: true,
	      	privacity: false,
	      	errorPrivacity: false,
	      	poblacion: props.currentEvent.centros[0].poblaciones,
	      	thinking: false,
	      	intervalId: 0,
	      	styleUpButton: '',
	      	sourceUrl: props.sourceUrl,
	      	duration: 0,
	      	timeToPopUp: 30,
	      	openRegisterPopup: false,
	      	haveOpenedPopup: 0,
	      	producte: ''
	    };

	    this.props.setCurrentPage('landing');

	    /*
	    document.querySelector('meta[property="og:title"]').setAttribute('content', props.currentEvent.titleShare);
	    document.querySelector('meta[name="description"]').setAttribute('content', props.currentEvent.descriptionShare);
	    document.querySelector('meta[property="og:description"]').setAttribute('content', props.currentEvent.descriptionShare);
	    document.querySelector('meta[property="og:image"]').setAttribute('content', props.currentEvent.imageShare);
		*/



	    //console.log('infoooo!!');

		/*var dataEvent = this.state.currentEvent.fechaFinal.split("-");
		this.state.currentEvent.fechaFinal = dataEvent[0]+i18n.t('landing.dateOf')+months[props.currentEvent.locale][dataEvent[1]]+" "+dataEvent[2];		
		*/

		/*var dataEventStart = this.state.currentEvent.dateStart.split("-");
		this.state.currentEvent.dateStart = dataEventStart[0]+i18n.t('landing.dateOf')+months[props.currentEvent.locale][dataEventStart[1]]+" "+dataEventStart[2];		
		
		var dataEventEnd = this.state.currentEvent.dateEnd.split("-");
		this.state.currentEvent.dateEnd = dataEventEnd[0]+i18n.t('landing.dateOf')+months[props.currentEvent.locale][dataEventEnd[1]]+" "+dataEventEnd[2];		
		*/

		//console.log(months[this.props.selectedLanguage][dataEvent[1]]);

	}



	componentDidMount() {	
		
		
		document.body.getElementsByClassName("footer-container")[0].style.marginBottom="70px";
		window.addEventListener('scroll', this.handleScroll);
		this.loadInfoUser();		
		this.scrollToElement();



		//this.intervalID = setInterval(() => this.tick(), 1000);

	}

	componentWillUnmount() {

		document.body.getElementsByClassName("footer-container")[0].style.marginBottom="0px";
		window.removeEventListener('scroll', this.handleScroll);
		//clearInterval(this.intervalID);

	}

	scrollToElement(){
		const { hash } = window.location;
		if (hash !== '') {
		   	setTimeout(() => {
		      	const id = hash.replace('#', '');
		      	const element = document.getElementById(id);
		      	if (element){
		      		element.scrollIntoView();
		      	} 
		    }, 1000);
		}
	}

	tick() {
		
		if(this.props.isSignedIn !== true){
			if(this.state.haveOpenedPopup == 0){
				if(this.state.duration == this.state.timeToPopUp){
		    		if((!this.state.openForm) && (!this.state.openAlert) ){
		    			//alert("portem "+this.state.duration);
		    			this.state.openRegisterPopup = true;
		    			this.state.haveOpenedPopup = 1;
		    			this.state.duration = 0;		    			
		    		}else{
		    			this.state.duration = 0;
		    		}
		    	}else{

		    		let time = this.state.duration + 1;

			    	this.setState({
			      		duration: time
			    	});
		    	}
			}	    	     	
	    }

  	}

	handleScroll = event => {
		if(window.scrollY > 170){
			this.setState({ styleUpButton: 'btn-up-visible' });
		}else{
			this.setState({ styleUpButton: '' });
		}

		/*
		const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if ((windowBottom >= (docHeight-200))&&(this.state.haveOpenedPopup == 0)&&(this.props.isSignedIn !== true)) {
        	this.state.duration = 0;
            this.state.openRegisterPopup = true;
            this.state.haveOpenedPopup = 1;            
        } else {
           
        }
		*/


	};

	loadInfoUser(){
		
		//if((this.props.isSignedIn === true)&&(this.props.isAnonymous === false)){
		if(this.props.isSignedIn === true){
			//fetch("http://127.0.0.1:9000/php/servlet/motor-privee/getUserData.php?uid="+this.props.userId)
			//.then(res => res.json())			
			

			this.props.getUserData(this.props.userId, this.props.selectedLanguage)
		    .then( (result) => {			      		
		    		
	        		this.setState({		        		
		        		nombre: this.props.userName,
		        		email: this.props.userMail,
		        		tlf: result.tlf
		        	});  

		        	
		        	const initData = {		        		
		        		nombre: this.props.userName,
		        		email: this.props.userMail,
		        		tlf: result.tlf
		        	}

		        	this.props.initialize(initData)

		    });
		}		
	}

	handleClickOpenTop = () => {
    	this.setState({ openForm: true });    	
    	 window.gtag('event', 'btn-top'); 		
  	};


  	handleClickOpenMiddle = () => {
    	this.setState({ openForm: true });    	
    	 window.gtag('event', 'btn-middle'); 
  	};


  	handleClickOpenBottom = () => {
    	this.setState({ openForm: true });
    	window.gtag('event', 'btn-bottom'); 
  	};

  	handleClickOpenBar = () => {
    	this.setState({ openForm: true });
    	window.gtag('event', 'btn-bar'); 
  	};

  	handleClickMemberButton = () => {
  		history.push("/users/login");
  	};

	handleClose = () => {
	    this.setState({ openForm: false });
	};

	handleCloseRegister = () => {
		this.setState({ openRegisterPopup: false, duration: 0 });
	};

	openAlert = (textMissatge) => {
    	this.setState({ openAlert: true, operacioOk: textMissatge });  
	};

	closeAlert = () => {
		this.setState({ openAlert: false });
	};

	handleChangeInput(e){	
		const inputName = e.target.name;
		const inputValue = e.target.value;		
		var copiaState = Object.assign({}, this.state);
		copiaState[inputName] = inputValue;
		this.setState(copiaState);  		
	}

	handleChangeCheckBox(e){	

		var copiaState = Object.assign({}, this.state);
		if(e.target.value == "false"){
			copiaState[e.target.id] = true;
		}else{
			copiaState[e.target.id] = false;
		}
		this.setState(copiaState);
	}

	handleChangeSelectPoblacion(e){		
		this.setState({ poblacion: e.target.value });
	}

	renderInput = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainer">
				<label className="input-label">{label}</label>				
				<input {...input} autoComplete="off" value={initialValue} placeholder={i18n.t('landing.enter')+" "+label.toLowerCase()} type={type} disabled={disabled} />
				{this.renderError(meta)}
			</div>
		);
	};

	renderTextArea = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainer">
				<label className="input-label">{label}</label>				
				<textarea {...input} autoComplete="off" value={initialValue} placeholder={label} type={type} disabled={disabled} />
				{this.renderError(meta)}
			</div>
		);
	};

	renderCheckBox = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainerCheckbox">								
				<input {...input}  type={type} value={initialValue} /><label className="input-label">{label}</label>
				{this.renderError(meta)}
			</div>
		);
	}; 

	renderAcceptPrivacity = () => {
		return(
			<div className="inputContainerCheckbox"> 			
				<input id="privacity" name="privacity" type="checkbox" checked={this.state.privacity} value={this.state.privacity} onChange={(e) => this.handleChangeCheckBox(e)} /><label className="input-label" dangerouslySetInnerHTML={{__html: i18n.t('landing.acceptPrivacity')}}></label>
			</div>
		);
	};

	renderErrorPrivacity = () => {

		if(!this.state.errorPrivacity){			
			return(
				<div></div>
			);
		}else{
			return(
				<div style={{marginTop: '15px', border: '1px solid #ed4c3e', paddingTop: '10px' }}>	
					<div className="error-container" style={{textAlign: 'center'}}><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{i18n.t('landing.errorPrivacity')}</div>
				</div>
			);
		}

	};

	renderConfirmPolicies = () => {		
		//if((this.props.isSignedIn === true)&&(this.props.isAnonymous === false)){
		if(this.props.isSignedIn === true){
			return('');
		}else{
			return('');
			/*return(
				<div className="inputContainerCheckbox"> 
					<input id="registrar" name="registrar" type="checkbox" checked={this.state.registrar} value={this.state.registrar} onChange={(e) => this.handleChangeCheckBox(e)} /><label className="input-label">{i18n.t('landing.acceptRegister')}</label>
				</div>
			);
			*/
		}
	};

	renderSelectRegion = () => {		
		return(
			<div className="selectRegionContainer">
				<label className="input-label">Selecciona la población dónde irías</label>
				<select id="poblacion" onChange={(e) => this.handleChangeSelectPoblacion(e)} value={this.state.poblacion}>
					{
						this.state.currentEvent.centros.map( (item, index) => (	
			        		<option key={index} value={item.poblaciones} >{item.poblaciones}</option>
			        	))
					}
				</select>
			</div>

		);
	};

	renderLoader = () => {

		if(this.state.thinking === true){
			return(
				<div className="div-loader"><div className="container-loader"><CircularProgress color="secondary" /></div></div>
			);
		}else{
			return(
				<div></div>				
			);
		}
	}

	renderError({error, touched}){		
		if(touched && error){
			return(
				<div>	
					<div className="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{error}</div>
				</div>
			);
		}
	};

	sendLead = (formValues) => {
	
		let inscripciones = this.state.currentEvent.fechaCierreInscripciones.split('-');
		let inscriptionsDate = new Date();
		inscriptionsDate.setFullYear(inscripciones[2],inscripciones[1]-1,inscripciones[0]);
		const today = Date.now();
		let tipusLead = 0;

		if(inscriptionsDate >= today){			
			tipusLead = 0;
		}else{
			tipusLead = 1;
		}

		

		this.state.thinking = true;
		return sleep(1000).then(() => {
		    // simulate server latency
		    if(!this.state.nombre){
		    	this.state.thinking = false;
		    	throw new SubmissionError({
		        	nombre: i18n.t('errorForms.required'),
		        	_error: 'Login failed!'
		     	 })
		    }else if(!this.state.email){
		    	this.state.thinking = false;
		    	throw new SubmissionError({
		        	email: i18n.t('errorForms.required'),
		        	_error: 'Login failed!'
		     	 })
		    }else if ( this.state.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email) ) {   

		    	this.state.thinking = false;	
		    	throw new SubmissionError({
		        	email: i18n.t('errorForms.invalidemail'),
		        	_error: 'Login failed!'
		     	 })
		    }else if(!this.state.tlf ){
		    	this.state.thinking = false;
		    	throw new SubmissionError({
		        	tlf: i18n.t('errorForms.required'),
		        	_error: 'Login failed!'
		     	 })
		    }else if(this.state.privacity === false){
		    	
		    	this.setState({
		    				errorPrivacity : true, 
		    				thinking: false
		    	});

		    }else{	    	
		    	
		    	var emailsProhibits = ["yopmail", "urhen", "bcaoo", "mailinator", "harakirimail", "dispostable", "sharklasers",
		    						"guerrillamail", "pokemail", "spam4", "spamgourmet", "mintemail", "trashmail", 
		    						"mailforspam", "mailnesia", "mailnull", "superrito.com", "teleworm.us", "wpdfs.com"];

				var totals = emailsProhibits.length;				
				let i, aux;
				

				for(i=0; i<totals; i++){
					if (this.state.email.indexOf(emailsProhibits[i]) === -1) {
						//console.log("no hi es");
					}else{
						this.state.thinking = false;	
				    	throw new SubmissionError({
				        	email: i18n.t('errorForms.invalidemail'),
				        	_error: 'Login failed!'
				     	 })
					}
				}




		    	if(this.props.isSignedIn === true){

					/*console.log('estem logats');
					console.log('sendLeadLogged');
					console.log(formValues.email);
					console.log(this.state.email);
					console.log(this.state.nombre);
					console.log(this.state.tlf);
					console.log('hem fet lead');
					console.log(this.props.utm_source);
					*/
					
					


					
					this.props.sendLeadUser(this.props.userId, this.state.nombre, this.state.email, this.state.tlf, this.state.currentEvent.idEvent, tipusLead, this.state.poblacion, this.props.utm_source, this.state.producte )
					.then( (result) => {
						//this.openAlert(result.message);
						//this.handleClose();
						//this.setState({inscritos: this.state.inscritos + 1 });
						history.push("/thank-you");	
					});
					
					
					
					

				}else{
					/*console.log('sendLeadNologged');
					console.log(this.state.email);
					console.log(this.state.nombre);
					console.log(this.state.tlf);
					console.log('hem fet lead');
					console.log(this.props.utm_source);	

					*/
					
					

					
					this.props.sendLeadNoLogged(this.state.nombre, this.state.email, this.state.tlf, this.state.registrar, this.state.currentEvent.idEvent, tipusLead, this.state.poblacion, this.props.utm_source, this.state.producte )
					.then( (result) => {
						//console.log('no logged lead');
						//console.log(result);						
						
						history.push(
								{ 
									pathname: "/thank-you-sign-up", 
									search: "?n="+formValues.nombre+"&m="+formValues.email+"&t="+formValues.tlf
									//,hash: "#inscribirse" 							
								}
							);	
						
						/*if(this.state.registrar == true){	
							history.push(
								{ 
									pathname: "/thank-you-sign-up", 
									search: "?n="+formValues.nombre+"&m="+formValues.email+"&t="+formValues.tlf
									//,hash: "#inscribirse" 							
								}
							);	
						}else{					
							history.push("/thank-you");
						}

						*/
					});
					
					
					
				}

		    	

		    }

		  });

			
			
			
		
	
	}

	
  	scrollToTop() {
  		window.scrollTo({
          top: 0,
          behavior: 'smooth'     
      	});
  	}



  	guardarProducte = (idProducte) => {
  	  		
  		this.setState({ 
  					openForm: true, 
  					producte: idProducte

  				});
		
  	}

  	renderSmart(){
  		
		return(
			<Grid container justify="center" >
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<div id="smartf2" style={{ margin: '30px auto', textAlign: 'center'}}>							
						<div style={{ position: 'relative'}}>
							<img style={{ width: '90%', marginTop: '0px'}} src="https://media.motorprive.com/campaigns/47/fortwo.png" />
							<span style={{ position: 'absolute', bottom: '0px', right: '10px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} >hasta<br/><span style={{ fontSize: '30px' }}>6.384€</span><br/>de descuento REAL</span>
						</div>
						<span style={{ fontWeight: '700', fontSize: '18px' }} >smart fortwo</span> 							
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<div id="smartf2cabrio" style={{ margin: '30px auto', textAlign: 'center'}}>							 
						<div style={{ position: 'relative'}}>
							<img style={{ width: '90%', marginTop: '0px'}} src="https://media.motorprive.com/campaigns/47/fortwo-cabrio.png" />
							<span style={{ position: 'absolute', bottom: '0px', right: '10px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} >hasta<br/><span style={{ fontSize: '30px' }}>4.800€</span><br/>de descuento REAL</span>
						</div>
						<span style={{ fontWeight: '700', fontSize: '18px' }} >smart fortwo cabrio</span>							
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={6} lg={6}>
					<p style={{textAlign: 'center', fontSize: '16px'}}>Disponibles en más colores</p>
					<p style={{textAlign: 'center', fontSize: '24px'}}><b>Unidades limitadas</b></p>
				</Grid>
			</Grid>
		);  		
  	}

  	renderLegal(idEvent){

  		if(idEvent == 48){
  			return(
	  			<Grid container justify="center" >
			  		<Grid item xs={12} sm={12} md={12} lg={12}>
			  			<p style={{ fontSize: '12px', color: '#9a9a9a', textAlign: 'left'}}>
			  				Nota legal:<br/>		  				
			  				<sup>[1]</sup>  Ejemplo de Financiación con Alternative de Mercedes-Benz Financial Services España, E.F.C., S.A. -Avda. Bruselas 30- 28108 Madrid, para un Clase A 200. PVP 34.900,00 € (Impuestos, transporte, bonificación de la marca y concesionario por financiar incluidos. Gastos de preentrega no incluidos). Válido para ofertas realizadas los días 24, 25 y 26 de octubre de 2019. Importe a financiar 25.692,36 €. Por 290,00 € al mes en 48 cuotas, 15.000 kms/año y cuota final de 19.962,30 €, entrada 9.207,64 €, TIN 8,75%, comisión de apertura 768,20 € (2,99%). TAE 10,19%. Importe total adeudado 34.650,50 €. Precio total a plazos 43.858,14 €.<br/>
			  				<sup>[2]</sup> Ejemplo de Financiación con Alternative de Mercedes-Benz Financial Services España, E.F.C., S.A. -Avda. Bruselas 30- 28108 Madrid, para un Clase C 220 d Estate. PVP 40.900,00 € (Impuestos, transporte, bonificación de la marca y concesionario por financiar incluidos. Gastos de preentrega no incluidos). Válido para ofertas realizadas los días 24, 25 y 26 de octubre de 2019. Importe a financiar 31.317,99 €. Por 350,00 € al mes en 48 cuotas, 15.000 kms/año y cuota final de 24.534,99 €, entrada 9.582,01 €, TIN 8,75%, comisión de apertura 936,41 € (2,99%). TAE 10,18%. Importe total adeudado 42.271,40 €. Precio total a plazos 51.853,41 €.<br/>
			  				<sup>[3]</sup> Ejemplo de Financiación con Alternative de Mercedes-Benz Financial Services España, E.F.C., S.A. -Avda. Bruselas 30- 28108 Madrid, para un GLC 220 d 4MATIC SUV. PVP 51.000,00 € (Impuestos, transporte, bonificación de la marca y concesionario por financiar incluidos. Gastos de preentrega no incluidos). Válido para ofertas realizadas los días 24, 25 y 26 de octubre de 2019. Importe a financiar 39.157,37 €. Por 450,00 € al mes en 48 cuotas, 15.000 kms/año y cuota final de 29.962,26 €, entrada 11.842,63 €, TIN 8,75%, comisión de apertura 1.170,81 € (2,99%). TAE 10,19%. Importe total adeudado 52.733,07 €. Precio total a plazos 64.575,70 €.<br/>
			  				<sup>[4]</sup>Descuento equivalente a un año de cuota aplicado al precio final total financiado.<br/>
			  				<br/>Bases del sorteo:<br/>
			  				<sup>*</sup>El sorteo se realizará los días 24, 25 y 26 entre todos los compradores de un vehículo de la promoción MotorPrivé de las marcas Mercedes-Benz y smart. Los ganadores serán informados el 28 de octubre por mail y recibirán su vale para canjear en el departamento de recambios de Paseo Bonanova 13. Este vale tiene una validez de 6 meses desde la fecha de adquisición del vehículo.<br/>
			  				<sup>**</sup>El sorteo se realizará entre todos los compradores de un vehículo familiar de la promoción MotorPrivé de las marcas Mercedes-Benz. Los ganadores serán informados el 28 de octubre por mail.<br/>
						</p>
			  		</Grid>
			  	</Grid>

	  		);
  		}else{
  			return(
	  			<Grid container justify="center" >
			  		<Grid item xs={12} sm={12} md={12} lg={12}>
			  			<p style={{ fontSize: '12px', color: '#9a9a9a', textAlign: 'left'}}>
			  				Nota legal:<br/>		  				
			  				<sup>[1]</sup>  Ejemplo de Financiación con Alternative de Mercedes-Benz Financial Services España, E.F.C., S.A. -Avda. Bruselas 30- 28108 Madrid, para un Clase A 200. PVP 34.900,00 € (Impuestos, transporte, bonificación de la marca y concesionario por financiar incluidos. Gastos de preentrega no incluidos). Válido para ofertas realizadas los días 24, 25 y 26 de octubre de 2019. Importe a financiar 25.692,36 €. Por 290,00 € al mes en 48 cuotas, 15.000 kms/año y cuota final de 19.962,30 €, entrada 9.207,64 €, TIN 8,75%, comisión de apertura 768,20 € (2,99%). TAE 10,19%. Importe total adeudado 34.650,50 €. Precio total a plazos 43.858,14 €.<br/>
			  				<sup>[2]</sup> Ejemplo de Financiación con Alternative de Mercedes-Benz Financial Services España, E.F.C., S.A. -Avda. Bruselas 30- 28108 Madrid, para un Clase C 220 d Estate. PVP 40.900,00 € (Impuestos, transporte, bonificación de la marca y concesionario por financiar incluidos. Gastos de preentrega no incluidos). Válido para ofertas realizadas los días 24, 25 y 26 de octubre de 2019. Importe a financiar 31.317,99 €. Por 350,00 € al mes en 48 cuotas, 15.000 kms/año y cuota final de 24.534,99 €, entrada 9.582,01 €, TIN 8,75%, comisión de apertura 936,41 € (2,99%). TAE 10,18%. Importe total adeudado 42.271,40 €. Precio total a plazos 51.853,41 €.<br/>
			  				<sup>[3]</sup> Ejemplo de Financiación con Alternative de Mercedes-Benz Financial Services España, E.F.C., S.A. -Avda. Bruselas 30- 28108 Madrid, para un GLC 220 d 4MATIC SUV. PVP 51.000,00 € (Impuestos, transporte, bonificación de la marca y concesionario por financiar incluidos. Gastos de preentrega no incluidos). Válido para ofertas realizadas los días 24, 25 y 26 de octubre de 2019. Importe a financiar 39.157,37 €. Por 450,00 € al mes en 48 cuotas, 15.000 kms/año y cuota final de 29.962,26 €, entrada 11.842,63 €, TIN 8,75%, comisión de apertura 1.170,81 € (2,99%). TAE 10,19%. Importe total adeudado 52.733,07 €. Precio total a plazos 64.575,70 €.<br/>
			  				<sup>[4]</sup>Descuento equivalente a un año de cuota aplicado al precio final total financiado.<br/>
			  			</p>
			  		</Grid>
			  	</Grid>
  			);
  		}
  		
  	}

  	renderLegalSegona(idEvent){

  		
  			return(
	  			<Grid container justify="center" >
			  		<Grid item xs={12} sm={12} md={12} lg={12}>
			  			<p style={{ fontSize: '12px', color: '#9a9a9a', textAlign: 'left'}}>
			  				Nota legal:<br/>	
			  				<sup>*</sup>Según condiciones y modelos.<br/>	  				
			  				Oferta válida por compras realizadas hasta el 19/06/2020, para unidades de stock limitadas, vehículos demos, ocasión, KM0 y gerencia, válida hasta final de existencias.<br/>
			  				Los precios indicados son válidos para vehículos concretos con motorización y equipamiento determinado,  pueden varíar para las demás unidades.<br/>

			  			</p>
			  		</Grid>
			  	</Grid>
  			);
  		
  		
  	}

  	renderMBCuotas(){
  		return(
  			<div>
	  			<Grid container justify="center" >
		  			<Grid item xs={12} sm={12} md={6} lg={4}>
						<div id="claseacuotas" style={{ margin: '30px 10px', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Nuevo Clase A 200</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-a-compacto.png" />							
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}><span style={{ fontWeight: '700', fontSize: '24px' }} >290€/mes<sup style={{ fontSize: '12px' }}>[1]</sup></span><br/>en 48 cuotas</p>												
							<p style={{ fontWeight: '700', textAlign: 'center', fontSize: '14px' }}>Te regalamos un año de cuota gratis<sup style={{ fontSize: '12px' }}>[4]</sup></p>		


							<hr/>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>Entrada: 9.207,64€<br/>Cuota final: 19.962,30€<br/>TIN: 8,75%<br/>Comisión de apertura: 768,20€ (2,99%)<br/>TAE: 10,19%</p>
							<hr/>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>Consigue el nuevo Clase A con MBUX por 290€/mes<sup>[1]</sup>!</p>
						</div>
					</Grid>		
		  			<Grid item xs={12} sm={12} md={6} lg={4}>
						<div id="claseccuotas" style={{ margin: '30px 10px', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase C 220D</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-c-estate.png" />							
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}><span style={{ fontWeight: '700', fontSize: '24px' }} >350€/mes<sup style={{ fontSize: '12px' }}>[2]</sup></span><br/>en 48 cuotas</p>												
							<p style={{ fontWeight: '700', textAlign: 'center', fontSize: '14px' }}>Te regalamos un año de cuota gratis<sup style={{ fontSize: '12px' }}>[4]</sup></p>		


							<hr/>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>Entrada: 9.582,01€<br/>Cuota final: 24.534,99€<br/>TIN: 8,75%<br/>Comisión de apertura: 936,41€ (2,99%)<br/>TAE: 10,18%</p>
							<hr/>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>Consigue el Mercedes Clase C 220D estate super equipado por 350€/mes<sup>[2]</sup>!</p>
						</div>
					</Grid>	
		  			<Grid item xs={12} sm={12} md={6} lg={4}>
						<div id="glccuotas" style={{ margin: '30px 10px', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >GLC 220d</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/glc.png" />							
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}><span style={{ fontWeight: '700', fontSize: '24px' }} >450€/mes<sup style={{ fontSize: '12px' }}>[3]</sup></span><br/>en 48 cuotas</p>												
							<p style={{ fontWeight: '700', textAlign: 'center', fontSize: '14px' }}>Te regalamos un año de cuota gratis<sup style={{ fontSize: '12px' }}>[4]</sup></p>

							<hr/>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>Entrada: 11.842,63€<br/>Cuota final: 29.962,26€<br/>TIN: 8,75%<br/>Comisión de apertura: 1.170,81€ (2,99%)<br/>TAE: 10,19%</p>
							<hr/>
							
							<p style={{ textAlign: 'center', fontSize: '14px' }}>Consigue el GLC por 450€/mes<sup>[3]</sup>!</p>
						</div>
						
					</Grid>	
					<Grid item xs={12} sm={12} md={6} lg={4}>
						<div>
						</div>
						
					</Grid>	
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div className="container-btn-advice">
							<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Por cuotas') } >
								<span className="text-btn">Solicitar información</span>
							</button>
						</div>
						
					</Grid>			
				</Grid>
			</div>

  		);
  	}

  	renderFamiliares(){

  		return(
  			<Grid container justify="center" >
  				<Grid item xs={12} sm={6} md={6} lg={6}>
					<div style={{ margin: '30px auto', textAlign: 'center'}}>
						<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase C 220d berlina</span> 
						<div style={{ position: 'relative'}}>
							<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-c-berlina.png" />
							<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
						</div>
						<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >5.800€</span><br/>de descuento</p>												
						<div className="container-btn-advice">
							<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase C 220d berlina') } >
								<span className="text-btn">Solicitar información</span>
							</button>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<div style={{ margin: '30px auto', textAlign: 'center'}}>
						<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase C 220d Coupé</span> 
						<div style={{ position: 'relative'}}>
							<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-c-coupe.png" />
							<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>7</span><br/>unidad</span>
						</div>
						<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >13.300€</span><br/>de descuento</p>												
						<div className="container-btn-advice">
							<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase C 220d Coupe') } >
								<span className="text-btn">Solicitar información</span>
							</button>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<div style={{ margin: '30px auto', textAlign: 'center'}}>
						<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase C 220d Estate</span> 
						<div style={{ position: 'relative'}}>
							<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-c-estate.png" />
							<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>7</span><br/>unidades</span>
						</div>
						<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >12.800€</span><br/>de descuento</p>												
						<div className="container-btn-advice">
							<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase C 220d Estate') } >
								<span className="text-btn">Solicitar información</span>
							</button>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<div style={{ margin: '30px auto', textAlign: 'center'}}>
						<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase E 220</span> 
						<div style={{ position: 'relative'}}>
							<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-e.png" />
							<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>3</span><br/>unidades</span>
						</div>
						<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >13.000€</span><br/>de descuento</p>												
						<div className="container-btn-advice">
							<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase E 220') } >
								<span className="text-btn">Solicitar información</span>
							</button>
						</div>
					</div>
				</Grid>
  			</Grid>
  		);

  	}

  	renderSuv(){

  		return(
  			<Grid container justify="center" >
		  		<Grid item xs={12} sm={6} md={4} lg={4}>
					<div id="gla" style={{ margin: '30px auto', textAlign: 'center'}}>
						<span style={{ fontWeight: '700', fontSize: '18px' }} >GLA 180</span> 
						<div style={{ position: 'relative'}}>
							<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/gla.png" />
							<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>4</span><br/>unidades</span>
						</div>
						<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >6.500€</span><br/>de descuento</p>												
						<div className="container-btn-advice">
							<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLA 180') } >
								<span className="text-btn">Solicitar información</span>
							</button>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={4}>
					<div id="glc" style={{ margin: '30px auto', textAlign: 'center'}}>
						<span style={{ fontWeight: '700', fontSize: '18px' }} >GLC 220</span> 
						<div style={{ position: 'relative'}}>
							<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/glc.png" />
							<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>5</span><br/>unidades</span>
						</div>
						<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >10.500€</span><br/>de descuento</p>												
						<div className="container-btn-advice">
							<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLC 220') } >
								<span className="text-btn">Solicitar información</span>
							</button>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={4}>
					<div id="gle" style={{ margin: '30px auto', textAlign: 'center'}}>
						<span style={{ fontWeight: '700', fontSize: '18px' }} >GLE 350 4Matic</span> 
						<div style={{ position: 'relative'}}>
							<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/gle.png" />
							<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
						</div>
						<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >18.361€</span><br/>de descuento</p>												
						<div className="container-btn-advice">
							<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLE 350 4Matic') } >
								<span className="text-btn">Solicitar información</span>
							</button>
						</div>
					</div>
				</Grid>
		  	</Grid>

  		);
  	}

  	renderCompactos(){
  		return(
  			<Grid container justify="center" >
	  			<Grid item xs={12} sm={6} md={6} lg={6}>
					<div style={{ margin: '30px auto', textAlign: 'center'}}>
						<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase A 180d</span> 
						<div style={{ position: 'relative'}}>
							<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-a-compacto.png" />
							<span style={{  minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>10</span><br/>unidades</span>
						</div>
						<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >5.000€</span><br/>de descuento</p>												
						<div className="container-btn-advice">
							<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase A 180 d') } >
								<span className="text-btn">Solicitar información</span>
							</button>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<div style={{ margin: '30px auto', textAlign: 'center'}}>
						<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase A 180 sedan</span> 
						<div style={{ position: 'relative'}}>
							<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-A-sedan-180.png" />
							<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>3</span><br/>unidades</span>
						</div>
						<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >7.800€</span><br/>de descuento</p>												
						<div className="container-btn-advice">
							<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase A sedan') } >
								<span className="text-btn">Solicitar información</span>
							</button>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<div style={{ margin: '30px auto', textAlign: 'center'}}>
						<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase B 180 AM</span> 
						<div style={{ position: 'relative'}}>
							<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-b.png" />
							<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>2</span><br/>unidades</span>
						</div>
						<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >8.600€</span><br/>de descuento</p>												
						<div className="container-btn-advice">
							<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase B 180 AM') } >
								<span className="text-btn">Solicitar información</span>
							</button>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<div style={{ margin: '30px auto', textAlign: 'center'}}>
						<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase B 180 nM</span> 
						<div style={{ position: 'relative'}}>
							<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-b.png" />
							<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>10</span><br/>unidades</span>
						</div>
						<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >4.900€</span><br/>de descuento</p>												
						<div className="container-btn-advice">
							<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase B 180 nM') } >
								<span className="text-btn">Solicitar información</span>
							</button>
						</div>
					</div>
				</Grid>				
				<Grid item xs={12} sm={6} md={6} lg={6}>
					<div style={{ margin: '30px auto', textAlign: 'center'}}>
						<span style={{ fontWeight: '700', fontSize: '18px' }} >GLA 180</span> 
						<div style={{ position: 'relative'}}>
							<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/gla.png" />
							<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>4</span><br/>unidades</span>
						</div>
						<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >6.500€</span><br/>de descuento</p>												
						<div className="container-btn-advice">
							<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLA 180') } >
								<span className="text-btn">Solicitar información</span>
							</button>
						</div>
					</div>
				</Grid>
			</Grid>
  		);
  	}

  	renderMBGeneral(idEvent, classes){

  		return(
	  		<div>
	  			<Grid container justify="center" >
		  			<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasea" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase A 180d</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-a-compacto.png" />
								<span style={{  minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>10</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >5.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase A 180 d') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="claseasedan" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase A 180 sedan</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-a-sedan-high.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>3</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >7.800€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase A sedan') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="claseb" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase B 180</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-b-old.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>2</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >8.600€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase B 180 AM') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="nuevoclaseb" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Nuevo Clase B 180</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-b.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>10</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >4.900€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase B 180 nM') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasec" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase C 220d berlina</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-c-berlina.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >5.800€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase C 220d berlina') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="claseccoupe" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase C 220d Coupé</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-c-coupe.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>7</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >13.300€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase C 220d Coupe') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasecestate" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase C 220d Estate</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-c-estate.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>7</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >12.800€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase C 220d Estate') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="cla" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >CLA 200 Coupe</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/cla-coupe.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >4.500€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('CLA 200 Coupe') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasb" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >CLA 200 Shooting Brake</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/cla-sb.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>2</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >11.643€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('CLA 200 SB') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasee" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase E 220</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-e.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>3</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >13.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase E 220') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid  item xs={12} sm={6} md={4} lg={4}>
						<div id="gla" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >GLA 180</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/gla.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>4</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >6.500€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLA 180') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="glc" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >GLC 220</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/glc.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>5</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >10.500€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLC 220') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="gle" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >GLE 350 4Matic</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/gle.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >18.361€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLE 350 4Matic') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="gls" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >GLS 350 4Matic</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/gls.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >11.100€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLS 350 4Matic') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
				</Grid>
				<Grid container justify="center" >
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="smartf2" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >smart fortwo</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/fortwo.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>4</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >6.384€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('smart fortwo') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="smartf2eq" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >smart fortwo EQ</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/fortwo-eq.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>2</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >4.800€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('smart fortwo EQ') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
				</Grid>
				<Grid container justify="center" >
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<h2 style={{ textAlign: 'center' }}>llévatelo también por cuotas</h2>
					</Grid>					
				</Grid>
				{this.renderMBCuotas()}

				{this.renderRegalos(idEvent)}
				{this.renderLegal(idEvent)}
			</div>
  		);

  	}

  	renderMBGeneralSegona(idEvent, classes){

  		return(
	  		<div>
	  			<Grid container justify="center" >
		  			<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasea" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase A</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-a-compacto.png" />
								<span style={{  minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>5</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >8.900€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase A') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="claseasedan" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase A Sedán</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-a-sedan-high.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>3</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >5.500€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase A sedan') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="claseaamg" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase A 35 AMG</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/58/clase-a-amg-35.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>6</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >6.400€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase A 35 AMG') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="claseb" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase B</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-b.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>12</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >4.600€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase B') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasecberlina" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase C Berlina</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-c-berlina.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>2</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >5.800€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase C berlina') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="claseccoupe" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase C Coupé</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-c-coupe.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>4</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >11.600€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase C Coupe') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasecestate" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase C Estate</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-c-estate.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>8</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >12.600€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase C Estate') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>					
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasb" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >CLA Shooting Brake</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/cla-sb.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>4</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >11.643€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('CLA SB') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="glc" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >GLC</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/glc.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >5.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLC') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="glccoupe" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >GLC Coupé</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/58/glc-coupe.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>6</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >6.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLC coupe') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="gle" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >GLE</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/gle.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >9.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLE') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="gls" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >GLS </span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/gls.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >11.100€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLS') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasee" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase E Estate</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/58/clase-e-estate.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>3</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >10.200€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase E estate') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="claseeallroad" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase E All-Road</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/58/clase-e-estate-allroad.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >7.400€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase E All-Road') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clases" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase S</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/58/clase-s.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >30.900€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase S') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasescoupe" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase S 63 4M Coupé</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/58/clase-s-coupe.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >52.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase S 63 4M Coupe') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					
					
				</Grid>
				<Grid container justify="center" >
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="smartf2" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >smart fortwo<br/>   &nbsp;</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/fortwo.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>2</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >6.384€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('smart fortwo') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="smartf4eq" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >smart forfour<br/>100% eléctrico</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/58/smart-forfour.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>3</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >6.100€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('smart forfour EQ') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="smartf2cabrio" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >smart fortwo cabrio<br/>100% eléctrico</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/58/fortwo-cabrio.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >8.300€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('smart fortwo cabrio') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
				</Grid>
				<Grid container justify="center" >
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div className="imp-infooo" style={{ margin: '30px auto', textAlign: 'center', backgroundColor: '#f3f3f3', padding: '15px'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >3 Meses de cuota gratis<sup>*</sup></span> <br/>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >En tu domicilio en 48h, sin coste<sup>*</sup></span> <br/>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Calculamos la cuota que mejor se adapte a ti</span> <br/>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >2 años de garantía y mantenimiento incluidos<sup>*</sup></span> <br/>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Puedes cambiarlo en 15 días<sup>*</sup></span>
						</div>
					</Grid>

				</Grid>



				{this.renderLegalSegona(idEvent)}
			</div>
  		);

  	}

  	renderMBGeneralTercera(idEvent, classes){

  		return(
	  		<div>
	  			<Grid container justify="center" >
		  			<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasea" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase A</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-a-compacto.png" />
								<span style={{  minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>4</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >9.800€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase A') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="claseasedan" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase A Sedán</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-a-sedan-high.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>2</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >5.600€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase A sedan') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="claseaamg" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase A 35 AMG</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/58/clase-a-amg-35.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>5</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >6.400€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase A 35 AMG') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="claseb" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase B</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-b.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>8</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >4.600€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase B') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasecberlina" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase C Berlina</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-c-berlina.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >6.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase C berlina') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="claseccoupe" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase C Coupé</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-c-coupe.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>2</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >10.600€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase C Coupe') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasecestate" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase C Estate</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/clase-c-estate.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>9</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >12.800€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase C Estate') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>					
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasb" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >CLA Shooting Brake</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/cla-sb.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>3</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >11.643€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('CLA SB') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="glc" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >GLC</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/glc.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >7.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLC') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="glccoupe" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >GLC Coupé</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/58/glc-coupe.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>5</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >8.100€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLC coupe') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="gle" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >GLE</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/gle.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >9.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLE') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="gls" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >GLS </span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/gls.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >11.100€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLS') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasescoupe" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >EQC</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/65/eqc.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >5.600€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('EQC') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasee" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase E Estate</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/58/clase-e-estate.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>3</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >15.900€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase E estate') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="claseeallroad" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase E All-Road</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/58/clase-e-estate-allroad.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >7.400€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase E All-Road') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clases" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase S</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/58/clase-s.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >30.900€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase S') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasescoupe" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase S 63 4M Coupé</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/58/clase-s-coupe.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >51.100€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase S 63 4M Coupe') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					
					
				</Grid>
				<Grid container justify="center" >
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="smartf2" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >smart fortwo<br/>100% eléctrico</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/48/stock/fortwo.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>6</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >7.300€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('smart fortwo EQ') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="smartf4eq" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >smart forfour<br/>100% eléctrico</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/58/smart-forfour.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>7</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >6.100€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('smart forfour EQ') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="smartf2cabrio" style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >smart fortwo cabrio<br/>100% eléctrico</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/58/fortwo-cabrio.png" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '0px', left: '15px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>2</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >8.300€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('smart fortwo cabrio') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
				</Grid>
				<Grid container justify="center" >
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div className="imp-infoooo" style={{ margin: '30px auto', textAlign: 'center', backgroundColor: '#f3f3f3', padding: '25px', lineHeight: '30px'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >3 Meses de cuota gratis<sup>*</sup></span> <br/>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >En tu domicilio en 48h, sin coste<sup>*</sup></span> <br/>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Calculamos la cuota que mejor se adapte a ti</span> <br/>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >2 años de garantía y mantenimiento incluidos<sup>*</sup></span><br/> 
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Puedes cambiarlo en 15 días<sup>*</sup></span>
						</div>
					</Grid>

				</Grid>



				{this.renderLegalSegona(idEvent)}
			</div>
  		);

  	}

  	renderCertifiedGeneral(classes){

  		return(
	  		<div>
	  			<Grid container justify="center" >
		  			<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasea" style={{ margin: '30px auto', textAlign: 'center'}}>
							<p style={{ fontWeight: '700', fontSize: '18px', marginBottom: '5px' }} >Clase A</p> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/55/stock/certified-A.jpg" />	
								<span style={{ minWidth: '60px', position: 'absolute', top: '-15px', left: '0px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>9</span><br/>unidades</span>							
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >3.000€</span><br/>de descuento</p>
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase A') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasec" style={{ margin: '30px auto', textAlign: 'center'}}>
							<p style={{ fontWeight: '700', fontSize: '18px', marginBottom: '5px' }} >Clase C</p> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/55/stock/certified-clase-c.jpg" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '-15px', left: '0px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>31</span><br/>unidades</span>		
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >4.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase C') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="cla" style={{ margin: '30px auto', textAlign: 'center'}}>
							<p style={{ fontWeight: '700', fontSize: '18px', marginBottom: '5px' }} >CLA</p> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/55/stock/certified-CLA.jpg" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '-15px', left: '0px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>20</span><br/>unidades</span>		
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >6.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('CLA') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="cls" style={{ margin: '30px auto', textAlign: 'center'}}>
							<p style={{ fontWeight: '700', fontSize: '18px', marginBottom: '5px' }} >CLS</p> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/55/stock/certified-CLS.jpg" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '-15px', left: '0px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >3.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('CLS') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="gla" style={{ margin: '30px auto', textAlign: 'center'}}>
							<p style={{ fontWeight: '700', fontSize: '18px', marginBottom: '5px' }} >GLA</p> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/55/stock/certified-GLA.jpg" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '-15px', left: '0px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>10</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >2.000</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLA') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="glc" style={{ margin: '30px auto', textAlign: 'center'}}>
							<p style={{ fontWeight: '700', fontSize: '18px', marginBottom: '5px' }} >GLC</p> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/55/stock/certified-GLC.jpg" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '-15px', left: '0px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>8</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >4.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLC') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="glccoupe" style={{ margin: '30px auto', textAlign: 'center'}}>
							<p style={{ fontWeight: '700', fontSize: '18px', marginBottom: '5px' }} >GLC Coupé</p> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/55/stock/certified-GLC-coupe.jpg" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '-15px', left: '0px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >6.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLC Coupe') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="gls" style={{ margin: '30px auto', textAlign: 'center'}}>
							<p style={{ fontWeight: '700', fontSize: '18px', marginBottom: '5px' }} >GLS</p> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/55/stock/certified-GLS.jpg" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '-15px', left: '0px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >12.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GLS') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="amggts" style={{ margin: '30px auto', textAlign: 'center'}}>
							<p style={{ fontWeight: '700', fontSize: '18px', marginBottom: '5px' }} >AMG GT S</p> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/55/stock/certified-GTS.jpg" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '-15px', left: '0px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>3</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >18.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('GTS') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="clasev" style={{ margin: '30px auto', textAlign: 'center'}}>
							<p style={{ fontWeight: '700', fontSize: '18px', marginBottom: '5px' }} >Clase V</p> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/55/stock/certified-clase-V.jpg" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '-15px', left: '0px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >8.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase V') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="audirs6" style={{ margin: '30px auto', textAlign: 'center'}}>
							<p style={{ fontWeight: '700', fontSize: '18px', marginBottom: '5px' }} >Audi RS6</p> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/55/stock/certified-RS6.jpg" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '-15px', left: '0px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >7.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Audi RS6') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={4}>
						<div id="infinitiq70" style={{ margin: '30px auto', textAlign: 'center'}}>
							<p style={{ fontWeight: '700', fontSize: '18px', marginBottom: '5px' }} >Infiniti Q70</p> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/55/stock/certified-Infinity.jpg" />
								<span style={{ minWidth: '60px', position: 'absolute', top: '-15px', left: '0px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>1</span><br/>unidad</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >6.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Infiniti Q50') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
				</Grid>
				
				
				
			</div>
  		);

  	}

  	renderRegalos(idEvent){
  		if(idEvent == 48){
  			return(
	  			<div>
	  				<Grid container justify="center" >
						<Grid item xs={12} sm={6} md={4} lg={4}>
							<h2 style={{ textAlign: 'center', marginTop: '50px' }}>y además...</h2>
						</Grid>										
					</Grid>  
					<Grid container justify="center" >
						<Grid item xs={12} sm={11} md={10} lg={10}>
							<p style={{ textAlign: 'center', fontWeight: '700' }}>Sorteo diario de 3 cheques de 200 euros para gastar en accesorios y boutique Mercedes<sup>*</sup></p>
						</Grid>										
					</Grid>
					<Grid container justify="center" >
						<Grid item xs={12} sm={6} md={4} lg={4}>
							<img style={{ width: '100%' }} src="https://media.motorprive.com/campaigns/48/accesorios-01.jpg" />
						</Grid>	
						<Grid item xs={12} sm={6} md={4} lg={4}>
							<img style={{ width: '100%' }} src="https://media.motorprive.com/campaigns/48/accesorios-02.jpg" />
						</Grid>	
						<Grid item xs={12} sm={6} md={4} lg={4}>
							<img style={{ width: '100%' }} src="https://media.motorprive.com/campaigns/48/accesorios-03.jpg" />
						</Grid>										
					</Grid>
					<Grid container justify="center" >
						<Grid item xs={12} sm={11} md={10} lg={10}>
							<p style={{ textAlign: 'center', marginTop: '50px', fontWeight: '700' }}>Sorteo de una sillita de coche Ikonic valorada en 399€ y un cochecito Kendo valorado en 569€<sup>**</sup></p>
						</Grid>										
					</Grid>
					<Grid container justify="center" >
						<Grid item xs={12} sm={6} md={4} lg={4}>
							<img style={{ width: '100%' }} src="https://media.motorprive.com/campaigns/48/ikonic.jpg" />
							<p style={{ textAlign: 'center', padding: '5px', fontSize: '14px' }}>La silla de seguridad con rotación 360º a contramarcha desde recién nacido hasta 105cm con opción a favor de la marcha.</p>
						</Grid>	
						<Grid item xs={12} sm={6} md={4} lg={4}>
							<img style={{ width: '100%' }} src="https://media.motorprive.com/campaigns/48/kendo.jpg" />
							<p style={{ textAlign: 'center', padding: '5px', fontSize: '14px' }}>El SUV de los cochecitos te permite rodar por cualquier terreno y moverte fácilmente gracias a su plegado compacto.</p>
						</Grid>	
															
					</Grid>
					
	  			</div>
  			);
  		}else{
  			return(<div></div>);
  		}
  		
  	}

  	renderFiat(classes){

  		
  		
  		

  			return(
  			<div>
  				<Grid container justify="center" >
  					<Grid item xs={12} sm={12} md={6} lg={6}>
						<div style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >Clase A 180d</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/43/images/prova-clase-A.png" />
								<span style={{ position: 'absolute', top: '0px', left: '10px', padding: '5px', borderRadius: '10px', color: '#fff', backgroundColor: '#000', fontSize: '10px'}} ><span style={{ fontSize: '30px' }}>10</span><br/>unidades</span>
							</div>
							<p style={{ textAlign: 'center', fontSize: '14px' }}>hasta<br/><span style={{ fontWeight: '700', fontSize: '24px' }} >5.000€</span><br/>de descuento</p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase A 180 d') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>						
						<Paper style={{ width: '100%', overflowX: 'auto' }}>
						    <Table className="table-prices" style={{ minWidth: 320}}>
						        <TableHead>
						          	<TableRow>
						          		<TableCell className={classes.head}>MODELO</TableCell>
						            	<TableCell className={classes.head}>COLOR</TableCell>
							            <TableCell className={classes.head}>PVP</TableCell>
							            <TableCell className={classes.head}>PRECIO OFERTA</TableCell>
							            <TableCell className={classes.headHide}>AHORRAS</TableCell>						            
						          	</TableRow>
						        </TableHead>
						        <TableBody>
						        	<TableRow key={1}>	
						        		<TableCell className={classes.cellPrice}>A 180d</TableCell>					              	
						              	<TableCell className={classes.cellPrice}>Blanco Polar</TableCell>
						              	<TableCell className={classes.cellPrice}>38.800€</TableCell>
						              	<TableCell className={classes.cellPrice}>35.800€</TableCell>
						              	<TableCell className={classes.cellPriceHide}>3.000€</TableCell>
						            </TableRow>
						            <TableRow key={2}>	
						            	<TableCell className={classes.cellPrice}>A 180d</TableCell>						              	
						              	<TableCell className={classes.cellPrice}>Gris Montaña</TableCell>
						              	<TableCell className={classes.cellPrice}>38.900€</TableCell>
						              	<TableCell className={classes.cellPrice}>35.700€</TableCell>
						              	<TableCell className={classes.cellPriceHide}>3.200€</TableCell>
						            </TableRow>
						        </TableBody>
						    </Table>
						</Paper>


					</Grid>


  				</Grid>

	  			<Grid container justify="center" >

	  				



					<Grid item xs={12} sm={12} md={6} lg={6}>
						<div style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '18px' }} >FIAT 500 LOUNGE 1.2 69CV</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/43/images/prova-clase-A.png" />
								<span style={{ position: 'absolute', bottom: '0px', right: '10px', padding: '10px', borderRadius: '10px', color: '#fff', backgroundColor: '#05bdac', fontSize: '12px'}} ><span style={{ fontSize: '20px' }}>28%</span><br/>desc.</span>
							</div>
							<p style={{ textAlign: 'center' }}>desde <span style={{ fontWeight: '700', fontSize: '18px' }} >12.670€</span></p>												
							<div className="container-btn-advice">
								<button className="btn1 btn-interested-product" onClick={ () => this.guardarProducte('Clase A 180 d') } >
									<span className="text-btn">Solicitar información</span>
								</button>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<div style={{ margin: '30px auto', textAlign: 'center' }}>
							<span style={{ fontWeight: '700', fontSize: '20px' }} >FIAT TIPO HB STREET 1.4 95 CV </span>
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/43/images/tipo_HB_street_02.png" />
								<span style={{ position: 'absolute', bottom: '0px', right: '10px', padding: '10px', borderRadius: '10px', color: '#fff', backgroundColor: '#05bdac', fontSize: '12px'}} ><span style={{ fontSize: '20px' }}>32%</span><br/>desc.</span>	
							</div>
							<p style={{ textAlign: 'center' }}>desde <span style={{ fontWeight: '700', fontSize: '18px' }} >12.375€</span></p>	
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<div style={{ margin: '30px auto', textAlign: 'center' }}>
							<span style={{ fontWeight: '700', fontSize: '20px' }} >FIAT TIPO SW MIRROR 1.4 TJET 120 CV</span>
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/43/images/tipo_sw_MIRROR_03.png" />
								<span style={{ position: 'absolute', bottom: '0px', right: '10px', padding: '10px', borderRadius: '10px', color: '#fff', backgroundColor: '#05bdac', fontSize: '12px'}} ><span style={{ fontSize: '20px' }}>32%</span><br/>desc.</span>	
							</div>
							<p style={{ textAlign: 'center' }}>desde <span style={{ fontWeight: '700', fontSize: '18px' }} >15.490€</span></p>	
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<div style={{ margin: '30px auto', textAlign: 'center'}}>
							<span style={{ fontWeight: '700', fontSize: '20px' }} >FIAT TIPO SEDÁN LOUNGE 1.4 95 CV</span>
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/43/images/tipo_sedan_LOUNGE_04.png" />
								<span style={{ position: 'absolute', bottom: '0px', right: '10px', padding: '10px', borderRadius: '10px', color: '#fff', backgroundColor: '#05bdac', fontSize: '12px'}} ><span style={{ fontSize: '20px' }}>32%</span><br/>desc.</span>
							</div>
							<p style={{ textAlign: 'center' }}>desde <span style={{ fontWeight: '700', fontSize: '18px' }} >13.400€</span></p>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<div style={{ margin: '30px auto', textAlign: 'center', position: 'relative'}}>
							<span style={{ fontWeight: '700', fontSize: '20px' }} >FIAT PANDA CITY CROSS 1.2 69 CV 4X2</span>
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/43/images/panda_CITY_CROSS_05.png" />
								<span style={{ position: 'absolute', bottom: '0px', right: '10px', padding: '10px', borderRadius: '10px', color: '#fff', backgroundColor: '#05bdac', fontSize: '12px'}} ><span style={{ fontSize: '20px' }}>32%</span><br/>desc.</span>
							</div>
							<p style={{ textAlign: 'center' }}>desde <span style={{ fontWeight: '700', fontSize: '18px' }} >11.990€</span></p>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<div style={{ margin: '30px auto', textAlign: 'center', position: 'relative'}}>
							<span style={{ fontWeight: '700', fontSize: '20px' }} >FIAT PANDA CROSS 4X4 0.9 TWIN AIR 85 CV</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/43/images/panda_cross_4X4_06.png" />
								<span style={{ position: 'absolute', bottom: '0px', right: '10px', padding: '10px', borderRadius: '10px', color: '#fff', backgroundColor: '#05bdac', fontSize: '12px'}} ><span style={{ fontSize: '20px' }}>32%</span><br/>desc.</span>
							</div>
							<p style={{ textAlign: 'center' }}>desde <span style={{ fontWeight: '700', fontSize: '18px' }} >16.100€</span></p>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<div style={{ margin: '30px auto', textAlign: 'center', position: 'relative'}}>
							<span style={{ fontWeight: '700', fontSize: '20px' }} >FIAT 500 L URBAN 1.4 95 CV</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/43/images/500_URBAN_07.png" />
								<span style={{ position: 'absolute', bottom: '0px', right: '10px', padding: '10px', borderRadius: '10px', color: '#fff', backgroundColor: '#05bdac', fontSize: '12px'}} ><span style={{ fontSize: '20px' }}>32%</span><br/>desc.</span>
							</div>
							<p style={{ textAlign: 'center' }}>desde <span style={{ fontWeight: '700', fontSize: '18px' }} >14.490€</span></p>
						</div>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6}>
						<div style={{ margin: '30px auto', textAlign: 'center', position: 'relative'}}>
							<span style={{ fontWeight: '700', fontSize: '20px' }} >FIAT 500 L CITY CROSS 1.4 95 CV</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/43/images/500L_CITY_CROSS_08.png" />
								<span style={{ position: 'absolute', bottom: '0px', right: '10px', padding: '10px', borderRadius: '10px', color: '#fff', backgroundColor: '#05bdac', fontSize: '12px'}} ><span style={{ fontSize: '20px' }}>32%</span><br/>desc.</span>
							</div>
							<p style={{ textAlign: 'center' }}>desde <span style={{ fontWeight: '700', fontSize: '18px' }} >15.000€</span></p>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<div style={{ margin: '30px auto', textAlign: 'center', position: 'relative'}}>
							<span style={{ fontWeight: '700', fontSize: '20px' }} >FIAT 500 X CROSS 1.0 FIREFLY 120 CV</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/43/images/500X_CROSS_09.png" />
								<span style={{ position: 'absolute', bottom: '0px', right: '10px', padding: '10px', borderRadius: '10px', color: '#fff', backgroundColor: '#05bdac', fontSize: '12px'}} ><span style={{ fontSize: '20px' }}>26%</span><br/>desc.</span>
							</div>
							<p style={{ textAlign: 'center' }}>desde <span style={{ fontWeight: '700', fontSize: '18px' }} >19.600€</span></p>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<div style={{ margin: '30px auto', textAlign: 'center', position: 'relative'}}>
							<span style={{ fontWeight: '700', fontSize: '20px' }} >FIAT 500 X 1.0 FIREFLY 120 CV 120 ANIVERSARI</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/43/images/500X_120TH_10.png" />
								<span style={{ position: 'absolute', bottom: '0px', right: '10px', padding: '10px', borderRadius: '10px', color: '#fff', backgroundColor: '#05bdac', fontSize: '12px'}} ><span style={{ fontSize: '20px' }}>21%</span><br/>desc.</span>
							</div>
							<p style={{ textAlign: 'center' }}>desde <span style={{ fontWeight: '700', fontSize: '18px' }} >18.700€</span></p>
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={6}>
						<div style={{ margin: '30px auto', textAlign: 'center', position: 'relative'}}>
							<span style={{ fontWeight: '700', fontSize: '20px' }} >FIAT DOBLO PANORANMA LOUNGE 1.4 TJET 120 CV</span> 
							<div style={{ position: 'relative'}}>
								<img style={{ width: '90%'}} src="https://media.motorprive.com/campaigns/43/images/doblo_11.jpg" />
								<span style={{ position: 'absolute', bottom: '0px', right: '10px', padding: '10px', borderRadius: '10px', color: '#fff', backgroundColor: '#05bdac', fontSize: '12px'}} ><span style={{ fontSize: '20px' }}>43%</span><br/>desc.</span>
							</div>
							<p style={{ textAlign: 'center' }}>desde <span style={{ fontWeight: '700', fontSize: '18px' }} >17.250€</span></p>
						</div>
					</Grid>
				</Grid>
			</div>
	  		);
  		
  		
  	}


  	renderFirstBlock(idEvent){
  		
  		switch(idEvent){
  			/*case '39':
  				return(this.renderSmart());
  			break;  	
  			*/	
  			case '49':
  				return(
  					<Grid container justify="center" >
						<Grid item xs={12} sm={10} md={8} lg={8}>													
							<div className="content-custom-landing"  dangerouslySetInnerHTML={{__html: this.state.currentEvent.slogan}}></div>
							
							{this.renderMBCuotas()}

						</Grid>
					</Grid>
					);


  			break;	
  			default:
  				return(
  					<Grid container justify="center" >
						<Grid item xs={12} sm={10} md={8} lg={6}>													
							<div className="content-custom-landing"  dangerouslySetInnerHTML={{__html: this.state.currentEvent.slogan}}></div>
						</Grid>
					</Grid>
  					);
  		}

  	}

  	renderSecondBlock(idEvent, classes){

  		switch(idEvent){
  			/*case '36':
  				return(this.renderFiat(classes));
  				break;*/
  			
  			case '47':
  				return(
  					
  					<Grid container justify="center" >
						<Grid item xs={12} sm={10} md={8} lg={6}>													
							<div className="content-custom-landing"  dangerouslySetInnerHTML={{__html: this.state.currentEvent.descripcion}}></div>
							{this.renderSmart()}
						</Grid>
					</Grid>
  					);
  			break;
  			case '48':
  				return(
  					<Grid container justify="center" >
						<Grid item xs={12} sm={10} md={10} lg={8}>													
							<div className="content-custom-landing"  dangerouslySetInnerHTML={{__html: this.state.currentEvent.descripcion}}></div>
							{this.renderMBGeneral(48, classes)}
						</Grid>
					</Grid>
  					

  					);
  			break;
  			case '49':
  				return(
  					<Grid container justify="center" >
						<Grid item xs={12} sm={10} md={8} lg={6}>													
							<div className="content-custom-landing"  dangerouslySetInnerHTML={{__html: this.state.currentEvent.descripcion}}></div>
							{this.renderLegal(49)}
						</Grid>
					</Grid>  					
  					);
  			break;
  			case '50':
  				return(
  					<Grid container justify="center" >
						<Grid item xs={12} sm={10} md={10} lg={8}>													
							<div className="content-custom-landing"  dangerouslySetInnerHTML={{__html: this.state.currentEvent.descripcion}}></div>
							{this.renderSuv()}
						</Grid>
					</Grid>
  					);
  			break;
  			case '51':
  				return(
  					<Grid container justify="center" >
						<Grid item xs={12} sm={10} md={10} lg={8}>													
							<div className="content-custom-landing"  dangerouslySetInnerHTML={{__html: this.state.currentEvent.descripcion}}></div>
							{this.renderCompactos()}
						</Grid>
					</Grid>
  					);
  			break;
  			case '52':
  				return(
  					<Grid container justify="center" >
						<Grid item xs={12} sm={10} md={10} lg={8}>													
							<div className="content-custom-landing"  dangerouslySetInnerHTML={{__html: this.state.currentEvent.descripcion}}></div>
							{this.renderFamiliares()}
						</Grid>
					</Grid>
  					);
  			break;
  			case '55':
  				return(
  					<Grid container justify="center" >
						<Grid item xs={12} sm={10} md={10} lg={8}>													
							<div className="content-custom-landing"  dangerouslySetInnerHTML={{__html: this.state.currentEvent.descripcion}}></div>
							{this.renderCertifiedGeneral(classes)}
						</Grid>
					</Grid>
  					

  					);
  			break;
  			case '58':
  				return(
  					<Grid container justify="center" >
						<Grid item xs={12} sm={10} md={10} lg={8}>													
							<div className="content-custom-landing"  dangerouslySetInnerHTML={{__html: this.state.currentEvent.descripcion}}></div>
							{this.renderMBGeneralSegona(58,classes)}
						</Grid>
					</Grid>
  					

  					);
  			break;
  			case '65':
  				return(
  					<Grid container justify="center" >
						<Grid item xs={12} sm={10} md={10} lg={8}>													
							<div className="content-custom-landing"  dangerouslySetInnerHTML={{__html: this.state.currentEvent.descripcion}}></div>
							{this.renderMBGeneralTercera(65,classes)}
						</Grid>
					</Grid>
  					

  					);
  			break;
  			default:
  				return(
  					<Grid container justify="center" >
						<Grid item xs={12} sm={10} md={8} lg={6}>													
							<div className="content-custom-landing"  dangerouslySetInnerHTML={{__html: this.state.currentEvent.descripcion}}></div>
						</Grid>
					</Grid>

  					);
  			
  		}

  	}

  	renderGetMember(){

  		if(this.props.isSignedIn !== true){
  			return(
		  		<Grid container justify="center" >
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div className="container-get-registered">
							<p className="text-get-registered">
								Like What You See? We’re Just Getting Started
							</p>	
							<button className=" btn-get-registered" onClick={this.handleClickMemberButton} >
								<span className="text-btn">{i18n.t('landing.getMember')}</span>
							</button>					
						</div>						
					</Grid>
				</Grid>
			);
  		}  		

  	}

  	renderResumBlocks(){

  		if(this.state.currentEvent.idEvent == 49){
  			return(<div></div>);
  		}else{
  			return(
	  			<Grid container justify="center" alignItems="center" >
						<Grid item xs={12} >
							<div className="container-main-info-event">
								<Grid container justify="center" alignItems="center" >
									<Grid item xs={12} sm={12} md={12} lg={8}>

										<Grid container justify="center" alignItems="center" >
											<Grid item xs={6} sm={3} md={3} lg={3}>
												<div className="container-where container-item" >
													<p><FontAwesomeIcon icon={['fal', 'map-marker-alt']} /></p>
													{this.state.currentEvent.localizacion}
													{				
														/*					        			
								        				this.state.currentEvent.centros.map( (item, index) => (	
								        					<span key={index}>{item.poblaciones} { index == totalPoblaciones-1 ? '' : ' - '  }</span>
								        				))*/
								        			}										
												</div>											
											</Grid>
											<Grid item xs={6} sm={3} md={3} lg={3}>
												<div className="container-where container-item" >
													<p><FontAwesomeIcon icon={['fal', 'calendar-alt']} /></p>
													<span>{this.state.currentEvent.fechaText}</span>
												</div>
											</Grid>
											<Grid item xs={6} sm={3} md={3} lg={3}>
												<div className="container-second-col container-item" >
													<p><FontAwesomeIcon icon={['fal', 'cars']} /></p>
													<span>{this.state.currentEvent.unidades}</span>
												</div>
											</Grid>
											<Grid item xs={6} sm={3} md={3} lg={3}>
												<div className="container-second-col container-item" >
													<p><FontAwesomeIcon icon={['fal', 'hand-holding-usd']} /></p>
													<span>{this.state.currentEvent.descuentos}</span>
												</div>
											</Grid>
										</Grid>	
									</Grid>
								</Grid>
							</div>
						</Grid>
					</Grid>

	  		);
  		}

  		
  	}

  	renderHeaderLanding(idEvent, estilTitol){

  		switch(idEvent){  			
  			case '48':
  				return(
  				<div>
  				<Grid container justify="center" >
					<Grid item xs={12}>	
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} >						
						<div className = "main-container-info">
							<div className="back-image-main-container" style={{ backgroundImage: `url(${this.state.currentEvent.imagenBack})`}}>
							</div>								
							<div className="incritos-container-mb">
								<div className="inscrito-main-container">
									<div className="number-inscritos-container">
										{this.state.inscritos}
									</div>
									<div className="inscrito-text-container">
										{i18n.t('landing.enrolled')}
									</div>
								</div>														
							</div>
							<div className="container-top-info">							
								<img className="main-car-image" src={this.state.currentEvent.imagen} />
							</div>
						</div>
						<div className="container-block-info-mp">
							<img src="https://media.motorprive.com/campaigns/48/info-motorprive.jpg" />
						</div>
					</Grid>					
				</Grid>
  				<Grid item xs={12} sm={12} md={12} lg={12}>
					<div className="container-fluid-btn-interested">
						<Grid container justify="center" >
							<Grid item xs={12} sm={10} md={8} lg={8}>
								<div className="container-btn-advice">
									<button className="btn1 btn-interested-top" onClick={this.handleClickOpenTop } >
										<span className="text-btn">{i18n.t('landing.inscribirme')}</span>
									</button>
								</div>								
							</Grid>
						</Grid>
					</div>
				</Grid>
				</div>

  					);
  			break;
  			
  			case '47':
  				return(
  				<div>
  				<Grid container justify="center" >
					<Grid item xs={12}>	
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} >						
						<div className = "main-container-info">
							<div className="back-image-main-container" style={{ backgroundImage: `url(${this.state.currentEvent.imagenBack})`}}>
							</div>								
							<div className="incritos-container-mb">
								<div className="inscrito-main-container">
									<div className="number-inscritos-container">
										{this.state.inscritos}
									</div>
									<div className="inscrito-text-container">
										{i18n.t('landing.enrolled')}
									</div>
								</div>														
							</div>
							<div className="container-top-info">							
								<img className="main-car-image" src={this.state.currentEvent.imagen} />
							</div>
						</div>
						<div className="container-block-info-mp">
							<img src="https://media.motorprive.com/campaigns/47/info-motorprive-smart.jpg" />
						</div>
					</Grid>					
				</Grid>
  				<Grid item xs={12} sm={12} md={12} lg={12}>
					<div className="container-fluid-btn-interested">
						<Grid container justify="center" >
							<Grid item xs={12} sm={10} md={8} lg={8}>
								<div className="container-btn-advice">
									<button className="btn1 btn-interested-top" onClick={this.handleClickOpenTop } >
										<span className="text-btn">{i18n.t('landing.inscribirme')}</span>
									</button>
								</div>								
							</Grid>
						</Grid>
					</div>
				</Grid>
				</div>

  					);
  			break;
  			
  			case '50':
  				return(
  				<div>
  				<Grid container justify="center" >
					<Grid item xs={12}>	
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} >						
						<div className = "main-container-info">
							<div className="back-image-main-container" style={{ backgroundImage: `url(${this.state.currentEvent.imagenBack})`}}>
							</div>								
							<div className="incritos-container-mb">
								<div className="inscrito-main-container">
									<div className="number-inscritos-container">
										{this.state.inscritos}
									</div>
									<div className="inscrito-text-container">
										{i18n.t('landing.enrolled')}
									</div>
								</div>														
							</div>
							<div className="container-top-info">							
								<img className="main-car-image" src={this.state.currentEvent.imagen} />
							</div>
						</div>
						<div className="container-block-info-mp">
							<img src="https://media.motorprive.com/campaigns/48/info-motorprive.jpg" />
						</div>
					</Grid>					
				</Grid>
  				<Grid item xs={12} sm={12} md={12} lg={12}>
					<div className="container-fluid-btn-interested">
						<Grid container justify="center" >
							<Grid item xs={12} sm={10} md={8} lg={8}>
								<div className="container-btn-advice">
									<button className="btn1 btn-interested-top" onClick={this.handleClickOpenTop } >
										<span className="text-btn">{i18n.t('landing.inscribirme')}</span>
									</button>
								</div>								
							</Grid>
						</Grid>
					</div>
				</Grid>
				</div>

  					);
  			break;
  			case '55':
  				return(
  				<div>
  				<Grid container justify="center" >
					<Grid item xs={12}>	
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} >						
						<div className = "main-container-info">
							<div className="back-image-main-container" style={{ backgroundImage: `url(${this.state.currentEvent.imagenBack})`}}>
							</div>								
							<div className="incritos-container-mb">
								<div className="inscrito-main-container">
									<div className="number-inscritos-container">
										{this.state.inscritos}
									</div>
									<div className="inscrito-text-container">
										{i18n.t('landing.enrolled')}
									</div>
								</div>														
							</div>
							<div className="container-top-info">							
								<img className="main-car-image" src={this.state.currentEvent.imagen} />
							</div>
						</div>
						<div className="container-block-info-mp">
							<img src="https://media.motorprive.com/campaigns/55/info-motorprive-certified.jpg" />
						</div>
					</Grid>					
				</Grid>
  				<Grid item xs={12} sm={12} md={12} lg={12}>
					<div className="container-fluid-btn-interested">
						<Grid container justify="center" >
							<Grid item xs={12} sm={10} md={8} lg={8}>
								<div className="container-btn-advice">
									<button className="btn1 btn-interested-top" onClick={this.handleClickOpenTop } >
										<span className="text-btn">{i18n.t('landing.inscribirme')}</span>
									</button>
								</div>								
							</Grid>
						</Grid>
					</div>
				</Grid>
				</div>

  					);
  			break;
  			case '58':
  				return(
  				<div>
  				<Grid container justify="center" >
					<Grid item xs={12}>	
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} >						
						<div className = "main-container-info">
							<div className="back-image-main-container" style={{ backgroundImage: `url(${this.state.currentEvent.imagenBack})`}}>
							</div>								
							<div className="incritos-container-mb">
								<div className="inscrito-main-container">
									<div className="number-inscritos-container">
										{this.state.inscritos}
									</div>
									<div className="inscrito-text-container">
										{i18n.t('landing.enrolled')}
									</div>
								</div>														
							</div>
							<div className="container-top-info">							
								<img className="main-car-image" src={this.state.currentEvent.imagen} />
							</div>
						</div>
						<div className="container-block-info-mp">
							<img src="https://media.motorprive.com/campaigns/58/info-motorprive-enero.jpg" />
						</div>
					</Grid>					
				</Grid>
  				<Grid item xs={12} sm={12} md={12} lg={12}>
					<div className="container-fluid-btn-interested">
						<Grid container justify="center" >
							<Grid item xs={12} sm={10} md={8} lg={8}>
								<div className="container-btn-advice">
									<button className="btn1 btn-interested-top" onClick={this.handleClickOpenTop } >
										<span className="text-btn">{i18n.t('landing.inscribirme')}</span>
									</button>
								</div>								
							</Grid>
						</Grid>
					</div>
				</Grid>
				</div>

  					);
  			break;
  			case '65':
  				return(
  				<div>
  				<Grid container justify="center" >
					<Grid item xs={12}>	
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12} >						
						<div className = "main-container-info">
							<div className="back-image-main-container" style={{ backgroundImage: `url(${this.state.currentEvent.imagenBack})`}}>
							</div>								
							
							<div className="container-top-info">							
								<img className="main-car-image" src={this.state.currentEvent.imagen} />
							</div>
						</div>
						<div className="container-block-info-mp">
							<img className="prova" src="https://media.motorprive.com/campaigns/65/info-motorprive-junio.jpg" />
						</div>
					</Grid>					
				</Grid>
  				<Grid item xs={12} sm={12} md={12} lg={12}>
					<div className="container-fluid-btn-interested">
						<Grid container justify="center" >
							<Grid item xs={12} sm={10} md={8} lg={8}>
								<div className="container-btn-advice">
									<button className="btn1 btn-interested-top" onClick={this.handleClickOpenTop } >
										<span className="text-btn">{i18n.t('landing.inscribirme')}</span>
									</button>
								</div>								
							</Grid>
						</Grid>
					</div>
				</Grid>
				</div>

  					);
  			break;
  			default:
  				return(
  				<div>
  					<Grid container justify="center" >
					<Grid item xs={12}>							
						<div className="container-title-custom-landing">							
							<h1 style={estilTitol}>{this.state.currentEvent.nombreEvento}</h1>							
						</div>
					</Grid>

					<Grid item xs={12} sm={12} md={8} lg={8}>						
						<div className = "main-container-info">
							<div className="back-image-main-container" style={{ backgroundImage: `url(${this.state.currentEvent.imagenBack})`}}>
							{/*<div className="back-image-main-container">*/}
								<div className="main-info-event">
									<div className="info-event-container">
										<FontAwesomeIcon icon={['fal', 'calendar-alt']} />
										<div className="date-event-custom-landing">{this.state.currentEvent.fechaText}</div>
										<div className="container-second-info">
											<FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
											<div className="location-event-custom-landing">
												{this.state.currentEvent.localizacion}
												{	/*									        			
							        				this.state.currentEvent.centros.map( (item, index) => (	
							        					<span key={index}>{item.poblaciones} { index == totalPoblaciones-1 ? '' : ' - '  }</span>
							        				))
							        				*/
							        			}
							        		</div>
							        		<div className="brands-event-custom-landing">
							        			{	
								        			this.state.currentEvent.marcas.map( (item, index) => (
								        				<img key={index} src={item.logo} alt={item.nombre} />			        					
							        				))
						        				}
							        		</div>

							        	</div>							        	
									</div>
								</div>								
							</div>	
							<div className="incritos-container">
								<div className="inscrito-main-container">
									<div className="number-inscritos-container">
										{this.state.inscritos}
									</div>
									<div className="inscrito-text-container">
										{i18n.t('landing.enrolled')}
									</div>
								</div>	
								{/*<div className="container-share-inscritos">
									<ShareButton titleCampaign={this.state.currentEvent.nombreEvento} />
								</div>
								*/}						
							</div>

							<div className="container-top-info">							
								<img className="main-car-image" src={this.state.currentEvent.imagen} />
							</div>
						</div>
					</Grid>
					<Grid item xs={12} sm={10} md={8} lg={8}>						
						<div className="container-mobile-info">
							<FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
							<div className="location-event-custom-landing">
								{this.state.currentEvent.localizacion}
								{	/*					        			
			        				this.state.currentEvent.centros.map( (item, index) => (	
			        					<span key={index}>{item.poblaciones} { index == totalPoblaciones-1 ? '' : ' - '  }</span>
			        				))
			        				*/
			        			}
			        		</div>			        		
						</div>					
					</Grid>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<div className="container-fluid-btn-interested">
						<Grid container justify="center" >
							<Grid item xs={12} sm={10} md={8} lg={8}>
								<div className="container-btn-advice">
									<button className="btn1 btn-interested-top" onClick={this.handleClickOpenTop } >
										<span className="text-btn">{i18n.t('landing.inscribirme')}</span>
									</button>
								</div>								
							</Grid>
						</Grid>
					</div>
				</Grid>
				</div>

  					);
  			
  		}

  	}


	render(){

		const { classes } = this.props;
		const totalPoblaciones = this.state.currentEvent.centros.length;
		const { openAlert } = this.state;

		let inscripciones = this.state.currentEvent.fechaCierreInscripciones.split('-');
		let inscriptionsDate = new Date();
		inscriptionsDate.setFullYear(inscripciones[2],inscripciones[1]-1,inscripciones[0]);
		const today = Date.now();
		
		let estil = {};
		let estilTitol = {};
		let classesTemplate = "custom-template-landing-campaign custom-landing ";

		if(this.props.isSignedIn !== true){			
			estil = {
				marginTop: '0px'
			};
			estilTitol = {
				marginTop: '30px'
			};
			classesTemplate = classesTemplate+"public ";
		}else{
			classesTemplate = classesTemplate+"logged ";
		}

		let classesPopUp = "";

		if((this.state.currentEvent.idEvent == 48)||(this.state.currentEvent.idEvent == 58)||
			(this.state.currentEvent.idEvent == 47)||(this.state.currentEvent.idEvent == 50)||
			(this.state.currentEvent.idEvent == 65)){
			classesTemplate = classesTemplate+" mercedes-benz-template";
			classesPopUp = " mercedes-benz-template-dialog";
		}

		if(this.state.currentEvent.idEvent == 55){
			classesTemplate = classesTemplate+" mercedes-benz-template certified-template";
			classesPopUp = " mercedes-benz-template-dialog";
		}



		return(
			<div className={classesTemplate} style={estil}>


				{this.renderHeaderLanding(this.state.currentEvent.idEvent, estilTitol)}
				
				<LlamameTopContainer 
								textButton={i18n.t('landing.callMeFree')} 
								textLabel={i18n.t('landing.callUsweCallYou')} 
								iconText="" 
								iconRight=""
								textTlf = "93 172 78 93"
								idEvent={this.state.currentEvent.idEvent}
								sourceUrl={this.state.sourceUrl}
				/>
				
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<div className="container-fluid-countdown">
						<Grid container justify="center" >
							<Grid item xs={10} sm={6} md={5} lg={4}>			
								<CountDown dataFinal={this.state.currentEvent.fechaCierreInscripciones} />	
							</Grid>
						</Grid>
					</div>
				</Grid>
				
				{this.renderResumBlocks()}
				
				{/*
				<Grid container justify="center" alignItems="center" >
					<Grid item xs={12} >
						<div className="container-main-info-event">
							<Grid container justify="center" alignItems="center" >
								<Grid item xs={12} sm={12} md={12} lg={8}>
									<Grid container justify="center" alignItems="center" >
										<Grid item xs={11} sm={11} md={6} lg={6}>
											<div className="container-negociador">
												<img src={this.state.currentEvent.imagenNegociador} className="img-negociador" />												
												<div className="negociador-talk" dangerouslySetInnerHTML={{__html: this.state.currentEvent.negociador}}>
													
												</div>
											</div>
										</Grid>
									</Grid>	
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Grid>
				*/}

				<Grid container justify="center" >
					{/*
					<Grid item xs={12}>
						<div className="container-sep">

						</div>
					</Grid>
					*/}
					<Grid item xs={12}>
						<div className="mobile-container-title-custom-landing">							
							<p className="landing-title-content">{this.state.currentEvent.nombreEvento} </p>							
							
							<h2>
								<FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
								<span>{this.state.currentEvent.localizacion}</span>
								{									        			
			        				/*this.state.currentEvent.centros.map( (item, index) => (	
			        					<span key={index}>{item.poblaciones} { index == totalPoblaciones-1 ? '' : ' - '  }</span>
			        				))
			        				*/
			        			}
							</h2>
							
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div className="container-fluid-slogan">
							{this.renderFirstBlock(this.state.currentEvent.idEvent)}
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div className="container-fluid-btn-interested" id="call-me">
							<Grid container justify="center" >
								<Grid item xs={12} sm={10} md={8} lg={8}>
									<div className="container-btn-advice">
										<button className="btn1 btn-interested" style={{animation: 'none'}} onClick={this.handleClickOpenMiddle} >
											<span className="text-btn">¡{i18n.t('landing.saveDate')}!</span>
										</button>
									</div>
									<LlamameButton 
										textButton={i18n.t('landing.callMeFree')} 
										textLabel={i18n.t('landing.weCallYou')} 
										iconText="" 
										iconRight=""
										idEvent={this.state.currentEvent.idEvent}
										sourceUrl={this.state.sourceUrl}
									/>
								</Grid>
							</Grid>
						</div>
					</Grid>
				</Grid>

				{/*this.renderGetMember()*/}
				
				{/*
				<Grid container justify="flex-end" >
					<Grid item xs={4} sm={4} md={4} lg={4}>
						<div style={{backgroundColor: '#e2f9f5', borderTopLeftRadius: '34px', borderBottomLeftRadius: '34px', minHeight: '20px', width: '100%'}}>
							
						</div>
					</Grid>
				</Grid>
				<Grid container justify="flex-end" >
					<Grid item xs={3} sm={3} md={3} lg={3}>
						<div style={{backgroundColor: '#bde4dd', borderTopLeftRadius: '34px', borderBottomLeftRadius: '34px', minHeight: '20px', width: '100%'}}>
							
						</div>
					</Grid>
				</Grid>
				<Grid container justify="flex-end" >
					<Grid item xs={2} sm={2} md={2} lg={2}>
						<div style={{backgroundColor: '#f3fffd', borderTopLeftRadius: '34px', borderBottomLeftRadius: '34px', minHeight: '20px', width: '100%'}}>
							
						</div>
					</Grid>
				</Grid>
				*/}
				<Grid container justify="center" >
					{/*
					<Grid item xs={12}>
						<div className="container-sep-bottom">

						</div>
					</Grid>
					*/}
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div className="container-fluid-description">
							{this.renderSecondBlock(this.state.currentEvent.idEvent,classes)}
									
						</div>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<div className="container-fluid-btns">
							<div className="container-btn-advice">
								<button className="btn1 btn-interested" onClick={this.handleClickOpenBottom} >
									<span className="text-btn">{i18n.t('landing.inscribirme')}</span>
								</button>
							</div>
							
							<div style={{textAlign: 'center'}}>
								<ShareButton titleCampaign={this.state.currentEvent.nombreEvento} />
							</div>
							<div style={{ textAlign: 'center' }}>
								<LlamameButton 
										textButton={i18n.t('landing.callMeFree')} 
										textLabel={i18n.t('landing.weCallYou')} 
										iconText="" 
										iconRight=""
										idEvent={this.state.currentEvent.idEvent}
										sourceUrl={this.state.sourceUrl}
									/>
							</div>
						</div>
					</Grid>
					
					{/*
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<FAQSBlock/>
					</Grid>
					*/}
				</Grid>
				<div className={`container-top-button ${this.state.styleUpButton}`}>
					<span onClick={ () => { this.scrollToTop(); }}><FontAwesomeIcon icon={['fal', 'chevron-up']} /></span>
				</div>
				<div className="container-btn-interested-custom">
					<div className="container-interested">
						{/*
						<div className="name-interested">
							<img src={this.state.currentEvent.imagenNegociador} style={{width: '50px', borderRadius: '30px'}} />
							<div className="event-seller-tell">{this.state.currentEvent.fraseNegociador}</div>
						</div>
						*/}
						<div className="name-interested">
							<div className="event-name">{this.state.currentEvent.nombreEvento}</div>
							<div className="event-date-final"><FontAwesomeIcon icon={['fal', 'calendar-alt']} />{this.state.currentEvent.fechaText}</div>
							<div className="event-location">								
								<FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
								<span>{this.state.currentEvent.localizacion}</span>
								{		
									/*        			
			        				this.state.currentEvent.centros.map( (item, index) => (	
			        					<span key={index}>{item.poblaciones} { index == totalPoblaciones-1 ? '' : ' - '  }</span>
			        				))
			        				*/
								}
							</div>
							{/*
							<div className="event-brands">
								{	
				        			this.state.currentEvent.marcas.map( (item, index) => (
				        				<img key={index} src={item.logo} alt={item.nombre} />			        					
			        				))
		        				}
							</div>
							*/}
						</div>
						
						<button className="btn1 btn-interested-bar" onClick={this.handleClickOpenBar} >
							<span className="text-btn">{i18n.t('landing.saveDate')}  »</span>
						</button>
					</div>
				</div>

				<Dialog
			        open={this.state.openForm}
			        onClose={this.handleClose}
			        maxWidth='sm'
			        classes={{ paper: classes.dialogPaper }}
			        fullWidth={true}
			        aria-labelledby="form-dialog-title"
			        className={classesPopUp}
		        >
		        	<DialogTitle id="form-dialog-title" onClose={this.handleClose}>{i18n.t('landing.formInscription')}</DialogTitle>
		        	{/*<form className="form-more-info" onSubmit={ this.props.handleSubmit(this.sendLead) }  >*/}
		        	
		        	<form className="form-more-info" onSubmit={ this.props.handleSubmit(this.sendLead) }  >
			        	<DialogContent classes={{root: classes.inicioForm}}>
			        		{inscriptionsDate >= today ? '' : <p className="text-closed-event">{i18n.t('landing.enroll')}</p>
			        		}
			           		<Field name="nombre" onChange={this.handleChangeInput} initialValue={this.state.nombre } component={this.renderInput} label={i18n.t('editAccount.name')} />
							<Field name="email" onChange={this.handleChangeInput} initialValue={this.state.email} component={this.renderInput} label={i18n.t('editAccount.email')}  />			
							<Field name="tlf" onChange={this.handleChangeInput} initialValue={this.state.tlf} component={this.renderInput} label={i18n.t('editAccount.tlf')}  />			
							{/*this.renderSelectRegion()*/}
							{this.renderAcceptPrivacity()}
							{this.renderConfirmPolicies()}		
							{this.renderErrorPrivacity()}				
				        </DialogContent>
				        <DialogActions classes={{root: classes.inicioBtn}}>
				            <button  className="btn1 btn-lead">
				            	{i18n.t('landing.send')}				            	
				            </button>			            
				        </DialogActions>
				        {this.renderLoader()}				        
			        </form>
		        </Dialog>


		        {/*<Dialog
			        open={this.state.openRegisterPopup}
			        onClose={this.handleCloseRegister}
			        maxWidth='sm'
			        classes={{ paper: classes.registerDialogPaper }}
			        fullWidth={false}
			        aria-labelledby="register-dialog-title"
		        >
		        	<DialogTitle id="register-dialog-title" classes={{root: classes.titleRegisterPopup}} onClose={this.handleCloseRegister}></DialogTitle>
		        	
		        	
		        	
		        	<DialogContent classes={{root: classes.contentRegisterPopup}}>
		        		Like What You See? We’re Just Getting Started!!!		
			        </DialogContent>
			        <DialogActions classes={{root: classes.footerRegisterPopup}}>
			            <button  className="btn-register-popup" onClick={this.handleClickMemberButton} >
			            	Quiero ser miembro				            	
			            </button>			            
			        </DialogActions>  
		        </Dialog>
		    	*/}
		        <Snackbar
			          anchorOrigin={{  vertical: 'bottom', horizontal: 'right' }}
			          open={openAlert}
			          onClose={this.closeAlert}			         
			          ContentProps={{			            
			            classes: {
                        	root: classes.root
                    	}
			          }}
			          autoHideDuration={1500}
			          message={ i18n.t(this.state.operacioOk) }			          
			    />
			</div>
		);
		

		
	}

}

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
/*
function submitFormulari(values) {
	const errors = {};
	const requiredFields = [	    
	    'nombre',
	    'email',	    
	    'tlf'	    
  	];

  return sleep(1000).then(() => {
    // simulate server latency
    if(!values.nombre){
    	throw new SubmissionError({
        	nombre: i18n.t('errorForms.required'),
        	_error: 'Login failed!'
     	 })
    }else if(!values.email){
    	throw new SubmissionError({
        	email: i18n.t('errorForms.required'),
        	_error: 'Login failed!'
     	 })
    }else if ( values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) ) {    	
    	throw new SubmissionError({
        	email: i18n.t('errorForms.invalidemail'),
        	_error: 'Login failed!'
     	 })
    }else if(!values.tlf ){
    	throw new SubmissionError({
        	tlf: i18n.t('errorForms.required'),
        	_error: 'Login failed!'
     	 })
    }

  })
}


const validate = (formValues) => {

	const errors = {};
	const requiredFields = [	    
	    'nombre',
	    'email',	    
	    'tlf'	    
  	];

  	 	
  	requiredFields.forEach(field => {
  		//console.log('validate');
  		//console.log(formValues[field]);
	    if (!formValues[field]) {
	      errors[field] = i18n.t('errorForms.required');
	    }
  	})

  	if ( formValues.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email) ) {
    	errors.email = i18n.t('errorForms.invalidemail');
  	}


	return errors;

};
*/

const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		selectedLanguage: state.auth.selectedLanguage,
		userId: state.auth.userId, 
		userMail: state.auth.userMail, 
		userName: state.auth.userName,
		utm_source : state.auth.utm_source
	};
};

const stylesDialog  = theme => ({
    dialogPaper: {       
        [theme.breakpoints.down('xs')]: {
     		margin: '20px',
    	}        
    },    
    inicioForm: {
    	[theme.breakpoints.down('xs')]: {
     		paddingTop: '0px !important',
    	} 
    },
    inicioBtn: {
    	[theme.breakpoints.down('xs')]: {
     		margin: '10px 0',
    	} 
    },
    registerDialogPaper:{
    	backgroundColor: '#00009A',
    	[theme.breakpoints.down('xs')]: {
     		margin: '20px',
    	} 
    },
    titleRegisterPopup: {
    	backgroundColor: '#00009A',
    	padding: '30px',
    	borderBottom: '0px',
    },
    contentRegisterPopup: {
    	backgroundColor: '#00009A',
    	color: '#fff',
    	fontSize: '18px',
    	textAlign: 'center',
    	padding: '20px',
    },
    footerRegisterPopup: {
    	fontSize: '16px',
    	backgroundColor: '#00009A',
    	margin: '40px',
    	
    },
    head: {
	    backgroundColor: theme.palette.common.black,
	    color: theme.palette.common.white,
	    textAlign: 'center',
	    padding: '10px'
	},
	cellPrice: {
		textAlign: 'center',
		padding: '10px'
	},
	hideMobile: {
		[theme.breakpoints.down('xs')]: {
     		display: 'none',
    	}
	},
	headHide: {
	    backgroundColor: theme.palette.common.black,
	    color: theme.palette.common.white,
	    textAlign: 'center',
	    padding: '10px',
	    [theme.breakpoints.down('xs')]: {
     		display: 'none',
    	}
	},
	cellPriceHide: {
		textAlign: 'center',
		padding: '10px',
		[theme.breakpoints.down('xs')]: {
     		display: 'none',
    	}
	},
	
});


AutobeltranLandingView = withStyles(stylesDialog)(AutobeltranLandingView);


AutobeltranLandingView = reduxForm({
  form: 'InterestedInCampaign',
  enableReinitialize: true,
  /*validate: validate */
  
})(AutobeltranLandingView);


AutobeltranLandingView = connect(
	mapStateToProps,
	{ sendLeadNoLogged, sendLeadUser, getUserData, setCurrentPage }
)(AutobeltranLandingView);



export default withNamespaces()(AutobeltranLandingView);


