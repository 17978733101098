import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

//import postsReducer from './postsReducer';
import authReducer from './authReducer';
import filtersReducer from './filtersReducer';
import steppersReducer from './steppersReducer';

/*
const selectedLanguageReducer = (selectedLanguage = 'es', action) => {

	if(action === 'LANGUAGE_SELECTED'){
		return action.payload;
	}
	return selectedLanguage;

};
*/

export default combineReducers({

	auth: authReducer,
	/*events: postsReducer,*/	
	form: formReducer,
	searchFilters: filtersReducer,
	steppers: steppersReducer
});
