import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import {  signOut } from '../../actions/';
import history from '../../history';

import { withStyles } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Hidden from "@material-ui/core/Hidden";
import Divider from '@material-ui/core/Divider';
import Drawer from "@material-ui/core/Drawer";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import LlamamePopUp from '../LlamamePopUp.jsx';

import { authFirebase } from '../Firebase/Firebase.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProfileSelectOptions from './ProfileSelectOptions.jsx';

import headerAuthStyle from '../../assets/styles/components/HeaderAuthStyle.jsx';



class HeaderAuth extends React.Component{

	constructor(props) {
	    super(props);
	   	this.state = {
	    	mobileOpen: false,
	    	open: false,
	    	isLanding: false,
	    	currentEvent: 0
	    };
	    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
	    this.handleClick = this.handleClick.bind(this);

	    /*
	    let currentUrl = window.location.href;

	    if(currentUrl.includes("campaigns/") === true){    	

	    	let separat = currentUrl.split("campaigns/");  
	    	if(separat[1].length > 0){
	    		this.state.isLanding = true;
	    		console.log('es landing pq longitud més gran');

	    		let cadenaLanding = separat[1].split('-');

	    		const idCampaign = cadenaLanding[cadenaLanding.length-1];

	    		this.state.currentEvent = idCampaign;
	    		console.log('idEvent-->'+idCampaign);

	    	}else{
	    		console.log('és 0 això vol dir ke no es landing');
	    		//this.state.isLanding = false;
	    	}

	    }else{
	    	console.log('NO CONTE CAMPAIGNS');
	    	//this.state.isLanding = false;
	    }
	    */


	}

	handleDrawerToggle() {
    	this.setState({ mobileOpen: !this.state.mobileOpen });
  	}

  	handleClick(){
  		this.setState({ open: !this.state.open });
  	}

  	optionMenuClicked = (path) => {
  		
  		this.handleDrawerToggle();
  		history.push(path);
  		
  	};

  	onLogOutClick = () => {
  		
  		authFirebase.signOut()
		.then(() => {
  			// Sign-out successful.  			
  			this.props.signOut();
  			history.push('/users/authentication');
		}).catch(function(error) {
  			// An error happened.
  			console.log(error);
		});

  	};

  	renderEmpezamos(totalSteps) { 

  		return(
  			<div>
	  			<Divider />
				<ListItem 	
	        			button key="ventas" 
	        			disableRipple={true } 
	        			classes={{ root: this.props.classes.mobileMenuOption }}
	        			onClick={() => this.optionMenuClicked('/users/steps')}
	        	>
		        	<span className="option-mobile-icon">
	            		<ListItemIcon>
	            			<Badge badgeContent={totalSteps+'/4'} color="secondary">
	            				<FontAwesomeIcon icon={['far', 'list']} />
	            			</Badge>
	            		</ListItemIcon>		
	            		<span className="option-mobile-text">{i18n.t('menu.start')}</span>
	            	</span>			                
	        	
	      		</ListItem>
	      	</div>
  		);
  	}

  	renderButton() {

  		if(this.props.currentPage === 'landing'){

  			let currentUrl = window.location.href;

	    	let separat = currentUrl.split("campaigns/"); 

	    	if(separat[1].length > 0){
	    		this.state.isLanding = true;
	    		let cadenaLanding = separat[1].split('-');
				const idCampaign = cadenaLanding[cadenaLanding.length-1];
	    		this.state.currentEvent = idCampaign;
	    	}
		    

  			return(
  					<LlamamePopUp 
							textButton={i18n.t('landing.callMeFree')} 
							textLabel={i18n.t('landing.weCallYou')} 
							iconText="" 
							iconRight=""
							idEvent={this.state.currentEvent}
							sourceUrl={window.location.href} /> 
  				);

  		}else{
  			return(<div></div>);
  		}
  		
  	}

	render(){

		const { classes } = this.props;

		const totalSteps = (this.props.stps.match(/1/g) || []).length;

		return(
			<div className={`${classes.root} header-auth-container`}>
		      <AppBar position="fixed">
		        <Toolbar className="container-header">
		        	<Hidden mdUp>
			          	<IconButton 
			          			color="inherit"  
			          			disableRipple={true } 
			          			aria-label="Menu"
			          			onClick={this.handleDrawerToggle}
			          	>
			            	<FontAwesomeIcon icon={['fal', 'bars']} />
			        	</IconButton>
			        </Hidden>
		        	<div className={ `logo-header ${classes.grow}` }>
		        		<Link to="/campaigns"><img src="https://media.motorprive.com/logos/motor-prive-white-logo.png" className="logo-header-auth" alt="motorPrive" /></Link>
		        	</div>   
		        	<Hidden mdUp>
		        		{this.renderButton()}
		        	</Hidden>   	
		        	<Hidden smDown>
		        		{/*
		        		<div className="banner-header-container">
		        			<img src="https://cdn.mediagach.com/motor-privee/banner-superior.jpg" alt="bar-banner" />
		        		</div>
		        		*/}

		        		<Link to="/campaigns" className="menu-option">{i18n.t('menu.currentSales')}</Link>
		        		{ totalSteps == 4 ? '' :  <Link to="/users/steps" className="menu-option"><Badge badgeContent={totalSteps+'/4'} color="secondary">{i18n.t('menu.start')}</Badge></Link>  }
		        		
		        		{/*<Link to="/users/alerts" className="menu-option">{i18n.t('menu.interests')}</Link>*/}
		        		<ProfileSelectOptions />	
		        	</Hidden>	          
		        </Toolbar>

		        <Hidden mdUp implementation="css">
		        	<Drawer
			            variant="temporary"
			            anchor={"left"}
			            open={this.state.mobileOpen}				            
			            onClose={this.handleDrawerToggle}
			            classes={{			            	
					        paper: this.props.classes.menuMobileContainer					        
					    }}
			        >
			            <div className="container-drawer">
			            	<div className={this.props.classes.drawerHeader}>
			                	<IconButton 
			                			classes={{ root: this.props.classes.iconCloseMobileMenu }}
			                			onClick={this.handleDrawerToggle}
			                	>
			                    	<FontAwesomeIcon icon={['far', 'chevron-left']} /><span className="drawer-logo"><img src="https://media.motorprive.com/logos/motor-prive-black-logo.png" alt="motorPrive" style={{width: '140px', marginTop: '10px', marginLeft: '10px'}} /></span>
			                  	</IconButton>
            				</div>
			            	<Divider />			            	
			              	<List>
			              		<ListItem 	button 
			              					onClick={this.handleClick} 
			              					disableRipple={true} 
			              					classes={{ root: this.props.classes.mobileMenuOption }}
			              		>
			              			<span className="option-mobile-icon">
				              			<ListItemIcon>
				              				<FontAwesomeIcon icon={['fal', 'user-alt']} />
				              			</ListItemIcon>
				              			<span className="option-mobile-text">{this.props.userName}</span>
				              			{this.state.open ? <FontAwesomeIcon icon={['fal', 'chevron-up']} /> : <FontAwesomeIcon icon={['fal', 'chevron-down']} />}
			              			</span>
			              		</ListItem>
			              		<Divider />
			              		<Collapse in={this.state.open} timeout="auto" unmountOnExit>
						          	<List component="div" disablePadding>
						            	<ListItem 	button 
						            				classes={{ root: this.props.classes.nested }} 
						            				disableRipple={true } 
						            				onClick={() => this.optionMenuClicked('/users/edit-account')}

						            	>	
						            		<span className="option-mobile-icon">
							            		<ListItemIcon>							            			
							            			<FontAwesomeIcon icon={['fal', 'user-edit']} />
							            		</ListItemIcon>					            		
							             		<span className="option-mobile-text">{i18n.t('menu.edit')}</span>
							             	</span>
						            	</ListItem>						            					            	
						         	 </List>
						         	 <Divider />
						        </Collapse>
						        <ListItem 	
						        			button key="ventas" 
						        			disableRipple={true } 
						        			classes={{ root: this.props.classes.mobileMenuOption }}
						        			onClick={() => this.optionMenuClicked('/campaigns')}
						        >
						        	<span className="option-mobile-icon">
					            		<ListItemIcon>
					            			<FontAwesomeIcon icon={['fal', 'car-side']} />
					            		</ListItemIcon>		
					            		<span className="option-mobile-text">{i18n.t('menu.currentSales')}</span>
					            	</span>			                
				                	{/* <ListItemText primary="Ventas" classes={{ root: this.props.classes.mobileMenuOption }} /> */}
				              	</ListItem>
				              	{ totalSteps == 4 ? '' : this.renderEmpezamos(totalSteps) }
				            	
				            	<Divider />
				            	<ListItem 	button key="misintereses" 
				            				onClick={() => this.optionMenuClicked('/users/alerts')} 
				            				disableRipple={true } 
				            				classes={{ root: this.props.classes.mobileMenuOption }}
				            	>
				            		<span className="option-mobile-icon">
					            		<ListItemIcon>
					            			<FontAwesomeIcon icon={['fal', 'heart']} />
					            		</ListItemIcon>		
					            		<span className="option-mobile-text">{i18n.t('menu.interests')}</span>			                
					                	
					                </span>
				              	</ListItem>
				              	<Divider />
				            	<ListItem 	button key="cerrar-sesion" 
				            				onClick={this.onLogOutClick} 
				            				disableRipple={true } 
				            				classes={{ root: this.props.classes.mobileMenuOption }}
				            	>
				            		<span className="option-mobile-icon">
					            		<ListItemIcon>
					            			<FontAwesomeIcon icon={['fal', 'sign-out']} />
					            		</ListItemIcon>		
					            		<span className="option-mobile-text">{i18n.t('menu.logout')}</span>			                
					                	
					                </span>
				              	</ListItem>
				              	<Divider />
				          	</List>
			            </div>
		          </Drawer>
		        </Hidden>
		      </AppBar>
		    </div>
			
		);
	}

}



const mapStateToProps = (state) => {
	return {
		isSignedIn: state.auth.isSignedIn,
		userName: state.auth.userName,
		stps: state.auth.stps,
		currentPage: state.auth.page
	};
};

HeaderAuth = withStyles(headerAuthStyle)(withNamespaces()(HeaderAuth));

HeaderAuth = connect(
	mapStateToProps, 
	{ signOut }
)(HeaderAuth);

export default HeaderAuth;
