import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { fetchEventsBlock } from '../actions/';
import CustomLink from './CustomLink.jsx';


class VentasBlock extends React.Component{


	constructor(props) {
		super(props);

		this.state = {	      
	      requestSent: false,
	      currentEvents: [],
	      totalCurrentEvents: 0,
	      futureEvents: [],
	      totalFuturEvents: 0,	      
	      finalPage: false,
	      noResults: true,
	      operacioOk: '',
	      idVenta: this.props.idVenta
	      
	    };	    

	}

	componentDidMount() {
		this.loadEvents();
	}

	loadEvents(){				
		
		this.props.fetchEventsBlock(this.state.idVenta, this.props.selectedLanguage )
	      .then(
	        (result) => {	  
	        	
	        	//console.log('hem rebut');
	        	//console.log(result);
	        	
	        	if(result.totalEvents == 0){
	        		this.setState({
		            	requestSent: true,	          
		            	currentEvents: [],  
		            	finalPage: result.final,
		            	noResults: true,
		            	totalCurrentEvents: 0						
		          	});
	        	}else{
	        		this.setState({
		            	requestSent: true,	          
		            	currentEvents: result.events, 
		            	totalCurrentEvents: result.totalEvents,		            	 
		            	finalPage: result.final,
		            	noResults: false	            
		          	});
	        	}	 
	        	       		         
	        },	       
	        (error) => {
	          this.setState({
	            requestSent: true,
	            error
	          });
	        }
	      );
	}

	styleImage = function(urlImage){

		const variable = 'url(' + urlImage + ')';
		return({
			backgroundImage: variable
		}
		);
	}

	renderListEvent(currentEvents, noResults, totalEvents, totalFuturEvents, futureEvents){



		if(noResults){
			return(
				<div className="container-no-results">

				</div>
			);
		}else{

			
			return(
				<div className="">
					<Grid container justify="center" alignItems="center" >
						<Grid item xs={12}>
							<h3 style={{textAlign: 'center', color: '#fff'}}>{i18n.t('global.otherCampaigns')}</h3>
						</Grid>
						<Grid item xs={6} sm={4} md={3} lg={2}>		
							<div className="block-container-seller">					
								<a onClick={ () => { window.gtag('event', 'click-block-campaigns-bottom'); } } href={ currentEvents[0]['tipoLanding'] == 3 ?  currentEvents[0]['url'] : currentEvents[0]['urlLocal']} target={ currentEvents[0]['tipoLanding'] == 3 ? '_blank' : '' } >
									<div className="container-wrapper-all" >		        						
			        					<div className="container-img-seller" style={this.styleImage(currentEvents[0]['imagenListado'])}>
			        						{/*
			        						<div className="container-brands">
				        						{	
								        			currentEvents[0]['marcas'].map( (item, index) => (
								        				<img key={index} src={item.logo} alt={item.nombre} />			        					
							        				))
							        			}
						        			</div>
						        			*/}
						        			
			        					</div>
			        					<div className="more-container">{i18n.t('landing.seeMore')}</div>
			        				</div>
		        					<div className="container-txt-seller">
		        						<h3>{currentEvents[0]['nombreEvento']}</h3>
		        					</div>				        					   					
		        				</a>
	        				</div>
						</Grid>
						<Grid item xs={6} sm={4} md={3} lg={2}>		
							<div className="block-container-seller">					
								<a onClick={ () => { window.gtag('event', 'click-block-campaigns-bottom'); } } href={ currentEvents[1]['tipoLanding'] == 3 ?  currentEvents[1]['url'] : currentEvents[1]['urlLocal']} target={ currentEvents[1]['tipoLanding'] == 3 ? '_blank' : '' } >
									<div className="container-wrapper-all" >
			        					<div className="container-img-seller" style={this.styleImage(currentEvents[1]['imagenListado'])}>
			        						{/*
			        						<div className="container-brands">
				        						{	
								        			currentEvents[1]['marcas'].map( (item, index) => (
								        				<img key={index} src={item.logo} alt={item.nombre} />			        					
							        				))
							        			}
						        			</div>
						        			*/}
			        					</div>
			        					<div className="more-container">{i18n.t('landing.seeMore')}</div>
			        				</div>
		        					<div className="container-txt-seller">
		        						<h3>{currentEvents[1]['nombreEvento']}</h3>
		        					</div>				        					   					
		        				</a>
	        				</div>
						</Grid>
						<Hidden xsDown>
							<Grid item xs={6} sm={4} md={3} lg={2}>		
								<div className="block-container-seller">					
									<a onClick={ () => { window.gtag('event', 'click-block-campaigns-bottom'); } } href={ currentEvents[2]['tipoLanding'] == 3 ?  currentEvents[2]['url'] : currentEvents[2]['urlLocal']} target={ currentEvents[2]['tipoLanding'] == 3 ? '_blank' : '' } >
										<div className="container-wrapper-all" >
				        					<div className="container-img-seller" style={this.styleImage(currentEvents[2]['imagenListado'])}>
				        						{/*
				        						<div className="container-brands">
					        						{	
									        			currentEvents[2]['marcas'].map( (item, index) => (
									        				<img key={index} src={item.logo} alt={item.nombre} />			        					
								        				))
								        			}
							        			</div>
							        			*/}
				        					</div>
				        					<div className="more-container">{i18n.t('landing.seeMore')}</div>
				        				</div>
			        					<div className="container-txt-seller">
			        						<h3>{currentEvents[2]['nombreEvento']}</h3>
			        					</div>				        					   					
			        				</a>
		        				</div>
							</Grid>
						</Hidden>
						<Hidden smDown>
							<Grid item xs={6} sm={4} md={3} lg={2}>		
								<div className="block-container-seller">					
									<a onClick={ () => { window.gtag('event', 'click-block-campaigns-bottom'); } } href={ currentEvents[3]['tipoLanding'] == 3 ?  currentEvents[3]['url'] : currentEvents[3]['urlLocal']} target={ currentEvents[3]['tipoLanding'] == 3 ? '_blank' : '' } >
										<div className="container-wrapper-all" >
				        					<div className="container-img-seller" style={this.styleImage(currentEvents[3]['imagenListado'])}>
				        						{/*<div className="container-brands">
					        						{	
									        			currentEvents[3]['marcas'].map( (item, index) => (
									        				<img key={index} src={item.logo} alt={item.nombre} />			        					
								        				))
								        			}
							        			</div>
							        		*/}
				        					</div>
				        					<div className="more-container">{i18n.t('landing.seeMore')}</div>
				        				</div>
			        					<div className="container-txt-seller">
			        						<h3>{currentEvents[3]['nombreEvento']}</h3>
			        					</div>				        					   					
			        				</a>
		        				</div>
							</Grid>
						</Hidden>
						<Hidden mdDown>
							<Grid item xs={6} sm={4} md={3} lg={2}>		
								<div className="block-container-seller">					
									<a onClick={ () => { window.gtag('event', 'click-block-campaigns-bottom'); } } href={ currentEvents[4]['tipoLanding'] == 3 ?  currentEvents[4]['url'] : currentEvents[4]['urlLocal']} target={ currentEvents[4]['tipoLanding'] == 3 ? '_blank' : '' } >
										<div className="container-wrapper-all" >
				        					<div className="container-img-seller" style={this.styleImage(currentEvents[4]['imagenListado'])}>
				        						{/*
				        						<div className="container-brands">
					        						{	
									        			currentEvents[4]['marcas'].map( (item, index) => (
									        				<img key={index} src={item.logo} alt={item.nombre} />			        					
								        				))
								        			}
							        			</div>
							        			*/}
				        					</div>
				        					<div className="more-container">{i18n.t('landing.seeMore')}</div>
				        				</div>
			        					<div className="container-txt-seller">
			        						<h3>{currentEvents[4]['nombreEvento']}</h3>
			        					</div>				        					   					
			        				</a>
		        				</div>
							</Grid>
						</Hidden>
					{/*
						currentEvents.map( (item, index) => (
							
								<Grid item xs={6} sm={4} md={3} lg={2} key={index}>		
									<div className="block-container-seller">					
										<CustomLink to={item.tipoLanding == 3 ? item.url : ''} idEvent={item.urlLocal} nomClasse={item.tipoEvento == 25 ? 'event-container-premium' : 'event-container-normal'} key={index}>
				        					<div className="container-img-seller" style={this.styleImage(item.imagenListado)}>
				        					</div>
				        					<div className="container-txt-seller">
				        						<p>{item.nombreEvento}</p>
				        					</div>				        					   					
				        				</CustomLink>
			        				</div>
								</Grid>
								
        				))
					*/}

					</Grid>
				</div>
			);
		}

	}


	render(){

		const { currentEvents, noResults, totalCurrentEvents, totalFuturEvents, futureEvents } = this.state;	

		return(
			<div className="container-wrapper-ventas">
				{this.renderListEvent(currentEvents, noResults, totalCurrentEvents, totalFuturEvents, futureEvents)}
			</div>
		);

	}



}


const mapStateToProps = (state) => {
	return { 
		userId: state.auth.userId,
		selectedLanguage: state.auth.selectedLanguage		
	};
};

VentasBlock = connect(
	mapStateToProps, { fetchEventsBlock }
)(VentasBlock);



export default withNamespaces()(VentasBlock);