import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import ResetAuth from '../components/Login/ResetAuth.jsx';

import { setCurrentPage } from '../actions/';

class ForgetPwdView extends React.Component {

	constructor(props) {
		super(props);
	    this.state = {	      
	      currentImage: 0	           
	    };	    
    	this.props.setCurrentPage('forgotPwd');
	}

	render(){

		if(this.props.isSignedIn === null){
			return (<div className="template-loading"><CircularProgress color="secondary" /></div>);
		}

		if(this.props.isSignedIn){
		
			return (<div></div>);
		
		}else{

			return(
				<div className="template-forget-pwd-page">
					<BackgroundContainer currentImage={this.state.currentImage} />
					<Grid container justify="center">
						<Grid item xs={12} sm={10} md={5} lg={4}>
				          <div className="container-block">
				          	<ResetAuth />
				          </div>
				        </Grid>
					</Grid>				
				</div>
			);
		}
	}

}


const BackgroundContainer = ({ currentImage }) => {

	
	/*const images = [
			"https://cdn.mediagach.com/motor-privee/banners/banner-gb-sea.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-03.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-04.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-05.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-06.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-07.jpg",
			"https://cdn.mediagach.com/motor-privee/banner-img-08.jpg"
	];*/

	const images = [
			"https://media.motorprive.com/banners/banner-bg-iceland.jpg",
			"https://media.motorprive.com/banners/banner-bg-driver.jpg",
			"https://media.motorprive.com/banners/banner-bg-beach-curve.jpg"				
	];


	return(
		<div className="container-images-bg">
			{/*<div className="container-background" style={{ backgroundImage: `url(${images[currentImage]})` }}></div>*/}
			<div className="container-background" style={{ backgroundImage: `url(${images[0]})` }}></div>
			<div className="container-background" style={{ backgroundImage: `url(${images[1]})` }}></div>
			<div className="container-background" style={{ backgroundImage: `url(${images[2]})` }}></div>
			
			{/*<div className="container-background-down" style={{ backgroundImage: `url(${images[futurImage]})` }}></div>*/}
		</div>
		
	);

};


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		userName: state.auth.userName
	};
};

ForgetPwdView = connect(
	mapStateToProps, { setCurrentPage }	
)(ForgetPwdView);

export default ForgetPwdView;

