import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
//import Dialog from '@material-ui/core/Dialog';

//import { DialogTitle, DialogContent, DialogActions } from '../../assets/styles/components/AlertNotificationStyle.jsx';

import { fetchBrandsModelsRegions, getUserVehicles, editUserVehicles } from '../../actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const stylesAlert = theme => ({
  	root: {
        background: '#009688'
    },
    snackAlert: {
          background: '#009688'
    },
    dialogPaper: {       
        [theme.breakpoints.down('xs')]: {
        margin: '20px',
      }        
    },
    inicioForm: {
      [theme.breakpoints.down('xs')]: {
        paddingTop: '0px !important',
      } 
    },
    inicioBtn: {
      [theme.breakpoints.down('xs')]: {
        margin: '10px 0',
      } 
    }
});



class VehiclesForm extends React.Component{


	constructor(props) {
    	super(props);
    	this.handleChangeInput = this.handleChangeInput.bind(this);

    	this.state = {
    		brands: [],
	    	modelos: [],
	    	selectedBrand: '',
	    	selectedModel: '',
	        nameVehicle1:'',
	        brandVehicle1:'',
	        matriculaVehicle1: '',
	        yearVehicle1: '',
	        sellVehicle1: 'false',
	        operacioOk: '',
	        openAlert: false 	        
	        /*nameVehicle2:'',
	        brandVehicle2:'',
	        matriculaVehicle2: '',
	        yearVehicle2: '',
	        sellVehicle2: ''	 
	        */      	
	    };   

    }

    componentDidMount(){  

    	
    	this.getVehiclesUser();
    	/*
    	if(this.props.goTo == 'vehicles-form'){
    		document.getElementById(this.props.goTo).scrollIntoView({ behavior: 'smooth'});
    	}
		*/

    }

    handleChangeInput(e){	
		const inputName = e.target.name;
		const inputValue = e.target.value;
		var copiaState = Object.assign({}, this.state);
		copiaState[inputName] = inputValue;
		this.setState(copiaState);  		
	}	

	handleChangeModel = (e) =>{

		this.setState({
			selectedModel: e.target.value
		});

		/*
		console.log('model seleccionat');
		console.log(e.target.value);
		*/
	}

	handleChangeBrand = (e) => {
		
		const idBrandSelected = e.target.value;
		const posicioMarca = _.findIndex(this.state.brands, { 'idMarca': idBrandSelected });

		//console.log('canvi de marca');
		//console.log(idBrandSelected);
		//console.log(posicioMarca);
		//console.log(this.state.brands[posicioMarca].modelos);

		this.setState({
			selectedBrand: idBrandSelected,
			selectedModel: '',
			modelos: this.state.brands[posicioMarca].modelos
		});
	}

	handleChangeSellCar = (e) => {

		/*
		console.log('clickem sell car');
		console.log(e.target.value);
		console.log(e);
		*/
		
		if(e.target.value === 'false'){
			this.setState({
				sellVehicle1: 'true'
			});
		}else{
			this.setState({
				sellVehicle1: 'false'
			});
		}
		
	}

    getVehiclesUser(){

    	this.props.fetchBrandsModelsRegions()
    	.then( (result) => {
    		/*
    		console.log('info vehicles brands');
			console.log(result.marcas);
			*/
    		this.setState({ 
				brands: result.marcas
			});

    		this.props.getUserVehicles(this.props.userId)
			.then( (result) => {
				

				let initData;
				/*if(result === null) console.log('soc null');
				if(result === []) console.log('soc []');
				if(typeof result === 'undefined') console.log('soc buit');
				*/
				if((result.marca != '')&&(typeof result.marca !== 'undefined')){
					const posicioMarca = _.findIndex(this.state.brands, { 'idMarca': result.marca });
					this.setState({
						selectedBrand: result.marca,
				    	selectedModel: result.modelo,
				        matriculaVehicle1: result.matricula,
				        yearVehicle1: result.anyo,
				        sellVehicle1: result.sellCar,
				        modelos: this.state.brands[posicioMarca].modelos
					});
					initData = {
		        		"matriculaVehicle1": result.matricula,
		        		"yearVehicle1": result.anyo,     
		        		"sellVehicle1": result.sellCar,		
		        		"brand": result.marca,
		        		"model": result.modelo
		        	};
					
				}else{

					if(typeof result.marca === 'undefined'){
						initData = {
			        		"matriculaVehicle1": '',
			        		"yearVehicle1": '',     
			        		"sellVehicle1": 'false',		
			        		"brand": '',
			        		"model": ''
			        	};

					}else{
						this.setState({
							selectedBrand: result.marca,
					    	selectedModel: result.modelo,
					        matriculaVehicle1: result.matricula,
					        yearVehicle1: result.anyo,
					        sellVehicle1: result.sellCar
					       
						});
						initData = {
			        		"matriculaVehicle1": result.matricula,
			        		"yearVehicle1": result.anyo,     
			        		"sellVehicle1": result.sellCar,		
			        		"brand": result.marca,
			        		"model": result.modelo
			        	};
					}
					
				}
				

				

				

	            this.props.initialize(initData);

			});

    		//console.log('carreguem marques');
    		//console.log(this.state.brands);
    		//console.log(this.state.modelos);
    	});


    }


    saveUserVehicles = (formValues) => {		

    	console.log(formValues);

		
		this.props.editUserVehicles(formValues, this.props.userId, this.props.userMail)
		.then( result => {

			//console.log('resultats edit user vehicles');
			//console.log(result);	
			this.openAlert(i18n.t('errors.'+result.error));

		} );
		
	}

	renderSelect= ({input, label, meta, initialValue, children}) => {
		return (
			<div className="inputContainer selectContainer">
				<label className="input-label">{label}</label>
				<select {...input} value={initialValue}>
					{children}
				</select>
				{this.renderError(meta)}
			</div>
		);
	}

	renderInput = ({input, label, meta, type, initialValue, disabled}) => {
		return (
			<div className="inputContainer">
				<label className="input-label">{label}</label>				
				<input {...input} autoComplete="off" value={initialValue} placeholder={label} type={type} disabled={disabled} />
				
			</div>
		);
	};

	renderInputPlaceholder = ({input, label, meta, type, placeholder, initialValue, disabled}) => {
		return (
			<div className="inputContainer">
				<label className="input-label">{label}</label>				
				<input {...input} autoComplete="off" value={initialValue} placeholder={placeholder} type={type} disabled={disabled} />
				
			</div>
		);
	};

	renderError({error, touched}){		
		if(touched && error){
			return(
				<div>	
					<div className="error-container"><span className="icon-error"><FontAwesomeIcon icon={['fal', 'exclamation-circle']} /></span>{error}</div>
				</div>
			);
		}
	}

	openAlert = (textMissatge) => {
    	this.setState({ openAlert: true, operacioOk: textMissatge });  
	};

	closeAlert = () => {
		this.setState({ openAlert: false });
	};


	render(){
		const { openAlert } = this.state;
		const { classes } = this.props;


		return(
			<div id="vehicles-form" className="container-parent-vehicles-data">
				<div className="container-title">
					<p className="title-sub-section"><span>{i18n.t('vehiclesUser.myCars')}</span></p>
				</div>
				<div className="container-change-pwd vehicle-form">									
					<form onSubmit={ this.props.handleSubmit(this.saveUserVehicles) } >
						
						<Grid container justify="center">
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Field name="brand" onChange={this.handleChangeBrand} initialValue={this.state.selectedBrand} component={this.renderSelect} label={i18n.t('vehiclesUser.brand')}>
									<option value="" name="no-value" disabled >{i18n.t('vehiclesUser.selectBrand')}</option>
									{this.state.brands.map( (item, i) => (
										<option value={item.idMarca} key={item.idMarca} name={item.nombreMarca}>{item.nombreMarca}</option>
									))}								
								</Field>				
							</Grid>					
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Field name="model" onChange={this.handleChangeModel} initialValue={this.state.selectedModel} component={this.renderSelect} label={i18n.t('vehiclesUser.model')}>
									<option value="" name="no-value" disabled >{i18n.t('vehiclesUser.selectModel')}</option>
									{this.state.modelos.map( (item, i) => (
										<option value={item.idModelo} key={item.idModelo} name={item.nombre}>{item.nombre}</option>
									))}
								</Field>						
							</Grid>
						</Grid>

						<Grid container justify="center">
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Field name="matriculaVehicle1" onChange={this.handleChangeInput} initialValue={ typeof this.state.matriculaVehicle1 === 'undefined' ? '' : this.state.matriculaVehicle1 } component={this.renderInputPlaceholder} placeholder={"xxxxyyyy"} label={i18n.t('vehiclesUser.plate')} />					
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6}>
								<Field name="yearVehicle1" onChange={this.handleChangeInput} initialValue={ typeof this.state.yearVehicle1 === 'undefined' ? '' : this.state.yearVehicle1 } component={this.renderInputPlaceholder} placeholder={"xxxx"} label={i18n.t('vehiclesUser.year')} />					
							</Grid>
						</Grid>
						{/*
						<Grid container justify="center">
							<Grid item xs={12} className="grid-check" >
								<div className="input-checkbox">
									<Field name="sellVehicle1" onChange={this.handleChangeSellCar} id="sellVehicle1" checked={this.state.sellVehicle1 == 'true' ? true: false} component="input" type="checkbox" />
								</div>
								<label>{i18n.t('vehiclesUser.sellCar')}</label>					
							</Grid>
						</Grid>		
						*/}
						<button className="btn btn1 btn-edit-pro">{i18n.t('vehiclesUser.save')}</button>
					</form>

					
					<Snackbar
				          anchorOrigin={{  vertical: 'bottom', horizontal: 'right' }}
				          open={openAlert}
				          onClose={this.closeAlert}			         
				          ContentProps={{			            
				            classes: {
	                        	root: classes.root
	                    	}
				          }}
				          autoHideDuration={2500}
				          message={<span className="snackbar-text-msg"><FontAwesomeIcon icon={['fal', 'check-circle']} />{this.state.operacioOk}</span>}
				          
				    />
					
					{/*
					<Dialog
	                  open={openAlert}
	                  onClose={this.closeAlert}
	                  maxWidth='sm'
	                  classes={{ paper: classes.dialogPaper }}
	                  fullWidth={true}
	                  aria-labelledby="form-dialog-title"
	                >
	                  <DialogTitle id="form-dialog-title" onClose={this.closeAlert}>&nbsp;</DialogTitle>
	                  
	                    <DialogContent classes={{root: classes.inicioForm}}>
	                      <p className="alert-text">{this.state.operacioOk}</p>        
	                    </DialogContent>
	                    <DialogActions classes={{root: classes.inicioBtn}}>
	                        <button  className="btn1 btn-lead" onClick={this.closeAlert}>
	                         {i18n.t('global.accept')}                 
	                        </button>                 
	                    </DialogActions>
	                </Dialog>
					*/}
				</div>
			</div>
		);

	}

}


const mapStateToProps = (state) => {
	return { 
		userId: state.auth.userId, 
		userMail: state.auth.userMail, 
		userName: state.auth.userName,
		selectedLanguage: state.auth.selectedLanguage
	};
};

VehiclesForm = reduxForm({
  form: 'VehiclesForm'
})(VehiclesForm);


VehiclesForm = withStyles(stylesAlert)(VehiclesForm);

VehiclesForm = connect(
	mapStateToProps, 
	{ fetchBrandsModelsRegions, getUserVehicles, editUserVehicles }
)(withNamespaces()(VehiclesForm));



export default VehiclesForm;