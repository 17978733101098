import React from 'react';
import { connect } from 'react-redux';

import history from '../history';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import queryString from 'query-string';

import Grid from '@material-ui/core/Grid';

import CancelAppointmentForm from '../components/Forms/CancelAppointmentForm.jsx';

class CancelDateView extends React.Component {

	
	constructor(props) {

		super(props);	

		if(this.props.location.search == ''){
			this.state = {
				ticketCode: '0',
				explanationText: null
			};
		}else{
			const parametres = queryString.parse(this.props.location.search);
			//console.log(parametres);
			let idTicket, explanation;
			if(parametres.id){
				idTicket = parametres.id;				
			}else{
				idTicket = '0';				
			}

			if(parametres.txt){
				explanation = parametres.txt;
			}else{
				explanation = null;
			}

			this.state = {
				ticketCode: idTicket,
				explanationText: explanation
			};

		}



	}


	renderContent(){

		if(this.state.ticketCode == '0'){
			return(
				<div className="container-block-contact">
				 <p className="title-text">{i18n.t('cancelDate.title')}</p>
				 <p className="error-explanation" dangerouslySetInnerHTML={{__html: i18n.t('cancelDate.errorEdit')}}></p>	
				</div>
			);
		}else{
			return (
				<div className="container-block-contact">
					<p className="title-text">{i18n.t('cancelDate.title')}</p>	
					<p className="text-comments">{i18n.t('cancelDate.yourTicket')}</p>
					<p className="text-code">{this.state.ticketCode}</p>
					<p className="text-explanation">{this.state.explanationText}</p>
					<CancelAppointmentForm codeTicket={this.state.ticketCode} />
				</div>
			);
		}

	}


	render(){

		return(
			<div className="template-page template-edit-date">
              <Grid container justify="center">
					<Grid item xs={11} sm={8} md={6} lg={5}>
						{this.renderContent()}
					</Grid>
				</Grid>
			</div>

		);

	}


}

const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		userName: state.auth.userName
	};
};






CancelDateView = connect(
	mapStateToProps,	
)(withNamespaces()(CancelDateView));

export default CancelDateView;

