import React from 'react';
import { connect } from 'react-redux';

import history from '../history';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import queryString from 'query-string';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import GoogleAuth from '../components/Login/GoogleAuth.jsx';
import FacebookAuth from '../components/Login/FacebookAuth.jsx';
import SignUpAuth from '../components/Login/SignUpAuth.jsx';

import { setCurrentPage } from '../actions/';


class RegisterView extends React.Component{

	constructor(props) {

		super(props);

		if(this.props.location.search == ''){
			
			this.state = {	      		
	        	nombre: '',
	        	email: '',
	        	tlf: '',
	        	missatge: i18n.t('global.messageRegister'),
	        	render: false    
	    	};

	    }else{
	
			const parametres = queryString.parse(this.props.location.search);
			
			let nombre, tlf, email;

			if(parametres.n){					
				nombre = parametres.n;
			}else{					
				nombre = '';
			}

			if(parametres.m){
				email = parametres.m;
			}else{
				email = '';
			}

			if(parametres.t){
				tlf = parametres.t;
			}else{
				tlf = '';
			}
			
			this.state = {	      		
	        	nombre: nombre,
	        	email: email,
	        	tlf: tlf,
	        	missatge: i18n.t('global.messageRegister'),
	        	render: false 		        	
	    	};
				
		}

		this.props.setCurrentPage('signin');

	}

	componentDidMount() {
		window.scrollTo(0,0);
  		setTimeout(function() { 
      		this.setState({render: true}) 
  		}.bind(this), 1000);
  		window.gtag('event', 'gracias-sign-up');
	}

	componentDidUpdate(prevProps) {
    	if(this.props.isSignedIn){
    		history.push('/campaigns');
    	}
    }



	render(){
		if(this.state.render) {
			if(this.props.isSignedIn == null){
				return (<div></div>);
			}

			if(this.props.isSignedIn){
				return (<div></div>);
			}else{
				return(
					<div className="template-sign-up">
						<Grid container justify="center">
							<Grid item xs={11} sm={8} md={6} lg={5}>
								<p className="title-text">{i18n.t('thankyou.thankyou')}</p>
								<div className="container-message-ok">
									<p dangerouslySetInnerHTML={{__html: this.state.missatge}}></p>								
								</div>
							</Grid>
						</Grid>
						<Grid container justify="center">
							<Grid item xs={11} sm={6} md={5} lg={3}>
								<div className="container-sign-up">
									<FacebookAuth btnText={i18n.t('login.loginfacebook')} />
									<GoogleAuth btnText={i18n.t('login.logingoogle')} />
									<div className="separator-with-text">{i18n.t('login.or')}</div>							
									<div className="container-form-sign-up">
										<SignUpAuth nombre={this.state.nombre} email={this.state.email} tlf={this.state.tlf} />
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
				);
			}
		}else{
			return ( <div className="template-loading-thanks"><CircularProgress color="secondary" /></div> );
		}

	}

}



const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		userName: state.auth.userName
	};
};



RegisterView = connect(
	mapStateToProps, { setCurrentPage }	
)(withNamespaces()(RegisterView));

export default RegisterView;




