import jsonMediagach from '../apis/jsonMediagach';
const querystring = require('querystring');

//import firebase, { authFirebase, providerGoogle } from '../components/Firebase/Firebase.js';

export const signIn = (userId, userMail, userName) => {
	
	return {
		type: 'LOG_IN',
		uid: userId,
		uMail: userMail,
		uName: userName
	};
};


export const signOut = () => {	
	return{
		type: 'LOG_OUT'
	};
};

export const getUtmSource = (utmSrc) => {

	return{
		type: 'UTM_SOURCE',
		utmSource: utmSrc
	};

};

export const setCurrentPage = (currentPage) => {

	return{
		type: 'SET_CURRENT_PAGE',
		page: currentPage
	};

};


export const infoLogged = (userId, userMail) => async (dispatch) => {

	const response = await jsonMediagach.post('getUserCredentials.php',
			querystring.stringify({
				uUid: userId,
				uMail: userMail
		}));

	//const response = await jsonMediagach.get(`getUserCredentials.php?uid=${userId}&uMail=${userMail}`);
	dispatch({ type: 'LOGGED', payload: response.data });	

};


export const infoAnonymous = (userId) => {
	return{
		type: 'LOGGED_ANONYMOUS', uid: userId
	};
};


export const createNewUser = (user) => async (dispatch) => {

	
	const response = await jsonMediagach.post('createNewUser.php', 
			querystring.stringify({
				uUid: user.uid,
				uMail: user.mail,
				uNombre: user.nombre,
				uApellido: user.apellido,
				uTlf: user.tlf,
				uPostal: user.codigoPostal,
				uSexo: user.sexo,
				uLanguage: user.language,
				uProvider: user.provider
			
		}));

	/*const response = await jsonMediagach.get('createNewUser.php', {
			params: {
				uUid: user.uid,
				uMail: user.mail,
				uNombre: user.nombre,
				uApellido: user.apellido,
				uTlf: user.tlf,
				uPostal: user.codigoPostal,
				uSexo: user.sexo,
				uLanguage: user.language,
				uProvider: user.provider
			}
		});*/
	dispatch({ type: 'NEW_USER', payload: user });
	return response.data;
};


export const errorLogin = (error) => {
	console.log("entrem a la funcio error login");
	return {
		type: 'LOG_IN_FAIL',
		messageError: error
	};
};


export const editUserData = (userData, uid, uMail) => async (dispatch) => {
	
	const response = await jsonMediagach.post('editUserData.php',
			querystring.stringify({
				uUid: uid,
				uMail: uMail,				
				uNombre: userData.nombre,
				uApellidos: userData.apellidos,
				uPostal: userData.zip,
				uTlf: userData.tlf,
				uSexo: userData.gender,
				uLanguage: userData.idioma,
				uPresupuesto: userData.presupuesto,
				uTiempoCompra: userData.tiempoCompra			
		}));

	/*const response = await jsonMediagach.get('editUserData.php',{
			params: {
				uUid: uid,
				uMail: uMail,				
				uNombre: userData.nombre,
				uApellidos: userData.apellidos,
				uPostal: userData.zip,
				uTlf: userData.tlf,
				uSexo: userData.gender,
				uLanguage: userData.idioma,
				uPresupuesto: userData.presupuesto,
				uTiempoCompra: userData.tiempoCompra
			}
		});*/
	dispatch({ type: 'EDIT_USER_DATA', payload: response.data });
	dispatch({ type: 'EDIT_USER_PURCHASE', payload: response.data });
	
	return response.data;
}

export const editUserPurchase = (userData, uid) => async (dispatch) => {
	
	const response = await jsonMediagach.post('editUserPurchase.php',
			querystring.stringify({
				uUid: uid,				
				uPresupuesto: userData.presupuesto,
				uTiempoCompra: userData.tiempoCompra			
		}));	

	dispatch({ type: 'CLOSE_POPUP_PURCHASE' });
	dispatch({ type: 'EDIT_USER_PURCHASE', payload: response.data });
	return response.data;
	
}

export const editUserVehicles = (userData, uid, uMail) => async (dispatch) => {
	
	const response = await jsonMediagach.post('editUserVehicles.php',
		querystring.stringify({			
			uUid: uid,
			uMail: uMail,				
			uBrand: userData.brand,
			uModel: userData.model,
			uMatricula: userData.matriculaVehicle1,
			uAnyo: userData.yearVehicle1/*,
			uSellCar: userData.sellVehicle1		
			*/	
		}));

	dispatch({ type: 'CLOSE_POPUP' });
	dispatch({ type: 'EDIT_USER_VEHICLE', payload: response.data });
	return response.data;

}

export const userProfileSteps = (uid) => async (dispatch) => {

	const response = await jsonMediagach.post('userProfileCompleted.php',
		querystring.stringify({			
			uUid: uid
		}));

	return response.data;

}


export const updateVehiclesStatusSteppers = (message) => async (dispatch) => {

	dispatch({type: 'VEHICLES_UPDATE_STEP', payload: message });

}


export const updateAlertsStatusSteppers = (message) => async (dispatch) => {

	dispatch({type: 'ALERTS_UPDATE_STEP', payload: message });

}

export const initFilters = () => {
	return{
		type: 'INITIALIZE_FILTERS'
	};
	
};


export const fetchFilters = (uLocale) => async (dispatch) => {
	/*const response = await jsonMediagach.get('filtersFetch_servlet.php',{
			params: {
				uLocale: uLocale
			}
		});	*/

	const response = await jsonMediagach.post('filtersFetch_servlet.php',
		querystring.stringify({
			uLocale: uLocale
		
	}));
	return response.data;
};

export const fetchBrandsModelsRegions = (uLocale) => async (dispatch) => {

	const response = await jsonMediagach.post('brandsModelsFetch_servlet.php',
		querystring.stringify({		
			uLocale: uLocale					
	}));

	//const response = await jsonMediagach.get('brandsModelsFetch_servlet.php');	
	return response.data;
};

export const fetchBrandsModelsRegionsAlerts = (uLocale) => async (dispatch) => {

	const response = await jsonMediagach.post('brandsModelsRegionsFetch_servlet.php',
		querystring.stringify({		
			uLocale: uLocale					
	}));

	//const response = await jsonMediagach.get('brandsModelsFetch_servlet.php');	
	return response.data;

};


export const fetchBrands = () => async (dispatch) => {
	const response = await jsonMediagach.post('brandsList_servlet.php');	
	return response.data;
};

export const fetchCampaigns = (brand) => async (dispatch) => {
	const response = await jsonMediagach.post('brandsList_servlet.php');
	return response.data;
};

export const fetchEventInfo = (id, locale) => async (dispatch) => {
	//const response = await jsonMediagach.get(`php/servlet/eventsList_servlet.php?idEvent=${id}`);
	//dispatch({ type: 'FETCH_EVENT', payload: response.data });

	const response = await jsonMediagach.post('eventInfo_servlet.php',
		querystring.stringify({		
			idEvent: id,
			locale: locale					
	}));

	
	return response.data;
};

export const fetchEventsList = (uBrand, uRegion, uRange, uLocale) => async (dispatch) => {

	const response = await jsonMediagach.post('eventsList_servlet.php',
		querystring.stringify({		
			brand: uBrand,
			region: uRegion,
			range1: uRange[0],
			range2: uRange[1],
			locale: uLocale					
	}));

	return response.data;
};


export const modifyFilterBrands = (brand) => {
	return {
		type: 'SEARCH_BRANDS_FILTER',
		brandSelected: brand
	};
};

export const modifyFilterRegion = (region) => {
	return {
		type: 'SEARCH_REGION_FILTER',
		regionSelected: region
	};
};

export const modifyFilterPrice = (priceRange) => {
	return {
		type: 'SEARCH_PRICE_FILTER',
		priceRangeSelected: priceRange
	};
};


export const sendLeadNoLogged = (uName, uMail, uTlf, uAccepted, uCampaign, uTipusLead, uPoblacion, uSourceUrl, uProducte = '') => async (dispatch) => {
	
	const response = await jsonMediagach.post('sendLeadNoLogged.php',
			querystring.stringify({
				uNombre: uName,
				uMail: uMail,
				uTlf: uTlf,
				uAccepted: uAccepted,
				uEvent: uCampaign,
				uTipusLead: uTipusLead,
				uPoblacion: uPoblacion,
				uSourceUrl: uSourceUrl,
				uProducte: uProducte			
	}));

	/*const response = await jsonMediagach.get('sendLeadNoLogged.php',{
			params: {
				uNombre: uName,
				uMail: uMail,
				uTlf: uTlf,
				uAccepted: uAccepted,
				uEvent: uCampaign
			}
		});	
		*/
	//dispatch({ type: 'LEAD_NO_LOGGED', payload: response.data });
	return response.data;
};

export const sendLeadUser = (uUid, uName, uMail, uTlf, uCampaign, uTipusLead, uPoblacion, uSourceUrl, uProducte = '') => async (dispatch) => {
	
	const response = await jsonMediagach.post('sendLeadUser.php',
			querystring.stringify({
				uUid: uUid,
				uNombre: uName,
				uMail: uMail,
				uTlf: uTlf,
				uEvent: uCampaign,
				uTipusLead: uTipusLead,
				uPoblacion: uPoblacion,
				uSourceUrl: uSourceUrl,
				uProducte: uProducte			
	}));

	/*const response = await jsonMediagach.get('sendLeadUser.php',{
			params: {
				uUid: uUid,
				uNombre: uName,
				uMail: uMail,
				uTlf: uTlf,
				uEvent: uCampaign
			}
		});	*/
	//dispatch({ type: 'LEAD_NO_LOGGED', payload: response.data });
	return response.data;
};

export const sendLeadCallMe = (uUid, uName, uTlf, uCampaign, uSourceUrl, uProducte = '') => async (dispatch) => {
	const response = await jsonMediagach.post('sendLeadCallMe.php',
			querystring.stringify({
				uUid: uUid,
				uNombre: uName,
				uTlf: uTlf,
				uEvent: uCampaign,
				uSourceUrl: uSourceUrl,
				uProducte: uProducte			
	}));

	return response.data;

};


export const sendContactMail = (uName, uMail, uMessage, uQuestion) => async (dispatch) => {

	const response = await jsonMediagach.post('sendContactMail_servlet.php',
			querystring.stringify({
				uNombre: uName,
				uMail: uMail,
				uQuestion: uQuestion,
				uMessage: uMessage
			
	}));
	/*
	const response = await jsonMediagach.get('sendContactMail_servlet.php',{
			params:{
				uNombre: uName,
				uMail: uMail,
				uMessage: uMessage
			}
	});
	*/
	return response.data;

};

export const getUserData = (uUid, uLocale) => async (dispatch) => {
	const response = await jsonMediagach.post('getUserData.php',		
			querystring.stringify({		
				uUid: uUid,
				uLocale: uLocale		
	}));

	return response.data;

};


export const getUserInterests = (uUid) => async (dispatch) => {

	const response = await jsonMediagach.post('getUserInterests_servlet.php',		
			querystring.stringify({		
				uUid: uUid		
	}));

	/*const response = await jsonMediagach.get('getUserInterests_servlet.php',{		
			params:{	
				uUid: uUid
			}		
	});*/
	return response.data;

};

export const getUserAlerts = (uUid) => async (dispatch) => {

	const response = await jsonMediagach.post('getUserAlerts_servlet.php',		
			querystring.stringify({		
				uUid: uUid		
	}));
	
	return response.data;

};


export const saveInterests = (uUid, uModels, uRegions) => async (dispatch) => {
	
	const response = await jsonMediagach.post('saveUserInterests_servlet.php',		
			querystring.stringify({		
				uUid: uUid,				
				uModels: uModels.toString(),
				uRegions: uRegions.toString()						
	}));

	
	dispatch({ type: 'CLOSE_POPUP_ALERTS' });
	dispatch({type: 'EDIT_USER_ALERTS', payload: response.data });

	/*const response = await jsonMediagach.get('saveUserInterests_servlet.php',{		
			params:{	
				uUid: uUid,				
				uModels: uModels,
				uRegions: uRegions	
			}		
	});*/
	return response.data;
	
};


export const saveSearch = (uUid, uMarca, uRegion) => async (dispatch) => {

	const response = await jsonMediagach.post('saveUserSearch_servlet.php',		
			querystring.stringify({	
				uUid: uUid,				
				uMarca: uMarca,
				uRegion: uRegion	
					
	}));

	/*
	const response = await jsonMediagach.get('saveUserSearch_servlet.php',{		
			params:{	
				uUid: uUid,				
				uMarca: uMarca,
				uRegion: uRegion	
			}		
	});
	*/
	return response.data;

};

export const getUserVehicles = (uUid) => async (dispatch) => {

	const response = await jsonMediagach.post('getUserVehicles_servlet.php',
		querystring.stringify({		
			uUid: uUid					
	}));

	return response.data;
};


export const getUserProvider = (uUid) => async (dispatch) => {

	const response = await jsonMediagach.post('getUserProvider_servlet.php',
		querystring.stringify({
			uUid: uUid
		})
	);
	return response.data;

};

export const fetchEventsBlock = (uCampaign, uLocale) => async (dispatch) => {

	const response = await jsonMediagach.post('eventsListBlock_servlet.php',
		querystring.stringify({		
			campaign: uCampaign,
			locale: uLocale					
	}));

	return response.data;
};







export const createNewUserFromOutside = () => async (dispatch) => {
	
	const response = await jsonMediagach.get('brandsList_servlet.php');	
	return response.data;

}


export const fetchSliders = () => async (dispatch) => {
	//const response = await jsonWordpress.get('posts');
	const response = await jsonMediagach.get('php/servlet/eventsList_servlet.php');
	dispatch({ type: 'FETCH_POSTS', payload: response.data });
};





export const fetchFuturEvents = () => async (dispatch) => {
	//const response = await jsonWordpress.get('posts');
	const response = await jsonMediagach.get('php/servlet/futurEventsList_servlet.php');
	dispatch({ type: 'FETCH_FUTUR_EVENTS', payload: response.data });
};


export const openPopUpVehiclesForm = () => {	
	return{
		type: 'OPEN_POPUP'
	};
};

export const closePopUpVehiclesForm = () => {	
	return{
		type: 'CLOSE_POPUP'
	};
};

export const openPopUpPurchaseForm = () => {	
	return{
		type: 'OPEN_POPUP_PURCHASE'
	};
};

export const closePopUpPurchaseForm = () => {	
	return{
		type: 'CLOSE_POPUP_PURCHASE'
	};
};


export const openPopUpAlertsForm = () => {	
	return{
		type: 'OPEN_POPUP_ALERTS'
	};
};

export const closePopUpAlertsForm = () => {	
	return{
		type: 'CLOSE_POPUP_ALERTS'
	};
};


export const editDateAppointment = (uCode, uMessage) => async (dispatch) => {
		
	const response = await jsonMediagach.post('editDateAppointment_servlet.php',
			querystring.stringify({
				uCode: uCode,
				uMessage: uMessage
			
	}));
	
	return response.data;

};


export const cancelDateAppointment = (uCode, uMessage) => async (dispatch) => {
		
	const response = await jsonMediagach.post('cancelDateAppointment_servlet.php',
			querystring.stringify({
				uCode: uCode,
				uMessage: uMessage
			
	}));
	
	return response.data;

};


