import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import i18n from 'i18next';
import { withNamespaces } from 'react-i18next';

import MotorEventsList from './lists/MotorEventsList.jsx';
import EventsListFilters from '../components/Filters/EventsListFilters.jsx';
import UserMessage from '../components/UserMessage.jsx';
import NoAccessPage from './NoAccessPage.jsx';

import { initFilters, setCurrentPage } from '../actions';

//import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

import history from '../history';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/*import TagManager from 'react-gtm-module';

const tagManagerArgs = { 
	gtmId: 'GTM-5Q2Z7NJ',
    dataLayer: {        
        page: 'campaigns'
    },
    dataLayerName: 'PageDataLayer'
};

*/


class CampaignsListView extends React.Component{


	constructor(props) {

	    super(props);

	    this.state = {
	    	/*justify: 'center',*/
	    	styleRight: '',
	    	styleLeft: '',
	    	openMobileBanner: '',
	    	numImatge: Math.floor((Math.random() * 2)),
	    	numMessage: Math.floor((Math.random() * 3))
	    };
	    this.props.initFilters();	   
	    //this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	    //TagManager.dataLayer(tagManagerArgs);

	    this.props.setCurrentPage('list');

	}

	componentDidMount(){		
			
      	window.scrollTo(0, 0);
  		//window.addEventListener('resize', this.updateWindowDimensions);
  		window.addEventListener('scroll', this.handleScroll); 
	}

	componentWillUnmount() {
  		//window.removeEventListener('resize', this.updateWindowDimensions);
  		window.removeEventListener('scroll', this.handleScroll);

	}

	handleCloseBanner = () => {
		

    	this.setState({ openMobileBanner: 'mobile-wrapper-container-hidden' });
  	};

	handleScroll = event => {
			
		if(window.scrollY > 130){
			this.setState({ styleRight: 'container-banner-fixed', styleLeft: 'container-banner-fixed-left' });
		}else{
			this.setState({ styleRight: '', styleLeft: '' });
		}
		
	};

	/*
	updateWindowDimensions = () => {
		
		if(window.innerWidth>1720){
			this.setState({ justify: 'center' });
		}else{

			if(window.innerWidth<1280){
				this.setState({ justify: 'center' });
			}else{
				this.setState({ justify: 'flex-start' });
			}
		}
		
	}; 
	*/

	moveToHome = () => {
		history.push('/');
	};


	renderContent(){

		if(this.props.isSignedIn === null){
			return ( <div className="template-loading"><CircularProgress color="secondary" /></div> );
		}else if(this.props.isSignedIn === true){
				return ( 
					<div className="template-campaigns-list">		
						{/*<Grid container justify="center" alignItems="flex-start" >	
							<Grid item xs={12} sm={12} md={10} lg={8}>
								<UserMessage name={this.props.userName} numImatge={this.state.numImatge} numMessage={this.state.numMessage} />
							</Grid>
						</Grid>*/}
						
						<EventsListFilters />
						<Grid container justify="center" alignItems="flex-start" >	

							<Hidden mdDown>
								<Grid item xs={12} sm={12} md={2} lg={2} >
									<div className={`container-lateral-banner-left ${this.state.styleLeft}`}>									
										{/*<img src="https://cdn.mediagach.com/motor-privee/banner-lateral.jpg" />*/}
									</div>
								</Grid>
							</Hidden>
							<Grid item xs={12} sm={12} md={10} lg={8}>							
								<MotorEventsList />								
							</Grid>
							<Hidden mdDown>
								<Grid item xs={12} sm={12} md={2} lg={2}>
									<div className={`container-lateral-banner-right ${this.state.styleRight}`}>
										{/*<img src="https://cdn.mediagach.com/motor-privee/banner-lateral.jpg" />*/}
									</div>
								</Grid>
							</Hidden>
						</Grid>
						{/*
						<Hidden mdUp>
							<div className={`mobile-wrapper-container ${this.state.openMobileBanner}`}>
								<Grid container justify="center">
									<Grid item xs={12}>
										<div className="mobile-banner-container">
											<div onClick={this.handleCloseBanner}><FontAwesomeIcon icon={['fal', 'times']} /></div>
											<img className="banner-mobile" src="https://www.motorprivee.com/media/banner-superior.jpg" />
										</div>
									</Grid>
								</Grid>
							</div>
						</Hidden>
						*/}					
					</div>
				);
		}else{
				return (
					<div>
						{/*<Grid container justify="center" alignItems="center">
							<Grid item xs={12} sm={12} md={8} lg={6}>
								<div className="container-access-forbidden">
									<div className="content-access-forbidden">
										<div className="container-big-letters">Uuups...</div>
										<div className="container-text-forbidden">
											<p className="text-forbidden">{i18n.t('global.accessForbidden')}</p>
										</div>
									</div>
									<button onClick={this.moveToHome} className="btn btn1 ">{i18n.t('global.homePage')}</button>									
								</div>
							</Grid>
						</Grid>	
						*/}
						<NoAccessPage />
					</div>
				); 
		}

	}

	render(){
		return(
			<div>				
				{this.renderContent()}
			</div>
		);
	}


}


const mapStateToProps = (state) => {
	return { 
		isSignedIn: state.auth.isSignedIn,
		brandSelected: state.searchFilters.brandSelected,
		userName: state.auth.userName
	};
};

export default connect(
	mapStateToProps, { initFilters, setCurrentPage }
)(withNamespaces()(CampaignsListView));
